import { Component, OnInit,ViewChild, ɵConsole } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { TaskManagerService } from '../task-manager.service';
import { Router, ActivatedRoute, Params  } from "@angular/router";
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { SidebarService } from '../../shared/sidebar/sidebar.service';
import { formControlBinding } from '@angular/forms/src/directives/reactive_directives/form_control_directive';


@Component({
  selector: 'app-task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.scss']
})
export class TaskViewComponent implements OnInit {

  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};

  constructor(private location: Location,
    private TaskManagerService:TaskManagerService,
    private router: Router,
    private datePipe: DatePipe,
    private sidebarService : SidebarService) { }
  myForm: FormGroup;
  public key ='loginData';
  public myItem:any;
  public data:any = [];
  public permission;
  public projectlist: any = [];
  public projectlists:any=[];
  client : any;
  project :any;
  is_new:any;
  no_of_hour:any;
  no_of_minute:any
  public hours:any=["00","01","02","03","04","05","06","07","08","09"];
  public minutes:any=["00","05","10","15","20","25","30","35","40","45","50","55"]; 
  marked = false; 
  theCheckbox = false;
  myDate = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    
  ngOnInit() {
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.permission= this.myItem.is_admin == 1 ? this.myItem.is_admin : this.myItem.is_manager == 1 ? this.myItem.is_manager : 0;
    this.myForm = new FormGroup({
      'id': new FormControl(0)
     
    }, {updateOn: 'change'});
    this.getClientList();
    this.dtOptions = {
      //used id for index 
      order: [[0, "ASC"]],
    };
  }

  public backtotask()
  {
    this.router.navigate(['employee/task-manager']);
  }

  public  getProject(selectedValue:string){
      this.projectlists=[];
      this.TaskManagerService.getProjectList(selectedValue).subscribe(resp => {
        this.projectlists = resp.data;
      })
  }

  public viewTask(Id){
    this.router.navigate(['employee/emp-task/'+Id]);
  }

  public getClientList()
    {
      let myDate = this.datePipe.transform(new Date(),"yyyy-MM-dd");
      let company_id = this.myItem.company_id;
      let teamLeadId = this.myItem.id;
      let isAdmin = this.myItem.is_admin;
      this.TaskManagerService.getEmployeeList(company_id,teamLeadId,isAdmin).subscribe(resp => {
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp['success']) { 
          this.data = resp.data;
          this.data.filter(element=>{

            switch(element.attendance) { 
              case "P": { 
                element.class = 'bg-green';
                break; 
              } 
              case "A": { 
                element.class = 'bg-red';
                break; 
              }
              case "L": { 
                element.class = 'bg-blue';
                break; 
              } 
              case "H": { 
                element.class = 'bg-yellow';
                break; 
              } 
              case "W": { 
                element.class = 'bg-grey';
                break; 
              }
              case "S": { 
                element.class = 'bg-light-green';
                break; 
              }               
              default: { 
                element.class = 'bg-primary';
                break; 
              }
            }
          });
          this.rerender();
        }     
      });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
      });
    });
  }
}
