import { Component, OnInit ,ViewChild} from '@angular/core';
import { ClientService } from "../client/client.service";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { ModulePermissionModel } from "./module-permission.model";
import { Router, ActivatedRoute, Params  } from "@angular/router";



@Component({
  selector: 'app-module-permission',
  templateUrl: './module-permission.component.html',
  styleUrls: ['./module-permission.component.scss']
})
export class ModulePermissionComponent implements OnInit {

  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;
   myForm:FormGroup;
  permission:ModulePermissionModel = new ModulePermissionModel({});


  constructor(private clientService : ClientService,
             private location: Location,
             private route: ActivatedRoute,
             private router:Router,) { }

  public module:any = [];
  public selectedModule:any;
  public userId ;
  public permissionData ;
  public key ='loginData';
  public myItem:any;
  public company_id;
  public clientName;
  public parentId;
  public headerStatus = false;
  public customerHeader = false;
  public moduleNotFound = false;

  ngOnInit() {
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.selectedModule=[];
    this.myForm = new FormGroup({
      'moduleId': new FormControl(null),
      'permissionLevel': new FormControl(null, [Validators.required])
  }, {updateOn: 'change'});
  

      this.route.params.subscribe(
      (params: Params) => {
        this.company_id =params.id; 
        this.headerStatus = true;
      });

      let responseString=this.router.url;
      if(responseString.match("/customer"))
      {
        this.customerHeader = true;
      }
    
      $(document).ready(function(){
        $("#permissions").addClass("tabactive");
      });
      this.getClientName();
  }

  public onSubmit()
  {
  
     let formData: FormData = new FormData();
     formData.append('data' ,JSON.stringify(this.module));
     formData.append('companyId' ,this.company_id);
     this.clientService.addModulePermission(formData).subscribe(resp=>{
      if(resp.status == 401)
      {
          this.router.navigate([this.myItem.url]);
      }
      if(resp.success) { 
        this.clientService.permissionAssign();
        this.getMdule();
      //window.location.reload();
     }
    });

  }

  public getClientName()
  {
    let formData: FormData = new FormData();
    formData.append('company_id',this.company_id);
    this.clientService.getClientName(formData).subscribe(
      resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success){
          this.clientName = resp.items.company_name;  
          this.parentId = resp.items.parent_id;   
          this.getMdule();
        }
      }
    );
  }

  public getMdule()
   {

    let formData: FormData = new FormData();
    formData.append('company_id',this.company_id);
    formData.append('id',this.parentId);
     this.clientService.getModule(formData).subscribe(
       resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
         this.module = resp.items;
         if(this.module.length == 0){
           this.moduleNotFound = true;
         }
        }
     );

   }

   public backClicked() 
   {
    this.location.back();
   }


   

   selectPermission(e){   
     var found = false;
    if(this.selectedModule.length == 0){
      this.selectedModule.push({'moduleId':e.target.id,'permissionLevel':e.target.value,'userId' : this.userId});
    }else
    {
      const index = this.selectedModule.findIndex(res => res.moduleId === e.target.id);
      if(index > -1){      
        this.selectedModule[index].permissionLevel = e.target.value;
      }else{
        this.selectedModule.push({'moduleId':e.target.id,'permissionLevel':e.target.value,'userId' : this.userId});
      }
    }
   // console.log(this.selectedModule);
  }


  updateStatus(e,id){   
    
    if(e.target.checked){
      this.module[id].permission=1; 
    }else{
      this.module[id].permission=0;
    }
    this.module[id].created_by= this.myItem.id;
  }

  updatePermissionLevel(e,id){    
    if(e.target.value){
      this.module[id].permissionLevel=parseInt(e.target.value); 
    }else{
      this.module[id].permissionLevel=0;
    }
    this.module[id].created_by= this.myItem.id;

  }





//   selectPermission(e,i){   
//     this.module[i].permissionLevel=parseInt(e.target.value);
// }

}


