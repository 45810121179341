import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ClientService } from "../../client/client.service";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-client-list',
  templateUrl: './client-attendance-list.component.html',
  styleUrls: ['./client-attendance-list.component.scss']
})
export class ClientAttendanceListComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  public data:  any[] = [];
  public key ='loginData';
  public myItem:any;

  constructor(private clientService : ClientService,
    private router:Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.get_data();
   // this.rerender();
  }
 

  public get_data()
  { 
     this.clientService.get_data(this.myItem.company_id,this.myItem.role_id).subscribe(resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        this.data=resp.items;
        this.rerender();
    });
  }

  public viewEmployee(id:number)
  {
    this.router.navigate(['attendance/attendancelist',id]);
   // this.router.navigate(['employee/employeelist',id]);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        // For checked fields
        $('input[type=checkbox]', this.footer()).on('checked change', function () {
          if (this['value'] === 'true') {
            that.search(this['value']).draw();
          } else {
            that.search('').draw();
          }
        });

        $('input[type=text]', this.footer()).on('keyup change', function () {
          console.log("text : " + this['value']);
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
}
