import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PayrollsComponent} from './payrolls.component';

const routes: Routes = [
  {
    path: '', 
    
     children: [   
      {
        path: 'payrolls',
        component: PayrollsComponent,
        data: {
          title: 'payrolls'
        }
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  
})
export class PayrollsRoutingModule { }
export const PayrollsList = [
    PayrollsComponent
];
