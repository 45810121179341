export class DesignationModel {

   
    designation_name :string;
    created_by : number;
    status :number;
    company_id: number;
    id : number;
    counts : number;
   
    
  

    constructor(params:any) {
        const model = params || {};
        this.designation_name =model.designation_name || '';
        this.created_by =model.created_by || '';
        this.status =model.status || 1 ;
        this.company_id =model.company_id || '';
        this.id =model.id || 0;
        this.counts =model.counts || 0;
    }
}
