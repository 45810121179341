import { Component, OnInit } from '@angular/core';
//import { SidebarComponent } from './sidebar/sidebar.component';
//import { environment } from '../../environments/environment.prod';


@Component({
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor() { }
  ngOnInit() {
   // let myItem = localStorage.getItem(environment.userLoginKey);
  }

}
