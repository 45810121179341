import { Component, OnInit,ViewChild } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { SidebarService } from './sidebar.service';
import { LoginService } from '../../service/login.service';
import {HostListener} from "@angular/core";
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {

    @ViewChild("content") content;
    public menuItems: any;
    public key ='loginData';
    public myItem:any; 
    public window : any;
    public modalReference;
    public closeResult:string ;
    

    constructor(private router: Router,
        private sidebarService: SidebarService,
        private route: ActivatedRoute, public translate: TranslateService,
        private loginService : LoginService,
        private modalService: NgbModal) {
    }

        // @HostListener('window:beforeunload', ['$event'])
        //  beforeunloadHandler(event) { 
        //    // if(window.confirm('Are you sure you want to logout?')) { 
        //     //   alert('hiiii');          
        //       this.Logout();
        //    // }
        //   }
        
        
    ngOnInit() {
        this.myItem = JSON.parse(localStorage.getItem(this.key));
        $.getScript('./assets/js/app-sidebar.js');
      // this.menuItems = ROUTES.filter(menuItem => menuItem);
      
        // get menu from database
       this.menuItems = [];    
       this.getSidebar(this.myItem.id);

       //console.log(this.router.url);


    //    window.onbeforeunload = function (event)  {
    //     console.log(event);
    //     var message = 'Important: Please click on \'Save\' button to leave this page.';
    //     if (typeof event == 'undefined') {
    //         event = window.event;
    //     }
    //     if(event) {
    //         event.returnValue = message;
    //     }     
    //      return message;
    //   }; 

    //   $(".btn").click(function (event) {
    //     window.onbeforeunload = null;
        

    //     });
        // $(function () {
        //     $("a").not('#lnkLogOut').click(function () {
        //         window.onbeforeunload = null;
               
        //     });
        //     $(".btn").click(function () {
        //       ///  window.onbeforeunload = null;
        //         this.Logout();
        // });
        // }); 
        
       
    }
    

    // ngOnDestroy(): void {
    //    this.Logout();
    // }
    
    // public Logout()
    // {   
    //         var fd:FormData = new FormData();
    //         fd.append('userId',this.myItem.id);
    //         this.loginService.logout(fd).subscribe(resp=>{
    //         });
    //         localStorage.removeItem('loginData');
    //         this.router.navigate(['/']);  
    // }
    
    // ** get menu from database
    public getSidebar(userId)
    {

        //console.log(this.myItem.sidebarPath);
        
        this.sidebarService.getSidebar(userId).subscribe(resp=>{

            if(resp.status == 401)
            {
                this.router.navigate([this.myItem.url]);
            }


            if(resp.success){

                this.menuItems = resp['items']; 

                //first time add active url path to has-sub class
                this.menuItems.forEach(elm => {
                    if(elm.class == 'has-sub')
                    {
                        elm.path = this.router.url;  
                    }
        
                });
    
                this.menuItems.forEach(element => {
                    let responseString=this.router.url;
                    if(responseString == element.path || this.myItem.sidebarPath ==element.path)
                    {   
                        if(element.class == 'has-sub')
                        {
                            element.class = 'has-sub';
    
                            if(element.submenu)
                            {
                                element.submenu.forEach(ele => {
                                   // console.log(ele);
    
                                    if(ele.path == this.myItem.sidebarPath)
                                    {
                                        ele.class = 'active';
                                    }
                                    else
                                    {
                                        ele.class = ''; 
                                    }
                                    
                                });
                            }
                        }
                        else
                        {
                            element.class = 'active';
                        }
                       
                    } 
                });

            }

           


           // console.log(this.menuItems);
        });
         
        this.sidebarService.getPermission(userId).subscribe(resp=>{
            this.sidebarService.setUserPermission(resp['items']);
        });
     }


    public getModuleId(val,id?,path?){
        
        this.menuItems.forEach(elm => {
            if(elm.class == 'has-sub')
            {
                elm.path = path;  
            }

        });

        this.myItem.sidebarPath = path;
        localStorage.setItem(this.key, JSON.stringify(this.myItem));

        this.menuItems.forEach(element => {
            if(element.moduleId == val){
                
                if(element.class =='has-sub')
                {
                    element.class =='has-sub';
                    if(element.submenu)
                    {
                       
                        element.submenu.forEach(ele => {
                            this.myItem.sidebarPath = path;
                            localStorage.setItem(this.key, JSON.stringify(this.myItem));
                             if(ele.id == id || ele.path == this.myItem.sidebarPath)
                            {
                                ele.class = 'active';
                            }
                            else
                            {
                                ele.class ='';
                            }    
                              
                        });
                    } 
                }
                else
                {
                     element.class ='active';
                }
            }
            else
            {
            
                if(element.class == 'has-sub')
                {
                    element.class ='has-sub';
                }
                else{
                    element.class = '';
                }
            }
          });

         // console.log(this.menuItems);
        

        var permission = this.sidebarService.getUserPermission().find(function(element) {
            if(element.moduleId==val){
                return element;
            }
          });
        this.sidebarService.setCurrentPermission(permission.permissionLevel);
    }
   // ** get menu from database
    
    public adminDashboard()
    {
        var path = '/admin/dashboard';
        this.menuItems.forEach(element => {
            if( path == element.path)
            {   
                element.class = 'active';
            } 
            else
            {
                element.class = '';
            }
        });
        this.router.navigate(['admin/dashboard']);
      
    }

    public employeeDashboard()
    {
        var path = '/employee/dashboard';
        this.menuItems.forEach(element => {
            if( path == element.path)
            {   
                element.class = 'active';
            } 
            else
            {
                element.class = '';
            }
        });
        this.router.navigate(['employee/dashboard']);
       
    }
    //NGX Wizard - skip url change
    ngxWizardFunction(path: string) {
        if (path.indexOf('forms/ngx') !== -1)
            this.router.navigate(['forms/ngx/wizard'], { skipLocationChange: false });
    }
   
    

    public openModel(content) {   
      
        this.modalReference = this.modalService.open(content);
        this.modalReference.result.then((result) => {
            this.closeResult = `Closed with: ${result}`;       
        });
    }

      
}


