import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ClientService } from "../client/client.service";
import { DocumentService } from "./document.service";
import { EmployeeService } from "../employee/employee.service";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { DocumentCategoryService } from "../document-category/document-category.service";
import { element } from '@angular/core/src/render3/instructions';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { DepartmentsService } from '../departments/departments.service';
import { DesignationService } from '../designation/designation.service';
import { BranchService } from '../branch/branch.service';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { SidebarService } from '../shared/sidebar/sidebar.service';


@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  @ViewChild("addDocumentModal") private addDocumentModal;
  @ViewChild("EmployeeFileModal") private employeeFileModal;
  @ViewChild("EmployeeFileViewModal") private employeeFileViewModal;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  dtOptionsForEmployee: DataTables.Settings = {};
  myForm: FormGroup;
  public data:  any[] = [];
  public data1:  any[] = [];
  public key ='loginData';
  public myItem:any;
  public editStatus = true;
  public status = false;
  public employeeDocuments:any = [];
  public id;
  public salaryData: any[] = [];
  public openStatus: number;
  public categoryName = 'No category selected';
  public selectedCategoryId = 0;
  public accessLevel = 0;
  public documentModalRef;
  public selectedFile;
  public documents:any=[];
  public employeeList:any=[];
  public company_id;
  public departments: any = [];
  public departmentList: any = [];
  public designation: any = [];
  public designationList: any = [];
  public branches: any = [];
  public brancheList: any = [];
  public selectedDepartments: any = [];
  public selectedDesignations: any = [];
  public selectedBranches: any = []; 
  public designationAllSelected = false;
  public departmentAllSelected = false;
  public branchAllSelected = false;
  public employeeFileModalref;
  public employeeFileViewModalref;
  public userId = 0;
  public roleId;
  public currentDocumentCateogryData;
  public employeeName;
  public isEmployee = false;
  public permission;

  constructor(private clientService : ClientService,
    private documentService : DocumentService,
    private router:Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private documentCategoryService : DocumentCategoryService,
    private modalService: NgbModal,
    private employeeService:EmployeeService,
    private departmentsService: DepartmentsService,
    private designationService:DesignationService,
    private branchService:BranchService,
    private sidebarService : SidebarService) { }

  ngOnInit() {
    this.permission= this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));

    this.activatedRoute.params.subscribe(
      (params: Params) => {
        if (params.id) {
          this.company_id = params.id;
        } else {
        this.company_id = this.myItem.company_id;
        this.userId = this.myItem.id;
        this.roleId = this.myItem.role_id;
        this.isEmployee= false;
        }

        if(params.companyId){
          this.company_id = params.companyId;
          this.userId = params.userId;
          this.roleId = 5;
          this.isEmployee = true;
        }
      }
    );

    this.getDepatment();
    this.getDesignation();
    this.getBranch();
    this.getData();    


    this.myForm = new FormGroup({
      'description': new FormControl(''),
      'isEnabled': new FormControl(1),
      'id': new FormControl(0),

    }, { updateOn: 'change' });

    this.dtOptions = {
      order: [[0, "asc"]],
    };

    this.dtOptionsForEmployee = {
      order: [[0, "ASC"]],
    };
  }
   
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        // For checked fields
        $('input[type=checkbox]', this.footer()).on('checked change', function () {
          if (this['value'] === 'true') {
            that.search(this['value']).draw();
          } else {
            that.search('').draw();
          }
        });

        $('input[type=text]', this.footer()).on('keyup change', function () {
          console.log("text : " + this['value']);
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
  
  public backClicked()
  {
    this.location.back();
  }
  
  public getData()
  {
      let formData: FormData = new FormData();
      formData.append('role_id',this.myItem.role_id);
      formData.append('company_id',this.company_id);
      formData.append('flag','add');
      this.documentCategoryService.getCategory(formData).subscribe(resp=>{
       if(resp.status == 401)
       {
           this.router.navigate([this.myItem.url]);
       }
       this.data=resp.items;
      //  this.data1 = this.data.filter(
      //   book => book.parent_id === 0);
      //   this.data1.filter(element=>{
      //     element.sub_category =  this.data.filter(ele=>ele.parent_id == element.id);
      //   });
    });
 }
 // new 

  public openPanel(val) {

    this.activeClass(val);
    if (this.openStatus === val.id) {
      this.openStatus = 0;
    } else {
      this.openStatus = val.id;
    }
  }

  public activeClass(val)
  {
  
    this.currentDocumentCateogryData = val;
     this.filterLists(val);
    this.data.filter(element=>{
      if(element.id == val.id){

        if(element.sub_category ==''){
          if(element.activeClass =='selected'){
            element.activeClass='';
            this.categoryName='No category selected';
            this.selectedCategoryId = 0;
            this.accessLevel = 0;
          //  this.getDocumentNew();
          //  this.getEmployee();
          } else {
            this.categoryName=element.category_name;
            element.activeClass = 'selected';
           //this.get_document(val.id);
            this.selectedCategoryId = element.id;
            this.accessLevel = element.access_level;

            // if(this.myItem.role_id == 5 || this.isEmployee){
            //  // this.getEmployeeDocument(this.myItem.id);
            //   this.accessLevel =1;
            // }

            this.getDocumentNew();
            if(element.access_level ==2 && this.roleId !=5){
              this.getEmployee();
            }                             
          }
        }      
      } else {
        element.activeClass='';
      }

      element.sub_category.filter(ele=>{
        if(ele.id == val.id){
          if(ele.activeClass =='selected'){
            ele.activeClass='';
            this.categoryName='No category selected';
            this.selectedCategoryId =0;
            this.accessLevel = 0;
            //this.getDocumentNew();
           // this.getEmployee();
          } else {
            this.categoryName=ele.category_name;
            ele.activeClass = 'selected';
          //  this.get_document(val.id);
            this.selectedCategoryId = ele.id;
            this.accessLevel = ele.access_level;

            // if(this.myItem.role_id == 5 || this.isEmployee){
            //   this.accessLevel = 1;
            // //  this.getEmployeeDocument(this.myItem.id);
            // } 
            // else {
            
            // }

            this.getDocumentNew();
            if(element.access_level ==2 && this.roleId !=5){
              this.getEmployee();
            }
           
            //this.getEmployee();
          }
        } else {
          ele.activeClass='';
        }
      });
    });
  }

  private filterLists(val){

    this.brancheList =[];
    this.departmentList=[];
    this.designationList=[];
    if (val.department != '' && val.department !=null) {
      this.selectedDepartments=[];
      if(val.department == 0){
        this.departments.filter(element=>{
          element.isSelected = 1;
          this.selectedDepartments.push(element.id);
          this.departmentList.push(element);
        });        
      } else {
        this.selectedDepartments = val.department.split(',');
        this.selectedDepartments.filter(ele=>
        {
          this.departments.filter(element => {
            if(element.id == ele){
              element.isSelected =1;
              this.departmentList.push(element);
            }
          });
        });
      }
    }

    if (val.locations != '' && val.locations !=null) {
      this.selectedBranches=[];
      if(val.locations == 0){
        this.branches.filter(element=>{
          element.isSelected = 1;
          this.selectedBranches.push(element.id);
          this.brancheList.push(element);
        });
      } else {
        this.selectedBranches = val.locations.split(',');
        this.selectedBranches.filter(ele=>
        {
          this.branches.filter(element => {
            if(element.id == ele){
              element.isSelected =1;
              this.brancheList.push(element);
            }
          });
        });
   
      }
    }

    if (val.designations != '' &&  val.designations !=null) {
      this.selectedDesignations = [];      
      if(val.designations == 0){
        this.designation.filter(element=>{
          element.isSelected = 1;
          this.selectedDesignations.push(element.id);
          this.designationList.push(element);
        });
      } else {
        this.selectedDesignations = val.designations.split(',');
        this.selectedDesignations.filter(ele=>
        {
          this.designation.filter(element => {
            if(element.id == ele){
              element.isSelected =1;
              this.designationList.push(element);
            }
          });
        });
      }
    }

    this.designationAllSelected = true;
    this.departmentAllSelected = true;
    this.branchAllSelected = true;
  }

  public openDocumentModal()
  {
    this.status = false;
    this.filterLists(this.currentDocumentCateogryData);
     this.editStatus = true;
     this.myForm.get('isEnabled').setValue(1);
     this.myForm.get('description').setValue('');
     this.myForm.get('id').setValue(0);
   
    this.documentModalRef = this.modalService.open(this.addDocumentModal,{size:'lg'});
  }

  public uploadFile(fileInput: any){
    this.selectedFile = fileInput.target.files[0];
  }

  public addOrganizationDocument()
  {
    let formData: FormData = new FormData();
    formData.append('created_by' ,this.myItem.id);
    formData.append('documentType' ,this.selectedCategoryId.toString());
    formData.append('userId','0');
    formData.append('company_id' ,this.company_id);
    formData.append('departments' ,this.selectedDepartments.toString());
    formData.append('designations' ,this.selectedDesignations.toString());
    formData.append('branches' ,this.selectedBranches.toString());
    formData.append('isEnabled', this.myForm.get('isEnabled').value);
    formData.append('description',this.myForm.get('description').value);
    formData.append('document' , this.selectedFile);
    if(this.myForm.get('id').value == 0){

      if(!this.selectedFile){
       this.status = true;
       return;
      }

      this.documentService.insertOrganizationDocument(formData).subscribe(resp=>{
        if(resp.status == 401)
        {
          this.router.navigate([this.myItem.url]);
        }
        if(resp.success){
          this.resetAll();
          this.documentModalRef.dismiss();
          this.documentService.insertSuccess(resp.message);
        }
      }); 
    } else {
      formData.append('document_id' ,this.myForm.get('id').value);
      this.documentService.updateOrganizationDocument(formData).subscribe(resp=>{
        if(resp.status == 401)
        {
          this.router.navigate([this.myItem.url]);
        }
        if(resp.success){
          this.resetAll();
          this.documentModalRef.dismiss();
          this.documentService.insertSuccess(resp.message);
        }
      }); 
    } 
  }

  public getDocumentNew()
  {
    let formData: FormData = new FormData();
    formData.append('user_id' , this.userId.toString());
    formData.append('company_id' , this.company_id);
    formData.append('documentType' ,this.selectedCategoryId.toString());
    formData.append('role_id' , this.roleId);
    formData.append('accessLevel' , this.accessLevel.toString());
    this.documentService.getOrganizationDocument(formData).subscribe(resp=>{
     if(resp.status == 401)
     {
         this.router.navigate([this.myItem.url]);
     }
     if(resp.success){
      this.documents = resp.items;
     // this.rerender();
     } else {
      this.documents = [];
     // this.rerender();
     }
    }); 
  }

  public getEmployee()
  {
     this.employeeService.getdata(this.company_id).subscribe(resp=>{
      if(resp.status == 401)
      {
          this.router.navigate([this.myItem.url]);
      }
      if(resp.success){
        this.employeeList=resp.items;
        this.rerender();
      } else {
        this.employeeList=[];
      }
     
    });
  }

  public resetAll()
  {
    this.selectedFile ='';
    this.getDocumentNew();
    this.myForm.get('isEnabled').setValue(1);
    this.myForm.get('description').setValue('');
    this.myForm.get('id').setValue(0);
    this.selectedBranches=[];
    this.selectedDepartments=[];
    this.selectedDesignations = [];
  }

  public Delete(id: number, i: number)
    {
      if(window.confirm('Are you sure you want to delete?')) {
        this.documentService.delete(id).subscribe(resp=>{
          if(resp.status == 401)
          {
              this.router.navigate([this.myItem.url]);
          }
          if(resp.success){
            this.documentService.deleteSuccess();
           this.documents.splice(i, 1);  
           this.rerender();
          }
        });
      }
    }

  public getDepatment() {
    this.departmentsService.getDepartment(this.company_id).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      this.departments = resp.items;
    });
  }

  public updateDepartments(e, i) {

    if(e.target.checked){
      this.departmentList.filter(element=>
      {
        if(element.id == e.target.value){
          element.isSelected = 1;
          this.selectedDepartments.push(e.target.value);
        }
      });
    } else {
      this.departmentList.filter(element=>
      {
        if(element.id == e.target.value){
          element.isSelected = 0;
          if(this.selectedDepartments.length > 0){
            var index = this.selectedDepartments.indexOf(e.target.value);
            if (index < 0) {
              this.selectedDepartments.splice(index, 1);
            }
          }
        }
      });
    }

    this.departmentAllSelected = true;
    this.selectedDepartments =[];
    this.departmentList.forEach(element => {
      if(element.isSelected == 0){
        this.departmentAllSelected = false;
      } else {
        this.selectedDepartments.push(element.id);
      }
    });

    // if(this.departmentAllSelected == true){
    //   this.selectedDepartments = 0;
    // }
    
  }

  public getDesignation() {    
    this.designationService.getDesignation(this.company_id).subscribe(
      resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
          this.designation = resp.items;
        });     
  }

  public updateDesignations(e, i) {

    if(e.target.checked){
    this.designationList.filter(element=>
      {
        if(element.id == e.target.value){
          element.isSelected = 1;
          this.selectedDesignations.push(e.target.value);
        }
      });
    } else {
      this.designationList.filter(element=>
      {
        if(element.id == e.target.value){
          element.isSelected = 0;
          if(this.selectedDesignations.length > 0){
            var index = this.selectedDesignations.indexOf(e.target.value);
            if (index < 0) {
              this.selectedDesignations.splice(index, 1);
            }
          }
        }
      });
    }

    this.designationAllSelected = true;
    this.selectedDesignations =[];
    this.designationList.forEach(element => {
      if(element.isSelected == 0){
        this.designationAllSelected = false;
      } else {
        this.selectedDesignations.push(element.id);
      }
    });

    // if(this.designationAllSelected == true){
    //   this.selectedDesignations = 0;
    // }
  
  }

  public getBranch()
  { 
     this.branchService.get_data(this.company_id).subscribe(resp=>{
      if(resp.status == 401)
      {
        this.router.navigate([this.myItem.url]);
      }
      if(resp.success){
        this.branches=resp.items.branches;
      }
    });
  }

  public updateBranches(e)
  {
    if(e.target.checked){
      this.brancheList.filter(element=>
        {
          if(element.id == e.target.value){
            element.isSelected = 1;
            this.selectedBranches.push(e.target.value);
          }
        });
    } else {
      this.brancheList.filter(element=>
      {
        if(element.id == e.target.value){
          element.isSelected = 0;
          if(this.selectedBranches.length > 0){
            var index = this.selectedBranches.indexOf(e.target.value);
            if (index < 0) {
              this.selectedBranches.splice(index, 1);
            }
          }
        }
      });
    }

    this.branchAllSelected = true;
    this.selectedBranches =[];
    this.brancheList.forEach(element => {
      if(element.isSelected == 0){
        this.branchAllSelected = false;
      } else {
        this.selectedBranches.push(element.id);
      }
    });

    // if(this.branchAllSelected == true){
    //   this.selectedBranches = 0;
    // }
  }

  public updateAll(e,flag)
  {
    if(flag =='designation'){
      this.selectedDesignations = [];
      if(e.target.checked){
        this.designationList.filter(element=>{
          element.isSelected = 1;
          this.selectedDesignations.push(element.id);
        });
        this.designationAllSelected = true;
      } else {
         this.designationList.filter(element=>{
          element.isSelected = 0;
        });
        this.selectedDesignations = [];
        this.designationAllSelected = false;
      }
    }

    if(flag =='department'){
      this.selectedDepartments=[];
      if(e.target.checked){
        this.departmentList.filter(element=>{
          element.isSelected = 1;
        this.selectedDepartments.push(element.id);
        });
         this.departmentAllSelected = true;
      } else {
         this.departmentList.filter(element=>{
          element.isSelected = 0;
        });
        this.selectedDepartments = [];
        this.departmentAllSelected = false;
      }
    }

    if(flag =='branch'){
      this.selectedBranches =[];
      if(e.target.checked){
        this.brancheList.filter(element=>{
          element.isSelected = 1;
          this.selectedBranches.push(element.id);
        });
         this.branchAllSelected = true;
      } else {
         this.brancheList.filter(element=>{
          element.isSelected = 0;
        });
        this.selectedBranches = [];
        this.branchAllSelected = false;
      }
    }
  }

  public editData(val)
  {
    this.status = false;
    this.documentModalRef = this.modalService.open(this.addDocumentModal,{size:'lg'});
    this.editStatus = false;
    this.myForm.get('isEnabled').setValue(val.isEnabled);
    this.myForm.get('description').setValue(val.description);
    this.myForm.get('id').setValue(val.id);

      // console.log(this.departmentList);
      // console.log(this.designationList);
      // console.log(this.brancheList);

    if (val.departments != '' && val.departments != null) {
      this.selectedDepartments = val.departments.split(',');


       this.departmentList.filter(element=>
        {
          let department = this.selectedDepartments.filter(entity => entity== element.id).length;
          if(department){
            element.isSelected =1;
          } else {
            element.isSelected =0;
            this.departmentAllSelected = false;
          }
          // console.log('here');
          // this.selectedDepartments.filter(element => {
          //   if(element.id == ele){
          //     ele.isSelected =1;
          //   } 
          //     else{
          //       ele.isSelected =0;
          //     this.departmentAllSelected = false;
          //   }
          // });
      });
    } else {
      this.departmentAllSelected = false;
      this.departmentList.filter(element => {
          element.isSelected =0;
        });
    }
    
    if (val.designations != '' && val.designations != null) {
      this.selectedDesignations = val.designations.split(',');
       this.designationList.filter(element=>
        {

          let designation = this.selectedDesignations.filter(entity => entity== element.id).length;
          if(designation){
            element.isSelected =1;
          } else {
            element.isSelected =0;
            this.designationAllSelected = false;
          }
          // this.designationList.filter(element => {
          //   if(element.id == ele){
          //     element.isSelected =1;
          //   } else {
          //     element.isSelected =0;
          //     this.designationAllSelected = false;
          //   }
          // });
      });
    } else {
      this.designationAllSelected = false;
      this.designationList.filter(element => {
        element.isSelected =0;
      });
    }

    if (val.branches != '' && val.branches != null) {
      this.selectedBranches = val.branches.split(',');
       this.brancheList.filter(element=>
        {
          let branch = this.selectedBranches.filter(entity => entity== element.id).length;
          if(branch){
            element.isSelected =1;
          } else {
            element.isSelected =0;
            this.branchAllSelected = false;
          }
          // this.brancheList.filter(element => {
          //   if(element.id == ele){
          //     element.isSelected =1;
          //   } else {
          //     element.isSelected =0;
          //     this.branchAllSelected = false;
          //   }
          // });
      });
    } else {
      this.branchAllSelected = false;
      this.brancheList.filter(element => {
          element.isSelected =0;
        });
    }
  }

  //employee 

  public addEmployeeDocument()
  {
    let formData: FormData = new FormData();
    formData.append('created_by' , this.myItem.id);
    formData.append('documentType' ,this.selectedCategoryId.toString());
    formData.append('userId',this.userId.toString());
    formData.append('company_id' ,this.company_id);
    formData.append('isEnabled', this.myForm.get('isEnabled').value);
    formData.append('description',this.myForm.get('description').value);
    formData.append('document' , this.selectedFile);

    if(this.myForm.get('id').value == 0)
    {
      if(!this.selectedFile){
        this.status = true;
        return;
      }
      this.documentService.insertEmployeeDocument(formData).subscribe(resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success){
          this.resetAll();
          this.documentService.insertSuccess(resp.message);
          this.employeeFileModalref.dismiss();
          this.getEmployeeDocument(this.userId);

        }
      }); 
    } else {
      formData.append('document_id' ,this.myForm.get('id').value);
      this.documentService.updateEmployeeDocument(formData).subscribe(resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success){
          this.resetAll();
          this.documentService.insertSuccess(resp.message);
          this.employeeFileModalref.dismiss();
           this.getEmployeeDocument(this.userId);
        }
      }); 
    }   
  }

  public openEmployeeFileUploadModal(val)
  {
    this.userId = val.id;
    this.employeeName = val.first_name +' '+ val.last_name;
    this.editStatus = true;
    this.resetAll();
    this.employeeFileModalref = this.modalService.open(this.employeeFileModal,{size:'lg'});
  }

  public openFileViewModal(val)
  {
    this.employeeName = val.first_name +' '+ val.last_name;
    this.getEmployeeDocument(val.id);
    this.employeeFileViewModalref = this.modalService.open(this.employeeFileViewModal,{size:'lg'});    
  }

  public getEmployeeDocument(id)
  {
    let formData: FormData = new FormData();
    formData.append('user_id',id);
    formData.append('category_id',this.selectedCategoryId.toString());
      this.documentService.getEmployeeDocument(formData).subscribe(resp=>{
      if(resp.status == 401)
      {
        this.router.navigate([this.myItem.url]);
      }
      if(resp.success){
        this.employeeDocuments=resp.items;
        //this.documents = resp.items;
      } else {
        this.employeeDocuments = [];
        //this.documents = [];
      }
    });
  }

  public editEmployeeDocument(val)
  {
    //this.employeeName = val.first_name +' '+ val.last_name;
    this.editStatus = false;
    this.status = false;
    this.selectedDepartments ='';
    this.selectedDesignations ='';
    this.selectedBranches ='';
    this.userId = val.userId;
    this.myForm.get('description').setValue(val.description);
    this.myForm.get('isEnabled').setValue(val.isEnabled);
    this.myForm.get('id').setValue(val.id);
    this.employeeFileModalref = this.modalService.open(this.employeeFileModal,{size:'lg'});
  }
}
