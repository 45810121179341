import { Component, ViewChild,ElementRef } from '@angular/core';
import { NgForm, FormControl, FormGroup, Validators, } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from "../service/login.service";
import * as myGlobals from '../globals';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})

export class LoginPageComponent {
    public username: string;
    public password: string;
    public data = sessionStorage.getItem('session');
    public user_id;

    @ViewChild('f') loginForm: NgForm;
    @ViewChild('vform') validationForm: FormGroup;
    @ViewChild("content") content;
    @ViewChild("authModal") authModal;

    myForm: FormGroup;
    myForm1: FormGroup;
    qrCodeForm: FormGroup;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private loginService: LoginService,
        private modalService: NgbModal,
        private el: ElementRef) { }

    public value;
    public key = 'loginData';
    public key1 = 'permission';
    public module_permission;

    //for otp modal
    public closeResult: string;
    public otp;
    public userId;
    public status;
    public showModal = false;
    public modalReference;
    public  _shown = false;
    public Email = true;
    public EmployeeCode=false;
    public AuthModalref;
    public message;
    public href;
    public customeLogin = false;
    //for otp modal


    ngOnInit() {

        $.getScript('./assets/js/vertical-timeline.js');
        
        var fd: FormData = new FormData();
        fd.append('url', this.router.url);
        this.loginService.verifyUrl(fd).subscribe(resp => {

            if(resp.success){
                let id = 'login_'+resp.items.id;
                $(document).ready(function(){
                    $(".test").addClass(id);
                });
            }else {
            $(document).ready(function(){
                $(".test").addClass("login");
            }); 
            }
        });
       
        
        this.myForm = new FormGroup({
            'username': new FormControl(null, [Validators.required]),
            'password': new FormControl(null, [Validators.required]),

        }, { updateOn: 'change' });

        this.myForm1 = new FormGroup({
            'otp': new FormControl(null, [Validators.required]),
        }, { updateOn: 'change' });

        this.qrCodeForm = new FormGroup({
            'qrCode': new FormControl(null, [Validators.required]),
        }, { updateOn: 'change' });
    }

    public onSubmit(flag) {

        var fd: FormData = new FormData();
        fd.append('username', this.myForm.get('username').value);
        fd.append('password', this.myForm.get('password').value);
        fd.append('appType', 'WEB');
        fd.append('flag', flag);
        this.loginService.checkLogin(fd).subscribe(resp => {

            if (resp.success) {

                resp.items.url = this.router.url;
                if(resp.items.google_secrate_code){                
                    this.open2factorAuthModal();
                    this.userId = resp.items.id;
                } else {

                    this.loginService.typeSuccess(resp.message);
                    this.loginService.getPermission(resp.items.id).subscribe(resp_permission => {
                        localStorage.setItem(this.key1, JSON.stringify(resp_permission.items));
                    });
                    if (resp.items.role_id == 1) {

                        localStorage.setItem(this.key, JSON.stringify(resp.items));                   
                        this.router.navigate(['admin/dashboard']);
                    }
                    else if (resp.items.role_id != 1) {
                    
                        localStorage.setItem(this.key, JSON.stringify(resp.items));
                        this.router.navigate(['employee/dashboard']);
                    }
                }             
            }
            else {
                 
                this.loginService.InvalidUser(resp.message);
                this.router.navigate([this.router.url]);
            
                // if (resp.total_count == 0) {
                //   this.loginService.InvalidUser(resp.message);
                //   this.router.navigate(['login']);
                //  }

                //for otp 
                // if(resp.total_count == 2)
                // {
                //     this.loginService.alreadyLoginError();
                //    // var content ;
                //      this.openModel(this.content);
                //     this.userId = resp.items.id;
                //     this.router.navigate(['login']);

                // }
            }
        });
    }

    public open2factorAuthModal() {
        this.AuthModalref = this.modalService.open(this.authModal);
    }
    
    public openModel(content) {
        this.otp = '';
        this.modalReference = this.modalService.open(content);
        this.modalReference.result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        });
    }

    onForgotPassword() {
        // On Forgot password link click
        this.router.navigate(['forgotpassword'], { relativeTo: this.route.parent });
    }
    // On registration link click
    onRegister() {
        this.router.navigate(['register'], { relativeTo: this.route.parent });
    }


    public clear() {
        this.username = '';
        this.password = '';
    }


    public sendOtp() {
        var fd: FormData = new FormData();
        fd.append('otp', this.otp);
        fd.append('userId', this.userId);
        fd.append('appType', 'WEB');
        this.loginService.sendOtpForLogin(fd).subscribe(resp => {
            if (resp.total_count) {
                this.showModal = true;
                this.loginService.typeSuccess(resp.message);
                this.modalReference.close(this.closeResult);
                this.loginService.getPermission(resp.items.id).subscribe(resp_permission => {
                    localStorage.setItem(this.key1, JSON.stringify(resp_permission.items));
                });
                if (resp.items.role_id == 1) {

                    localStorage.setItem(this.key, JSON.stringify(resp.items));
                    this.router.navigate(['admin/dashboard']);
                    // this.router.navigate(['admin/das']);
                }
                else if (resp.items.role_id == 2 || resp.items.role_id == 5 || resp.items.role_id == 3 || resp.items.role_id == 4) {
                    localStorage.setItem(this.key, JSON.stringify(resp.items));
                    this.router.navigate(['employee/dashboard']);
                }
            }
            else {
                this.status = true;
            }
        });
    }

    toggle() {
        this._shown = !this._shown;
        if (this._shown) {
            this.el.nativeElement.setAttribute('type', 'text');
            $.getScript('./assets/js/vertical-timeline.js');
            $(document).ready(function () {
                $(".ft-eye").addClass("color-blue");
                $(".ft-eye").removeClass("color-black");
            });
            //span.innerHTML = 'Hide password';
        } else {
            this.el.nativeElement.setAttribute('type', 'password');
            $(document).ready(function () {
                $(".ft-eye").addClass("color-black");
                $(".ft-eye").removeClass("color-blue");
            });
            //span.innerHTML = 'Show password';
        }
    }

    public loginWith(flag)
    {
        console.log(flag);
        if(flag=='Email'){
            this.Email=true;
            this.EmployeeCode=false;
            this.myForm.reset();
        }

        if(flag=='EmployeeCode'){
            this.Email=false;
            this.EmployeeCode=true;
            this.myForm.reset();
        }
    }

    public verifyCode() {

        var fd: FormData = new FormData();
        fd.append('user_id', this.userId);
        fd.append('code', this.qrCodeForm.get('qrCode').value);
        fd.append('appType', 'WEB');
        this.loginService.verifyCodeForLogin(fd).subscribe(resp => {
            if (resp.success) {
                resp.items.url = this.router.url;
                this.AuthModalref.dismiss();
                this.loginService.typeSuccess(resp.message);
                this.loginService.getPermission(resp.items.id).subscribe(resp_permission => {
                    localStorage.setItem(this.key1, JSON.stringify(resp_permission.items));
                });
                if (resp.items.role_id == 1) {

                    localStorage.setItem(this.key, JSON.stringify(resp.items));                   
                    this.router.navigate(['admin/dashboard']);
                }
                else if (resp.items.role_id == 2 || resp.items.role_id == 5 || resp.items.role_id == 3 || resp.items.role_id == 4) {
                
                    localStorage.setItem(this.key, JSON.stringify(resp.items));
                    this.router.navigate(['employee/dashboard']);
                }
            }else {
                //this.loginService.InvalidUser(resp.message);
                this.message = resp.message;
                this.qrCodeForm.get('qrCode').setValue('');
                console.log(this.message);
            }              
        });
    }

    public loginFormReset()
    {
        this.myForm.reset();
    }


}