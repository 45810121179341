import { Component, OnInit,ViewChild} from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { ClientsService } from '../task-manager/clients/clients.service';
import { SidebarService } from '../shared/sidebar/sidebar.service';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

import { formControlBinding } from '@angular/forms/src/directives/reactive_directives/form_control_directive';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

@Component({
  selector: 'app-payrolls',
  templateUrl: './payrolls.component.html',
  styleUrls: ['./payrolls.component.scss'],
  providers: [DatePipe]
})
export class PayrollsComponent implements OnInit {

  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};

  constructor(private location: Location,
    private ClientsService: ClientsService,
    private router: Router,
    private datePipe: DatePipe,
    private sidebarService: SidebarService) { }

  
    myForm: FormGroup;
    public key = 'loginData';
    public myItem: any;
    public data: any = [];
    public permission;
    public projectlist: any = [];
    public projectlists: any = [];
    client: any;
    project: any;
    is_new: any;
    no_of_hour: any;
    marked = false;
    ngOnInit() {
      this.permission = this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.myForm = new FormGroup({
      'id': new FormControl(0),
      'name': new FormControl(null, [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z][a-zA-Z ]*)?$')]),
      'payroll_type': new FormControl(null, [Validators.required])
    }, { updateOn: 'change' });
    this.getMasterPayrollList();
    this.dtOptions = {
      //used id for index 
      order: [[0, "DESC"]],
    };

  }

  toggleVisibility(e) {
    this.marked = e.target.checked;
  }


  public addpayroll() {
    let formData: FormData = new FormData();
    formData.append('name', this.myForm.get('name').value);
    formData.append('payroll_type', this.myForm.get('payroll_type').value);
    formData.append('companyId', this.myItem.company_id);
    if (this.myForm.get('id').value == 0) {
      this.ClientsService.insertpayroll(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.ClientsService.insertPayrollSuccess();
          this.getMasterPayrollList();

          this.myForm.reset();
          this.myForm.get('id').setValue(0);
        } else {
          this.ClientsService.payrollalreadyExists();
        }
      });
    } else {

      formData.append('id', this.myForm.get('id').value);
      this.ClientsService.updatepayroll(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.ClientsService.updatePayrollSuccess();
          this.getMasterPayrollList();
          this.myForm.reset();
          this.myForm.get('id').setValue(0);
          }
      });
    }
  }

  public getMasterPayrollList() {
    let myDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.ClientsService.getMasterPayrollList().subscribe((resp: any) => {


      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp['success']) {
        this.data = resp.data;
        this.rerender();
      }
    });
  }

  backClicked() {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

      });
    });
  }

  public getEdit(val) {
    this.myForm.get('id').setValue(val.id);
    this.myForm.get('name').setValue(val.name);
    this.myForm.get('payroll_type').setValue(val.payrollTypes);
   }

  public Delete(id: number, i: number) {
    if (window.confirm('Are you sure you want to delete?')) {
      this.ClientsService.deletePayroll(id).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.ClientsService.deletePayrollSuccess();
          this.data.splice(i, 1);
          this.getMasterPayrollList();
          this.rerender();
        }
      });
    }
  }

}
