import { Component, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { DatePipe } from '@angular/common';

import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { TaskManagerService } from '../task-manager.service';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { SidebarService } from '../../shared/sidebar/sidebar.service';
import { formControlBinding } from '@angular/forms/src/directives/reactive_directives/form_control_directive';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


import 'sweetalert2/src/sweetalert2.scss'
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGrigPlugin from '@fullcalendar/timegrid';
// import interactionPlugin from '@fullcalendar/interaction'; // for dateClick
// import { EventInput } from '@fullcalendar/core';

@Component({
  selector: 'app-leave-type',
  templateUrl: './emp-task.component.html',
  styleUrls: ['./emp-task.component.scss']
})
export class EmpTaskComponent implements OnInit {

  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  @ViewChild("approveAllTaskModel") private approveAllTaskModel;


  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  employeeName: any;
  // calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin]; // important!
  // calendarEvents: EventInput[] = [
  //   { title: 'Event Now', start: new Date() }
  // ];

  constructor(private location: Location,
    private TaskManagerService: TaskManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private sidebarService: SidebarService,
    private modalService: NgbModal, ) { }

  myForm1: FormGroup;
  public key = 'loginData';
  public myItem: any;
  public data: any = [];
  public permission;
  public employeeList: any = [];
  id: any;
  myDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  public approveAllTaskModelRef;

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('Id');
    });
    this.permission = this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.getClientList(this.myDate);
    this.myForm1 = new FormGroup({
      'fromDate': new FormControl(null, [Validators.required])
    }, { updateOn: 'change' });


    this.getEmployeeList();

    this.dtOptions = {
      //used id for index 
      order: [[0, "DESC"]],
    };
  }

  // public handleDateClick(calDate) {
  //   console.log(calDate);
  //   this.myDate = calDate.dateStr;
  //   this.calendarEvents = [
  //     { title: calDate.date,backgroundColor: 'gray', start: calDate.dateStr,borderColor:'#000',overlap:true}
  //   ];
  //   this.getClientList(calDate.dateStr);
  // }

  public backtotask() {
    this.router.navigate(['employee/task-view']);
  }

  public changeStatus(val) {

    // let dissapproved = 'disapproved';
    // let approved = 'approve
    let status;
    let message;
    if (val.isApproved == 1) {
      message = 'Disapproved';
      status = 0;

    } else {
      message = 'Approved';
      status = 1;
    }

    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes," + message + " it!",
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.TaskManagerService.statusChange(val.id, status, this.myItem.id).subscribe((resp: any) => {
          if (resp['success']) {
            Swal.fire(
              message + '!',
              'Task has been ' + message + '.',
              'success'
            )
          }
          this.getClientList(this.myDate);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled'
        )
        this.getClientList(this.myDate);
      }
    });
  }

  public getClientList(selectedDate) {
    let myDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.TaskManagerService.getTaskList(this.id, selectedDate).subscribe((resp: any) => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp['success']) {

        if(resp.data){
          this.data = resp.data;
        } else {
          this.data=[];
        }
       
        this.rerender();
      } else {
        this.data = [];
        this.rerender();
      }
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
      });
    });
  }

  public taskfilter() {
    this.myDate = this.myForm1.get('fromDate').value;
    this.getClientList(this.myDate);
    this.rerender();
  }

  public openApprovedAllTaskModal() {

    this.approveAllTaskModelRef = this.modalService.open(this.approveAllTaskModel, { size: 'lg' });

    // Swal.fire({
    //   title: 'Are you sure?',
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonText: 'Yes, approve it!',
    //   cancelButtonText: 'No, keep it'
    // }).then((result) => {
    //   if (result.value) {
    //     let formData: FormData = new FormData();
    //     formData.append('data', JSON.stringify(this.data));
    //     formData.append('approvedBy', this.myItem.id);
    //     this.TaskManagerService.approveAll(formData).subscribe((resp: any) => {
    //       if (resp['success']) {
    //         Swal.fire(
    //           'Approved!',
    //           'All task has been approved.',
    //           'success'
    //         )
    //       }
    //       this.getClientList(this.myDate);
    //     });
    //   } else if (result.dismiss === Swal.DismissReason.cancel) {
    //     Swal.fire(
    //       'Cancelled'
    //     )
    //     this.getClientList(this.myDate);
    //   }
    // });
  }

  public updateTaskArray(event, flag) {

    if (flag == 'single') {
      if (event.target.checked) {
        this.data.filter(element => {
          if (element.id == event.target.value) {
            element.isApproved = 1;
          }
        });
      } else {
        this.data.filter(element => {
          if (element.id == event.target.value) {
            element.isApproved = 0;
          }
        });
      }
    }

    if (flag == 'all') {
      if (event.target.checked) {
        this.data.filter(element => {
          element.isApproved = 1;
        });
      } else {
        this.data.filter(element => {
          element.isApproved = 0;
        });
      }
    }
  }

  public getEmployeeList() {
    let myDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    let company_id = this.myItem.company_id;
    let teamLeadId = this.myItem.id;
    let isAdmin = this.myItem.is_admin;
    this.TaskManagerService.getEmployeeList(company_id, teamLeadId, isAdmin).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp['success']) {
        this.employeeList = resp.data;

        this.employeeList.filter(element => {
          if (element.id == this.id) {
            this.employeeName = element.firstName.toString() + ' ' + element.lastName.toString();
          }
        });
      }
    });
  }

  public filterEmployeeList(e) {
    this.employeeList.filter(element => {
      if (element.id == e.target.value) {
        this.employeeName = element.firstName.toString() + ' ' + element.lastName.toString();
        this.id = e.target.value;
        this.getClientList(this.myDate);
      }
    });
  }

  public approvedAllTask() {
    let formData: FormData = new FormData();
    formData.append('data', JSON.stringify(this.data));
    formData.append('approvedBy', this.myItem.id);
    this.TaskManagerService.approveAll(formData).subscribe((resp: any) => {
      if (resp['success']) {
        Swal.fire(
          'Approved!',
          'All task status has been updated.',
          'success'
        );
      }
      this.getClientList(this.myDate);
    });
  }
}
