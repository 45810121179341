import { Component, HostBinding } from '@angular/core';
import { Router  } from "@angular/router";


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent{
    //Variables
    currentDate : Date = new Date();
    public key ='loginData';
    public myItem:any;

    constructor(
        private router: Router,
        ) { }


    ngOnInit() {
        this.myItem = JSON.parse(localStorage.getItem(this.key));
        
    }
    public homePage()
    {
        if(this.myItem.role_id == 1){
              this.router.navigate(['admin/dashboard']);
        }else
        {
            this.router.navigate(['employee/dashboard']);
        }
    }
  

}
