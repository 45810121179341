import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ChartistModule } from 'ng-chartist';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchHeightModule } from "../../shared/directives/match-height.directive";

import {EmpTaskList, EmpTaskRoutingModule } from "./emp-task-routing.module";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';
// import { FullCalendarModule } from '@fullcalendar/angular';

@NgModule({
  imports: [
    CommonModule,
    EmpTaskRoutingModule,
    ChartistModule,
    NgbModule,
    MatchHeightModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    NgxDatatableModule,
    DataTablesModule,
    // FullCalendarModule
  ],
  declarations: [
    EmpTaskList,
],  
})
export class EmpTaskModule { }


/*
* calendar, on click of calender show own task
* on click of emp shown emp task
*/