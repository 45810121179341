import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";


import { ChartistModule } from 'ng-chartist';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchHeightModule } from "../shared/directives/match-height.directive";

import { AdminRoutingModule ,AdminComponentList } from "./admin-routing.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { ClientModule } from "../client/client.module";
import { EmployeeModule } from "../employee/employee.module";
import { DepartmentsModule } from "../departments/departments.module";
import { ModuleModule } from "../module/module.module";
import { LeaveModule } from "../leave/leave.module";
import { DocumentCategoryModule } from  "../document-category/document-category.module";
import { DesignationModule } from "../designation/designation.module";
import { SalaryslipTemplateModule } from '../salaryslip-template/salaryslip-template.module';
import {AdminComponent} from './admin.component';
import {LeaveTypeModule} from '../leave-type/leave-type.module';
import {TaskManagerModule} from '../task-manager/task-manager.module';
import {TaskViewModule} from '../task-manager/task-view/task-view.module';
import {ToDoModule} from '../task-manager/to-do/to-do.module';
import {ClientsModule} from '../task-manager/clients/clients.module';
import {ProjectsModule} from '../task-manager/projects/projects.module';
import {PayrollsModule} from '../payrolls/payrolls.module';
import {TaskTypeModule} from '../task-manager/task-type/task-type.module';
import {EmpTaskModule} from '../task-manager/emp-task/emp-task.module';
@NgModule({
    imports: [
        CommonModule,
        AdminRoutingModule,
        ClientModule,
        EmployeeModule,
        DepartmentsModule,
        ModuleModule,
        LeaveModule,
        DocumentCategoryModule,
        ChartistModule,
        NgbModule,
        MatchHeightModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgxDatatableModule,
        DesignationModule,
        SalaryslipTemplateModule,
        LeaveTypeModule,
        TaskManagerModule,
        TaskViewModule,
        ToDoModule,
        ClientsModule,ProjectsModule,
        EmpTaskModule,
        TaskTypeModule,
        PayrollsModule
    ],   
    exports: [],
    declarations: [
        AdminComponentList,
        AdminComponent
        
    ],  
    providers: [], 
})
export class AdminModule { }
