import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewsComponent } from './news.component';
import { AddNewsComponent } from  './add-news/add-news.component';

const routes: Routes = [
  {
    path: '',  
    
    children: [   
      {
        path: 'news',
        component: NewsComponent,
        data: {
          title: 'news'
        }
      },
      {
        path: 'add-news',
        component: AddNewsComponent,
        data: {
          title: 'news'
        }
      },
      {
        path: 'add-news/:id',
        component: AddNewsComponent,
        data: {
          title: 'news'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NewsRoutingModule { }

export const NewsComponentList = [
  NewsComponent,
  AddNewsComponent
];

