import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientComponent } from "../client/client.component";
import { AddClientComponent } from "../client/add-client/add-client.component";
import { ClientProfileComponent } from "../client/client-profile/client-profile.component";
import { EmployeeComponent } from "../client/employee/employee.component";
import { AddEmployeeComponent } from "../client/employee/add-employee/add-employee.component";
import { EmployeeProfileComponent } from "../client/employee/employee-profile/employee-profile.component";
import { BulkEmployeeComponent } from "../client/employee/bulk-employee/bulk-employee.component";
import { AuthGuard } from '../shared/auth/auth-guard.service';


const routes: Routes = [
  {
    path: '',  
    
    children: [   
      {
        path: 'client',
        component: ClientComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'client'
        }
      },
      {
        path: 'addclient',
        component: AddClientComponent,
        data: {
          title: 'Addclient'
        }
     },
      {
        path: 'addclient/:id',
        component: AddClientComponent,
        data: {
          title: 'EditClient'
        }
      },
        {
          path: 'clientProfile/:id',
          component: ClientProfileComponent,
          data: {
            title: 'clientProfile'
          }
        },  
      {
        path: 'subclient/:id',
        component: ClientComponent,
        data: {
          title: 'client'
        }
      },
      {
        path: 'employee/:id',
        component: EmployeeComponent,
        data: {
          title: 'employee list'
        }
      },

      {
        path: 'client_employee/addemployee',
        component: AddEmployeeComponent,
        data: {
          title: 'employee add'
        }
      },

      {
        path: 'client_employee/addemployee/:id',
        component: AddEmployeeComponent,
        data: {
          title: 'employee edit'
        }
      },
      {
        path: 'client_employee/employeeProfile/:id',
        component: EmployeeProfileComponent,
        data: {
          title: 'employee profile'
        }
      },
      {
        path: 'client_employee/bulk-employee/:id',
        component: BulkEmployeeComponent,
        data: {
          title: 'employee'
        }
      },  
      
      
       
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientRoutingModule { }

export const ClientComponentList = [
  ClientComponent,
  AddClientComponent,
  ClientProfileComponent,

  // new
  EmployeeComponent,
  AddEmployeeComponent,
  EmployeeProfileComponent,
  BulkEmployeeComponent
 
];

