import { Component, OnInit,ViewChild } from '@angular/core';

import { Router, ActivatedRoute, Params  } from "@angular/router";
import { DesignationService } from "./designation.service";
import { Location } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { SidebarService } from '../shared/sidebar/sidebar.service';



@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.scss']
})
export class DesignationComponent implements OnInit {
@ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
   public data:  any[] = [];
   public key ='loginData';
   public key1 ='permission';
   public myItem:any;
   public modulePermission:any;
   public api_token = '59240171-6f5c-42e3-8d87-0044ac120184';
   public selfCountDept ;
   public permission;
   public companyId;
   public headerStatus = false;
   public customerHeader = false;
  
  constructor(private router:Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private sidebarService : SidebarService,
    private designationService : DesignationService) { }

  ngOnInit() {
    this.permission= this.sidebarService.getCurrentPermission();
    this.modulePermission = JSON.parse(localStorage.getItem(this.key1));  
    this.myItem = JSON.parse(localStorage.getItem(this.key));

    this.dtOptions = {
      //used id for index 
      order: [[0, "DESC"]],
    };
    
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        if(params.id) {
          this.companyId = params.id;
          this.headerStatus = true;
        } else {
          this.companyId = this.myItem.company_id;
        }
      }
    );

    let responseString=this.router.url;
    if(responseString.match("/customer"))
    {
      this.customerHeader = true;

    }

    $(document).ready(function(){
      $("#designations").addClass("tabactive");
    });
    this.getAllDesignation();
    this.getModulePermission();
   // this.rerender();
   }

   public getAllDesignation()
   {
    if(this.myItem.role_id ==1 && this.companyId !=1){
      this.designationService.getDesignation(this.companyId).subscribe(
        resp=>{
          if(resp.status == 401)
          {
              this.router.navigate([this.myItem.url]);
          }
          this.data = resp.items;
        // this.selfCountDept = this.data.filter((obj) => obj.company_id === this.myItem.company_id).length;
          this.rerender();
        });
    }
    if(this.myItem.role_id ==1 && this.companyId ==1){
      this.designationService.getAllDesignation().subscribe(resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        this.data=resp.items;
        this.rerender();
    });
    }

    if(this.myItem.role_id==2 || this.myItem.role_id==3)
    {
      this.designationService.getDesignation(this.companyId).subscribe(
        resp=>{
          if(resp.status == 401)
          {
              this.router.navigate([this.myItem.url]);
          }
          this.data = resp.items;
        // this.selfCountDept = this.data.filter((obj) => obj.company_id === this.myItem.company_id).length;
          this.rerender();
        });
    }

  }

  public addDesignation()
    {
      this.router.navigate(['designation/adddesignation',{companyId : this.companyId}]);
    }

    public getEdit(id:number)
    {
      this.router.navigate(['designation/adddesignation',{id:id, companyId : this.companyId}]);
    }

    public Delete(id: number, i: number)
    {
      if(window.confirm('Are you sure you want to delete?')) {
        this.designationService.delete(id).subscribe(resp=>{
          if(resp.status == 401)
          {
              this.router.navigate([this.myItem.url]);
          }
          if(resp.success){
            this.designationService.deleteSuccess();
           this.data.splice(i, 1);
           this.rerender();
          }
        });
      }
    }
 
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
  
    rerender(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
    }
  
    ngAfterViewInit(): void {
      this.dtTrigger.next();
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function () {
          const that = this;
  
          // For checked fields
          $('input[type=checkbox]', this.footer()).on('checked change', function () {
            if (this['value'] === 'true') {
              that.search(this['value']).draw();
            } else {
              that.search('').draw();
            }
          });
  
          $('input[type=text]', this.footer()).on('keyup change', function () {
            console.log("text : " + this['value']);
            if (that.search() !== this['value']) {
              that.search(this['value']).draw();
            }
          });
        });
      });
    }
    
    public backClicked()
    {
      this.location.back();
    } 

    public getModulePermission()
    {
      this.modulePermission.forEach(element => {
       // 6 Stand for Designation Module Id
        if(element.moduleId == 6){
          this.permission = element.permissionLevel;
        }
      });
    }
    
}
