export class EmployeeListModel {

   
   
    userId : number;
    created_by : number;
    documentType : number;
    description : string ; 
   
    
  

    constructor(params:any) {
        const model = params || {};
      
        this.created_by =model.created_by || '';
        this.userId =model.userId || '';
        this.documentType =model.documentType || '';
        this.description =model.description || '';
    }
}
