import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DesignationComponent } from "./designation.component";
import { AddDesignationComponent } from "./add-designation/add-designation.component";



const routes: Routes = [
  {
    path: '',  
    
    children: [   
      {
        path: 'designation',
        component: DesignationComponent,
        data: {
          title: 'designation'
        }
      },
      {
        path: 'designation/:id',
        component: DesignationComponent,
        data: {
          title: 'designation'
        }
      },
      {
        path: 'adddesignation',
        component: AddDesignationComponent,
        data: {
          title: 'AddDepartments'
        }
      },
      {
        path: 'adddesignation/:id',
        component: AddDesignationComponent,
        data: {
          title: 'AddDepartments'
        }
      },
       
     
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DesignationRoutingModule { }

export const DesignationComponentList = [
  DesignationComponent,
  AddDesignationComponent
  
];

