import { Component, OnInit, ViewEncapsulation,ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ClientService } from "../client/client.service";
import { PARAMETERS } from '@angular/core/src/util/decorators';
import { id } from '@swimlane/ngx-charts/release/utils';
import * as alertFunctions from '../shared/data/sweet-alerts';
import swal from 'sweetalert2';
import * as Chartist from 'chartist';
import { ChartType, ChartEvent } from "ng-chartist";
import { count } from 'rxjs/operators';
import * as tableData from '../../app/shared/data/smart-data-table';
import { Location } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { SidebarService } from '../shared/sidebar/sidebar.service';

declare var require: any;


const data: any = require('../shared/data/chartist.json');

export interface Chart {
    type: ChartType;
    data: Chartist.IChartistData;
    options?: any;
    responsiveOptions?: any;
    events?: ChartEvent;
}


@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};


  public data:  any[] = [];
  public editStatus;
  public totalClient;
  public item;
  public key ='loginData';
  public myItem:any;
  public parent_id;
  public flag ;
  public permission;
  public headerStatus = false;


  constructor(private clientService : ClientService,   
    private router:Router,
    private location: Location,
    private sidebarService : SidebarService,
    private activatedRoute: ActivatedRoute) {
     }


  ngOnInit() {

    // permisssion
    this.permission= this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    
    this.dtOptions = {
      //used id for index 
      order: [[0, "DESC"]],
    };

    
    this.activatedRoute.params.subscribe(
      (params: Params) => {
      this.parent_id = params.id;
      if(params.id){
        this.editStatus = true;
        this.parent_id = params.id;
        this.get_data(this.parent_id);
        this.headerStatus = true;
      }
      else{
        this.editStatus = false;
        this.myItem = JSON.parse(localStorage.getItem(this.key));
        this.parent_id = this.myItem.company_id;
        this.get_data(this.parent_id);
      }
        }
    );
   
   // this.rerender();
    $(document).ready(function(){
      $("#customers").addClass("tabactive");
    });

    }

   public addCustomer()
    {
      this.router.navigate(['customer/addcustomer',{'parent_id': this.parent_id}]);
    }

    public get_data(parent_id)
    { 
       this.clientService.get_data(parent_id,this.myItem.role_id).subscribe(resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success){
          this.totalClient = resp.items.length ;
          this.data=resp.items;
          this.rerender();
        }
      });
    }

    public getEdit(id)
    {
      this.router.navigate(['customer/addcustomer',id]);
    }

    public getpayroll(id){
      this.router.navigate(['client/payroll-relations',id]);
    }
    public Delete(id: number, i: number)
    {
      if(window.confirm('Are you sure you want to delete?')) {
        this.clientService.delete(id).subscribe(resp=>{
          if(resp.status == 401)
          {
              this.router.navigate([this.myItem.url]);
          }
          if(resp.success){
            this.clientService.deleteSuccess();
           this.data.splice(i, 1);  
           this.get_data(this.parent_id);
           this.rerender();
          }
        });
      }
    }

    public companyProfile(id:number)
    {    
       this.router.navigate(['customer/customerProfile',id]);
    }

    
     // line chart configuration Starts
     WidgetlineChart: Chart = {
      type: 'Line', data: data['WidgetlineChart'],
      options: {
          axisX: {
              showGrid: true,
              showLabel: false,
              offset: 0,
          },
          axisY: {
              showGrid: false,
              low: 40,
              showLabel: false,
              offset: 0,
          },
          lineSmooth: Chartist.Interpolation.cardinal({
              tension: 0
          }),
          fullWidth: true,
      },
  };
  // Line chart configuration Ends

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        // For checked fields
        $('input[type=checkbox]', this.footer()).on('checked change', function () {
          if (this['value'] === 'true') {
            that.search(this['value']).draw();
          } else {
            that.search('').draw();
          }
        });

        $('input[type=text]', this.footer()).on('keyup change', function () {
        //  console.log("text : " + this['value']);
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  public backClicked()
  {
    this.location.back();
  }   

  public redirectToBranch(companyId)
  {
    this.router.navigate(['customer/branch',companyId]);
  }

}
