import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { App_Url } from '../globals';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient, public toastr: ToastrService) { }
  public key = 'loginData';
  public myItem: any;

  public add(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/news-add`, formData);
  }

  public update(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/news-update`, formData);
  }

  public getdata(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/news-get`, formData);
  }

  public getItem(id): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/news-single`, formData);
  }

  public delete(id): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/news-delete`, formData);
  }

  public getNewsForEmployee(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/get_news_for_employee`, formData);
  }

  insertSuccess() {
    this.toastr.success('News has been inserted successfully!', 'Success!');
  }

  updateSuccess() {
    this.toastr.success('News has been updated successfully!', 'Success!');
  }
  deleteSuccess() {
    this.toastr.success('News has been deleted successfully!', 'Success!');
  }


  getApiToken() {
    //var api_token ='59240171-6f5c-42e3-8d87-0044ac120184';
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    var api_token = this.myItem.tokenKey;
    return api_token;

  }
}
