import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { App_Url } from '../globals';



@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private http: HttpClient, public toastr: ToastrService) { }
  public key ='loginData';
  public myItem:any;

 public checkLogin(fd:any):Observable<any>
 {
  //return this.http.post(App_Url+`api/login`,fd);loginwithFlag
  return this.http.post(App_Url+`api/loginwithFlag`,fd);
 }

 public forgotPassword(fd:any):Observable<any>
 {
  return this.http.post(App_Url+`api/forgotPassword`,fd);
 }

 InvalidUser(message) {
  this.toastr.error('', message, { "timeOut": 2000 });
}

alreadyLoginError() {
  this.toastr.error('', 'You are already logged In!', { "timeOut": 2000 });
}

public sendOtpForLogin(fd:any):Observable<any>
 {
  return this.http.post(App_Url+`api/sendOtpForLogin`,fd);
 }

 public verifyCodeForLogin(fd:any):Observable<any>
 {
  return this.http.post(App_Url+`api/verifyCodeForLogin`,fd);
 }


 


 //public getPermission(id):Observable<any>
 // {
   //return this.http.get(`http://127.0.0.1:8000/api/permission/${id}?api_token=59240171-6f5c-42e3-8d87-0044ac120184`);
  // return this.http.get(App_Url+`api/permission/${id}`);
  //}

  public getPermission(userId):Observable<any>
  {
    let formData: FormData = new FormData();
   // formData.append('api_token',this.getApiToken());
    formData.append('userId',userId);
   return this.http.post(App_Url+`api/permission`,formData);   
  
  }

  typeSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  passwordUpdated() {
    this.toastr.success('Please check your mail for new password.!', 'Success!', { "timeOut": 4000 });
  }

  // userNotFound() {
  //   this.toastr.error('', 'Email id not found.!', { "timeOut": 2000 });
  // }
  public logout(formData:any):Observable<any>
  {
   formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/logout`,formData);
  }

  public verifyUrl(fd:any):Observable<any>
  {
   return this.http.post(App_Url+`api/verifyUrl`,fd);
  }
 
  getApiToken(){
   this.myItem = JSON.parse(localStorage.getItem(this.key));
   var api_token = this.myItem.tokenKey;
    return api_token ;
   }
}


