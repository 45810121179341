import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { EmployeeService } from "./employee.service";
import { PARAMETERS } from '@angular/core/src/util/decorators';
import { id } from '@swimlane/ngx-charts/release/utils';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeModel } from "./employee.model";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { SidebarService } from '../../shared/sidebar/sidebar.service';
import { Md5 } from 'ts-md5/dist/md5';
import { ExcelService } from '../../service/excel.service';


const md5 = new Md5();


@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  employee: EmployeeModel = new EmployeeModel({});
  myForm: FormGroup;
  myForm1: FormGroup;
  myForm2:FormGroup;
  public data: any[] = [];
  public checkedList = [];
  public company_id;
  public flag;
  public key = 'loginData';
  public key1 = 'permission';
  public myItem: any;
  public modulePermission: any;
  public totalEmployee;
  public company_name = '';
  public closeResult: string;
  public modelId: string = '';
  public modelUsername: string = '';
  // public modelPassword:string = '';
  public managerStatus = true;
  public permission;
  //public passwordShow = false;
  //public password;
  public attendancePermission;
  public documentPermission;

  // for change device id
  public oldDeviceId;
  public newDeviceId;
  public userId;

  constructor(private employeeService: EmployeeService,
    private router: Router,
    private location: Location,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private sidebarService: SidebarService,
    private excelService: ExcelService,
  ) { }

  ngOnInit() {
    //this.permission= this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.modulePermission = JSON.parse(localStorage.getItem(this.key1));
    this.myForm2 = new FormGroup({
      'userId': new FormControl("", [Validators.required])
    }, { updateOn: 'change' });
   
    this.dtOptions = {
      //used id for index 
      order: [[0, "DESC"]],
    };


    this.activatedRoute.params.subscribe(
      (params: Params) => {
        if (params.id) {
          this.company_id = params.id;
        }
        else {
          this.company_id = this.myItem.company_id;
        }
      }
    );

    if (this.myItem.role_id == 5 && this.myItem.is_manager == 1) {
      this.managerStatus = false;
    }

    this.myForm = new FormGroup({
      'modelPassword': new FormControl(null, [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/_=]*)?$')]),
    }, { updateOn: 'change' });

    this.myForm1 = new FormGroup({
      'newDeviceId': new FormControl(null, [Validators.required]),
    }, { updateOn: 'change' });

    this.getdata(this.company_id);
    this.getModulePermission();
    $.getScript('./assets/js/vertical-timeline.js');
    $(document).ready(function () {
      $("#employees").addClass("tabactive");
    });


    //this.rerender();
  }
  public addEmployee() {
    this.router.navigate(['client/customer_employee/addemployee', { company_id: this.company_id }]);
  }

  public addBulkEmployee() {
    this.router.navigate(['client/customer_employee/bulk-employee', this.company_id]);
  }

  public onCheckboxChange(course, event) {
    if (event.target.checked) {
      this.checkedList.push(course.id);
     
    } else {
      for (var i = 0; i < this.checkedList.length; i++) {
        if (this.checkedList[i] == course.id) {
          this.checkedList.splice(i, 1);
        }
       
      }
    }
    console.log(this.checkedList)
  }
  public getdata(company_id: number) {

    if (this.myItem.role_id == 1) {
      this.employeeService.getDataWithAdmin(company_id).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.data = resp.items;
        if (resp.success) {
          this.company_name = resp.items[0].company_name;
        }
        this.rerender();
      });
    }
    else if (this.myItem.role_id == 4) {
      this.employeeService.getEmployeeForManager(this.myItem.id).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.data = resp.items;
        if (resp.success) {
          this.company_name = resp.items[0].company_name;
        }
        this.rerender();
      });
    }
    else {
      this.employeeService.getdata(company_id).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.data = resp.items;
        if (resp.success) {
          this.company_name = resp.items[0].company_name;
        }

        this.rerender();
      });
    }

  }


  public onSubmit() {
    // console.log('abcd');
  }

  public getEdit(id: number) {
    this.router.navigate(['client/customer_employee/addemployee', id, { company_id: this.company_id }]);
  }

  public Delete(id: number, i: number) {
    if (window.confirm('Are you sure you want to delete?')) {
      this.employeeService.delete(id).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.employeeService.deleteSuccess();
          this.data.splice(i, 1);
          this.rerender();
        }
      });
    }
  }

  public assignCourseSubmit() {
    if (this.checkedList.length == 0) {
      //this.isSubmited = true;
      this.employeeService.insertFailure("Please select at least 1 employee.!");
      return false;
    }
    let formData: FormData = new FormData();
   
    formData.append('userId', this.checkedList.toString());
    console.log(formData)
    this.employeeService.InactiveBulk(formData).subscribe(resp => {
      console.log(resp)
      if (resp.status == 200) {
        this.employeeService.inactiveSuccess(resp.message);
        this.getdata(this.company_id);
      } else {
        this.employeeService.insertFailure(resp.message);
      }
    });
  }

  public employeeProfile(id: number) {
    this.router.navigate(['client/customer_employee/employeeProfile', id]);
  }

  //permission

  public Permission(data) {
    this.router.navigate(['employee/permission', { id: data.id, cid: data.company_id }]);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        // For checked fields
        $('input[type=checkbox]', this.footer()).on('checked change', function () {
          if (this['value'] === 'true') {
            that.search(this['value']).draw();
          } else {
            that.search('').draw();
          }
        });

        $('input[type=text]', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  public backClicked() {
    var permission = this.sidebarService.getUserPermission().find(function (element) {
      if (element.moduleId == 2) {
        return element;
      }
    });
    this.sidebarService.setCurrentPermission(permission.permissionLevel);
    this.location.back();
  }

  //for password change modals
  openModel(content, obj) {
    //this.password = obj.password;
    this.modelId = obj.id;
    //this.modelPassword = '';
    this.modelUsername = obj.user_name;
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    });
  }

  updatePassword() {
    let formData: FormData = new FormData();
    formData.append('id', this.modelId);
    formData.append('password', this.myForm.get('modelPassword').value);
    this.employeeService.changePassword(formData).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.myForm.reset();
        this.employeeService.password();
      }

    });
  }

  // public showPassword()
  // {
  //   this.passwordShow = true;
  //   this.modelPassword = this.password;
  // }

  public Attendance(id: number) {
    this.router.navigate(['attendance/attendance', id]);
  }

  public Document(val)
  {
   this.router.navigate(['employee/document',{'userId':val.id,'companyId':val.company_id}]);
  }

  public getModulePermission() {

    this.modulePermission.forEach(element => {

      if (element.moduleId == 14) {
        this.permission = element.permissionLevel;
      }

      // 11 Stand for Attendance Module Id
      if (element.moduleId == 11) {
        this.attendancePermission = element.permissionLevel;
      }

      // 10 Stand for Document Module Id
      if (element.moduleId == 10) {
        this.documentPermission = element.permissionLevel;
      }

    });
  }



  // for change device id

  changeDeviceId(deviceData, obj) {
    this.userId = obj.id;
    this.oldDeviceId = obj.deviceId;
    this.modalService.open(deviceData).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    });
  }

  public updateDeviceId() {
    let formData: FormData = new FormData();
    formData.append('userId', this.userId);
    formData.append('deviceId', '');
    this.employeeService.updateDeviceId(formData).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.newDeviceId = '';
        this.employeeService.deviceIdChange();
        this.getdata(this.company_id);
      }

    });
  }

  public exportEmployee() {
    let formData: FormData = new FormData();
    formData.append('roleId', this.myItem.role_id);
    formData.append('userId', this.myItem.id);
    formData.append('companyId', this.company_id);
    this.employeeService.getEmployeeForExport(formData).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.excelService.exportAsExcelFile(resp.items, 'EmployeeData');
      }
    });
  }
}
