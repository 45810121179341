import { Component, OnInit,ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { ManageHolidaysService } from './manage-holidays.service'
import { Router, ActivatedRoute, Params  } from "@angular/router";
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { SidebarService } from '../shared/sidebar/sidebar.service';
import { _ } from 'core-js';


@Component({
  selector: 'app-manage-holidays',
  templateUrl: './manage-holidays.component.html',
  styleUrls: ['./manage-holidays.component.scss']
})
export class ManageHolidaysComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};

  constructor(private location: Location,
    private manageHolidaysService:ManageHolidaysService,
    private router: Router,
    private sidebarService : SidebarService,
    private route: ActivatedRoute,) { }
  myForm: FormGroup;
  public key ='loginData';
  public myItem:any;
  public data:any = [];
  public permission;
  public companyId;
  public buttonStatus:boolean = false;
  public companyName:string;
  public branches:any=[];
  public selectedBranches:any=[];
  public status:boolean = false;

  ngOnInit() {
    this.permission= this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.myForm = new FormGroup({
      'id': new FormControl(0),
      'holiday_name': new FormControl(null,[Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/]*)?$')]),
      //'holiday_name': new FormControl(null,[Validators.required]),
      'date': new FormControl(null, [Validators.required]),
     
    }, {updateOn: 'change'});

    this.route.params.subscribe(
      (params: Params) => {
        if (params.id) {
          this.buttonStatus = true;
          this.companyId = params.id
        } else {
          this.companyId = this.myItem.company_id;
        }
      });

    this.getHolidays();
    this.getBranches();
  
    this.dtOptions = {
      //used id for index 
      order: [[0, "asc"]],
    };
  }

  public addHolidays()
  {

    if(this.selectedBranches.length ==0){
      this.status = true;
      return;
    } else {
      this.status = false;
    }

    let formData: FormData = new FormData();
    formData.append('holiday_name',this.myForm.get('holiday_name').value);
    formData.append('date',this.myForm.get('date').value);
    formData.append('createdBy',this.myItem.id);
    formData.append('companyId',this.companyId);
    formData.append('branchId',this.selectedBranches.toString());
  

    if(this.myForm.get('id').value == 0){
      this.manageHolidaysService.add(formData).subscribe(resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success) { 
          this.manageHolidaysService.insertSuccess();
          this.getHolidays();
          this.myForm.reset();
          this.myForm.get('id').setValue(0);
          this.selectedBranches=[];
          //this.myForm.get('holiday_name').setValue('');
          //this.myForm.get('date').setValue('');
        }else {
          this.manageHolidaysService.alreadyExists();
        }    
      });
    } else {
      formData.append('id',this.myForm.get('id').value);
      this.manageHolidaysService.update(formData).subscribe(resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success) { 
          this.manageHolidaysService.updateSuccess();
          this.getHolidays();
          this.myForm.reset();
          this.myForm.get('id').setValue(0);
          this.selectedBranches=[];
          //this.myForm.get('holiday_name').setValue('');
         // this.myForm.get('date').setValue('');
        }     
      });
   }

  }

  public getHolidays()
  {
    let formData: FormData = new FormData();
    formData.append('createdBy',this.myItem.id);
    formData.append('companyId',this.companyId);
    formData.append('userId',this.myItem.id);
    this.manageHolidaysService.getHolidays(formData).subscribe(resp=>{
      if(resp.status == 401)
      {
          this.router.navigate([this.myItem.url]);
      }
      if(resp.success) { 
        this.data = resp.items.holidays;
        this.companyName = resp.items.company_name;
        this.rerender();
      }     
    });
  }

  backClicked() {
    this.location.back();
   } 


   ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        // For checked fields
        $('input[type=checkbox]', this.footer()).on('checked change', function () {
          if (this['value'] === 'true') {
            that.search(this['value']).draw();
          } else {
            that.search('').draw();
          }
        });

        $('input[type=search]', this.footer()).on('keyup change', function () {
        //  console.log("text : " + this['value']);
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }


  public getEdit(val)
  {
    this.myForm.get('id').setValue(val.id);
    this.myForm.get('holiday_name').setValue(val.holiday_name);
    this.myForm.get('date').setValue(val.date);
    this.selectedBranches=[];
    if (val.branch_id != ''  && val.branch_id != null) {
      this.selectedBranches = val.branch_id.split(',');
    }

   //console.log(this.selectedBranches);
  }

  public Delete(id: number, i: number)
  {
    if(window.confirm('Are you sure you want to delete?')) {
      this.manageHolidaysService.delete(id).subscribe(resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success){
          this.manageHolidaysService.deleteSuccess();
         this.data.splice(i, 1);  
         this.getHolidays();
         this.rerender();
        }
      });
    }
  }

  public getBranches()
  {
    this.manageHolidaysService.getBranches(this.companyId).subscribe(resp=>{
      if(resp.status == 401)
      {
          this.router.navigate([this.myItem.url]);
      }
      if(resp.success){
        this.branches=resp.items.branches;
      }
    });
  }


  public updateBranches(e, i) {
    var index = this.selectedBranches.indexOf(e.target.value);
    if (e.target.checked) {
      if (index < 0) {
        this.selectedBranches.push(e.target.value);
        this.status = false;
      }
    } else {
      this.selectedBranches.splice(index,1);
    }
  }

public getSelectedBranches(id) {
    if (this.selectedBranches.indexOf(id.toString()) < 0) {
      return false;
    } else {
      return true;
    }
}
}
