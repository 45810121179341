import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { BrowserModule } from '@angular/platform-browser';

import { ChartistModule } from 'ng-chartist';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchHeightModule } from "../shared/directives/match-height.directive";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { DataTablesModule } from 'angular-datatables';
///import { ManagerModule } from "../manager/manager.module";
//import { CustomerModule } from "../customer/customer.module"
//import { DepartmentsModule } from "../departments/departments.module";
//import { DesignationModule } from "../designation/designation.module";
import { CustomerHeaderComponent } from "./customer-header.component";

@NgModule({
    imports: [
        CommonModule,
        ChartistModule,
        NgbModule,
        MatchHeightModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgxDatatableModule,
        Ng2SmartTableModule,
        DataTablesModule,
       // ManagerModule,
       // CustomerModule,
       // DepartmentsModule,
       // DesignationModule,
        
    ],   
    exports: [
        CustomerHeaderComponent
    ],
    declarations: [
        CustomerHeaderComponent
      
        
    ],  
    providers: [], 
})
export class CustomerHeaderModule { }
