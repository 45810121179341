import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ManageHolidaysComponent} from './manage-holidays.component';
//mport { HolidayCustomerListComponent } from './manage-holidays.component';
import { HolidayCustomerListComponent } from "./holiday-customer-list/holiday-customer-list.component"

const routes: Routes = [
  {
    path: '', 
    
     children: [ 
      {
        path: 'holidays',
        component: ManageHolidaysComponent,
        data: {
          title: 'Manage holidays'
        }
      },  
      {
        path: 'manage-holidays',
        component: ManageHolidaysComponent,
        data: {
          title: 'Manage holidays'
        }
      },
      {
        path: 'manage-holidays/:id',
        component: ManageHolidaysComponent,
        data: {
          title: 'Manage holidays'
        }
      },
      {
        path: 'holiday-customer-list',
        component: HolidayCustomerListComponent,
        data: {
          title: 'Manage holidays'
        }
      },
      {
        path: 'holidays',
        component: ManageHolidaysComponent,
        data: {
          title: 'Holidays list'
        }
      },
      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManageHolidaysRoutingModule { }

export const ManageHolidaysList = [
  ManageHolidaysComponent,
  HolidayCustomerListComponent
];

