import { Component, ViewChild } from '@angular/core';
import { NgForm,FormControl, FormGroup, Validators} from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from "../service/login.service";


@Component({
    selector: 'app-forgot-password-page',
    templateUrl: './forgot-password-page.component.html',
    styleUrls: ['./forgot-password-page.component.scss']
})

export class ForgotPasswordPageComponent {
    @ViewChild('f') forogtPasswordForm: NgForm;
    @ViewChild('vform') validationForm: FormGroup;
    myForm: FormGroup;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private loginService : LoginService) { }

        public email : string;
        public invalidEmail = false;

        ngOnInit(){
            this.myForm = new FormGroup({
                'email': new FormControl(null, [Validators.required,,Validators.email,Validators.pattern('[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$')]),
              
            }, {updateOn: 'change'});
        }


    // On submit click, reset form fields
    onSubmit() {
        var fd:FormData = new FormData();
        fd.append('email',this.email);
		fd.append('appType', 'WEB');
        this.loginService.forgotPassword(fd).subscribe(resp=>{
            if(resp.success)
            {
            this.router.navigate(['login'], { relativeTo: this.route.parent });
            this.loginService.passwordUpdated();
            }
            else
            {
               //this.loginService.userNotFound();
               this.invalidEmail = true;
            }
         });
    }

    // On login link click
    onLogin() {
        this.router.navigate(['login'], { relativeTo: this.route.parent });
    }

    // On registration link click
    onRegister() {
        this.router.navigate(['register'], { relativeTo: this.route.parent });
    }

    public clear(){
        this.email = '';
        }
}