import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { LeaveModel } from "../leave.model";
import { LeaveService } from "../leave.service";
import { Location, formatDate } from '@angular/common';
import { DatePipe } from '@angular/common'



@Component({
  selector: 'app-add-leave',
  templateUrl: './add-leave.component.html',
  styleUrls: ['./add-leave.component.scss']
})
export class AddLeaveComponent implements OnInit {
  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;


  myForm: FormGroup;
  leave: LeaveModel = new LeaveModel({});

  public key = 'loginData';
  public myItem: any;
  public leave_type: any[] = [];
  public fdate;
  public tdate;
  public editStatus = true;
  public today = this.convertDate(new Date());
  public minDate=this.convertDate(new Date());
  public maxDate;
  public dateRange:any = [];
  public selectedDate:any = [];
  public status = false;
  public NoOfDaysLimitForLeave;


  constructor(private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private leaveService: LeaveService,
    public datepipe: DatePipe) { }

  backClicked() {
    this.location.back();
  }

  ngOnInit() {
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.getLeave();

    this.myForm = new FormGroup({
      'leaveType': new FormControl(null, [Validators.required]),
      'reason': new FormControl(null, [Validators.required]),
      'fromDate': new FormControl(null, [Validators.required]),
      'toDate': new FormControl(null, [Validators.required]),
      //  'remark': new FormControl(null, [Validators.required]),
      'approvalStatus': new FormControl(null),
      'noDays': new FormControl(null),
      'remark': new FormControl(null)

    }, { updateOn: 'change' });


    this.route.params.subscribe(
      (params: Params) => {
        if (params.id) {
          this.editStatus = true;
          this.getData(params.id);
        } else {
          this.editStatus = false;
        }
      }
    );
    this.countDays();
    this.isAllowed();
  }

  public getDaysArra(s,e) {
    for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1))
    {
      var obj = {'date':this.convertDate(d),'isSelected':0};
       a.push(obj);
    }
    this.dateRange = a;

    if(this.leave.noDays == 0.5){
      this.dateRange.forEach(element => {       
        element.isSelected=1;
        this.status = true;
        this.selectedDate.push(element.date);
      });
    } else {
      this.status = false;
    }
  };
 

  public convertDate(date) {
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth() + 1).toString();
    var dd = date.getDate().toString();

    var mmChars = mm.split('');
    var ddChars = dd.split('');

    return yyyy + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + (ddChars[1] ? dd : "0" + ddChars[0]);
  }

  public getLeave() {
    this.leaveService.getLeave(this.myItem.id).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      this.leave_type = resp.items;
    });
  }

  public onSubmit(data) {
    if (data.userId == '') {
      data.userId = this.myItem.id;
    }

    let formData: FormData = new FormData();
    formData.append('id', String(this.leave.id));
    formData.append('userId', String(this.leave.userId));
    formData.append('leaveType', String(this.leave.leaveType));
    formData.append('reason', this.leave.reason);
    formData.append('fromDate', this.leave.fromDate);
    formData.append('toDate', this.leave.toDate);
    formData.append('remark', this.leave.remark);
    formData.append('noDays', String(this.leave.noDays));
    formData.append('approvalStatus', String(this.leave.approvalStatus));
    formData.append('halfdayDates', this.selectedDate.toString());
    // formData.append('description',this.leave.description);

    if (this.leave.id == 0) {

      this.leaveService.add(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.leaveService.insertSuccess();
          this.location.back();
          this.getLeave();
        }
        else {
          this.router.navigate(['leave/addleave']);
        }
      });
    }
    else {
      this.leaveService.update(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.leaveService.updateSuccess();
          this.location.back();
          this.getLeave();
        }
        else {
          this.router.navigate(['leave/addleave']);
        }
      });
    }
  }

  public countDays() {
    this.leave.noDays = 0;
    this.selectedDate = [];
    this.dateRange = [];

    if(this.leave.fromDate != '' && this.leave.toDate == ''){
      this.leave.toDate = this.leave.fromDate;
      this.minDate = this.leave.fromDate;
    }else if(this.leave.fromDate == '' && this.leave.toDate != ''){
      this.minDate = this.today;
      this.leave.fromDate = this.leave.toDate;
    }else if(this.leave.fromDate == '' && this.leave.toDate == '') {
      this.minDate = this.today;
    } else {
      this.minDate = this.leave.fromDate;
    }

    var fromDateTemp = new Date(this.leave.fromDate);
    var toDateTemp = new Date(this.leave.toDate);
   
    if(fromDateTemp.getTime() >= toDateTemp.getTime()){
      toDateTemp = fromDateTemp;
      this.leave.toDate = this.leave.fromDate;
    }
    var diffDays = 0;   
    
    if (this.leave.leaveType != 0) {

      this.leave_type.filter(element => {
        if (element.leave_type_id == this.leave.leaveType) {   

          diffDays=this.dayDifference(fromDateTemp,toDateTemp);

          if(element.opening_balance == 0.5){
            this.leave.noDays = element.opening_balance;
          } else {
            this.leave.noDays = diffDays;
          }
          
          if(diffDays > Math.round(element.opening_balance)) {
            this.leave.toDate = this.leave.fromDate;
            diffDays = 1;
           // this.leave.noDays = 1;
           // this.maxDate=fromDateTemp.setDate( (fromDateTemp.getDate() + diffDays));
            this.maxDate=fromDateTemp.setDate( (diffDays));
            this.maxDate =this.datepipe.transform(this.maxDate,"yyyy-MM-dd");
          } else
          {
            this.maxDate=fromDateTemp.setDate( (fromDateTemp.getDate() + (Math.round(element.opening_balance)- 1)));
            this.maxDate =this.datepipe.transform(this.maxDate,"yyyy-MM-dd");
          }
        }
      });
    }
    this.getDaysArra(new Date(this.leave.fromDate),new Date(this.leave.toDate));
    //this.leave.noDays = diffDays;
  }

  public dayDifference(from,to){
    var timeDiff = Math.abs(from - to);
    return Math.ceil(timeDiff / (1000 * 3600 * 24)+1);
  }

  public getData(id: number) {
    this.leaveService.getItem(id).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        var fdate = this.datepipe.transform(resp.items.fromDate, 'yyyy-MM-dd');
        var tdate = this.datepipe.transform(resp.items.toDate, 'yyyy-MM-dd');
        resp.items.fromDate = fdate;
        resp.items.toDate = tdate;
        this.leave = resp.items;
      }
    );
  }

  public updateTotalLeave(e) {
    if (this.leave.leaveType != 0) {
      this.leave_type.filter(element => {
        if (element.leave_type_id == this.leave.leaveType) {
          if (e.target.value > parseInt(element.opening_balance)) {
            e.target.value = element.opening_balance;
          }
        }
      });
    }
  }

  public updateDateRange(obj,event,i)
  {
    if(event.target.checked)
    {
      this.dateRange.forEach(element => {       
        if(element.date == obj.date){
          element.isSelected=1;
          this.leave.noDays = this.leave.noDays - 0.5;
           this.selectedDate.push(obj.date);
        }
     });
    }else
    {
      this.dateRange.forEach(element => {
        if(element.date == obj.date){
          element.isSelected = 0;
          this.leave.noDays = this.leave.noDays + 0.5;
          var index = this.selectedDate.indexOf(new Date(obj.date));
          if (index < 0) {
            this.selectedDate.splice(index, 1);
          }
        }
      });
    }
  }

  public isAllowed()
  {
    let dte = new Date(this.today);
    dte.setDate(dte.getDate() - this.myItem.no_of_days_limit_for_leave);   
    this.NoOfDaysLimitForLeave = this.convertDate(dte);
  }
  
}
