import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { App_Url } from '../globals';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})

export class AdminService {
  
  constructor(private http: HttpClient,public toastr: ToastrService) { }
  public key ='loginData';
  public myItem:any;

  public getTotalCount(parentId):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('parent_id',parentId);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/getAllTotalCountForSuperadmin`,formData);
  }

  getApiToken(){ 
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    var api_token = this.myItem.tokenKey;
  // var api_token ='59240171-6f5c-42e3-8d87-0044ac120184';
   return api_token ;
  }
}
