export class LeaveModel {

    id: number;
    userId : number;
    leaveType : number;
    reason : string;
    fromDate : string;
    toDate : string;
    remark : string;
    noDays : number;
    approvalStatus : number;
    description : string;
    
  

    constructor(params:any) {
        const model = params || {};
        this.id =model.id || 0;
        this.userId =model.userId || '';
        this.leaveType =model.leaveType || '0';
        this.fromDate =model.fromDate || '';
        this.toDate =model.toDate || '';
        this.remark =model.remark || '';
        this.noDays =model.noDays || '0';
        this.approvalStatus =model.approvalStatus || '0';
        this.description =model.description || ' ';

    }
}
