import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import { App_Url } from '../globals';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class DriverDeliveryService {

  constructor(private http: HttpClient, public toastr: ToastrService) { }
  public key = 'loginData';
  public myItem: any;




  public getData(ids: any, startDate, endDate, loginUser): Observable<any> {

    let formData: FormData = new FormData();
    formData.append('user_id', ids);
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    formData.append('loginUser', loginUser);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/getAllDeliveryData`, formData);
  }

  public approveDelivery(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/approved_delivery_status`, formData);
  }

  message(message) {
    this.toastr.success(message, 'Success!');
  }

  error(message) {
    this.toastr.error('', message, { "timeOut": 2000 });
  }

  deleteSuccess() {
    this.toastr.success('Category has been deleted successfully', 'Success!');
  }

  getApiToken() {
    //var api_token ='59240171-6f5c-42e3-8d87-0044ac120184';
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    var api_token = this.myItem.tokenKey;
    return api_token;
  }

}
