import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ChartistModule } from 'ng-chartist';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchHeightModule } from "../shared/directives/match-height.directive";

import { DocumentRoutingModule ,DocumentList } from "./document-routing.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';
import { TagInputModule } from 'ngx-chips';



@NgModule({
    imports: [
        CommonModule,
        DocumentRoutingModule,
        ChartistModule,
        NgbModule,
        MatchHeightModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgxDatatableModule,
        DataTablesModule,
        TagInputModule
    ],   
    exports: [],
    declarations: [
        DocumentList,
        
        
        
        
    ],  
    providers: [], 
})
export class DocumentModule{ }
