
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule,ReactiveFormsModule }   from '@angular/forms';
import { HashLocationStrategy, LocationStrategy,PathLocationStrategy } from '@angular/common';
import { NgbModule,NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "./shared/shared.module";
import { ToastrModule } from 'ngx-toastr';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';

import { AppComponent } from './app.component';
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { DragulaService } from 'ng2-dragula';
import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';

import * as $ from 'jquery';

import { AdminModule } from "./admin/admin.module";
import { EmployeeModule } from "./employee/employee.module";
//import { LoginPageModule } from "./login/login-page.module";
import { ForgotPasswordPageComponent } from "./forgot-password/forgot-password-page.component";
import { LoginPageComponent } from './login/login-page.component';
import { ClientModule } from "./client/client.module";
import { ErrorPageComponent } from "./error/error-page.component";
import { DsrModule } from "./dsr/dsr.module";
//import { DesignationModule } from "./designation/designation.module";
import { ExcelService } from './service/excel.service';
//import { SalaryDataComponent } from './salary-data/salary-data.component';

//import { PayrollsComponent } from './payrolls/payrolls.component';


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  }

@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        ContentLayoutComponent,
        ForgotPasswordPageComponent,
        LoginPageComponent,
        ErrorPageComponent,
       // SalaryDataComponent,
       
        //PayrollsComponent
            
    ],
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        StoreModule.forRoot({}),
        AppRoutingModule,
        SharedModule,
        AdminModule,
        ClientModule,
        EmployeeModule,
        //LoginPageModule,
     //   DesignationModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        NgbModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
              }
        }),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBr5_picK8YJK7fFR2CPzTVMj6GG1TtRGo'
        }),
        DsrModule,
        ReactiveFormsModule,
       
        
    ],
    providers: [
        AuthService,
        AuthGuard,
        DragulaService,
        ExcelService,
       {provide: LocationStrategy, useClass: HashLocationStrategy},
       NgbActiveModal,
    ],
    bootstrap: [AppComponent],
   
})
export class AppModule { }