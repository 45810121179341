import { Component, OnInit ,ViewChild } from '@angular/core';
import { ClientModel } from "../client.model";
import { ClientService } from "../client.service";
import { Router, ActivatedRoute, Params  } from "@angular/router";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { SidebarService } from '../../shared/sidebar/sidebar.service';

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.scss']
})
export class ClientProfileComponent implements OnInit {
  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;
  myForm: FormGroup;

  currentPage: string = "About";
  company:ClientModel = new ClientModel({});
  constructor(private clientService : ClientService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private sidebarService : SidebarService,) { }
    public editStatus = true;
    public key ='loginData';
    public myItem:any;
    public  flag;
    public permission;
    public companyId;

    public totalCustomer;
    public totalCustomerEmployee;
    public totalEmployee;
    public totalPresent;
    public totalAbsent;

  ngOnInit() {
    //session
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.permission= this.sidebarService.getCurrentPermission();


    //validation
    this.myForm = new FormGroup({
      'company_name': new FormControl(null, [Validators.required]),
      'company_location': new FormControl(null, [Validators.required]),
      'company_address': new FormControl(null, [Validators.required]),
      'company_email': new FormControl(null, [Validators.required, Validators.email]),
      'company_number': new FormControl(null, [Validators.required]),
     
  }, {updateOn: 'change'});


    this.route.params.subscribe(
      (params: Params) => {
        if (params.id) { 
          this.companyId = params.id;       
          this.getData(params.id);
        }
      }
    );

    $.getScript('./assets/js/vertical-timeline.js');
    $(document).ready(function(){
      $("#about").addClass("tabactive");
    });


    this.getAllTotalCount(this.companyId);
  }

    showPage(page: string) {
      this.currentPage = page;
  }

 
    getData(id : number)
    {
    this.clientService.getItem(id).subscribe(
      resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success){
          this.company = resp.items;
        }
        
      }
    );
    }

    public  getAllTotalCount(id : number)
    {
    this.clientService.getAllTotalCount(id).subscribe(
      resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }

        if(resp.success){
          this.totalCustomer = resp.items.totalCustomer;
          this.totalCustomerEmployee = resp.items.totalCustomerEmployee;
          this.totalEmployee = resp.items.totalEmployee; 
          this.totalPresent = resp.items.totalPresent;
          this.totalAbsent = resp.items.totalAbsent;
        }
      }
    );
    }

  

   public getEdit(id)
   {
     this.router.navigate(['client/addclient',id]);
   }

  
   public backClicked()
  {
    this.router.navigate(['admin/client']);
    ///this.location.back();
  }   
}
