export class EmployeeModel {

    id: number;
    employeeCode :string;
    first_name :string;
    parent_id : number;
    last_name:string;
    middle_name :string;
    gender :number;
    birth_date: string;
    joining_date : Date;    
    email :string;
    contact_no:string;
    department :number;
    designation :number;
    address: string;
    present_address : string;
    city : string;
    state :string;
    zipcode:string;
    is_admin :number;
    created_by :number;
    company_id :number;
    role_id :number;
    is_manager :number;
    profileImage: File;
    password : string;

    //other details

    motherName :string ;
    ctc :number;
    takeHome :number;
    grossSalary :number;
    maritalStatus : number;
    spouseName :string ;
    uaNumber :string ;
    panNumber :string ;
    adharNumber :string ;
    esicNumber :string ;
    tenure :string ;
    bankName :string ; 
    bankAccountNumber :string ;
    ifscCode :string ;
    designation_name : string ;
    geofencing : number;
    latitude : number;
    longitude : number;
    distance : number;
    spouse_contact_no:string;
    qualification:string;
    weekend_days:string;
    branch_id:number;
    time_zone_id:number;
    status : string;
    passportNumber:string;
    passport_issue_date:string;
    passport_expiry_date:string;
    nic_no:string;
    vissaNumber:string;
    vissa_issue_date:string;
    vissa_expiry_date:string;
    vissaStatus:string;
    emirates:string;
    emirateCode:string;
    emiratesExpiryDate:string;
    licenNo:string;
    licenExpiryDate:string;
    licenType:string;
 

    constructor(params:any) {
        const model = params || {};
        this.id =model.id || 0;
        this.parent_id =model.parent_id || '';
        this.first_name =model.first_name || '';
        this.employeeCode =model.employeeCode || '';
        this.middle_name =model.middle_name || '';
        this.last_name =model.last_name || '';
        this.gender =model.gender || 0;
        this.birth_date =model.birth_date || '';
        this.joining_date =model.joining_date || '';
        this.email =model.email || '';
        this.contact_no =model.contact_no || '';
        this.department =model.department || '';
        this.designation =model.designation || '';
        this.address =model.address || '';
        this.present_address = model.present_address || '';
        this.city =model.city || '';
        this.state =model.state || '';
        this.zipcode =model.zipcode || '';
        this.is_admin =model.is_admin || 0 ;
        this.created_by =model.created_by || '';
        this.company_id =model.company_id || '';
        this.role_id =model.role_id || '';
        this.is_manager =model.is_manager || 0;
        this.profileImage =model.profile_image || '';
        this.password =model.password || '';
        this.weekend_days =model.weekend_days || '';
      
 
        this.motherName =model.motherName || '';
        this.ctc =model.ctc || '';
        this.takeHome =model.takeHome || '';
        this.grossSalary =model.grossSalary || '';
        this.maritalStatus =model.maritalStatus || 0 ; 
        this.spouseName =model.spouseName || '';
        this.uaNumber =model.uaNumber || '';
        this.panNumber =model.panNumber || '';
        this.adharNumber =model.adharNumber || '';
        this.esicNumber =model.esicNumber || '';
        this.tenure =model.tenure || '';
        this.bankName =model.bankName || '';
        this.bankAccountNumber =model.bankAccountNumber || '';
        this.ifscCode =model.ifscCode || '';
        this.designation_name =model.designation_name || '';
        this.geofencing =model.geofencing || 0 ; 
        this.distance =model.distance || '' ; 
        this.latitude =model.latitude || '' ; 
        this.longitude =model.longitude || '' ; 
        this.spouse_contact_no =model.spouce_contact_no || '' ; 
        this.qualification =model.qualification || '' ; 
        this.branch_id =model.branch_id || '';
        this.time_zone_id =model.time_zone_id ||'';
        this.status =model.status ||1;

        this.passportNumber =model.passportNumber || '';
        this.passport_issue_date =model.passport_issue_date || '';
        this.passport_expiry_date =model.passport_expiry_date || '';
        this.nic_no =model.nic_no || '';
        this.vissaNumber =model.vissaNumber || '';
        this.vissa_issue_date =model.vissa_issue_date || '';
        this.vissa_expiry_date =model.vissa_expiry_date || '';
        this.vissaStatus =model.vissaStatus || '';

        this.emirates =model.emirates || '';
        this.emirateCode =model.emirateCode || '';
        this.emiratesExpiryDate =model.emiratesExpiryDate || '';
        this.licenNo =model.licenNo || '';
        this.licenExpiryDate =model.licenExpiryDate || '';
        this.licenType =model.licenType || '';

    }
}
