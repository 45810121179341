export class ClientModel {

    id: number;
    parent_id : number;
    company_name :string;
    company_location:string;
    company_address :string;
    company_email :string;
    company_number: string;
    company_logo : File;
    created_by : number;
    created_at : number;
    fullday_working_hours:string;
    halfday_working_hours:string;
    weekend_days:string;
    login_url:string;
    is_custom_login:number;
    status:number;
    time_zone_id:number;
    

    constructor(params:any) {
        const model = params || {};
        this.id =model.id || 0;
        this.parent_id =model.parent_id || 0;
        this.company_name =model.company_name || '';
        this.company_location =model.company_location || '';
        this.company_address =model.company_address || '';
        this.company_email =model.company_email || '';
        this.company_number =model.company_number || '';
        this.company_logo =model.company_logo || '';
        this.created_by = model.created_by ||  '';
        this.created_at = model.created_at ||  '';
        this.fullday_working_hours = model.fullday_working_hours ||  '';
        this.halfday_working_hours = model.halfday_working_hours ||  '';
        this.weekend_days = model.weekend_days ||  '';
        this.login_url = model.login_url ||  '';
        this.is_custom_login = model.is_custom_login || 0;
        this.status = model.status || 1;
        this.time_zone_id = model.time_zone_id || '';

    }
}
