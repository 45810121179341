import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Dashboard1Component } from "./dashboard1/dashboard1.component";
import { EmployeeComponent } from "./employee.component";
import { AddEmployeeComponent } from "../employee/add-employee/add-employee.component";
import { EmployeeProfileComponent } from "../employee/employee-profile/employee-profile.component";
//import { PermissionComponent } from '../permission/permission.component';
import { BulkEmployeeComponent } from "./bulk-employee/bulk-employee.component";
import { AuthGuard } from './auth/auth.guard';
import { ImportCtcComponent } from './import-ctc/import-ctc.component';



const routes: Routes = [
  {
    path: '',  
    
    children: [   
        { path: 'employee', component: EmployeeComponent},
        // { path: 'employee/:id', component: EmployeeComponent},
        { path: 'dashboard',
         component: Dashboard1Component,
         data: {title: 'dashboard'},
        // canActivate: [AuthGuard]    
        },
       {
        path: 'addemployee/:id',
        component: AddEmployeeComponent,
        data: {
          title: 'employee'
        }
      },
      {
        path: 'client/employee/:id',
        component: EmployeeComponent,
        data: {
          title: 'employee'
        }
      },

      {
        path: 'addemployee',
        component: AddEmployeeComponent,
        data: {
          title: 'employee'
        }
      },
      {
        path: 'employeeProfile/:id',
        component: EmployeeProfileComponent,
        data: {
          title: 'employeeProfile'
        }
      },
      // {
      //   path: 'permission',
      //   component: PermissionComponent,
      //   data: {
      //     title: 'permission'
      //   }
      // },
      {
        path: 'bulk-employee/:id',
        component:BulkEmployeeComponent,
        data: {
          title: 'employee'
        }
      },

      {
        path: 'import-ctc',
        component: ImportCtcComponent,
        canActivate: [AuthGuard]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EmployeeRoutingModule { }

export const EmployeeComponentList = [
  Dashboard1Component,
  EmployeeComponent,
  AddEmployeeComponent,
  EmployeeProfileComponent,
 // PermissionComponent,
  BulkEmployeeComponent,
  ImportCtcComponent

];

