export class DocumentCategoryModel {

    id : number;
    document_type :string;
    created_by : number;
    status :number;
    company_id : number;
   
    
  

    constructor(params:any) {
        const model = params || {};
        this.id =model.id || 0;
        this.document_type =model.department_name || '';
        this.created_by =model.created_by || '';
        this.status =model.status || 1 ;
        this.company_id =model.company_id || '';
    }
}
