import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params  } from "@angular/router";
import { DocumentCategoryService } from "./document-category.service";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { SidebarService } from '../shared/sidebar/sidebar.service';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-document-category',
  templateUrl: './document-category.component.html',
  styleUrls: ['./document-category.component.scss']
})
export class DocumentCategoryComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  @ViewChild("subCategoryModal") private subCategoryModal;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  public data:  any[] = [];
  public data1:  any[] = [];
  public key ='loginData';
  public myItem:any;
  public company_id;
  public permission;
  public openStatus: number;

  constructor(private router:Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private documentCategoryService : DocumentCategoryService,
    private sidebarService : SidebarService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.permission= this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.getData();
     
    this.dtOptions = {
      //used id for index 
      order: [[0, "DESC"]],
    };
    
     this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.company_id = params.id;
        if(this.company_id == 0)
        {
          this.company_id = this.myItem.company_id;
        }       
      }
    );
  }

  public addDocumentCategory()
  {
    this.router.navigate(['document-category/add-document-category']);
  }

  public getData()
   {  
      let formData: FormData = new FormData();
      formData.append('role_id',this.myItem.role_id);
      formData.append('company_id',this.myItem.company_id);
      formData.append('flag','get');
      this.documentCategoryService.getCategory(formData).subscribe(resp=>{
        if(resp.status == 401)
        {
          this.router.navigate([this.myItem.url]);
        }
        this.data=resp.items;
        // this.data1 = this.data.filter(
        //   book => book.parent_id === 0);
        // this.data1.filter(element=>{
        //   element.subCategory =  this.data.filter(ele=>ele.parent_id == element.id);
        // });
        //this.rerender();
     });
    
  }

  public getEdit(id:number)
    {
      this.router.navigate(['document-category/add-document-category',id]);
    }

    public Delete(id: number, i: number)
    {
      if(window.confirm('Are you sure you want to delete?')) {
        this.documentCategoryService.delete(id).subscribe(resp=>{
          if(resp.status == 401)
          {
              this.router.navigate([this.myItem.url]);
          }
          if(resp.success){
            this.documentCategoryService.deleteSuccess();
            this.data.splice(i, 1);
          //  this.rerender();
             this.getData();
          }
        });
      }
    }

    public employeeList(id:number)
    {
      this.router.navigate(['document-category/employee',{typeId: id, company_id:this.company_id }]);
    }
 
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
  
    // rerender(): void {
    //     this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //       dtInstance.destroy();
    //       this.dtTrigger.next();
    //     });
    // }
  
    // ngAfterViewInit(): void {
    //   this.dtTrigger.next();
    //   this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //     dtInstance.columns().every(function () {
    //       const that = this;
  
    //       // For checked fields
    //       $('input[type=checkbox]', this.footer()).on('checked change', function () {
    //         if (this['value'] === 'true') {
    //           that.search(this['value']).draw();
    //         } else {
    //           that.search('').draw();
    //         }
    //       });
  
    //       $('input[type=text]', this.footer()).on('keyup change', function () {
    //         console.log("text : " + this['value']);
    //         if (that.search() !== this['value']) {
    //           that.search(this['value']).draw();
    //         }
    //       });
    //     });
    //   });
    // }

  public backClicked()
  {
    this.location.back();
  } 

  public openPanel(val) {
    if (this.openStatus === val.id) {
      this.openStatus = 0;
    } else {
      this.openStatus = val.id;
    }
  }
   
}
