import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { AttendanceService } from "../attendance/attendance.service";
import { DriverDeliveryService } from "./driver-delivery.service";
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
//import { start } from 'repl';
import { TravelMarker, TravelMarkerOptions, TravelData, TravelEvents } from 'travel-marker';
import { Img_Url } from '../globals';
import { ExcelService } from '../service/excel.service';
import { logo_url } from '../globals';
import { element } from '../../../node_modules/@angular/core/src/render3/instructions';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'app-driver-delivery',
  templateUrl: './driver-delivery.component.html',
  styleUrls: ['./driver-delivery.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DriverDeliveryComponent implements OnInit {

  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  @ViewChild('addAttendanceModal') addAttendanceModal: ElementRef;
  public key = 'loginData';
  public myItem: any;
  public data: any[] = [];
  public excelData: any[] = [];
  public employee_id;
  public attendanceDetailDivShow = false;
  public currentDeliveryDataDivShow = false;

  public employeeIds = [];
  public employeeList: any = [];
  public employeeListAll: any = [];
  public attendanceDetail: any = [];
  public currentDeliveryData: any = [];
  public today = this.convertDate(new Date());
  public addAttendanceModalRef;
  myForm: FormGroup;

  //public startDate = '2018-10-16';
  //public endDate = '2018-10-16';

  public startDate = this.convertDate(new Date());
  public endDate = this.convertDate(new Date());
  public startSearchDate = this.convertDate(new Date());
  public endSearchDate = this.convertDate(new Date());
  public employeeIdsSearch = [];
  public selectedStag = { id: '', title: '' };
  public tagList: any[] = [];
  public tagForDispaly = [];
  public mapClick = false;
  public mapImage;
  public opacity;
  public icon;
  public customLoadingTemplate;



  public options = {
    readonly: undefined,
    placeholder: '+ Add Name',
    secondaryPlaceholder: '',
  };
  public autocompleteItemsAsObjects: any = [];


  public companyId = '';
  public pauseDetails: any[] = [];
  public companyName;
  public companyLogo;
  public absent;
  public present;
  public leaves;
  public weekEnds;
  public holidays;
  public loading = false;
  public received = 0;
  public delivered = 0;

  coordinates = [];
  // coordinates=[
  //   {lat:22.307159,lng:73.181221},
  //   {lat:22.563200,lng:72.960281},
  //   {lat:22.775680,lng:73.606770},
  //   ];
  zoom: number = 15;

  // initial center position for the map
  lat: number = 0;
  lng: number = 0;
  public selectedName;
  public status: boolean = false;
  public markAttendanceEmployeeId;

  constructor(private router: Router,
    private location: Location,
    private attendanceService: AttendanceService,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private excelService: ExcelService,
    private modalService: NgbModal,
    private driverDeliveryService: DriverDeliveryService) { }

  ngOnInit() {

    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.mapImage = Img_Url + 'img/' + 'map.png';
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        if (params.id) {
          this.companyId = params.id;
        }
        else {
          this.companyId = this.myItem.company_id;
        }

      }
    );


    this.get_employeeList();
    this.getCompanyName();

    this.myForm = new FormGroup({
      'id': new FormControl(null),
      'punchDate': new FormControl(null, [Validators.required]),
      'inTime': new FormControl(null, [Validators.required]),
      'outTime': new FormControl(null, [Validators.required]),
      'reason': new FormControl(null, [Validators.required]),
    }, { updateOn: 'change' });

  }




  // public get_data(id) {
  //   this.attendanceService.get_data(id).subscribe(resp => {
  //     if (resp.status == 401) {
  //       this.router.navigate([this.myItem.url]);
  //     }
  //     this.data = resp.items;
  //   });
  // }

  public get_data_web(ids, startDate, endDate) {
    this.data = [];
    this.driverDeliveryService.getData(ids, startDate, endDate, this.myItem.id).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      this.data = resp.items.delivery_data;
      this.received = resp.items.received;
      this.delivered = resp.items.delivered;

      this.data.filter(element=>{
        if(element.id == this.currentDeliveryData.id){
          element.active ='selected';
        }
      });
      
    });


  }



  public get_employeeList() {

    if (this.myItem.role_id == 4) {
      this.attendanceService.getEmployeeForManager(this.myItem.id).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.employeeList = resp.items;
        this.employeeListAll = resp.items;
        this.autocompleteItemsAsObjects = resp.items;
        this.employeeList.forEach(element => {
          this.employeeIds.push(element.id);
        });

      });
    }
    else {
      this.attendanceService.employeeList(this.companyId).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.employeeList = resp.items;
        this.employeeListAll = resp.items;
        this.autocompleteItemsAsObjects = resp.items;
        this.employeeList.forEach(element => {
          this.employeeIds.push(element.id);
          //this.autocompleteItemsAsObjects.push({value:"'"+element.user_name+"'", id: element.id, extra: 0});
        });

      });
    }
  }



  //  public exportAsXLSX():void {
  //     this.excelService.exportAsExcelFile(this.data, 'sample');
  //   }

  // public get_employeeData(ids)
  // {
  //   this.attendanceService.employeeList(ids).subscribe(resp=>{  

  //     this.employeeList=resp.items;
  //     this.employeeList.forEach(element => {
  //       this.employeeIds.push(element.id);
  //     });
  //     this.get_data_web(this.employeeIds,this.startDate,this.endDate);
  //   });   
  // }



  ngOnDestroy(): void {

  }



  ngAfterViewInit(): void {
  }

  public backClicked() {
    this.location.back();
  }

  public showDetailToggle(id) {



   // this.attendanceDetail = [];
    this.data = [];

    // }else{      
    this.attendanceDetailDivShow = true;
    this.currentDeliveryDataDivShow = false;

    //  }

    if (this.attendanceDetailDivShow) {
      this.get_data_web(id, this.startDate, this.endDate);
    }

  }

  public openDeliveryDataToggle(i, value) {

    this.data.forEach(element => {
      if (element.date == value.date) {
        element.active = 'selected';
      }
      else {
        if (element.active == 'selected') {
          element.active = '';
        }
      }
    });

    this.currentDeliveryData = [];
    if(this.currentDeliveryDataDivShow){     
       this.currentDeliveryDataDivShow = false;
    }else{      
    this.currentDeliveryDataDivShow = true;
    this.currentDeliveryData = this.data[i];
    }

  }

  search() {

    this.startDate = this.startSearchDate;
    this.endDate = this.endSearchDate;
    this.attendanceDetailDivShow = false;
    this.currentDeliveryDataDivShow = false;
    this.data = [];
    if (this.employeeIdsSearch.length == 0) {
      this.get_employeeList();
    } else {
      this.employeeList = this.employeeListByArray(this.employeeIdsSearch);
    }
  }


  public convertDate(date) {
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth() + 1).toString();
    var dd = date.getDate().toString();

    var mmChars = mm.split('');
    var ddChars = dd.split('');

    return yyyy + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + (ddChars[1] ? dd : "0" + ddChars[0]);
  }

  onItemAdded(tag) {
    let obj = { id: '', title: '' };
    if (tag.id) {
      obj = { id: tag.id, title: tag.user_name };
    } else {
      obj = { id: '', title: tag.value };
    }
    this.tagList.push(obj);
    this.employeeIdsSearch = this.tagList.map(({ id }) => id)
  }


  onItemRemoved(tag) {
    var index = this.tagList.findIndex(x => x.id == tag.id);
    this.tagList.splice(index, 1);
    this.employeeIdsSearch = this.tagList.map(({ id }) => id)
  }

  remove_duplicates(a, b) {
    for (var i = 0, len = a.length; i < len; i++) {
      for (var j = 0, len2 = b.length; j < len2; j++) {
        if (a[i].id === b[j].id) {
          b.splice(j, 1);
          len2 = b.length;
        }
      }
    }
  }

  searchDateChange(e) {
    var date1 = new Date(this.startSearchDate);
    var date2 = new Date(this.endSearchDate);
    if (Date.parse(this.startSearchDate) > Date.parse(this.endSearchDate)) {
      this.endSearchDate = this.startSearchDate;
    }
  }



  employeeListByArray(idArray) {
    this.attendanceService.employeeListByArray(idArray).subscribe(resp => {
      this.employeeList = resp.items;
      this.employeeList.forEach(element => {
        this.employeeIds.push(element.id);
      });
    });
  }



  reset() {

    this.attendanceDetailDivShow = false;
    this.currentDeliveryDataDivShow = false;
    this.startDate = this.convertDate(new Date());
    this.endDate = this.convertDate(new Date());
    this.startSearchDate = this.convertDate(new Date());
    this.endSearchDate = this.convertDate(new Date());
    this.employeeIdsSearch = [];
    this.tagForDispaly = [];
    this.get_employeeList();

  }

  ConvertToString(value) {
    return String(value);
  }

  public exportAsXLSX(flag): void {

    this.startDate = this.startSearchDate;
    this.endDate = this.endSearchDate;
    this.loading = true;
    if (this.employeeIdsSearch.length != 0) {

      this.attendanceService.employeeListByArray(this.employeeIdsSearch).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.employeeList = resp.items;
        this.attendanceService.getAttendanceExcelData(this.startDate, this.endDate, this.companyId, flag).subscribe(resp => {
          this.loading = false;
          this.excelData = resp;
          this.excelService.exportAsExcelFile(this.excelData, 'Attendance');
        });
      });
    }
    else {
      this.attendanceService.getAttendanceExcelData(this.startDate, this.endDate, this.companyId, flag).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.loading = false;
        this.excelData = resp;
        this.excelService.exportAsExcelFile(this.excelData, 'Attendance');
      });
    }


  }

  // public exportAsXLSXReport():void{
  //   this.attendanceService.getAttendanceExcelDataReport(this.employeeList,this.startDate,this.endDate).subscribe(resp=>{


  //   });
  // }


  public getCompanyName() {
    this.attendanceService.getCompanyName(this.companyId).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      this.companyName = resp.items.company_name;
      this.companyLogo = resp.items.company_logo;
    });
  }

 

  public setTime() {
    if (this.myForm.get('outTime').value < this.myForm.get('inTime').value) {
      this.status = true;
      this.myForm.get('outTime').setValidators;
    } else {
      this.status = false;
    }
  }

  public updateStatus(e,flag)
  {
    if(flag=="status"){
      this.currentDeliveryData.status = parseInt(e.target.value);
    }

    if(flag=="remark"){
      this.currentDeliveryData.remark = e.target.value;
    }
  }

  public approveStatus()
  {
    let formData: FormData = new FormData();
    formData.append('approved_by', this.myItem.id);
    formData.append('status',this.currentDeliveryData.status);
    formData.append('remark',this.currentDeliveryData.remark);
    formData.append('id',this.currentDeliveryData.id);

    this.driverDeliveryService.approveDelivery(formData).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.get_data_web(resp.items.user_id, this.startDate, this.endDate);
      }
    });
  }
}
