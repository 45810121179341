import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { FileSelectDirective, FileDropDirective, FileUploader, FileLikeObject } from 'ng2-file-upload';
import { logo_url } from '../../globals';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NewsService } from '../news.service';
import { Location } from '@angular/common';
import { EmployeeService } from "../../employee/employee.service";
import { DatePipe } from '@angular/common';


function readBase64(file): Promise<any> {
  var reader = new FileReader();
  var future = new Promise((resolve, reject) => {
    reader.addEventListener("load", function () {
      resolve(reader.result);
    }, false);
    reader.addEventListener("error", function (event) {
      reject(event);
    }, false);
    reader.readAsDataURL(file);
  });
  return future;
}

const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.scss']
})
export class AddNewsComponent implements OnInit {

  myForm: FormGroup;
  public compulsoryField = 'This field is required';
  public selectedFile: File = null;
  public selectedFileView: any;
  public uploader: FileUploader = new FileUploader({ url: URL, disableMultipart: true });
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;
  public key = 'loginData';
  public myItem: any;
  public companyId;
  public employeeList: any = [];
  public AllEmployeeSelected = false;
  public selectedEmployeeList: any = [];


  public employeeIdsSearch: any = [];
  public selectedStag = { id: '', title: '' };
  public tagList: any[] = [];
  public tagForDispaly = [];
  public autocompleteItemsAsObjects: any = [];
  public employeeIds = [];
  public editStatus = false;

  public options = {
    readonly: undefined,
    placeholder: '+ Add Name',
    secondaryPlaceholder: '',
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private newsService: NewsService,
    private employeeService: EmployeeService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.selectedFileView = logo_url + 'logo-default.png';
    this.myItem = JSON.parse(localStorage.getItem(this.key));


    this.route.params.subscribe(
      (params: Params) => {
        if (params.companyId) {
          this.companyId = params.companyId;
          this.getEmployee();
        } else {
          this.companyId = this.myItem.company_id;
          this.getEmployee();
        }
        if (params.id) {
          this.editStatus = true;
          this.getData(params.id);
        }

      });

    this.myForm = new FormGroup({
      'title': new FormControl('', [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/]*)?$')]),
      'message': new FormControl('', [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/]*)?$')]),
      'start_time': new FormControl('',[Validators.required]),
      'end_time': new FormControl('',[Validators.required]),
      'status': new FormControl(1),
      'image_url': new FormControl(''),
      'youtube_video_code': new FormControl('',[Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/]*)?$')]),
      'id': new FormControl(0),
      'company_id': new FormControl(null),
      'created_by': new FormControl(''),

    }, { updateOn: 'change' });


  }


  onFileChange(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
    this.selectedFile = file;
    readBase64(file).then(function (data) {
      //this.selectedFileView = data;   
      $('#selectedFileView').attr('src', data);
    });
  }


  public onSubmit() {
    let formData: FormData = new FormData();
    this.myForm.get('company_id').setValue(this.companyId);
    this.myForm.get('created_by').setValue(this.myItem.id);
    formData.append('image_url', this.selectedFile);
    formData.append('seledted_employees', this.employeeIdsSearch.toString());
    formData.append('data', JSON.stringify(this.myForm.value));

    if (this.myForm.get('id').value == 0) {
      this.newsService.add(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.newsService.insertSuccess();
          this.location.back();
        }
        else {
        }
      });
    }
    else {
      this.newsService.update(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.newsService.updateSuccess();
          this.location.back();
        }
        else {
          this.router.navigate(['departments/adddepartments']);
        }
      });
    }
  }

  public getEmployee() {
    if (this.myItem.role_id != 4) {
      this.employeeService.getdata(this.companyId).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }

        if (resp.success) {
          this.employeeList = resp.items;
          // this.employeeList.filter(element=>{
          //   element.isSelected = 1;
          // });
          // this.selectedEmployeeList = 0;
          // this.AllEmployeeSelected = true;

          this.autocompleteItemsAsObjects = resp.items;
          // this.employeeList.forEach(element => {
          // this.employeeIds.push(element.id);    
          //  });
        }
      });
    }
    else if (this.myItem.role_id == 4) {
      this.employeeService.getEmployeeForManager(this.myItem.id).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }

        if (resp.success) {
          this.employeeList = resp.items;
          this.autocompleteItemsAsObjects = resp.items;
        }
      });
    }
  }

  // public updateAll(e)
  // {
  //     if(e.target.checked){
  //       this.employeeList.filter(element=>{
  //         element.isSelected = 1;
  //       });
  //       this.selectedEmployeeList = 0;
  //       this.AllEmployeeSelected = true;
  //     } else {
  //        this.employeeList.filter(element=>{
  //         element.isSelected = 0;
  //       });
  //       this.selectedEmployeeList = [];
  //       this.AllEmployeeSelected = false;
  //     }

  // }

  // public updateEmployees(e)
  // {
  //   if(e.target.checked){
  //     this.employeeList.filter(element=>
  //       {
  //         if(element.id == e.target.value){
  //           element.isSelected = 1;
  //           this.selectedEmployeeList.push(e.target.value);
  //         }
  //       });
  //   } else {
  //     this.employeeList.filter(element=>
  //     {
  //       if(element.id == e.target.value){
  //         element.isSelected = 0;
  //         if(this.selectedEmployeeList.length > 0){
  //           var index = this.selectedEmployeeList.indexOf(e.target.value);
  //           if (index < 0) {
  //             this.selectedEmployeeList.splice(index, 1);
  //           }
  //         }
  //       }
  //     });
  //   }
  //   this.AllEmployeeSelected = true;
  //   this.selectedEmployeeList =[];
  //   this.employeeList.forEach(element => {
  //     if(element.isSelected == 0){
  //       this.AllEmployeeSelected = false;
  //     } else {
  //       this.selectedEmployeeList.push(element.id);
  //     }
  //   });
  //   if(this.AllEmployeeSelected == true){
  //     this.selectedEmployeeList = 0;
  //   }
  // }

  onItemAdded(tag) {
    let obj = { id: '', title: '' };
    if (tag.id) {
      obj = { id: tag.id, title: tag.user_name };
    } else {
      obj = { id: '', title: tag.value };
    }
    this.tagList.push(obj);

    //console.log(this.tagList);
    this.employeeIdsSearch = this.tagList.map(({ id }) => id);
    console.log(this.employeeIdsSearch);
  }


  onItemRemoved(tag) {
    var index = this.tagList.findIndex(x => x.id == tag.id);
    this.tagList.splice(index, 1);
    this.employeeIdsSearch = this.tagList.map(({ id }) => id)
  }

  public getData(id: number) {
    this.newsService.getItem(id).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.myForm.get('id').setValue(resp.items.id);
        this.myForm.get('title').setValue(resp.items.title);
        this.myForm.get('message').setValue(resp.items.message);
        this.myForm.get('youtube_video_code').setValue(resp.items.youtube_video_code);
        this.myForm.get('status').setValue(resp.items.status);
        this.myForm.get('start_time').setValue(this.datePipe.transform(resp.items.start_time,"yyyy-MM-ddThh:mm"));
        this.myForm.get('end_time').setValue(this.datePipe.transform(resp.items.end_time,"yyyy-MM-ddThh:mm"));

        if (resp.items.user_id != '' && resp.items.user_id != null) {

          let userId = resp.items.user_id.split(',');

          if (resp.items.user_id == 0) {
            this.employeeIdsSearch = 0;
          } else {

            this.employeeIdsSearch = userId.toString();

            let data1 = this.employeeList;
            userId.filter(ele => {
              let data = data1.find(element => element.id == ele);
              this.tagList.push({ id: data.id, title: data.email });
              this.tagForDispaly.push(data.first_name);
            });
          }
        }
        if (resp.items.image_url != '') {
          this.selectedFileView = resp.items.image_url;
        }
      });
  }

  public backClicked()
  {
    this.location.back();
  }
}
