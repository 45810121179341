//
// ===== File globals.ts    
//
'use strict';

export const sep='/';
export const keyRole1 = 'keyRole1';
export const keyRole2 = 'keyRole2';
export const keyRole3 = 'keyRole3';
export const keyRole4 = 'keyRole4';
export const keyRole5 = 'keyRole5';
   
//export const Img_Url: string ="D:/xampp/htdocs/HrmsApi/assets/img/profile_image/";


// for local host
// export const App_Url: string="http://127.0.0.1:8000/"; 
// export const logo_url: string ="http://localhost:4200/assets/img/logos/";
// export const Img_Url: string ="http://localhost:4200/assets/";
// Testing dev
//  export const App_Url: string="http://hrmsdevelopment.aipex.co/hrms_web_backend/";    
//  export const logo_url: string ="http://hrmsdevelopment.aipex.co/hrms_web_backend/assets/img/logos/";
//  export const Img_Url: string ="http://hrmsdevelopment.aipex.co/hrms_web_backend/assets/";

//New Aipex
  export const App_Url: string="http://aipex.co/hrmsApi/";    
  export const logo_url: string ="http://aipex.co/hrmsApi/";
  export const Img_Url: string ="http://aipex.co/hrmsApi/";
    
