import { Component, OnInit,ViewChild } from '@angular/core';
import { ManageLeaveTypeService } from './manage-leave-policy.service';
import { SidebarService } from '../shared/sidebar/sidebar.service';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { data } from 'app/shared/data/smart-data-table';

@Component({
  selector: 'app-manage-leave-policy',
  templateUrl: './manage-leave-policy.component.html',
  styleUrls: ['./manage-leave-policy.component.scss']
})
export class ManageLeavePolicyComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};

  constructor(private manageLeaveTypeService: ManageLeaveTypeService,
    private router: Router,
    private route: ActivatedRoute,
    private sidebarService: SidebarService) { }

  myForm: FormGroup;
  public key = 'loginData';
  public myItem: any;
  public data: any = [];
  public companyName:any;
  public employeeData: any = [];
  public permission;
  public companyId:any;
  public currentPage: string = "Home";
  public leaveType: any = [];
  public companyLeavePolicyData:any=[];
  public  start = 2001;
  public  end = new Date().getFullYear();
  public yearList:any=[];
  public editStatus=false;
  public dates=[];
  public monthList = [
      {value:'01',name:"January"},
      {value:'02',name:"February"},
      {value:'03',name:"March"},
      {value:'04',name:"April"},
      {value:'05',name:"May"},
      {value:'06',name:"June"},
      {value:'07',name:"July"},
      {value:'08',name:"August"},
      {value:'09',name:"September"},
      {value:'10',name:"October"},
      {value:'11',name:"November"},
      {value:'12',name:"December"}];
    

  ngOnInit() {


    for(let i = 1; i <=28; i++){
      this.dates.push(i);
    }

    console.log(this.dates);

    this.permission = this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));
   
    this.dtOptions = {
      //used id for index 
      order: [[0, "DESC"]],
    };

    this.myForm = new FormGroup({
      'id': new FormControl(0),
      'year': new FormControl('', [Validators.required]),
      'start_month': new FormControl('', [Validators.required]),

    }, { updateOn: 'change' });

    for(var year = this.start ; year <=this.end; year++){
      this.yearList.push(year);
    }
    this.route.queryParams.subscribe(
      (params) => {
        console.log(params);
        
        if (params.companyId != undefined) {
          this.companyId= params.companyId
          
        } else {

          this.companyId = this.myItem.company_id;
        }
      });
      console.log(this.companyId);
      this.getLeaveType();
      this.getCompanyLeave();
      this.getEmployee();
      this.getCompany();
  
  }

  showPage(page: string) {
    this.currentPage = page;
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        // For checked fields
        $('input[type=checkbox]', this.footer()).on('checked change', function () {
          if (this['value'] === 'true') {
            that.search(this['value']).draw();
          } else {
            that.search('').draw();
          }
        });

        $('input[type=search]', this.footer()).on('keyup change', function () {
        //  console.log("text : " + this['value']);
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

public getCompany(){
  this.manageLeaveTypeService.getCompany(this.companyId).subscribe(resp=>{
    if(resp.status == 401)
    {
        this.router.navigate([this.myItem.url]);
    }
    if(resp.success) {
      console.log(resp.items); 
      this.companyName = resp.items.company_name;
      console.log(this.companyName);
      // this.data.filter(element=>{
      //   element.value = 0;
      // });
    }     
  });
}
  public getLeaveType()
  {
    this.manageLeaveTypeService.getLeaveType(this.companyId,this.myItem.role_id).subscribe(resp=>{
      if(resp.status == 401)
      {
          this.router.navigate([this.myItem.url]);
      }
      if(resp.success) { 
        this.data = resp.items;
        // this.data.filter(element=>{
        //   element.value = 0;
        // });
      }     
    });
  }

  public getCompanyLeave()
  {
    this.manageLeaveTypeService.getCompanyLeave(this.companyId).subscribe(resp=>{
      if(resp.status == 401)
      {
          this.router.navigate([this.myItem.url]);
      }
      if(resp.success) { 
        this.companyLeavePolicyData = resp.items;
        this.rerender();
      }     
    });
  }

  public addLeaves(e, id) {
    this.data.filter(element => {
      if (element.id == id) {
        element.value = e.target.value;
      }
    });
  }

  public submit() {
    let formData: FormData = new FormData();
    formData.append('year', this.myForm.get('year').value);
    formData.append('start_month', this.myForm.get('start_month').value);
    formData.append('data', JSON.stringify(this.data));
    formData.append('created_by', this.myItem.id);
    formData.append('company_id', this.companyId);


    if (this.editStatus == false) {
      this.manageLeaveTypeService.add(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.manageLeaveTypeService.insertSuccess();
          this.myForm.get('id').setValue(0);
          this.myForm.get('year').setValue('');
          this.myForm.get('start_month').setValue('');
          this.getLeaveType();
          this.getCompanyLeave();
          this.getEmployee();
        } else {
          this.manageLeaveTypeService.alreadyExists();
        }
      });
    }
    else {
      this.manageLeaveTypeService.update(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.editStatus = false;
          this.manageLeaveTypeService.insertSuccess();
          this.myForm.get('id').setValue(0);
          this.myForm.get('year').setValue('');
          this.myForm.get('start_month').setValue('');
          this.getLeaveType();
          this.getCompanyLeave();
          this.getEmployee();
        }
      });
    }
  }

 

  public getEmployee() {
    console.log(this.companyId);
    this.manageLeaveTypeService.getEmployee(this.companyId).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      this.employeeData = resp.items;

      // if(this.employeeData.employeeLeavePolicy.length){
      //   this.employeeData.employeeLeavePolicy = this.employeeData.companyLeavePolicy;
      // }

      if (resp.success) {
        this.employeeData.forEach(element => {
          element.employeePolicy.forEach(employee => {
            element.carryForwordBalance.forEach(carry => {
              if(employee.leave_type_id == carry.leave_type_id){
                element.totalBalance.forEach(balance => {
                  if(balance.leave_type_id == carry.leave_type_id){
                    balance.carry_forword_balance =  parseInt(carry.carry_forword_balance);
                    balance.opening_balance = parseInt(employee.opening_balance) + parseInt(carry.carry_forword_balance); 
                    balance.total_opening_balance = parseInt(employee.opening_balance);
                  }
                });  
              }
            });
          });
        });
      } 
    });
  }

  public changeStatus(e, id, flag) {
    if (flag == 'isCarryForword') {
      this.data.filter(element => {
        if (element.id == id) {
          if (e.target.checked) {
            element.is_carry_forword = 1;
          } else {
            element.is_carry_forword = 0;
          }
        }
      });
    }

    if (flag == 'isActive') {
      this.data.filter(element => {
        if (element.id == id) {
          if (e.target.checked) {
            element.is_active = 1;
          } else {
            element.is_active = 0;
          }
        }
      });
    }

    if (flag == 'isProRata') {
      this.data.filter(element => {
        if (element.id == id) {
          if (e.target.checked) {
            element.is_pro_rata = 1;
          } else {
            element.is_pro_rata = 0;
          }
        }
      });
    }

    if (flag == 'isProRataValue') {
      this.data.filter(element => {
        if (element.id == id) {
          element.is_pro_rata_value = parseInt(e.target.value);
        }
      });
    }

    if (flag == 'isDate') {
      this.data.filter(element => {
        if (element.id == id) {
          element.start_date = parseInt(e.target.value);
        }
      });
    }

    console.log(this.data);
  }

  public getTypeName(id)
  {
    return this.data.find(x => x.id == id).leaveType;
  }

  public getIsCarryForword(id)
  {
    return this.data.find(x => x.id == id).is_carry_forword;
  }

  public updateTotalLeave(e, typeId, userId,opening_balance) {   

    var op_balance = parseInt(opening_balance);
    if(e.target.value > op_balance)
    {   
      e.target.value = op_balance;  
    }  
    this.employeeData.forEach(element => {
      if(element.id == userId){
            element.totalBalance.forEach(balance => {
              if (balance.leave_type_id == typeId) {
                balance.opening_balance = e.target.value;
              }
        });
      }
    });
  } 
  

  //new 01-04-2020
  public updateCarryForwordLeave(e, typeId, userId,opening_balance) {   

    // var op_balance = parseInt(opening_balance);
    // if(e.target.value > op_balance)
    // {   
    //   e.target.value = op_balance;  
    // }  
    this.employeeData.forEach(element => {
      if(element.id == userId){
          element.carryForwordBalance.forEach(balance => {
            if (balance.leave_type_id == typeId) {
              balance.opening_balance = parseInt(e.target.value);
            }
          });

          element.totalBalance.forEach(balance => {
            if (balance.leave_type_id == typeId) {
              balance.carry_forword_balance = parseInt(e.target.value);
            }
          });
      }
    });
  }



  public submitLeave(userId,flag)
  {
    let formData: FormData = new FormData();
    formData.append('created_by', this.myItem.id);
   // formData.append('user_id', userId);
   // formData.append('flag', flag);

    if(flag == 'single'){
      let data =[];
      this.employeeData.forEach(element => {
        if(element.id == userId){
          data.push(element);
          formData.append('data', JSON.stringify(data));
        }
      });
    } else {
      formData.append('data', JSON.stringify(this.employeeData));
    }

  
    this.manageLeaveTypeService.adaddEmployeeLeave(formData).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.manageLeaveTypeService.insertSuccess();
        this.getEmployee();
      }
    });
  }


  public getEdit(year)
  {
    this.manageLeaveTypeService.editCompanyLeavePolicy(this.companyId,year).subscribe(resp => {
      if(resp.success){
        this.editStatus = true;
        let editData = resp.items;
        this.data.filter(element => {
          editData.filter(ele=> {
            if(element.id == ele.leave_type_id){
              element.value = ele.opening_balance;
              element.is_pro_rata = ele.is_pro_rata;
              element.is_pro_rata_value = ele.is_pro_rata_value;
              element.is_carry_forword = ele.is_carry_forword;
              element.start_date = ele.start_date;
              this.myForm.get('year').setValue(ele.year);
              this.myForm.get('start_month').setValue(ele.start_month);
            }
          })
        });
      }
     
    });
  }

 public goToTop(){
    window.scrollTo(0,10);
  }

 
}
