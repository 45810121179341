import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NavbarService } from "./navbar.service";
import * as myGlobals from '../../globals';
import { LoginService } from "../../service/login.service";
//import { EventEmitter } from '../../../../node_modules/travel-marker/dist/types/typed-event-emitter';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NavbarModel } from "./navbar.model";
import { FormControl, FormGroup, Validators, NgForm, FormBuilder } from '@angular/forms';


//const image_url = 'D:/xampp/htdocs/HrmsApi/assets/img/profile_image/';
//const image_url = 'assets/img/profile_image/';
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
    @ViewChild('logoutModal') private logoutModal;
    navbar: NavbarModel = new NavbarModel({});
    myForm: FormGroup;
    currentLang = 'en';
    toggleClass = 'ft-maximize';
    placement = 'bottom-right'
    public isCollapsed = true;
    public key = 'loginData';
    public myItem: any;
    public profileImage;
    public oldPassword: string = '';
    public password: string = '';
    public confirmPassword: string = '';

    public closeResult: string;
    public status = false;
    public confirmPasswordStatus;
    public invalidPassword = false;
    public logoutModalRef;
    private _shown = false;


    constructor(public translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private navbarService: NavbarService,
        private loginService: LoginService,
        private builder: FormBuilder,
        private el: ElementRef) {
        const browserLang: string = translate.getBrowserLang();
        translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');


    }

    ChangeLanguage(language: string) {
        this.translate.use(language);
    }

    ngOnInit() {

        this.myItem = JSON.parse(localStorage.getItem(this.key));


        this.myForm = this.builder.group({
            'password': new FormControl(null, [Validators.required]),
            'oldPassword': new FormControl(null),
            'confirmPassword': new FormControl(null, [Validators.required]),
        }, { validator: this.checkPassword('password', 'confirmPassword') });

        this.getProfile();
        // this.profileImage = this.myItem.profileImage;



    }

    checkPassword(passwordKey: string, passwordConfirmationKey: string) {
        return (group: FormGroup) => {
            let password = group.controls[passwordKey],
                confirmPassword = group.controls[passwordConfirmationKey];
            if (password.value !== confirmPassword.value) {
                this.confirmPasswordStatus = true;
                return confirmPassword.setErrors({ notEquivalent: true })
            }
            else {
                this.confirmPasswordStatus = false;
                return confirmPassword.setErrors(null);
            }
        }
    }


    ToggleClass() {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        }
        else
            this.toggleClass = 'ft-maximize'
    }

    public openLogoutModel() {
        this.logoutModalRef = this.modalService.open(this.logoutModal);
    }


    Logout() {
        var fd: FormData = new FormData();
        fd.append('userId', this.myItem.id);
        this.loginService.logout(fd).subscribe(resp => {

            if (resp.success) {
                localStorage.clear();
                this.logoutModalRef.dismiss();
                this.navbarService.logoutSuccess();
                this.router.navigate([this.myItem.url]);
                localStorage.removeItem('loginData');
            } else {
                this.navbarService.logoutError();
            }
        });
    }

    getProfile() {
        this.profileImage = this.myItem.profileImage;
    }

    openModel(content) {
        this.confirmPassword = '';
        this.password = '';
        this.oldPassword = '';
        // this.status = false;
        this.invalidPassword = false;
        this.modalService.open(content).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
            this.oldPassword = '';
            this.confirmPassword = '';
            this.password = '';
            this.status = false;
        });
    }

    public closeModel() {

        this.oldPassword = '';
        this.confirmPassword = '';
        this.password = '';
        this.status = false;

    }
    public verifyPassword() {
        this.navbarService.verifyPassword(this.oldPassword, this.myItem.id).subscribe(resp => {
            if (resp.success) {
                this.status = true;
                this.invalidPassword = false;
            }
            else {
                //  this.navbarService.emailIdNotFound();
                this.status = false;
                this.invalidPassword = true;
                this.confirmPassword = '';
                this.password = '';
            }
        });
    }
    // public checkPassword()
    // {
    //     if(this.password == this.confirmPassword)
    //     {
    //         this.confirmPasswordStatus = false;
    //     }
    //     else
    //     {
    //         this.confirmPasswordStatus = true;
    //     }
    // } 

    public updatePassword() {
        let formData: FormData = new FormData();
        formData.append('userId', this.myItem.id);
        formData.append('confirmPassword', this.confirmPassword);
        this.navbarService.changePassword(formData).subscribe(resp => {
            if (resp.success) {
                this.navbarService.updatePassword();
                // this.status = false;
                this.confirmPassword = '';
                this.password = '';
                this.oldPassword = '';
            }
        });
    }

    toggle(span: HTMLElement) {
        this._shown = !this._shown;
        if (this._shown) {
            this.el.nativeElement.setAttribute('type', 'text');
            $.getScript('./assets/js/vertical-timeline.js');
            $(document).ready(function () {
                $(".ft-eye").addClass("color-blue");
                $(".ft-eye").removeClass("color-black");
            });
            //span.innerHTML = 'Hide password';
        } else {
            this.el.nativeElement.setAttribute('type', 'password');
            $(document).ready(function () {
                $(".ft-eye").addClass("color-black");
                $(".ft-eye").removeClass("color-blue");
            });
            //span.innerHTML = 'Show password';
        }
    }
}
