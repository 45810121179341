import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Dashboard1Component } from "./dashboard1/dashboard1.component";
import { AuthGuard } from '../shared/auth/auth-guard.service';





const routes: Routes = [
  {
    path: '',  
    
    children: [   
      {
        path: 'dashboard',
        component: Dashboard1Component,
        canActivate: [AuthGuard]         
      },  
      
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule { }

export const AdminComponentList = [
  Dashboard1Component,
];

