import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import 'rxjs/add/operator/toPromise';
import { App_Url } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  constructor(private http: HttpClient,public toastr: ToastrService) { }
  public key ='loginData';
  public myItem:any;

  public getManager(id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id',id);
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/getManager`,formData);
  }

  public add(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/employeeAllocation`,formData);
  }

  Success() 
  {
      this.toastr.success('Employee has been assigned successfully!', 'Success!');
  }
  Fail()
  {
      this.toastr.error('', 'Please select atleast one record.!', { "timeOut": 2000 });
  }
  
  public getdata(company_id,managerId):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id',company_id);
    formData.append('managerId',managerId);
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/getEmployeeManagerRelation`,formData);
  }

  public updateDeviceId(formData:any):Observable<any>
  {
   formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/updateDeviceId`,formData);
  }

  public getCompanyModulePermission(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/getCompanyModulePermission`,formData);
  }
  
  getApiToken()
  {
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    var api_token = this.myItem.tokenKey;
    return api_token ;
  }
}
