import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChartistModule } from 'ng-chartist';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchHeightModule } from "../shared/directives/match-height.directive";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import {PayrollsComponent} from './payrolls.component';
import {PayrollsList,PayrollsRoutingModule} from "./payrolls-routing.module";
import { DataTablesModule } from 'angular-datatables';
@NgModule({
  imports: [
    NgbModule,
    MatchHeightModule,
    CommonModule,
    PayrollsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule
  ],
  declarations: [

    PayrollsList,
   
  ]
})
export class PayrollsModule { }
