import { Component, OnInit, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { EmployeeModel } from "../employee.model";
import { EmployeeService } from "../employee.service";
import { BranchService } from "../../../branch/branch.service";
import { take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';
import { isInteger } from '../../../../../node_modules/@ng-bootstrap/ng-bootstrap/util/util';
import { elementStyleProp } from '../../../../../node_modules/@angular/core/src/render3/instructions';
//import { DatePicker } from 'angular2-datetimepicker';
import { Img_Url } from '../../../globals';
import { FileSelectDirective, FileDropDirective, FileUploader, FileLikeObject } from 'ng2-file-upload';
//import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ClientService } from '../../client.service'



function readBase64(file): Promise<any> {
  var reader = new FileReader();
  var future = new Promise((resolve, reject) => {
    reader.addEventListener("load", function () {
      resolve(reader.result);
    }, false);
    reader.addEventListener("error", function (event) {
      reject(event);
    }, false);
    reader.readAsDataURL(file);
  });
  return future;
}

const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';


@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss']
})
export class AddEmployeeComponent implements OnInit {
  // datePickerConfig : Partial<BsDatepickerConfig>;
  @ViewChild('invalidFieldModal') invalidFieldModal: ElementRef;
  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;
  myForm: FormGroup;
  employee: EmployeeModel = new EmployeeModel({});
  formData: { date?: string } = {}

  // date: Date = new Date();
  settings = {
    bigBanner: true,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false
  }

  public editStatus = true;
  public key = 'loginData';
  public myItem: any;
  public department;
  public company_id;
  public today;
  public user_status = false;
  public designation;
  public calenderShow = false;
  public role = false;
  public compulsoryField = 'This field is required';
  public selectedFile: File = null;
  public selectedFileView: any;
  public uploader: FileUploader = new FileUploader({ url: URL, disableMultipart: true });
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;
  public invalid = [];
  public submitted = false;
  public companyGeofencingPermission: number;
  public weekDays: any = [];
  public weekDaysObj: any = [];
  public selectedWeekDays: any = [];
  public branches: any = [];
  public invalidFieldModalRef;
  public time_zone_list: any = [];

  constructor(private employeeService: EmployeeService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private branchService: BranchService,
    private clientService :ClientService
  ) { }

  backClicked() {
    this.location.back();
  }

  ngOnInit() {

    this.weekDaysObj = [
      {
        'day': 'Monday', 'value': '1.0', 'isSelected': 0, 'weeknumbers': [
          { 'value': 1, 'isSelected': 0 },
          { 'value': 2, 'isSelected': 0 },
          { 'value': 3, 'isSelected': 0 },
          { 'value': 4, 'isSelected': 0 },
          { 'value': 5, 'isSelected': 0 },
        ]
      },
      {
        'day': 'Tuesday', 'value': '2.0', 'isSelected': 0, 'weeknumbers': [
          { 'value': 1, 'isSelected': 0 },
          { 'value': 2, 'isSelected': 0 },
          { 'value': 3, 'isSelected': 0 },
          { 'value': 4, 'isSelected': 0 },
          { 'value': 5, 'isSelected': 0 },
        ]
      },
      {
        'day': 'Wednesday', 'value': '3.0', 'isSelected': 0, 'weeknumbers': [
          { 'value': 1, 'isSelected': 0 },
          { 'value': 2, 'isSelected': 0 },
          { 'value': 3, 'isSelected': 0 },
          { 'value': 4, 'isSelected': 0 },
          { 'value': 5, 'isSelected': 0 },
        ]
      },
      {
        'day': 'Thursday', 'value': '4.0', 'isSelected': 0, 'weeknumbers': [
          { 'value': 1, 'isSelected': 0 },
          { 'value': 2, 'isSelected': 0 },
          { 'value': 3, 'isSelected': 0 },
          { 'value': 4, 'isSelected': 0 },
          { 'value': 5, 'isSelected': 0 },
        ]
      },
      {
        'day': 'Friday', 'value': '5.0', 'isSelected': 0, 'weeknumbers': [
          { 'value': 1, 'isSelected': 0 },
          { 'value': 2, 'isSelected': 0 },
          { 'value': 3, 'isSelected': 0 },
          { 'value': 4, 'isSelected': 0 },
          { 'value': 5, 'isSelected': 0 },
        ]
      },
      {
        'day': 'Saturday', 'value': '6.0', 'isSelected': 0, 'weeknumbers': [
          { 'value': 1, 'isSelected': 0 },
          { 'value': 2, 'isSelected': 0 },
          { 'value': 3, 'isSelected': 0 },
          { 'value': 4, 'isSelected': 0 },
          { 'value': 5, 'isSelected': 0 },
        ]
      },
      {
        'day': 'Sunday', 'value': '7.0', 'isSelected': 0, 'weeknumbers': [
          { 'value': 1, 'isSelected': 0 },
          { 'value': 2, 'isSelected': 0 },
          { 'value': 3, 'isSelected': 0 },
          { 'value': 4, 'isSelected': 0 },
          { 'value': 5, 'isSelected': 0 },
        ]
      },
    ];

    this.weekDays = [
      { 'day': 'Monday', 'isSelected': 0 },
      { 'day': 'Tuesday', 'isSelected': 0 },
      { 'day': 'Wednesday', 'isSelected': 0 },
      { 'day': 'Thursday', 'isSelected': 0 },
      { 'day': 'Friday', 'isSelected': 0 },
      { 'day': 'Saturday', 'isSelected': 0 },
      { 'day': 'Sunday', 'isSelected': 0 }
    ];
    //   this.datePickerConfig = Object.assign({}, {
    //     containerClass : 'theme-dark-blue',
    //     //minDate : new Date(2019,0,1),
    //     //maxDate : new Date(2019,11,1)
    //     dateInputFormat : 'DD/MM/YYYY'

    //  });

    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.selectedFileView = Img_Url + 'img/attendance_image/' + 'default.png';
    this.route.params.subscribe(
      (params: Params) => {
        if (params.company_id) {
          this.company_id = params.company_id;
        } else {
          this.company_id = 0;
        }
      }
    );

    this.route.params.subscribe(
      (params: Params) => {
        if (params.id) {
          this.editStatus = true;
          //   this.status = true;
          this.getData(params.id);
        } else {
          this.editStatus = false;
        }
      }
    );



    this.myForm = new FormGroup({

      'first_name': new FormControl(null, [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z][a-zA-Z ]*)?$')]),
      'employeeCode': new FormControl(null, [Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/_=]*)?$')]),
      'middle_name': new FormControl(null),
      'last_name': new FormControl(null, [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z][a-zA-Z ]*)?$')]),
      'gender': new FormControl(null),
      'birth_date': new FormControl(null),
      'joining_date': new FormControl(null, [Validators.required]),
      'email': new FormControl(null, [Validators.email, Validators.pattern('[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$')]),
      'contact_no': new FormControl(null, [Validators.required, Validators.pattern('^(?!\d+$)(?:[0-9][0-9]*)?$')]),
      'department': new FormControl(null),
      'designation': new FormControl(null),
      'address': new FormControl(null),
      'present_address': new FormControl(null),
      'city': new FormControl(null, [Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/_=]*)?$')]),
      'state': new FormControl(null, [Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/_=]*)?$')]),
      'zipcode': new FormControl(null),
      'is_admin': new FormControl(null),
      'is_manager': new FormControl(null),

      'motherName': new FormControl(null, [Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/_=]*)?$')]),
      'ctc': new FormControl(null, [Validators.pattern('^[0-9,? ]*$')]),
      'takeHome': new FormControl(null, [Validators.pattern('^[0-9,? ]*$')]),
      'grossSalary': new FormControl(null, [Validators.pattern('^[0-9,? ]*$')]),
      'maritalStatus': new FormControl(null),
      'spouseName': new FormControl(null),
      'uaNumber': new FormControl(null, [Validators.pattern('[0-9 ]+')]),
      'panNumber': new FormControl(null),
      'adharNumber': new FormControl(null, [Validators.pattern('[0-9 ]+'), Validators.minLength(12)]),
      'esicNumber': new FormControl(null, [Validators.pattern('[0-9 ]+'), Validators.minLength(10)]),
      'tenure': new FormControl(null),
      'bankName': new FormControl(null, [Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/_=]*)?$')]),
      'bankAccountNumber': new FormControl(null, [Validators.pattern('[0-9]{9,18}')]),
      'ifscCode': new FormControl(null),
      'profileImage': new FormControl(null),
      'geofencing': new FormControl(null),
      'latitude': new FormControl(null),
      'longitude': new FormControl(null),
      'distance': new FormControl(null),
      'spouse_contact_no': new FormControl(null),
      'qualification': new FormControl(null),
      'branch_id': new FormControl(null,[Validators.required]),
      'id': new FormControl(null),
      'role_id': new FormControl(null),
      'time_zone_id': new FormControl(''),
      'status': new FormControl(1),
      'passportNumber':new FormControl(null, [Validators.pattern('^(?!^0+$)[a-zA-Z0-9]{6,9}$')]),
      'passport_issue_date':new FormControl(null),
      'passport_expiry_date':new FormControl(null),
      'nic_no':new FormControl(null, [Validators.pattern('^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$')]),
      'vissaNumber':new FormControl(null),
      'vissa_issue_date':new FormControl(null),
      'vissa_expiry_date':new FormControl(null),
      'vissaStatus':new FormControl(null),

      'emirates':new FormControl(null, [Validators.pattern('^(?!\d+$)(?:[a-zA-Z][a-zA-Z ]*)?$')]),
      'emirateCode':new FormControl(null),
      'emiratesExpiryDate':new FormControl(null),
      'licenNo':new FormControl(null),
      'licenExpiryDate':new FormControl(null),
      'licenType':new FormControl(null),

    }, { updateOn: 'change' });

    // fetch department
    this.getDepartment();
    this.getDesignation();
    this.getCompanyModulePermission(this.company_id);
    this.getBranches(this.company_id);
    this.getTimeZone();
  }

  get f() { return this.myForm.controls; }

  onDateChanged(event) { }


  // public onFileChange(fileInput :any)
  // {
  //   this.selectedFile= fileInput.target.files[0];
  // }


  onFileChange(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
    this.selectedFile = file;
    readBase64(file).then(function (data) {
      $('#selectedFileView').attr('src', data);
    });
  }

  public onSubmit() {
    this.submitted = true;

    
    // stop here if form is invalid
    if (this.myForm.invalid) {
     // this.invalidFieldModalRef =  this.modalService.open(this.invalidFieldModal);
     // this.findInvalidControls();
      return;
    }

    this.employee.created_by = this.myItem.id;
    if (!this.employee.id) {
      this.employee.company_id = this.company_id;
    }

    //client admin
    // admin and manager both
    if (this.employee.is_admin == 1 && this.employee.is_manager == 1) {
      this.employee.role_id = 2;
    }
    if (this.employee.is_admin == 1 && this.employee.is_manager == 0) {
      this.employee.role_id = 2;
    }

    if (this.employee.is_admin == 0 && this.employee.is_manager == 0) {
      this.employee.role_id = 5;
    }
    if (this.employee.is_manager == 1 && this.employee.is_admin == 0) {
      this.employee.role_id = 4;
    }

    let formData: FormData = new FormData();
    //  formData.append('api_token','59240171-6f5c-42e3-8d87-0044ac120184');
    formData.append('first_name', this.employee.first_name);
    formData.append('employeeCode', this.employee.employeeCode);
    formData.append('last_name', this.employee.last_name);
    formData.append('middle_name', this.employee.middle_name);
    formData.append('gender', String(this.employee.gender));

    // formData.append('birth_date',String(this.datePipe.transform(this.employee.birth_date,"yyyy-MM-dd")));
    // formData.append('joining_date',String(this.datePipe.transform(this.employee.joining_date,"yyyy-MM-dd")));
    formData.append('birth_date', this.employee.birth_date);
    formData.append('joining_date', this.employee.joining_date);
    formData.append('email', this.employee.email);
    formData.append('contact_no', this.employee.contact_no);
    formData.append('department', String(this.employee.department));
    formData.append('address', this.employee.address);
    formData.append('present_address', this.employee.present_address);
    formData.append('designation', String(this.employee.designation));
    formData.append('city', this.employee.city);
    formData.append('state', this.employee.state);
    formData.append('zipcode', this.employee.zipcode);
    formData.append('is_admin', String(this.employee.is_admin));
    formData.append('created_by', String(this.employee.created_by));
    formData.append('company_id', String(this.employee.company_id));
    formData.append('role_id', String(this.employee.role_id));
    formData.append('is_manager', String(this.employee.is_manager));
    formData.append('id', String(this.employee.id));


    formData.append('motherName', this.employee.motherName);
    formData.append('ctc', String(this.employee.ctc));
    formData.append('takeHome', String(this.employee.takeHome));
    formData.append('grossSalary', String(this.employee.grossSalary));
    formData.append('maritalStatus', String(this.employee.maritalStatus));
    formData.append('spouseName', String(this.employee.spouseName));
    formData.append('uaNumber', String(this.employee.uaNumber));
    formData.append('panNumber', String(this.employee.panNumber));
    formData.append('adharNumber', String(this.employee.adharNumber));
    formData.append('esicNumber', String(this.employee.esicNumber));
    formData.append('tenure', String(this.employee.tenure));
    formData.append('bankName', String(this.employee.bankName));
    formData.append('bankAccountNumber', String(this.employee.bankAccountNumber));
    formData.append('ifscCode', String(this.employee.ifscCode));
    formData.append('profileImage', this.selectedFile);

    if (this.employee.geofencing == 0) {
      formData.append('geofencing', '0');
      formData.append('latitude', '');
      formData.append('longitude', '');
      formData.append('distance', '');
    } else {
      formData.append('geofencing', String(this.employee.geofencing));
      formData.append('latitude', String(this.employee.latitude));
      formData.append('longitude', String(this.employee.longitude));
      formData.append('distance', String(this.employee.distance));
    }

    formData.append('spouse_contact_no', this.employee.spouse_contact_no);
    formData.append('qualification', this.employee.qualification);
    formData.append('weekend_days', JSON.stringify(this.weekDaysObj));
    formData.append('branch_id', String(this.employee.branch_id));
    formData.append('time_zone_id', String(this.employee.time_zone_id));
    formData.append('status', String(this.employee.status));

    formData.append('passportNumber', String(this.employee.passportNumber));
    formData.append('passport_issue_date', this.employee.passport_issue_date);
    formData.append('passport_expiry_date', this.employee.passport_expiry_date);
    formData.append('nic_no', String(this.employee.nic_no));
    formData.append('vissaNumber', String(this.employee.vissaNumber));
    formData.append('vissa_issue_date', this.employee.vissa_issue_date);
    formData.append('vissa_expiry_date', this.employee.vissa_expiry_date);
    formData.append('vissaStatus', String(this.employee.vissaStatus));

    formData.append('emirates', String(this.employee.emirates));
    formData.append('emirateCode', String(this.employee.emirateCode));
    formData.append('emiratesExpiryDate', this.employee.emiratesExpiryDate);
    formData.append('licenNo', String(this.employee.licenNo));
    formData.append('licenExpiryDate', this.employee.licenExpiryDate);
    formData.append('licenType', String(this.employee.licenType));
    if (this.employee.id == 0) {

      this.employeeService.add(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.employeeService.insertSuccess();
          this.location.back();
          // this.router.navigate(['admin/client/employee/',resp.items.company_id]);
        }
        else {
          this.router.navigate(['employee/addemployee', resp.items.company_id]);
        }
      });
    }
    else {
      this.employeeService.update(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.employeeService.updateSuccess();
          this.location.back();
        }
        else {
          this.router.navigate(['employee/addemployee', resp.items.company_id]);
        }
      });
    }
  }

  // public findInvalidControls() {
  //   this.invalid = [];
  //   const controls = this.myForm.controls;
  //   for (const name in controls) {
  //     if (controls[name].invalid) {
  //       this.invalid.push(name);
  //     }
  //   }
  //   return this.invalid;
  // }


  getData(id: number) {
    this.employeeService.getItem(id).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }

        this.employee = resp.items;
        console.log(this.employee);
        this.getBranches(resp.items.company_id);
        this.weekDaysObj = resp.items.weekend_days_obj;
        this.getCompanyModulePermission(this.employee.company_id);
        //  this.employee.birth_date = this.datePipe.transform(this.employee.birth_date,"DD/MM/YYYY"); 
        // this.employee.joining_date = this.datePipe.transform(this.employee.joining_date,"DD/MM/YYYY"); 

        if (resp.items.profileImage) {
          this.selectedFileView = resp.items.profileImage;
        }
      }
    );
  }

  getDepartment() {
    this.employeeService.getDepartment(this.company_id).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.department = resp.items;
      });
  }

  getDesignation() {
    this.employeeService.getDesignation(this.company_id).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.designation = resp.items;
      });
  }

  public checkEmail() {

    this.employeeService.checkEmail(this.employee.email).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.employee.email = '';
          this.user_status = true;
        }
        else {
          this.user_status = false;
        }
      }
    );
  }

  public openCalender() {
    this.calenderShow = true;
  }

  public getCompanyModulePermission(companyId) {
    let formData: FormData = new FormData();
    formData.append('companyId', companyId);
    this.employeeService.getCompanyModulePermission(formData).subscribe(
      resp => {

        let data = [];
        data = resp.items;
        // 17 Stand for Geofancing Module Id
        data.forEach(element => {
          if (element.moduleId == 17 || element.module_name == 'geofencing') {
            this.companyGeofencingPermission = element.permissionLevel;
          }
        });
      });
  }


  public updateWeekDays(e, i) {
    var index = this.selectedWeekDays.indexOf(e.target.value);
    if (e.target.checked) {
      if (index < 0) {
        this.selectedWeekDays.push(e.target.value);
      }
    } else {
      this.selectedWeekDays.splice(index, 1);
    }
  }

  public getSelectedWeekday(day) {
    if (this.selectedWeekDays.indexOf(day) < 0) {
      return false;
    } else {
      return true;
    }
  }

  public getBranches(companyId) {
    this.branchService.get_data(companyId).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.branches = resp.items.branches;
      }
    });
  }

  public updateStatus(obj, event, i) {
    if (event.target.checked) {
      this.weekDaysObj.forEach(element => {

        if (element.value == obj.value) {
          element.isSelected = 1;
          element.weeknumbers.forEach(ele => {
            ele.isSelected = 1;
          });
        }
      });
    } else {
      this.weekDaysObj.forEach(element => {
        if (element.value == obj.value) {
          element.isSelected = 0;
          element.weeknumbers.forEach(ele => {
            ele.isSelected = 0;
          });
        }
      });
    }
  }

  public singleUpdate(obj, event, i, j, mainobj) {
    var chk = true;
    if (event.target.checked) {
      this.weekDaysObj[i].weeknumbers[j].isSelected = 1;
    } else {
      this.weekDaysObj[i].weeknumbers[j].isSelected = 0;
    }

    this.weekDaysObj[i].weeknumbers.forEach(element => {
      if (element.isSelected == 0) { chk = false; }
    });

    if (chk) {
      this.weekDaysObj[i].isSelected = 1;
    } else {
      this.weekDaysObj[i].isSelected = 0;
    }
  }

  public getTimeZone() {
    this.clientService.getTimeZone().subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.time_zone_list = resp.items;
        }
      }
    );
  }

  public copyPermanentAddress(e)
  {
    if(e.target.checked){
      this.employee.present_address = this.employee.address;
    } else {
      this.employee.present_address ='';
    }
  }

}
