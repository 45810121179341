import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  public key = 'loginData';
  public myItem:any;

  constructor(private routes : Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      if(localStorage.getItem(this.key)!= null){
        this.myItem = JSON.parse(localStorage.getItem(this.key));
        if(this.myItem.role_id=='2'){
          return true;
        } else {
            this.routes.navigate([this.myItem.url]);
            return false;
        }  
      } else {
        this.routes.navigate([this.myItem.url]);
        return false;
      }
  }
}
