import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { EmployeeService } from "../employee/employee.service";
import { ManagerService } from "./manager.service";
import { ClientService } from "../client/client.service";
import { PARAMETERS } from '@angular/core/src/util/decorators';
import { id } from '@swimlane/ngx-charts/release/utils';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeModel } from "../employee/employee.model";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { SidebarService } from '../shared/sidebar/sidebar.service';
import { element } from '@angular/core/src/render3/instructions';
import { EmployeeProfileComponent } from 'app/client/employee/employee-profile/employee-profile.component';
import { et } from 'date-fns/esm/locale';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss']
})
export class ManagerComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  @ViewChild('customerEmployeeModel') private customerEmployeeModel;

  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  employee: EmployeeModel = new EmployeeModel({});
  myForm: FormGroup;
  myForm1: FormGroup;

  public data: any[] = [];
  public editStatus;
  public company_id;
  public flag;
  public key = 'loginData';
  public key1 = 'permission';
  public myItem: any;
  public modulePermission: any;
  public totalEmployee;
  public company_name;
  public closeResult: string;
  public modelId: string = '';
  public modelUsername: string = '';
  //public modelPassword:string = '';
  public employeeList: any[] = [];
  public managerId;
  public permission;
  public headerStatus = false;
  public customerHeader = false;
  public attendancePermission;
  public documentPermission;
  public customerEmployeeStatus = false;

  // for change device id
  public oldDeviceId;
  public newDeviceId;
  public userId;
  public status = false;
  public modalReference;
  public employeeNotPresent = false;
  public employeeFilter: any = [];
  public customerEmployeeModelRef;
  public customerList: any = [];
  public customerEmployeeList: any = [];
  public customerEmployeeListFilter: any = [];


  constructor(private employeeService: EmployeeService,
    private managerService: ManagerService,
    private router: Router,
    private location: Location,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private sidebarService: SidebarService,
    private clientService: ClientService
  ) { }

  ngOnInit() {
    //this.permission= this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.modulePermission = JSON.parse(localStorage.getItem(this.key1));

    this.dtOptions = {
      //used created_at index 
      order: [[0, "DESC"]],
    };

    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.flag = params.flag;
        // if(this.myItem.role_id ==2){
        //   this.flag="Client";
        // }
        // if(this.myItem.role_id ==3){
        //   this.flag="Subclient";
        // }

        if (params.id) {
          this.company_id = params.id;
          this.headerStatus = true;
        }
        else {
          this.company_id = this.myItem.company_id;
        }
      }
    );


    this.myForm = new FormGroup({
      'modelPassword': new FormControl(null, [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/_=]*)?$')]),
    }, { updateOn: 'change' });


    this.myForm1 = new FormGroup({
      'newDeviceId': new FormControl(null, [Validators.required]),
    }, { updateOn: 'change' });


    let responseString = this.router.url;
    if (responseString.match("/customer")) {
      this.customerHeader = true;
    }

    $(document).ready(function () {
      $("#managers").addClass("tabactive");
    });

    this.getdata(this.company_id);
    this.getModulePermission();
    //this.rerender();
  }
  public addManager() {
    // this.myItem.role = 'Manager';
    localStorage.setItem(this.key, JSON.stringify(this.myItem));
    this.router.navigate(['manager/addmanager', { company_id: this.company_id }]);
  }


  public getdata(company_id: number) {
    this.managerService.getManager(company_id).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      this.data = resp.items;
      this.rerender();
    });
  }

  

  public getEdit(id: number) {
    this.router.navigate(['manager/addmanager', { id: id, company_id: this.company_id }]);
  }

  public Delete(id: number, i: number) {

    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.employeeService.delete(id).subscribe((resp: any) => {
          if (resp.success) {
            this.data.splice(i, 1);
            this.rerender();
            Swal.fire(
              'Deleted!',
              'Manager has been deleted successfully.',
              'success'
            )
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled'
        )
      }
    });


    // if(window.confirm('Are you sure you want to delete?')) {
    //   this.employeeService.delete(id).subscribe(resp=>{
    //       if(resp.status == 401)
    //       {
    //           this.router.navigate([this.myItem.url]);
    //       }
    //       if(resp.success){
    //         this.employeeService.deleteSuccess();
    //         this.data.splice(i, 1);
    //         this.rerender();
    //       }
    //   });
    // }
  }

  public employeeProfile(id: number) {
    this.router.navigate(['employee/employeeProfile', id]);
  }

  //permission

  public Permission(data) {
    this.router.navigate(['employee/permission', { id: data.id, cid: data.company_id }]);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        // For checked fields
        $('input[type=checkbox]', this.footer()).on('checked change', function () {
          if (this['value'] === 'true') {
            that.search(this['value']).draw();
          } else {
            that.search('').draw();
          }
        });

        $('input[type=text]', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  public backClicked() {
    this.location.back();
  }

  //for modals
  openModel(content, obj) {
    this.modelId = obj.id;
    //this.modelPassword = '';
    this.modelUsername = obj.user_name;
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    });
  }



  updatePassword() {
    let formData: FormData = new FormData();
    formData.append('id', this.modelId);
    formData.append('password', this.myForm.get('modelPassword').value);
    formData.append('api_token', '59240171-6f5c-42e3-8d87-0044ac120184');
    this.employeeService.changePassword(formData).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.myForm.reset();
        this.employeeService.password();
      }
    });
  }


  updateCheckedOptions(option, event) {
    this.employeeList.forEach(v => {
      if (v.id == event.target.value) {
        v.selected = event.target.checked;
        return false;
      }
    });
  }


  public Attendance(id: number) {
    this.router.navigate(['attendance/attendance', id]);
  }

  public Document(val) {
    this.router.navigate(['employee/document', { 'userId': val.id, 'companyId': val.company_id }]);
  }

  public getModulePermission() {

    if (this.modulePermission) {
      this.modulePermission.forEach(element => {
        // 8  Stand for Manager Module Id
        if (element.moduleId == 8) {
          this.permission = element.permissionLevel;
        }
        // 11 Stand for Attendance Module Id
        if (element.moduleId == 11) {
          this.attendancePermission = element.permissionLevel;
        }
        // 10 Stand for Document Module Id
        if (element.moduleId == 10) {
          this.documentPermission = element.permissionLevel;
        }

      });
    }
  }


  // for change device id

  changeDeviceId(deviceData, obj) {
    this.userId = obj.id;
    this.oldDeviceId = obj.deviceId;
    this.modalService.open(deviceData).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    });
  }

  public updateDeviceId() {
    let formData: FormData = new FormData();
    formData.append('userId', this.userId);
    formData.append('deviceId', this.newDeviceId);
    this.managerService.updateDeviceId(formData).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.newDeviceId = '';
        this.employeeService.deviceIdChange();
        this.getdata(this.company_id);
      }
    });
  }

  openEmployeeModel(employeeData, obj, flag) {

    this.managerId = obj.id;
    this.status = false;

    if (flag == 'employee') {
      this.company_id = obj.company_id;
      this.customerEmployeeStatus = false;
      this.managerService.getdata(obj.company_id, this.managerId).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.employeeList = resp.items;
          this.employeeFilter = resp.items;
          if (this.employeeList.length == 0) {
            this.employeeNotPresent = true;
          }
        } else {
          this.employeeList = [];
          this.employeeFilter = [];
        }

      });
    } else {
      this.customerEmployeeStatus = true;
      this.employeeList = [];
      this.employeeFilter = [];
      this.clientService.get_data(obj.company_id, this.myItem.role_id).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.customerList = resp.items;
        }
      });
    }

    this.modalReference = this.modalService.open(employeeData, { size: 'lg' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    });
  }

  public Search(e) {
    let term = e.target.value.toLowerCase();
    this.employeeFilter = this.employeeList.filter(function (val) {
      const firstName = val.first_name.toLowerCase().includes(term);
      const lastName = val.last_name.toLowerCase().includes(term);

      return (firstName + lastName);
    });
  }

  // public openCustomerEmployeeModel(val) {
  //   this.managerId = val.id;
  //   this.clientService.get_data(val.company_id, this.myItem.role_id).subscribe(resp => {
  //     if (resp.status == 401) {
  //       this.router.navigate([this.myItem.url]);
  //     }
  //     if (resp.success) {
  //       this.customerList = resp.items;
  //       this.customerEmployeeModelRef = this.modalService.open(this.customerEmployeeModel, { size: 'lg' });
  //     }
  //   });
  // }

  public getEmployee(e) {
    this.company_id = e.target.value;
    //this.managerId = e.id;
    this.status = false;
    this.managerService.getdata(e.target.value, this.managerId).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.employeeList = resp.items;
        this.employeeFilter = resp.items;

        if (this.employeeList.length == 0) {
          this.employeeNotPresent = true;
        }

      } else {
        this.employeeList = [];
        this.employeeFilter = [];
      }
    });
  }

  public onSubmit() {
    let formData: FormData = new FormData();
    formData.append('data', JSON.stringify(this.employeeList));
    formData.append('managerId', this.managerId);
    formData.append('created_by', this.myItem.id);
    formData.append('company_id', this.company_id);
    this.managerService.add(formData).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      //this.employeeList=[];
      if (resp.success) {

        this.modalReference.close(this.closeResult);
        this.managerService.Success();
        this.status = false;

      }
      else {
        // this.managerService.Fail();
        this.status = true;
      }
    });

  }

  //   public SearchCustomerEmployee(e) {
  //     let term = e.target.value.toLowerCase();
  //     this.customerEmployeeListFilter = this.customerEmployeeList.filter(function (val) {
  //       const firstName = val.first_name.toLowerCase().includes(term);
  //       const lastName = val.last_name.toLowerCase().includes(term);
  //       return (firstName + lastName);
  //     });
  //   }


  //  public updateCheckedOptionForCEMployee(option, event) {
  //     this.customerEmployeeList.forEach(v => {
  //       if (v.id == event.target.value) {
  //         v.selected = event.target.checked;
  //         return false;
  //       }
  //     });
  //   }

}
