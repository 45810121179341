export class SalaryslipTemplateModel {

   
    templateName :string;
    path :string;
    createdBy : number;
    status :number;
    id : number;
    counts : number;
   
    
  

    constructor(params:any) {
        const model = params || {};
        this.templateName =model.templateName || '';
        this.path =model.path || '';
        this.createdBy =model.createdBy || '';
        this.status =model.status || 1 ;
        this.id =model.id || 0;
        this.counts =model.counts || 0;
    }
}
