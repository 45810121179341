import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EmployeeModel } from "./employee.model";
import { App_Url } from '../../globals';
import { ToastrService } from 'ngx-toastr';



@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  constructor(private http: HttpClient,public toastr: ToastrService) { }
  public key ='loginData';
  public myItem:any;
  public add(formData:any):Observable<any>
  {
   formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/employee-add`,formData);
  }
  public update(formData:any):Observable<any>
  {
  formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/employee-update`,formData);
  }

  public InactiveBulk(formData:any):Observable<any>
  {
  formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/employee-inactive`,formData);
  }



  public getdata(company_id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id',company_id);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/get_employee`,formData);
  }
  
  
  public getEmployeeForManager(id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id',id);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/getEmployeeForManager`,formData);
  }
 
  public getDataWithAdmin(company_id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id',company_id);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/employee`,formData);
  }

  public getItem(id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id',id);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/employee-single`,formData);
  } 
  

  public delete(id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id',id);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/employee-delete`,formData);
  }

  // public getDepartment():Observable<any>
  // {
  //   let formData: FormData = new FormData();
  //   formData.append('api_token',this.getApiToken());
  //  return this.http.post(App_Url+`api/get_active_department`,formData);
  // }

  public getDepartment(company_id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('api_token',this.getApiToken());
    formData.append('companyId',company_id);
    return this.http.post(App_Url+`api/get_active_department`,formData);
  }
 
  public uploadProfile(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/uploadProfile`,formData);
  }

  public getCompanyModulePermission(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/getCompanyModulePermission`,formData);
  }

  public getEmployeeForExport(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/getEmployeeForExport`,formData);
  }
    
  // for successfully file upload
  typeSuccess() {
    this.toastr.success('Uploading Successfully!', 'Success!');
  }
   //for insert and delete
  insertSuccess() {
    this.toastr.success('Employee has been inserted successfully!', 'Success!');
  } 
  updateSuccess() {
    this.toastr.success('Employee has been updated successfully!', 'Success!');
  }

  managerInsertSuccess() {
    this.toastr.success('Manager has been inserted successfully!', 'Success!');
  } 
  managerUpdateSuccess() {
    this.toastr.success('Manager has been updated successfully!', 'Success!');
  }

  deleteSuccess() {
    this.toastr.success('Employee has been deleted successfully!', 'Success!');
  }
  fileUplodingSuccess() {
    this.toastr.success('Profile image has been updated successfully!', 'Success!');
  }
  selectFile() {
    this.toastr.error('', 'Please select image for upload.!', { "timeOut": 2000 });
  }
  csvError() {
    this.toastr.error('', 'Error in inserting employees.!', { "timeOut": 2000 });
  }

  insertFailure(msg) {
    this.toastr.error('', msg, { "timeOut": 2000 });
  }

  inactiveSuccess(msg){
    this.toastr.success(msg,'Success');
  }

  public deviceIdChange() {
    this.toastr.success('Device ID has been reset successfully!', 'Success!');
  }
 
  public checkEmail(email):Observable<any>
  {
    let formData: FormData = new FormData();
     formData.append('email', email);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/checkEmail`,formData);

  }

  public getEmployeeTotal():Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/employee_count`,formData);
  }

  public changePassword(formData:any):Observable<any>
  {
   formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/changePassword`,formData);
  }

  public updateDeviceId(formData:any):Observable<any>
  {
   formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/updateDeviceId`,formData);
  }
 
  password() {
    this.toastr.success('Password has been updated successfully!', 'Success!');
    } 

  error() {
    this.toastr.error('', 'Password Not Updating!', { "timeOut": 2000 });
  }

  public getDesignation(company_id):Observable<any>
  {
  let formData: FormData = new FormData();
  formData.append('api_token',this.getApiToken());
  formData.append('companyId',company_id);
  return this.http.post(App_Url+`api/getActiveDesignation`,formData);
  }
  
  public uploadCsvFile(file):Observable<any>
  {
  let formData: FormData = new FormData();
  formData.append('api_token',this.getApiToken());
  formData.append('userFile',file,file); 
  return this.http.post(App_Url+`api/upload-csv-file`,formData);
  }
  
  public addBulkUser(data,company_id,createdBy):Observable<any>
  {
 
  let formData: FormData = new FormData();
  formData.append('api_token',this.getApiToken());
  formData.append('data',JSON.stringify(data)); 
  formData.append('companyId',company_id); 
  formData.append('createdBy',createdBy); 
  return this.http.post(App_Url+`api/addBulkUser`,formData);
  }

  public getQrCode(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/getQrCode`,formData);
  }

  public insertQrCode(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/insertQrCode`,formData);
  }

  successMessage() {
    this.toastr.success('Code has been inserted successfully!', 'Success!');
  } 
  
  getApiToken(){
 // var api_token ='59240171-6f5c-42e3-8d87-0044ac120184';
  this.myItem = JSON.parse(localStorage.getItem(this.key));
  var api_token = this.myItem.tokenKey;
  return api_token ;
 }

}




	