import { Injectable,EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { App_Url } from '../../globals';
import { ToastrService } from 'ngx-toastr';




@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  constructor(private http: HttpClient,public toastr: ToastrService) {}
  public key ='loginData';
  public myItem:any;

  
  public verifyPassword(oldPassword,userId):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('oldPassword',oldPassword);
    formData.append('userId',userId);
    formData.append('api_token',this.getApiToken());   
    return this.http.post(App_Url+`api/verifyPassword`,formData);  
  }

  public changePassword(formData):Observable<any>
  {
    formData.append('api_token',this.getApiToken());   
    return this.http.post(App_Url+`api/changeUserPassword`,formData);  
  }

  updatePassword() {
    this.toastr.success('Password has been updated successfully!', 'Success!');
  }

  logoutSuccess() {
    this.toastr.success('Logout Successfully!', 'Success!');
  }
  logoutError() {
    this.toastr.error('', 'Error in logout!', { "timeOut": 2000 });
  }

  // emailIdNotFound() {
  //   this.toastr.error('', 'Old password is wrong!', { "timeOut": 2000 });
  // }
  


  getApiToken(){
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    var api_token = this.myItem.tokenKey;
     return api_token ;
    }
}




	