import { Component, OnInit, ViewChild } from '@angular/core';
import { PermissionService } from "./permission.service";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { PermissionModel } from "./permission.model";
import { Router, ActivatedRoute, Params } from "@angular/router";




@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss']
})
export class PermissionComponent implements OnInit {
  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;
  myForm: FormGroup;
  permission: PermissionModel = new PermissionModel({});


  constructor(private permissionService: PermissionService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router, ) { }

  public module: any=[];
  public selectedModule: any;
  public userId;
  public companyId;
  public permissionData;
  public userPermission;
  public geoTrackingPermission = false;
  public employeeName;
  public key = 'loginData';
  public key1 = 'permission';
  public myItem: any;

  ngOnInit() {

    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.selectedModule = [];
    this.myForm = new FormGroup({
      'moduleId': new FormControl(null),
      'permissionLevel': new FormControl(null, [Validators.required]),
      'location_tracking_duration': new FormControl(null, [Validators.required, Validators.pattern('[0-9]*')])
    }, { updateOn: 'change' });


    this.route.params.subscribe(
      (params: Params) => {
        this.userId = params.id;
        this.companyId = params.cid;

      });



    this.getMdule();
    // this.getUserPermission();
  }

  public onSubmit() {
    let formData: FormData = new FormData();
    formData.append('userId', this.userId);
    formData.append('data', JSON.stringify(this.module));
    this.permissionService.add(formData).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.getMdule();
        this.permissionService.permissionAssign();
        this.location.back();
      }
    });

  }

  public getMdule() {
    let formData: FormData = new FormData();
    // formData.append('companyId',this.companyId);
    formData.append('userId', this.userId);
    this.permissionService.getModuleForEmployee(formData).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.module = resp.items.permission;
          this.employeeName = resp.items.employeeName;
          this.module.forEach((element) => {
            if (element.moduleId == 11) {
              if (element.permissionLevel == 0) {
                this.geoTrackingPermission = false;
              }
              else {
                this.geoTrackingPermission = true;
              }
            }
          });
        }
      }
    );
  }

  public backClicked() {
    this.location.back();
  }

  selectPermission(e, i) {
    this.module[i].permissionLevel = parseInt(e.target.value);
    console.log(this.module);
    this.module.forEach((element) => {
      if (element.moduleId == 11) {
        if (element.permissionLevel >= 1) {
          this.geoTrackingPermission = true;
        }
        else {
          this.geoTrackingPermission = false;
        }
      }
    });
  }

  location_tracking(e, i) {
    this.module[i].location_tracking_duration = parseInt(e.target.value);

  }

  getNumbersForLoop(num: number) {
    return Array(num + 1);

  }

  permissionTitle(i) {
    switch (i) {
      case 1: {
        return 'Read';
        // break; 
      }
      case 2: {
        return 'Update [Update + Read]';
        // break; 
      }
      case 3: {
        return 'Delete [Delete + Update + Read]';
        // break; 
      }
      case 4: {
        return 'All [Create + Delete + Update + Read]';
        //  break; 
      }
      default: {
        return 'Select Permission';
        //  break; 
      }
    }
  }


}
