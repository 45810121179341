import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DriverDeliveryComponent } from './driver-delivery.component';
import { VehicleListComponent } from './vehicle-list/vehicle-list.component';
import { ImportBulkVehicleDetailsComponent } from './vehicle-list/import-bulk-vehicle-details/import-bulk-vehicle-details.component';
import { AddVehicleDetailsComponent } from './vehicle-list/add-vehicle-details/add-vehicle-details.component';

const routes: Routes = [
  {
    path: '',

    children: [
      {
        path: 'driver_delivery',
        component: DriverDeliveryComponent,
        data: {
          title: 'Driver delivery list'
        }
      },
      {
        path: 'driver_delivery/vehicle_list',
        component: VehicleListComponent,
        data: {
          title: 'vehicle list'
        }
      },
      {
        path: 'driver_delivery/vehicle_list/add_vehicle_details',
        component: AddVehicleDetailsComponent,
        data: {
          title: 'Add vehicle details'
        }
      },
      {
        path: 'driver_delivery/vehicle_list/add_vehicle_details/:id',
        component: AddVehicleDetailsComponent,
        data: {
          title: 'Edit vehicle details'
        }
      },
      {
        path: 'driver_delivery/vehicle_list/bulk_import_vehicle_details',
        component: ImportBulkVehicleDetailsComponent,
        data: {
          title: 'Import bulk vehicle details'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DriverDeliveryRoutingModule { }

export const DriverDelivreryComponentList = [
  DriverDeliveryComponent,
  VehicleListComponent,
  ImportBulkVehicleDetailsComponent,
  AddVehicleDetailsComponent
];

