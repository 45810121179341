import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { App_Url } from '../globals';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})


export class ManageLeaveTypeService {
  
  constructor(private http: HttpClient,public toastr: ToastrService) { }
   public key ='loginData';
   public myItem:any;

  public add(formData:any):Observable<any>
  { 
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/company-leave-policy-add`,formData);
  }

  public update(formData:any):Observable<any>
  { 
   formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/company-leave-policy-update`,formData);
  }
  public getLeaveType(companyId,roleId):Observable<any>
  {
    let formData: FormData = new FormData();
     formData.append('companyId', companyId);
     formData.append('roleId', roleId);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/leave-type-get`,formData);
  }

  public getCompany(companyId):Observable<any>
  {
    let formData: FormData = new FormData();
     formData.append('id', companyId);
     
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/company-single`,formData);
  }
  public getEmployee(companyId):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id',companyId);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/get_employee_for_leave_policy`,formData);
  }

  public editCompanyLeavePolicy(companyId,year):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('companyId',companyId);
    formData.append('year',year);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/edit_company_leave_policy`,formData);
  }

  public adaddEmployeeLeave(formData:any):Observable<any>
  { 
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/employee-leave-policy-add`,formData);
  }

  public getCompanyLeave(companyId):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('companyId',companyId);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/get_company_leave_policy`,formData);
  }
  
  

  insertSuccess() {
    this.toastr.success('Leave policy has been inserted successfully', 'Success!');
  } 

  updateSuccess() {
    this.toastr.success('Leave policy has been updated successfully', 'Success!');
  }
  deleteSuccess() {
    this.toastr.success('Leave policy has been deleted successfully', 'Success!');
  }
  alreadyExists(){
    this.toastr.error('', 'Company Leave policy already exists for this year', { "timeOut": 1000 });
  }

  getApiToken(){
   this.myItem = JSON.parse(localStorage.getItem(this.key));
   var api_token = this.myItem.tokenKey;
   return api_token ;
  }
}
