import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { AttendanceService } from "../attendance.service";
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
//import { start } from 'repl';
import { TravelMarker, TravelMarkerOptions, TravelData, TravelEvents } from 'travel-marker';
import { Img_Url } from '../../globals';
import { isThisQuarter } from 'date-fns';
import { htmlAlert } from '../../shared/data/sweet-alerts';
import { ExcelService } from '../../service/excel.service';
import { EmployeeService } from "../../employee/employee.service";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-attendance-list.component.html',
  styleUrls: ['./employee-attendance-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class EmployeeAttendanceListComponent implements OnInit {
  @ViewChild('addAttendanceModal') addAttendanceModal: ElementRef;
   dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  public key = 'loginData';
  public myItem: any;
  public data: any[] = [];
  public employee_id;
  public attendanceDetailDivShow = false;
  public mapDetailDivShow = false;
  public attendanceDetail: any = [];
  public mapDetail: any = [];
  public today = this.convertDate(new Date());
  public dte = new Date();
  public previousDate;
  public startDate = this.convertDate(new Date());
  public endDate = this.convertDate(new Date());
  public startSearchDate = this.convertDate(new Date());
  public endSearchDate = this.convertDate(new Date());
  public employeeIdsSearch = [];
  public selectedStag = { id: '', title: '' };
  public tagList: any[] = [];
  public tagForDispaly = [];
  public mapClick = false;
  public image;
  public mapImage;
  public opacity;
  public icon;
  public employeeList: any = [];
  public companyId;

  //16-04-2019
  public pauseDetails: any[] = [];
  public userDetails: any = [];
  myForm: FormGroup;
  public addAttendanceModalRef;
  public attendanceHistory:any=[];
  public options = {
    readonly: undefined,
    placeholder: '+ Add Name',
    secondaryPlaceholder: '',
  };
  public autocompleteItemsAsObjects: any = [];
  public mapdata: any;
  public id;
  public absent;
  public present;
  public leaves;
  public weekEnds;
  public holidays;
  public loading = false;
  public customLoadingTemplate;
  public markAttendanceEmployeeId;

  coordinates = [];
  // coordinates=[
  //   {lat:22.307159,lng:73.181221},
  //   {lat:22.563200,lng:72.960281},
  //   {lat:22.775680,lng:73.606770},
  //   ];
  zoom: number = 15;
  // initial center position for the map
  lat: number = 0;
  lng: number = 0;
  public selectedName;
  public status:boolean = false;

  constructor(private router: Router,
    private location: Location,
    private attendanceService: AttendanceService,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private excelService: ExcelService,
    private employeeService: EmployeeService,
    private modalService: NgbModal) { }

  ngOnInit() {


    this.dte.setDate(this.dte.getDate() - 1);
    this.previousDate =this.datePipe.transform(this.dte, 'yyyy-MM-dd');
  // console.log(this.convertDate(this.dte.toString()));
    this.image = Img_Url + 'img/' + 'map.png';
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        if (params.id) {
          this.id = params.id;
        }
        else {
          this.id = this.myItem.id;
        }

      }
    );

    this.get_attendance(this.id, this.startDate, this.endDate);
    this.getUserDetails(this.id);

    this.myForm = new FormGroup({
      'id': new FormControl(null),
      'punchDate': new FormControl(null, [Validators.required]),
      'inTime': new FormControl(null, [Validators.required]),
      'outTime': new FormControl(null, [Validators.required]),
      'reason': new FormControl(null, [Validators.required]),
    }, { updateOn: 'change' });

    this.dtOptions = {
      //used id for index 
      order: [[0, "DESC"]],
    };
  }



  public getUserDetails(id: number) {
    this.employeeService.getItem(id).subscribe(
      resp => {
        this.userDetails = resp.items;
      }
    );
  }

  public get_data(id) {
    this.attendanceService.get_data(id).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      this.data = resp.items;
    });
  }

  // public get_data_web(startDate,endDate)
  // {
  //   this.attendanceService.get_single_attendance(this.id,startDate,endDate,flag='single').subscribe(resp=>{
  //     this.data=resp.items; 
  //   });
  // }
  public get_attendance(id, startDate, endDate) {

    //var flag;
    this.attendanceService.get_single_attendance(id, startDate, endDate).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if(resp.success){
        this.data = resp.items.attendance;
        this.absent = resp.items.absent;
        this.present = resp.items.present;
        this.leaves = resp.items.leaves;
        this.weekEnds = resp.items.weekEnds;
        this.holidays = resp.items.holidays;
      }
      
    });
  }


  public backClicked() {
    this.location.back();
  }


  public mapDetailToggle(i, value) {
    this.data.forEach(element => {
      if (element.punchDate == value.punchDate) {
        element.attendance = 'selected';
      }
      else {
        if (element.attendance == 'selected') {
          element.attendance = 'present';
        }
      }
    });

    this.mapDetail = [];
    this.mapClick = false;
    this.coordinates = [];

    if (this.mapDetailDivShow) {
      // this.mapDetailDivShow = false;
    } else {
      this.mapDetailDivShow = true;
    }

    if (this.mapDetailDivShow) {
      this.mapDetail = this.data[i];
      this.attendanceService.getPauseDetails(this.mapDetail.userId, this.mapDetail.punchDate).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.pauseDetails = resp.items;
        } else {
          this.pauseDetails = [];
        }
      });
    }
  }

  search() {
    this.startDate = this.startSearchDate;
    this.endDate = this.endSearchDate;
    this.data = [];
    this.get_attendance(this.id, this.startDate, this.endDate);
  }


  public convertDate(date) {
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth() + 1).toString();
    var dd = date.getDate().toString();

    var mmChars = mm.split('');
    var ddChars = dd.split('');

    return yyyy + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + (ddChars[1] ? dd : "0" + ddChars[0]);
  }

  onItemAdded(tag) {
    let obj = { id: '', title: '' };
    if (tag.id) {
      obj = { id: tag.id, title: tag.user_name };
    } else {
      obj = { id: '', title: tag.value };
    }
    this.tagList.push(obj);
    this.employeeIdsSearch = this.tagList.map(({ id }) => id)
  }


  onItemRemoved(tag) {
    let val = "";
    if (tag.id) {
      val = tag.title;
    } else {
      val = tag.display;
    }
    var index = this.tagList.indexOf(val);
    this.tagList.splice(index, 1);

    // var index1 = this.employeeIdsSearch.indexOf(tag.id);
    // if (index1 > -1) {
    //   this.employeeIdsSearch.splice(index1, 1);
    // }
    this.employeeIdsSearch = this.tagList.map(({ id }) => id)
  }

  remove_duplicates(a, b) {
    for (var i = 0, len = a.length; i < len; i++) {
      for (var j = 0, len2 = b.length; j < len2; j++) {
        if (a[i].id === b[j].id) {
          b.splice(j, 1);
          len2 = b.length;
        }
      }
    }
  }

  searchDateChange(e) {
    var date1 = new Date(this.startSearchDate);
    var date2 = new Date(this.endSearchDate);
    if (Date.parse(this.startSearchDate) > Date.parse(this.endSearchDate)) {
      this.endSearchDate = this.startSearchDate;
    }
  }


  showMap(date, userId) {

    this.mapClick = false;
    this.mapdata = [];
    this.coordinates = [];
    let formData: FormData = new FormData();
    formData.append('userId', userId);
    formData.append('date', date);
    this.attendanceService.getGeoTrakingByDate(formData).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }

      this.mapdata = resp;
      if (this.mapdata.items.length > 0) {
        this.mapClick = true;
        this.mapdata.items.forEach(element => {

          //this.icon = 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png';

          if (element.locationAccuracy == 0) {
            this.icon = Img_Url + 'icon/yellow.png';
            this.opacity = 0.0;
          }
          if (element.locationAccuracy == 0 && element.spotDurationSec == 0) {
            this.icon = Img_Url + 'icon/yellow.png';
            this.opacity = 0.0;
          }
          if (element.locationAccuracy == 0 && element.spotDurationSec >= 300) {
            this.opacity = 1;
            this.icon = Img_Url + 'icon/yellow.png';
          }
          if (element.locationAccuracy == 1) {
            this.opacity = 1;
            this.icon = Img_Url + 'icon/green.png';
          }
          if (element.locationAccuracy == 2) {
            this.opacity = 1;
            this.icon = Img_Url + 'icon/orange.png';
          }
          if (element.locationAccuracy == 3) {
            this.opacity = 1;
            this.icon = Img_Url + 'icon/green-parrot.png';
          }
          if (element.locationAccuracy == 4) {
            this.opacity = 1;
            this.icon = Img_Url + 'icon/red.png';
          }
          if (element.locationAccuracy == 5) {
            this.opacity = 1;
            this.icon = Img_Url + 'icon/blue.png';
          }
          if (element.locationAccuracy == 7) {
            this.opacity = 1;
            this.icon = Img_Url + 'icon/skyblue.png';
          }
          this.coordinates.push({ lat: element.latitude, lng: element.longitude, created_at: element.created_at, spotDurationSec: element.spotDurationSec, distance: element.distance, deviceDate: element.deviceDate, locationAccuracy: element.locationAccuracy, icon: this.icon, opacity: this.opacity });
        });

        this.coordinates.map(function (res) {
          res.lat = parseFloat(res.lat);
          res.lng = parseFloat(res.lng);
          return res;
        })



        if (this.mapdata.items.length > 0) {
          this.lat = this.coordinates[0].lat;
          this.lng = this.coordinates[0].lng;
        }
      }
    });
  }

  reset() {

    this.attendanceDetailDivShow = false;
    this.mapDetailDivShow = false;
    this.startDate = this.convertDate(new Date());
    this.endDate = this.convertDate(new Date());
    this.startSearchDate = this.convertDate(new Date());
    this.endSearchDate = this.convertDate(new Date());
    this.tagForDispaly = [];
    this.get_attendance(this.id, this.startDate, this.endDate);
  }

  ConvertToString(value) {
    return String(value)
  }

  // mapClicked($event: MouseEvent) {
  //   this.markers.push({
  //     lat: $event.coords.lat,
  //     lng: $event.coords.lng,
  //     draggable: true
  //   });
  // }


  // public checkVisible(val)
  //  add in agm Marker
  //[visible]="checkVisible(coordinate1.spotDurationSec)"
  // {
  //   if(val)
  //   {
  //     return true;
  //   }
  //   else
  //   {
  //     return false;
  //   }
  // }


  public exportAsXLSX(flag): void {

    this.startDate = this.startSearchDate;
    this.endDate = this.endSearchDate;
    this.loading = true;
    this.companyId = '';
    this.attendanceService.employeeListByArray(this.id).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }

      this.employeeList = resp.items;
      this.attendanceService.getAttendanceExcelData(this.startDate, this.endDate, this.companyId,flag).subscribe(resp => {
        this.excelService.exportAsExcelFile(resp, 'Attendance');
        this.loading = false;
      });
    });
  }

  public addAttendance(value) {
    //this.getAttendanceHistory();
    this.markAttendanceEmployeeId = value.userId;
    this.myForm.get('punchDate').setValue(value.punchDate);
    this.addAttendanceModalRef = this.modalService.open(this.addAttendanceModal, { size: 'lg', windowClass: 'modal-xl' });
  }

  public editAttendance(value) {
    //this.getAttendanceHistory();
    this.markAttendanceEmployeeId = value.userId;
    this.myForm.get('inTime').setValue(this.datePipe.transform(value.inTime,'HH:mm'));
    this.myForm.get('outTime').setValue(this.datePipe.transform(value.outTime,'HH:mm'));
    this.myForm.get('reason').setValue(value.reason);
    this.myForm.get('punchDate').setValue(value.punchDate);
    this.addAttendanceModalRef = this.modalService.open(this.addAttendanceModal, { size: 'lg', windowClass: 'modal-xl' });
  }

  // public getAttendanceHistory()
  // {
  //   this.attendanceService.getAttendanceHistory(this.myItem.id).subscribe(resp => {
  //     if (resp.status == 401) {
  //       this.router.navigate([this.myItem.url]);
  //     }
  //     if(resp.success){
  //       this.attendanceHistory = resp.items;
  //     }
  //   });
  // }

  public saveAttendance()
  {

    let formData: FormData = new FormData();
    formData.append('userId', this.markAttendanceEmployeeId);
    formData.append('punchDate', this.myForm.get('punchDate').value);
    formData.append('inTime', this.myForm.get('punchDate').value + ' ' + this.myForm.get('inTime').value + ':00');
    formData.append('outTime', this.myForm.get('punchDate').value + ' ' + this.myForm.get('outTime').value + ':00');
    formData.append('approval_status', 'pending');
    formData.append('reason',  this.myForm.get('reason').value);

    this.attendanceService.addAttendance(formData).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if(resp.success){
        this.addAttendanceModalRef.dismiss();
        this.myForm.get('inTime').setValue('');
        this.myForm.get('outTime').setValue('');
        this.myForm.get('reason').setValue('');
        this.get_attendance(this.id, this.startDate, this.endDate);
       // this.getAttendanceHistory();
        this.attendanceService.insertSuccess(resp.message);
      } else {
       // this.attendanceService.alreadyExists();
      }
    });
  }

  public setTime()
  {
    if(this.myForm.get('outTime').value < this.myForm.get('inTime').value){
      this.status = true;
      this.myForm.get('outTime').setValidators;
    } else {
      this.status =false;
    }
  }

  public isAllowed(date)
  {
    let dte = new Date(this.today);
    dte.setDate(dte.getDate() - 10);   
    this.convertDate(dte);
    if(this.convertDate(dte) <= date && date != this.today){
      return true;
    } else {
      return false;
    }
  }
}

