import { Component, OnInit ,ViewChild } from '@angular/core';
import { ClientModel } from "../../client/client.model";
import { ClientService } from "../../client/client.service";
import { Router, ActivatedRoute, Params  } from "@angular/router";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { SidebarService } from '../../shared/sidebar/sidebar.service';


@Component({
  selector: 'app-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.scss']
})
export class CustomerProfileComponent implements OnInit {
  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;
  myForm: FormGroup;

  currentPage: string = "About";
  company:ClientModel = new ClientModel({});
  constructor(private clientService : ClientService,
    private sidebarService: SidebarService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location) { }
    public editStatus = true;
    public key ='loginData';
    public myItem:any;
    public  flag;
    public permission;

    public companyId;
    public totalDepartment;
    public totalManager;
    public totalEmployee;
    public totalPresent;
    public totalAbsent;

  ngOnInit() {
    //session
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.permission= this.sidebarService.getCurrentPermission();
    
    //validation
    

    this.route.params.subscribe(
      (params: Params) => {
        if (params.id) {
          this.companyId = params.id;
          this.getData(params.id);
        }
      }
    );

    $.getScript('./assets/js/vertical-timeline.js');

    $(document).ready(function(){
      $("#about").addClass("tabactive");
    });

    this.getAllTotalCount(this.companyId);
  }

    showPage(page: string) {
      this.currentPage = page;
  }

    getData(id : number)
    {
    this.clientService.getItem(id).subscribe(
      resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success){
          this.company = resp.items;
        }
      }
    );
    }

    public  getAllTotalCount(id : number)
    {
    this.clientService.getAllTotalCount(id).subscribe(
      resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success){
          this.totalDepartment = resp.items.totalDepartment;
          this.totalManager = resp.items.totalManager;
          this.totalEmployee = resp.items.totalEmployee; 
          this.totalPresent = resp.items.totalPresent;
          this.totalAbsent = resp.items.totalAbsent;
        }
      }
    );
    }

  
   public getEdit(id)
   {
     this.router.navigate(['customer/addcustomer',id]);
   }

  
   public backClicked()
  {
     this.location.back();
  }   
}
