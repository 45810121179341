import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { ManagerModel } from "../manager.model";
import { EmployeeService } from "../../employee/employee.service";
import { BranchService } from "../../branch/branch.service";

import { take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';
import { isInteger } from '../../../../node_modules/@ng-bootstrap/ng-bootstrap/util/util';
import { elementStyleProp } from '../../../../node_modules/@angular/core/src/render3/instructions';
import { Img_Url } from '../../globals';
import { FileSelectDirective, FileDropDirective, FileUploader, FileLikeObject } from 'ng2-file-upload';
import { ClientService } from '../../client/client.service';


function readBase64(file): Promise<any> {
  var reader = new FileReader();
  var future = new Promise((resolve, reject) => {
    reader.addEventListener("load", function () {
      resolve(reader.result);
    }, false);
    reader.addEventListener("error", function (event) {
      reject(event);
    }, false);
    reader.readAsDataURL(file);
  });
  return future;
}

const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';


@Component({
  selector: 'app-add-manager',
  templateUrl: './add-manager.component.html',
  styleUrls: ['./add-manager.component.scss']
})
export class AddManagerComponent implements OnInit {
  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;
  myForm: FormGroup;
  manager: ManagerModel = new ManagerModel({});
  formData: { date?: string } = {}

  public editStatus = true;
  public key = 'loginData';
  public myItem: any;
  public department: number;
  public company_id;
  public flag;
  public today;
  public user_status = false;
  public designation: number;
  public calenderShow = false;
  // public selectedFile :File=null;

  //for profile image
  public selectedFile: File = null;
  public selectedFileView: any;
  public uploader: FileUploader = new FileUploader({ url: URL, disableMultipart: true });
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;
  public compulsoryField = 'This field is required';
  public submitted = false;
  public companyGeofencingPermission: number;
  public weekDays: any = [];
  public weekDaysObj: any = [];
  public selectedWeekDays: any = [];
  public branches:any=[];
  public time_zone_list: any = [];


  constructor(private employeeService: EmployeeService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private datePipe: DatePipe,
    private branchService:BranchService,
    private clientService :ClientService
    ) { }


  config = {
    format: "MM/DD/YYYY",
    showTwentyFourHours: true,
  };

  backClicked() {
    this.location.back();
  }

  ngOnInit() {

    this.weekDaysObj = [
      { 'day': 'Monday','value':'1.0','isSelected': 0, 'weeknumbers':[
          { 'value':1,  'isSelected': 0 },
          { 'value':2,  'isSelected': 0 },
          { 'value':3,  'isSelected': 0 },
          { 'value':4,  'isSelected': 0 },
          { 'value':5,  'isSelected': 0 },
        ]
      },
      { 'day': 'Tuesday','value':'2.0','isSelected': 0, 'weeknumbers':[
        { 'value':1,  'isSelected': 0 },
        { 'value':2,  'isSelected': 0 },
        { 'value':3,  'isSelected': 0 },
        { 'value':4,  'isSelected': 0 },
        { 'value':5,  'isSelected': 0 },
      ] },
      { 'day': 'Wednesday','value':'3.0','isSelected': 0, 'weeknumbers':[
        { 'value':1,  'isSelected': 0 },
        { 'value':2,  'isSelected': 0 },
        { 'value':3,  'isSelected': 0 },
        { 'value':4,  'isSelected': 0 },
        { 'value':5,  'isSelected': 0 },
      ] },
      { 'day': 'Thursday','value':'4.0','isSelected': 0, 'weeknumbers':[
        { 'value':1,  'isSelected': 0 },
        { 'value':2,  'isSelected': 0 },
        { 'value':3,  'isSelected': 0 },
        { 'value':4,  'isSelected': 0 },
        { 'value':5,  'isSelected': 0 },
      ] },
      { 'day': 'Friday','value':'5.0','isSelected': 0, 'weeknumbers':[
        { 'value':1,  'isSelected': 0 },
        { 'value':2,  'isSelected': 0 },
        { 'value':3,  'isSelected': 0 },
        { 'value':4,  'isSelected': 0 },
        { 'value':5,  'isSelected': 0 },
      ] },
      { 'day': 'Saturday','value':'6.0','isSelected': 0, 'weeknumbers':[
        { 'value':1,  'isSelected': 0 },
        { 'value':2,  'isSelected': 0 },
        { 'value':3,  'isSelected': 0 },
        { 'value':4,  'isSelected': 0 },
        { 'value':5,  'isSelected': 0 },
      ] },
      { 'day': 'Sunday','value':'7.0','isSelected': 0, 'weeknumbers':[
          { 'value':1,  'isSelected': 0 },
          { 'value':2,  'isSelected': 0 },
          { 'value':3,  'isSelected': 0 },
          { 'value':4,  'isSelected': 0 },
          { 'value':5,  'isSelected': 0 },
        ] },
    ];

    this.weekDays = [
      { 'day': 'Monday', 'isSelected': 0 },
      { 'day': 'Tuesday', 'isSelected': 0 },
      { 'day': 'Wednesday', 'isSelected': 0 },
      { 'day': 'Thursday', 'isSelected': 0 },
      { 'day': 'Friday', 'isSelected': 0 },
      { 'day': 'Saturday', 'isSelected': 0 },
      { 'day': 'Sunday', 'isSelected': 0 }
    ];

    this.selectedFileView = Img_Url + 'img/attendance_image/' + 'default.png';
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.route.params.subscribe(
      (params: Params) => {
        this.flag = params.flag;
        if(params.company_id){
          this.company_id = params.company_id;
        } else {
          this.company_id =0;
        }
      }
    );

    this.route.params.subscribe(
      (params: Params) => {
        if (params.id) {
          this.editStatus = true;
          this.getData(params.id);
        } else {
          this.editStatus = false;
        }
      }
    );



    this.myForm = new FormGroup({

      'first_name': new FormControl(null, [Validators.required,Validators.pattern('^(?!\d+$)(?:[a-zA-Z][a-zA-Z ]*)?$')]),
      'employeeCode': new FormControl(null, [Validators.pattern('[A-Za-z 0-9_-]*')]),
      'middle_name': new FormControl(null),
      'last_name': new FormControl(null, [Validators.required,Validators.pattern('^(?!\d+$)(?:[a-zA-Z][a-zA-Z ]*)?$')]),
      'gender': new FormControl(null),
      'birth_date': new FormControl(null),
      'joining_date': new FormControl(null,[Validators.required]),
      'email': new FormControl(null, [Validators.required, Validators.email, Validators.pattern('[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$')]),
      'contact_no': new FormControl(null, [Validators.required,Validators.pattern('^(?!\d+$)(?:[0-9][0-9]*)?$')]),
      'department': new FormControl(null),
      'designation': new FormControl(null),
      'address': new FormControl(null),
      'present_address': new FormControl(null),
      'city': new FormControl(null, [Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/_=]*)?$')]),
      'state': new FormControl(null, [Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/_=]*)?$')]),
      'zipcode': new FormControl(null),
      'is_admin': new FormControl(null),
      'is_manager': new FormControl(null),

      'motherName': new FormControl(null, [Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/_=]*)?$')]),
      'ctc': new FormControl(null, [Validators.pattern('^[0-9,? ]*$')]),
      'takeHome': new FormControl(null, [Validators.pattern('^[0-9,? ]*$')]),
      'grossSalary': new FormControl(null, [Validators.pattern('^[0-9,? ]*$')]),
      'maritalStatus': new FormControl(null),
      'spouseName': new FormControl(null),
      'uaNumber': new FormControl(null, [Validators.pattern('[0-9 ]+')]),
      'panNumber': new FormControl(null, [Validators.required]),
      'adharNumber': new FormControl(null, [Validators.pattern('[0-9 ]+'), Validators.minLength(12)]),
      'esicNumber': new FormControl(null, [Validators.pattern('[0-9 ]+'), Validators.minLength(10)]),
      'tenure': new FormControl(null),
      'bankName': new FormControl(null, [Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/_=]*)?$')]),
      'bankAccountNumber': new FormControl(null, [Validators.pattern('[0-9]{9,18}')]),
      'ifscCode': new FormControl(null),
      'profileImage': new FormControl(null),
      'geofencing': new FormControl(null),
      'latitude': new FormControl(null),
      'longitude': new FormControl(null),
      'distance': new FormControl(null),
      'spouse_contact_no': new FormControl(null),
      'qualification': new FormControl(null),
      'branch_id': new FormControl(null,[Validators.required]),
      'id': new FormControl(null),
      'role_id': new FormControl(null),
      'time_zone_id': new FormControl(''),
      'status': new FormControl(1),     

    }, { updateOn: 'change' });

    // fetch department
    this.getDepartment();
    this.getDesignation();
    this.getCompanyModulePermission(this.company_id);
    this.getBranches(this.company_id);
    this.getTimeZone();

  }

  get f() { return this.myForm.controls; }

  // public onFileChange(fileInput :any)
  // {
  //   this.selectedFile= fileInput.target.files[0];
  // }

  onFileChange(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
    this.selectedFile = file;
    readBase64(file).then(function (data) {
      $('#selectedFileView').attr('src', data);
    });
  }


  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.myForm.invalid) {
      return;
    }

    this.manager.created_by = this.myItem.id;
    if (!this.manager.id) {
      this.manager.company_id = this.company_id;
    }

    this.manager.is_manager = 1;
    this.manager.role_id = 4;



    let formData: FormData = new FormData();
    // formData.append('api_token','59240171-6f5c-42e3-8d87-0044ac120184');
    formData.append('first_name', this.manager.first_name);
    formData.append('employeeCode', this.manager.employeeCode);
    formData.append('last_name', this.manager.last_name);
    formData.append('middle_name', this.manager.middle_name);
    formData.append('gender', String(this.manager.gender));

    //this.manager.birth_date = this.datePipe.transform(this.manager.birth_date,"yyyy-MM-dd"); 
    formData.append('birth_date', String(this.manager.birth_date));
    formData.append('joining_date', String(this.manager.joining_date));
    formData.append('email', this.manager.email);
    formData.append('contact_no', this.manager.contact_no);
    formData.append('department', String(this.manager.department));
    formData.append('address', this.manager.address);
    formData.append('present_address', this.manager.present_address);
    formData.append('designation', String(this.manager.designation));
    formData.append('city', this.manager.city);
    formData.append('state', this.manager.state);
    formData.append('zipcode', this.manager.zipcode);
    formData.append('is_admin', String(this.manager.is_admin));
    formData.append('created_by', String(this.manager.created_by));
    formData.append('company_id', String(this.manager.company_id));
    formData.append('role_id', String(this.manager.role_id));
    formData.append('is_manager', String(this.manager.is_manager));
    formData.append('id', String(this.manager.id));


    formData.append('motherName', this.manager.motherName);
    formData.append('ctc', String(this.manager.ctc));
    formData.append('takeHome', String(this.manager.takeHome));
    formData.append('grossSalary', String(this.manager.grossSalary));
    formData.append('maritalStatus', String(this.manager.maritalStatus));
    formData.append('spouseName', String(this.manager.spouseName));
    formData.append('uaNumber', String(this.manager.uaNumber));
    formData.append('panNumber', String(this.manager.panNumber));
    formData.append('adharNumber', String(this.manager.adharNumber));
    formData.append('esicNumber', String(this.manager.esicNumber));
    formData.append('tenure', String(this.manager.tenure));
    formData.append('bankName', String(this.manager.bankName));
    formData.append('bankAccountNumber', String(this.manager.bankAccountNumber));
    formData.append('ifscCode', String(this.manager.ifscCode));
    formData.append('profileImage', this.selectedFile);

    if (this.manager.geofencing == 0) {
      formData.append('geofencing', '0');
      formData.append('latitude', '');
      formData.append('longitude', '');
      formData.append('distance', '');
    } else {
      formData.append('geofencing', String(this.manager.geofencing));
      formData.append('latitude', String(this.manager.latitude));
      formData.append('longitude', String(this.manager.longitude));
      formData.append('distance', String(this.manager.distance));
    }

    formData.append('spouse_contact_no', String(this.manager.spouse_contact_no));
    formData.append('qualification', String(this.manager.qualification));
    formData.append('weekend_days', JSON.stringify(this.weekDaysObj)); 
    formData.append('branch_id', String(this.manager.branch_id));
    formData.append('time_zone_id', String(this.manager.time_zone_id));
    formData.append('status', String(this.manager.status));


    if (this.manager.id == 0) {

      this.employeeService.add(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.employeeService.managerInsertSuccess();
          this.location.back();
        }
        else {
          this.router.navigate(['manager/addmanager', resp.items.company_id]);
        }
      });
    }
    else {
      this.employeeService.update(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.employeeService.managerUpdateSuccess();
          this.location.back();
        }
        else {
          this.router.navigate(['manager/addmanager', resp.items.company_id]);
        }
      });
    }

  }

  getData(id: number) {
    this.employeeService.getItem(id).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.manager = resp.items;
        this.getBranches(resp.items.company_id);
        this.selectedWeekDays = resp.items.weekend_days;       
        this.getCompanyModulePermission(this.manager.company_id);
        if (resp.items.profileImage) {
          this.selectedFileView = resp.items.profileImage;
        }
        this.weekDaysObj = resp.items.weekend_days_obj;
        // this.manager.birth_date = this.datePipe.transform(this.manager.birth_date,"MM/dd/yyyy"); 
      }
    );
  }

  getDepartment() {
    this.employeeService.getDepartment(this.company_id).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.department = resp.items;
      });
  }

  getDesignation() {
    this.employeeService.getDesignation(this.company_id).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.designation = resp.items;
      });
  }

  public checkEmail() {

    this.employeeService.checkEmail(this.manager.email).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.manager.email = '';
          this.user_status = true;
        }
        else {
          this.user_status = false;
        }
      }
    );
  }


  public openCalender() {
    this.calenderShow = true;
  }

  public getCompanyModulePermission(companyId) {
    let formData: FormData = new FormData();
    formData.append('companyId', companyId);
    this.employeeService.getCompanyModulePermission(formData).subscribe(
      resp => {

        let data = [];
        data = resp.items;
        // 17 Stand for Geofancing Module Id
        data.forEach(element => {
          if (element.moduleId == 17 || element.module_name == 'geofencing') {
            this.companyGeofencingPermission = element.permissionLevel;
          }
        });
      });
  }

  public updateWeekDays(e, i) {
    var index = this.selectedWeekDays.indexOf(e.target.value);
    if (e.target.checked) {
      if (index < 0) {
        this.selectedWeekDays.push(e.target.value);
      }
    } else {
      this.selectedWeekDays.splice(index, 1);
    }
  }

  public getSelectedWeekday(day) {
    if (this.selectedWeekDays.indexOf(day) < 0) {
      return false;
    } else {
      return true;
    }
  }

  public getBranches(companyId)
  {
    this.branchService.get_data(companyId).subscribe(resp=>{
      if(resp.status == 401)
      {
        this.router.navigate([this.myItem.url]);
      }
      if(resp.success){
        this.branches=resp.items.branches;
      }
    });
  }

  public updateStatus(obj,event,i){
    if(event.target.checked)
    {
      this.weekDaysObj.forEach(element => {
        if(element.value == obj.value){
          element.isSelected=1;
          element.weeknumbers.forEach(ele => {
            ele.isSelected=1;            
          });
        }
     });
    }else
    {
      this.weekDaysObj.forEach(element => {
        if(element.value == obj.value){
          
          element.weeknumbers.forEach(ele => {
            ele.isSelected = 0;            
          });
        }
     });
    }
  }

  public singleUpdate(obj,event,i,j,mainobj){
    var chk = true;
    if(event.target.checked){                
      this.weekDaysObj[i].weeknumbers[j].isSelected = 1;          
    }else
    {         
      this.weekDaysObj[i].weeknumbers[j].isSelected = 0;         
    } 

    this.weekDaysObj[i].weeknumbers.forEach(element => {         
          if(element.isSelected == 0){ chk = false; }
    });

    if(chk){
      this.weekDaysObj[i].isSelected = 1;
    }else
    {
      this.weekDaysObj[i].isSelected = 0;
    }  
  }

  public getTimeZone() {
    this.clientService.getTimeZone().subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.time_zone_list = resp.items;
        }
      }
    );
  }

  public copyPermanentAddress(e)
  {
    if(e.target.checked){
      this.manager.present_address = this.manager.address;
    } else {
      this.manager.present_address ='';
    }
  }

}




