import { Component, OnInit,ViewChild} from '@angular/core';
import { Router, ActivatedRoute, Params  } from "@angular/router";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { stringify } from 'querystring';
import { SalaryslipTemplateModel } from "./add-salaryslip-template.model";
import { SalaryslipTemplateService } from "../salaryslip-template.service";

@Component({
  selector: 'app-add-salaryslip-template',
  templateUrl: './add-salaryslip-template.component.html',
  styleUrls: ['./add-salaryslip-template.component.scss']
})
export class AddSalaryslipTemplateComponent implements OnInit {

  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;
  myForm:FormGroup;
  salayslipTemplate:SalaryslipTemplateModel = new SalaryslipTemplateModel({});
  public key ='loginData';
  public myItem:any;
  public editStatus= false ;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private salaryslipTemplateService : SalaryslipTemplateService
  ) { }

  ngOnInit() {
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.myForm = new FormGroup({
      'templateName': new FormControl(null, [Validators.required]),
      'path': new FormControl(null, [Validators.required]),
      'status': new FormControl(null)
     
  }, {updateOn: 'change'});

  this.route.params.subscribe(
    (params: Params) => {
      if (params.id) {
        this.editStatus = true;
        this.getData(params.id);
      } else {
        this.editStatus = false;
      }
    }
  );

 
  }

  backClicked() {
    this.location.back();
   }
   
   public onSubmit()
   { 
    
     let formData: FormData = new FormData();
     formData.append('createdBy',this.myItem.id);
     formData.append('status',String(this.salayslipTemplate.status));
     formData.append('templateName',this.salayslipTemplate.templateName);
     formData.append('path',this.salayslipTemplate.path);
     formData.append('id',String(this.salayslipTemplate.id));
     
      if(this.salayslipTemplate.id == 0)
        {
        
        this.salaryslipTemplateService.add(formData).subscribe(resp=>{
                if(resp.status == 401)
                {
                  this.router.navigate([this.myItem.url]);
                }
                if(resp.success) { 
                  this.salaryslipTemplateService.insertSuccess();
                // this.router.navigate(['admin/departments']);
                this.location.back();
                  }
                  else{
                      this.router.navigate(['salaryslip-template/add_salaryslip_template']);
                  }
                  }); 
        }                      
      else
      {  
        this.salaryslipTemplateService.update(formData).subscribe(resp=>{
          if(resp.status == 401)
          {
            this.router.navigate([this.myItem.url]);
          }
          if(resp.success) { 
            this.salaryslipTemplateService.updateSuccess();
            this.location.back();
            }
            else{
                this.router.navigate(['salaryslip-template/add_salaryslip_template']);
            }
        });
      }
    }
  
  public getData(id : number)
   {
    this.salaryslipTemplateService.getItem(id).subscribe(
      resp=>{
        if(resp.status == 401)
        {
          this.router.navigate([this.myItem.url]);
        }
        this.salayslipTemplate = resp.items;
      }
    );
   }

}
