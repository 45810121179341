import { Component, OnInit, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

var fireRefreshEventOnWindow = function () {
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
};

@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss']
})

export class FullLayoutComponent implements OnInit {

        public key ='loginData';
        public myItem:any;
        public role_id ; 
        public is_admin ; 
        public is_manager;


    options = {
        direction: 'ltr'
    };


    constructor(private elementRef: ElementRef) { }

    ngOnInit() {
        //sidebar toggle event listner
        // this.elementRef.nativeElement.querySelector('#sidebarToggle')
        //     .addEventListener('click', this.onClick.bind(this));
        //customizer events
      //  this.elementRef.nativeElement.querySelector('#cz-compact-menu')
          ///  .addEventListener('click', this.onClick.bind(this));
       // this.elementRef.nativeElement.querySelector('#cz-sidebar-width')
         //   .addEventListener('click', this.onClick.bind(this));

            this.myItem = JSON.parse(localStorage.getItem(this.key));
            
            this.role_id = this.myItem.role_id;
            //console.log(this.role_id);
            this.is_admin = this.myItem.is_admin;
            this.is_manager =this.myItem.is_manager;

        //console.log(this.is_manager);

            if(this.role_id == 1)
            {
                this.elementRef.nativeElement.querySelector('#sidebarToggle')
               // .addEventListener('click', this.onClick.bind(this));  
            }
            else{
                this.elementRef.nativeElement.querySelector('#EmpsidebarToggle')
               // .addEventListener('click', this.onClick.bind(this));  
            }

           // console.log(this.role_id);

    }

    onClick(event) {
        //initialize window resizer event on sidebar toggle click event
        setTimeout(() => { fireRefreshEventOnWindow() }, 300);
    }

    getOptions($event): void {
        this.options = $event;
    }

}