import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { App_Url } from '../../globals';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})


export class ClientsService {
  
  constructor(private http: HttpClient,public toastr: ToastrService) { }
   public key ='loginData';
   public myItem:any;

  public add(formData:any):Observable<any>
  { 
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/v2/addClient`,formData);
  }

  public insertpayroll(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/addPayrolls`,formData);
  }

  public updatepayroll(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/addPayrolls`,formData);
  }

  public update(formData:any):Observable<any>
  { 
   formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/v2/addClient`,formData);
  }

  public getMasterPayrollList():Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/getPayrollsList`,formData);
  }
  public getClientList(companyId,roleId):Observable<any>
  {
 
    let formData: FormData = new FormData();
     formData.append('companyId', companyId);
     formData.append('roleId', roleId);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/v2/getClientList`,formData);

  }

  public deletePayroll(id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/deletePayrolls`,formData);
  }

  public delete(id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/v2/deleteClient`,formData);
  }

  insertSuccess() {
    this.toastr.success('Client has been inserted successfully', 'Success!');
  }
  insertPayrollSuccess(){
    this.toastr.success('Payroll has been inserted successfully', 'Success!');
  }
  updatePayrollSuccess() {
    this.toastr.success('Payroll has been updated successfully', 'Success!');
  }

  updateSuccess() {
    this.toastr.success('Client has been updated successfully', 'Success!');
  }
  deleteSuccess() {
    this.toastr.success('Client has been deleted successfully', 'Success!');
  }

  deletePayrollSuccess(){
    this.toastr.success('Master Payroll has been deleted successfully', 'Success!');
  }
  alreadyExists(){
    this.toastr.error('', 'Client already exists', { "timeOut": 1000 });
  }
  payrollalreadyExists(){
    this.toastr.error('', 'This Master already exists', { "timeOut": 1000 });
  }

  getApiToken(){
   this.myItem = JSON.parse(localStorage.getItem(this.key));
   var api_token = this.myItem.tokenKey;
   return api_token ;
  }
}
