import { Component, OnInit,ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { LeaveTypeService } from './leave-type.service'
import { Router, ActivatedRoute, Params  } from "@angular/router";
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { SidebarService } from '../shared/sidebar/sidebar.service';

@Component({
  selector: 'app-leave-type',
  templateUrl: './leave-type.component.html',
  styleUrls: ['./leave-type.component.scss']
})
export class LeaveTypeComponent implements OnInit {

  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};

  constructor(private location: Location,
    private leaveTypeService:LeaveTypeService,
    private router: Router,
    private sidebarService : SidebarService) { }
  myForm: FormGroup;
  public key ='loginData';
  public myItem:any;
  public data:any = [];
  public permission;

  ngOnInit() {
    this.permission= this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.myForm = new FormGroup({
      'id': new FormControl(0),
      'leaveType': new FormControl(null, [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/+_=]*)?$')]),
      'short_name': new FormControl(null, [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/+_=]*)?$')]),
      'company_id': new FormControl(null),
     
    }, {updateOn: 'change'});

    this.getLeaveType();
  
    this.dtOptions = {
      //used id for index 
      order: [[0, "ASC"]],
    };
  }

  public addLeaveType()
  {
    let formData: FormData = new FormData();
    formData.append('leaveType',this.myForm.get('leaveType').value);
    formData.append('short_name',this.myForm.get('short_name').value);
    formData.append('createdBy',this.myItem.id);
    formData.append('companyId',this.myItem.company_id);

    if(this.myForm.get('id').value == 0){
      this.leaveTypeService.add(formData).subscribe(resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success) { 
          this.leaveTypeService.insertSuccess();
          this.getLeaveType();
         // this.myForm.get('id').setValue(0);
         // this.myForm.get('leaveType').setValue('');
          //this.myForm.get('short_name').setValue('');
          this.myForm.reset();
          this.myForm.get('id').setValue(0);
        }else {
          this.leaveTypeService.alreadyExists();
        }    
      });
    } else {
      formData.append('id',this.myForm.get('id').value);
      formData.append('companyId',this.myForm.get('company_id').value);
      this.leaveTypeService.update(formData).subscribe(resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success) { 
          this.leaveTypeService.updateSuccess();
          this.getLeaveType();
         // this.myForm.get('id').setValue(0);
          this.myForm.reset();
          this.myForm.get('id').setValue(0);
          //this.myForm.get('leaveType').setValue('');
          //this.myForm.get('short_name').setValue('');
        }     
      });
    }

  }

  public getLeaveType()
  {
    this.leaveTypeService.getLeaveType(this.myItem.company_id,this.myItem.role_id).subscribe(resp=>{
      if(resp.status == 401)
      {
          this.router.navigate([this.myItem.url]);
      }
      if(resp.success) { 
        this.data = resp.items;
        this.rerender();
      }     
    });
  }

  backClicked() {
    this.location.back();
   } 


   ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        // For checked fields
        $('input[type=checkbox]', this.footer()).on('checked change', function () {
          if (this['value'] === 'true') {
            that.search(this['value']).draw();
          } else {
            that.search('').draw();
          }
        });

        $('input[type=search]', this.footer()).on('keyup change', function () {
        //  console.log("text : " + this['value']);
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }


  public getEdit(val)
  {
    this.myForm.get('id').setValue(val.id);
    this.myForm.get('leaveType').setValue(val.leaveType);
    this.myForm.get('short_name').setValue(val.short_name);
    this.myForm.get('company_id').setValue(val.companyId);
  }

  public Delete(id: number, i: number)
  {
    if(window.confirm('Are you sure you want to delete?')) {
      this.leaveTypeService.delete(id).subscribe(resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success){
          this.leaveTypeService.deleteSuccess();
         this.data.splice(i, 1);  
         this.getLeaveType();
         this.rerender();
        }
      });
    }
  }

}
