import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {
  public key ='loginData';
  public myItem:any;
  token: string;

  constructor(private router:Router) {}
  

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
  }

  logout() {   
    this.token = null;
  }

  getToken() {    
    return this.token;
  }

  isAuthenticated() {
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    if(this.myItem)
    {
      return true;
    }   
    else
    {
      this.router.navigate([this.myItem.url]);
    } 
  }
  
}
