import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {TaskViewComponent} from './task-view.component';

const routes: Routes = [
  {
    path: '', 
    
     children: [   
      {
        path: 'task-view',
        component: TaskViewComponent,
        data: {
          title: 'task-view'
        }
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  
})
export class TaskViewRoutingModule { }
export const TaskViewList = [
  TaskViewComponent
];
