import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ClientsComponent} from './clients.component';

const routes: Routes = [
  {
    path: '', 
    
     children: [   
      {
        path: 'clients',
        component: ClientsComponent,
        data: {
          title: 'clients'
        }
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  
})
export class ClientsRoutingModule { }
export const ClientsList = [
  ClientsComponent
];
