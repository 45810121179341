
import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { SalaryslipTemplateService } from "../salaryslip-template.service";



@Component({
  selector: 'app-salaryslip-list',
  templateUrl: './salaryslip-list.component.html',
  styleUrls: ['./salaryslip-list.component.scss']
})
export class SalaryslipListComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  public data:  any[] = [];
  public key ='loginData';
  public myItem:any;
  public editStatus = true;
  public userDoc ;
  public id;
  public salaryData: any[] = [];
  public userId;
  public monthYear;


  constructor(
    private router:Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private salaryslipTemplateService : SalaryslipTemplateService) { }

  ngOnInit() {
    this.myItem = JSON.parse(localStorage.getItem(this.key));

    this.activatedRoute.params.subscribe(
      (params: Params) => {
        if (params.id) {
          this.id = params.id
        } else {
        this.id = this.myItem.id;
        }
      }
    );

   
    this.salaryslip(this.id);
  }
   
  
  public viewDocument(id : number)
  {
    this.router.navigate(['document-category/document-category',id]);
  }

  

  public salaryslip(id)
  { 
     this.salaryslipTemplateService.salaryslip(this.id).subscribe(resp=>{
      if(resp.status == 401)
      {
        this.router.navigate([this.myItem.url]);
      }
      this.salaryData=resp.items;
      this.rerender();
    });
  }

  public viewSalarySlip(id)
  {
    // this.userId = val.userId;
    // this.monthYear = val.monthYear;
    this.router.navigate(["salaryslip-template/salaryslip_template1",id]);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.salaryslipTemplateService.userId = this.userId;
    this.salaryslipTemplateService.monthYear = this.monthYear;
  }

  rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        // For checked fields
        $('input[type=checkbox]', this.footer()).on('checked change', function () {
          if (this['value'] === 'true') {
            that.search(this['value']).draw();
          } else {
            that.search('').draw();
          }
        });

        $('input[type=text]', this.footer()).on('keyup change', function () {
          console.log("text : " + this['value']);
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
  
  public backClicked()
  {
    this.location.back();
  }   
}
