export class NavbarModel {

    password : string;
    oldPassword : string;
    confirmPassword : string;

 

    constructor(params:any) {
        const model = params || {};
        this.password =model.password || '';
        this.oldPassword =model.oldPassword || '';
        this.confirmPassword =model.confirmPassword || '';
    }
}
