import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { VehicleListService } from "../vehicle-list.service";
import { Location } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { SidebarService } from '../../../shared/sidebar/sidebar.service';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { FileSelectDirective, FileDropDirective, FileUploader, FileLikeObject } from 'ng2-file-upload';
import { logo_url } from '../../../globals';


function readBase64(file): Promise<any> {
  var reader = new FileReader();
  var future = new Promise((resolve, reject) => {
    reader.addEventListener("load", function () {
      resolve(reader.result);
    }, false);
    reader.addEventListener("error", function (event) {
      reject(event);
    }, false);
    reader.readAsDataURL(file);
  });
  return future;
}

const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';


@Component({
  selector: 'app-add-vehicle-details',
  templateUrl: './add-vehicle-details.component.html',
  styleUrls: ['./add-vehicle-details.component.scss']
})
export class AddVehicleDetailsComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private vehicleListService: VehicleListService
  ) { }

  myForm: FormGroup;
  public editStatus = true;
  public key = 'loginData';
  public myItem: any;
  public compulsoryField = 'This field is required';
  public selectedFile: File = null;
  public selectedFileView: any;
  public uploader: FileUploader = new FileUploader({ url: URL, disableMultipart: true });
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;

  ngOnInit() {

    this.myItem = JSON.parse(localStorage.getItem(this.key));

    this.route.params.subscribe(
      (params: Params) => {
        if (params.id) {
          this.editStatus = true;
          this.getData(params.id);
        } else {
          this.editStatus = false;
        }
      });

    this.selectedFileView = logo_url + 'upload.png';

    this.myForm = new FormGroup({
      'vehicle_name': new FormControl('', [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/+_=]*)?$')]),
      'vehicle_registration_no': new FormControl('', [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/+_=]*)?$')]),
      'vehicle_no': new FormControl('', [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/+_=]*)?$')]),
      'vehicle_model': new FormControl('', [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/+_=]*)?$')]),
      'vendor_name': new FormControl('', [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/+_=]*)?$')]),
      'is_active': new FormControl(1),
      'document_url': new FormControl(''),
      'id': new FormControl(0),
      'company_id': new FormControl(''),
      'created_by': new FormControl(''),

    }, { updateOn: 'change' });
  }

  onFileChange(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
    this.selectedFile = file;
    readBase64(file).then(function (data) {
      //this.selectedFileView = data;   
      $('#selectedFileView').attr('src', data);
    });
  }

  public onSubmit() {

    let formData: FormData = new FormData();
    formData.append('created_by', this.myItem.id);
    formData.append('company_id', this.myItem.company_id);
    formData.append('vehicle_registration_no', this.myForm.get('vehicle_registration_no').value);
    formData.append('vehicle_no', this.myForm.get('vehicle_no').value);
    formData.append('vehicle_name', this.myForm.get('vehicle_name').value);
    formData.append('vehicle_model', this.myForm.get('vehicle_model').value);
    formData.append('vendor_name', this.myForm.get('vendor_name').value);
    formData.append('is_active', this.myForm.get('is_active').value);
    formData.append('document_url', this.selectedFile);
    formData.append('id', this.myForm.get('id').value);
    if (this.myForm.get('id').value == 0) {
      this.vehicleListService.add(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.vehicleListService.insertSuccess();
          this.location.back();
        }
        else {
          //this.router.navigate(['client/addclient']);
        }
      });
    }
    else {
      this.vehicleListService.update(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.vehicleListService.updateSuccess();
          this.location.back();
        }
        else {
          //this.router.navigate(['client/addclient']);
        }
      });
    }
  }

  public getData(id) {
    this.vehicleListService.getData(id).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.myForm.get('vehicle_name').setValue(resp.items.vehicle_name);
        this.myForm.get('vehicle_registration_no').setValue(resp.items.vehicle_registration_no);
        this.myForm.get('vehicle_no').setValue(resp.items.vehicle_no);
        this.myForm.get('vehicle_model').setValue(resp.items.vehicle_model);
        this.myForm.get('vendor_name').setValue(resp.items.vendor_name);
        this.myForm.get('is_active').setValue(parseInt(resp.items.is_active));
        this.myForm.get('id').setValue(parseInt(resp.items.id));

        if (resp.items.document_url) {
          this.selectedFileView = resp.items.document_url;
        }
      }
      else {
        //this.router.navigate(['client/addclient']);
      }
    });
  }

  public backClicked() {
    this.location.back();
  }

}
