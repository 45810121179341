import { Component, OnInit,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';

import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { LeaveTypeService } from '../../leave-type/leave-type.service';
import { TaskManagerService } from './../task-manager.service';
import { Router, ActivatedRoute, Params  } from "@angular/router";
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { SidebarService } from '../../shared/sidebar/sidebar.service';
import { formControlBinding } from '@angular/forms/src/directives/reactive_directives/form_control_directive';

@Component({
  selector: 'app-to-do',
  templateUrl: './to-do.component.html',
  styleUrls: ['./to-do.component.scss'],
  providers:[DatePipe]
})
export class ToDoComponent implements OnInit {

  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};

  constructor(private location: Location,
    private leaveTypeService:LeaveTypeService,
    private TaskManagerService:TaskManagerService,
    private router: Router,
    private datePipe: DatePipe,
    private sidebarService : SidebarService) { }
  myForm: FormGroup;
  myForm1:FormGroup;
  public key ='loginData';
  public myItem:any;
  public data:any = [];
  public permission;
  public projectlist: any = [];
  public projectlists:any=[];
  client : any;
  project :any;
  is_new:any;
  no_of_hour:any;
  no_of_minute:any;
  todaysDate:any;
  public hours:any=["00","01","02","03","04","05","06","07","08","09"];
  public minutes:any=["00","05","10","15","20","25","30","35","40","45","50","55"]; 
  marked = false; 
  theCheckbox = false;
  ngOnInit() {
    this.todaysDate = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.permission= this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    console.log(this.myItem);
   
    this.myForm = new FormGroup({
      'id': new FormControl(0),
      'hours': new FormControl('', [Validators.required]),
      'client': new FormControl('', [Validators.required]),
      'task_type': new FormControl('', [Validators.required]),
      'project':new FormControl('', [Validators.required]),
      'task':new FormControl(null, [Validators.required]),
      'minute': new FormControl('', [Validators.required]),
      'is_new':new FormControl(0)
     
    }, {updateOn: 'change'});
    
    this.TaskManagerService.getClientList(this.myItem.company_id).subscribe(resp => {
      console.log(resp.data);
      this.projectlist = resp.data;

  });
  this.myForm1 = new FormGroup({
      
    'fromDate': new FormControl(null, [Validators.required])
   

  }, { updateOn: 'change' });

    this.getLeaveType(this.todaysDate);
  
    this.dtOptions = {
      //used id for index 
      order: [[0, "ASC"]],
    };
  }

public  getProject(selectedValue:string){
    console.log('value is ',selectedValue);
    this.projectlists=[];
    this.TaskManagerService.getProjectList(selectedValue).subscribe(resp => {
      this.projectlists = resp.data;
    })
 
    }
 
    toggleVisibility(e){
      this.marked= e.target.checked;
      console.log(this.marked);
    }
    public addclients() {

      this.router.navigate(['admin/clients']);
    }
    public viewlist()
    {
      
      this.router.navigate(['employee/task-view']);
    }
    public addprojects() {
      this.router.navigate(['admin/projects']);
    }
  public addtask()
  {
  
   let myDate = this.datePipe.transform(new Date(),"yyyy-MM-dd");
   let formData: FormData = new FormData();
   var totalhour=String(parseInt(this.myForm.get('hours').value) * 60 + parseInt(this.myForm.get('minute').value));
   if(totalhour=='0'){
    this.TaskManagerService.zeroMinuteError();
   }else{
        formData.append('hours',this.myForm.get('hours').value);
        formData.append('userId',this.myItem.id);
        formData.append('taskDate',myDate);
        formData.append('clientId',this.myForm.get('client').value);
        formData.append('taskType',this.myForm.get('task_type').value);
        formData.append('projectId',this.myForm.get('project').value);
        formData.append('task',this.myForm.get('task').value);
        formData.append('hours',this.myForm.get('hours').value);
        formData.append('minutes',totalhour);
        formData.append('is_new',this.myForm.get('is_new').value);
        if(this.myForm.get('id').value == 0){

          this.TaskManagerService.add(formData).subscribe(resp=>{
            if(resp.status == 401)
            {
                this.router.navigate([this.myItem.url]);
            }
            if(resp.success) { 
              this.TaskManagerService.insertSuccess();
              this.getLeaveType(this.todaysDate);
              this.formReset();
            }else {
              this.TaskManagerService.alreadyExists();
            }    
          });
        } else {
        
          formData.append('taskId',this.myForm.get('id').value);
          this.TaskManagerService.update(formData).subscribe(resp=>{
            if(resp.status == 401)
            {
                this.router.navigate([this.myItem.url]);
            }
            if(resp.success) { 
              this.TaskManagerService.updateSuccess();
              this.getLeaveType(this.todaysDate);
              this.formReset();
            }     
          });
        }
    }

  }

  public formReset()
  {
    this.myForm.get('id').setValue(0);
    this.myForm.get('project').setValue('');
    this.myForm.get('client').setValue('');
    this.myForm.get('task_type').setValue('');
    this.myForm.get('hours').setValue('');
    this.myForm.get('minute').setValue('');
    this.myForm.get('task').setValue('');
    this.myForm.get('is_new').setValue(0);
  }


  public getLeaveType(todaysDate)
  {
    let myDate = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    
    this.TaskManagerService.getTaskList(this.myItem.id,todaysDate).subscribe((resp:any)=>{
   
   
      if(resp.status == 401)
      {
          this.router.navigate([this.myItem.url]);
      }
      if(resp['success']) { 
        this.data = resp.data;
        console.log(this.data);
        this.rerender();
      }     
    });
  }

  backClicked() {
    this.location.back();
   } 


   ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        // For checked fields
        // $('input[type=checkbox]', this.footer()).on('checked change', function () {
        //   if (this['value'] === 'true') {
        //     that.search(this['value']).draw();
        //   } else {
        //     that.search('').draw();
        //   }
        // });

        // $('input[type=search]', this.footer()).on('keyup change', function () {
        // //  console.log("text : " + this['value']);
        //   if (that.search() !== this['value']) {
        //     that.search(this['value']).draw();
        //   }
        // });
      });
    });
  }

  public taskfilter()
  {
    var todaysDate1=this.myForm1.get('fromDate').value;
    console.log(todaysDate1);
    this.getLeaveType(todaysDate1);
    this.rerender();
  }

  public getEdit(val)
  {
    console.log(val);
    this.myForm.get('id').setValue(val.id);
    this.getProject(val.clientId);
    let overminute=val.minutes;
    let x = overminute.split(":");
  
    this.myForm.get('project').setValue(val.projectId);
    this.myForm.get('client').setValue(val.clientId);
    this.myForm.get('task_type').setValue(val.taskType);
    this.myForm.get('task').setValue(val.task);
    this.myForm.get('hours').setValue(x[0]);
    this.myForm.get('minute').setValue(x[1]);
    this.myForm.get('is_new').setValue(val.isNew);
 
  }

  public Delete(id: number, i: number)
  {
    if(window.confirm('Are you sure you want to delete?')) {
      this.TaskManagerService.delete(id).subscribe(resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success){
          this.TaskManagerService.deleteSuccess();
         this.data.splice(i, 1);  
         this.getLeaveType(this.todaysDate);
         this.rerender();
        }
      });
    }
  }

}
