import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LeaveTypeComponent} from './leave-type.component';


const routes: Routes = [
  {
    path: '', 
    
     children: [   
      {
        path: 'leave-type',
        component: LeaveTypeComponent,
        data: {
          title: 'Leave type'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LeaveTypeRoutingModule { }

export const LeaveTypeList = [
  LeaveTypeComponent
];

