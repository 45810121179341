import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ClientModel } from "./client.model";
import { App_Url } from '../globals';
import { ToastrService } from 'ngx-toastr';
//import { environment } from '../../environments/environment.prod';



@Injectable({
  providedIn: 'root'
})


export class ClientService {

  constructor(private http: HttpClient, public toastr: ToastrService) { }
  public key = 'loginData';
  public myItem: any;

  public add(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/company-add`, formData);
  }

  public update(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/company-update`, formData);
  }



  public get_data(parent_id,role_id): Observable<any> {

    let formData: FormData = new FormData();
    formData.append('id', parent_id);
    formData.append('role_id', role_id);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/get_client_data`, formData);

  }

  public getItem(id): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/company-single`, formData);
  }

  public delete(id): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/company-delete`, formData);
  }

  insertSuccess() {
    this.toastr.success('Client has been inserted successfully!', 'Success!');
  }

  updateSuccess() {
    this.toastr.success('Client has been updated successfully!', 'Success!');
  }
  deleteSuccess() {
    this.toastr.success('Client has been deleted successfully!', 'Success!');
  }

  permissionAssign() {
    this.toastr.success('Permission has been assigned successfully!', 'Success!');
  }

  public getModule(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/get_module`, formData);
  }
  public getpayrollModule(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/get_payroll_module`, formData);
  }
  public getClientName(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/getClientName`, formData);
  }

  public addModulePermission(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/module-permission`, formData);
  }

  public addPayrollModulePermission(formData: any):Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/payroll-module-permission`, formData);
  }

  public checkClientEmail(company_email): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('company_email', company_email);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/checkClientEmail`, formData);

  }

  //for get all count

  public getAllTotalCount(companyId): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('companyId', companyId);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/getAllTotalCount`, formData);
  }

  public getTimeZone(): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/get_time_zone`, formData);
  }


  getApiToken() {
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    var api_token = this.myItem.tokenKey;
    return api_token;
  }
}
