export class ModuleModel {

    id: number;
    module_name :string;
    created_by : number;
    status :number;
   
    
  

    constructor(params:any) {
        const model = params || {};
        this.module_name =model.module_name || '';
        this.created_by =model.created_by || '';
        this.status =model.status || '1';
        this.id =model.id || 0;
    }
}
