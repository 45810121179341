import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ChartistModule } from 'ng-chartist';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchHeightModule } from "../shared/directives/match-height.directive";

import { DesignationRoutingModule ,DesignationComponentList } from "./designation-routing.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';
import { AddDesignationComponent } from './add-designation/add-designation.component';
import {ClientHeaderModule} from '../client-header/client-header.module';
import {CustomerHeaderModule} from '../customer-header/customer-header.module';


@NgModule({
    imports: [
        CommonModule,
        DesignationRoutingModule,
        ChartistModule,
        NgbModule,
        MatchHeightModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgxDatatableModule,
        DataTablesModule,
        ClientHeaderModule,
        CustomerHeaderModule
    ],   
    exports: [],
    declarations: [
        DesignationComponentList,
        AddDesignationComponent
        
    ],  
    providers: [], 
})
export class DesignationModule{ }
