import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { EmployeeService } from "../../employee/employee.service";
import { EmployeeListModel } from "./employee-list.model";
import { DocumentCategoryService } from "../document-category.service";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { SidebarService } from '../../shared/sidebar/sidebar.service';


@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  public data:  any[] = [];
  public editStatus;
  public company_id;
  public documentType_id;
  public key ='loginData';
  public myItem:any;
  public permission;

  employeelist:EmployeeListModel = new EmployeeListModel({});

  


  constructor(private employeeService : EmployeeService,
    private documentCategoryService : DocumentCategoryService,
    private router:Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private sidebarService : SidebarService,
  ) { }

  ngOnInit() {
    this.permission= this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));

    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.company_id = params.company_id;
        this.documentType_id = params.typeId;
        
      }
    );

    this.getdata(this.company_id);
   // this.rerender();
  }
   
  public getdata(company_id : number)
  {
     this.employeeService.getdata(company_id).subscribe(resp=>{
      if(resp.status == 401)
      {
          this.router.navigate([this.myItem.url]);
      }
      this.data=resp.items;
      this.rerender();
    });
  }

  //  public uploadFile(fileInput: any)
  //  {
  //     this.employeelist.created_by = this.myItem.id;
  //     this.employeelist.userId = fileInput.target.id;
  //     this.employeelist.documentType = this.documentType_id;
  //    let formData: FormData = new FormData();
  //    formData.append('data' ,JSON.stringify(this.employeelist));
  //    formData.append('document' , fileInput.target.files[0]);
  //    this.documentCategoryService.addDocument(formData).subscribe(resp=>{
  //     if(resp.status == 401)
  //     {
  //         this.router.navigate([this.myItem.url]);
  //     }

  //     if(resp.success){
  //       this.employeeService.typeSuccess();

  //     }
      
  //    }

  //   ); 
  //  }

   ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        // For checked fields
        $('input[type=checkbox]', this.footer()).on('checked change', function () {
          if (this['value'] === 'true') {
            that.search(this['value']).draw();
          } else {
            that.search('').draw();
          }
        });

        $('input[type=text]', this.footer()).on('keyup change', function () {
          console.log("text : " + this['value']);
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  } 

  public backClicked()
  {
    this.location.back();
  }   

}
