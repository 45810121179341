import { Component, OnInit,ViewChild,ElementRef} from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import {AttendanceService} from '../attendance.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SidebarService } from '../../shared/sidebar/sidebar.service';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-manage-attendance',
  templateUrl: './manage-attendance.component.html',
  styleUrls: ['./manage-attendance.component.scss']
})
export class ManageAttendanceComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  @ViewChild('addAttendanceModal') addAttendanceModal: ElementRef;
  @ViewChild('ApproveAttendanceModal') approveAttendanceModal;
  
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};

  constructor(private attendanceService:AttendanceService,
    private router:Router,
    private sidebarService : SidebarService,
    private modalService: NgbModal) { }

  public data:  any[] = [];
  public key ='loginData';
  public key1 ='permission';
  public myItem:any;
  public modulePermission:any;
  public permission;
  public currentAttendanceData;
  myForm: FormGroup;
  public addAttendanceModalRef;
  public approveAttendanceModalRef;

  ngOnInit() {
    this.permission= this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.modulePermission = JSON.parse(localStorage.getItem(this.key1));   

    this.dtOptions = {
      //used id for index 
      order: [[0, "DESC"]],
    };

    this.getEmployee();

    this.myForm = new FormGroup({
      'approval_status': new FormControl('',Validators.required),
      'remark': new FormControl(null,Validators.required),
    }, { updateOn: 'change' });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        // For checked fields
        $('input[type=checkbox]', this.footer()).on('checked change', function () {
          if (this['value'] === 'true') {
            that.search(this['value']).draw();
          } else {
            that.search('').draw();
          }
        });

        $('input[type=text]', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  public getEmployee()
  {
    this.attendanceService.getEmployeeForAttendanceApprove(this.myItem.id).subscribe(resp=>{
      if(resp.status == 401)
      {
          this.router.navigate([this.myItem.url]);
      }
      if(resp.success)
      {
        this.data = resp.items;
        this.rerender();
      } else {
        this.data = [];
        this.rerender();
      } 
     
    });
  }

  public editAttendance(val)
  {
    this.currentAttendanceData = val;
    this.myForm.get('approval_status').setValue(val.approval_status);
    this.myForm.get('remark').setValue(val.remark);
    this.addAttendanceModalRef = this.modalService.open(this.addAttendanceModal, { size: 'lg', windowClass: 'modal-xl' });
  }

  public submitAttendanceStatus(id,flag)
  {

    let data = [{
      'id' : id,
      'approval_status' : this.myForm.get('approval_status').value,
      'remark' : this.myForm.get('remark').value
    }];

    let formData: FormData = new FormData();
    formData.append('created_by', this.myItem.id);
    //formData.append('id', id);
   // formData.append('approval_status', this.myForm.get('approval_status').value);
    //formData.append('remark', this.myForm.get('remark').value);
    if(flag =='single'){
      formData.append('data', JSON.stringify(data));
    } else {
      formData.append('data', JSON.stringify(this.data));
    }
    
    this.attendanceService.attendanceApprovalStatus(formData).subscribe(resp=>{
      if(resp.status == 401)
      {
        this.router.navigate([this.myItem.url]);
      }
     
      if(resp.success)
      {
        this.getEmployee();
        this.attendanceService.approvedStatus(resp.message);
        this.currentAttendanceData =[];
        this.myForm.get('approval_status').setValue('');
        this.myForm.get('remark').setValue('');
      }
     
    });
  }
  
  public approveAttendance()
  {
    this.approveAttendanceModalRef = this.modalService.open(this.approveAttendanceModal, { size: 'lg', windowClass: 'modal-xl' });
  }

  public updateAttendanceArray(e,id,flag)
  {
    if(flag == 'status'){
      this.data.filter(element=>{
        if(element.id == id){
          element.approval_status = e.target.value;
        }
      });
    } 

    if(flag == 'remark') {
      this.data.filter(element=>{
        if(element.id == id){
          element.remark = e.target.value;
        }
      });
    }

    if(flag == 'approvedAll') {
      this.data.filter(element=>{
        element.approval_status = e.target.value;
      });
    }

    if(flag == 'remarkAll') {
      this.data.filter(element=>{
        element.remark = e.target.value;
      });
    }

    console.log(this.data);
  }

}
