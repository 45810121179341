import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { ClientModel } from "../../client/client.model";
import { ClientService } from "../../client/client.service";
import { take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { FileSelectDirective, FileDropDirective, FileUploader, FileLikeObject } from 'ng2-file-upload';
import { stringify } from '../../../../node_modules/@angular/compiler/src/util';
import { logo_url } from '../../globals';


function readBase64(file): Promise<any> {
  var reader = new FileReader();
  var future = new Promise((resolve, reject) => {
    reader.addEventListener("load", function () {
      resolve(reader.result);
    }, false);
    reader.addEventListener("error", function (event) {
      reject(event);
    }, false);
    reader.readAsDataURL(file);
  });
  return future;
}

const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})

export class AddCustomerComponent implements OnInit {

  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;
  @ViewChild("fileInput") fileInput;
  myForm: FormGroup;
  company: ClientModel = new ClientModel({});


  public editStatus = true;
  public key = 'loginData';
  public myItem: any;
  public selectedFile: File = null;
  public selectedFileView: any;
  public uploader: FileUploader = new FileUploader({ url: URL, disableMultipart: true });
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;
  public parent_id;
  public status = true;
  public emailStatus = false;
  public weekDays: any = [];
  public weekDaysObj: any = [];
  public weekNumbers: any = [];
  public selectedWeekDays: any = [];
  public compulsoryField = 'This field is required';
  public time_zone_list: any = [];

  constructor(private clientService: ClientService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location) { }

  backClicked() {
    this.location.back();
  }

  ngOnInit() {

    this.weekDaysObj = [
      {
        'day': 'Monday', 'value': '1.0', 'isSelected': 0, 'weeknumbers': [
          { 'value': 1, 'isSelected': 0 },
          { 'value': 2, 'isSelected': 0 },
          { 'value': 3, 'isSelected': 0 },
          { 'value': 4, 'isSelected': 0 },
          { 'value': 5, 'isSelected': 0 },
        ]
      },
      {
        'day': 'Tuesday', 'value': '2.0', 'isSelected': 0, 'weeknumbers': [
          { 'value': 1, 'isSelected': 0 },
          { 'value': 2, 'isSelected': 0 },
          { 'value': 3, 'isSelected': 0 },
          { 'value': 4, 'isSelected': 0 },
          { 'value': 5, 'isSelected': 0 },
        ]
      },
      {
        'day': 'Wednesday', 'value': '3.0', 'isSelected': 0, 'weeknumbers': [
          { 'value': 1, 'isSelected': 0 },
          { 'value': 2, 'isSelected': 0 },
          { 'value': 3, 'isSelected': 0 },
          { 'value': 4, 'isSelected': 0 },
          { 'value': 5, 'isSelected': 0 },
        ]
      },
      {
        'day': 'Thursday', 'value': '4.0', 'isSelected': 0, 'weeknumbers': [
          { 'value': 1, 'isSelected': 0 },
          { 'value': 2, 'isSelected': 0 },
          { 'value': 3, 'isSelected': 0 },
          { 'value': 4, 'isSelected': 0 },
          { 'value': 5, 'isSelected': 0 },
        ]
      },
      {
        'day': 'Friday', 'value': '5.0', 'isSelected': 0, 'weeknumbers': [
          { 'value': 1, 'isSelected': 0 },
          { 'value': 2, 'isSelected': 0 },
          { 'value': 3, 'isSelected': 0 },
          { 'value': 4, 'isSelected': 0 },
          { 'value': 5, 'isSelected': 0 },
        ]
      },
      {
        'day': 'Saturday', 'value': '6.0', 'isSelected': 0, 'weeknumbers': [
          { 'value': 1, 'isSelected': 0 },
          { 'value': 2, 'isSelected': 0 },
          { 'value': 3, 'isSelected': 0 },
          { 'value': 4, 'isSelected': 0 },
          { 'value': 5, 'isSelected': 0 },
        ]
      },
      {
        'day': 'Sunday', 'value': '7.0', 'isSelected': 0, 'weeknumbers': [
          { 'value': 1, 'isSelected': 0 },
          { 'value': 2, 'isSelected': 0 },
          { 'value': 3, 'isSelected': 0 },
          { 'value': 4, 'isSelected': 0 },
          { 'value': 5, 'isSelected': 0 },
        ]
      },
    ];

    // this.weekDays = [
    //   { 'day': 'Monday', 'isSelected': 0 },
    //   { 'day': 'Tuesday', 'isSelected': 0 },
    //   { 'day': 'Wednesday', 'isSelected': 0 },
    //   { 'day': 'Thursday', 'isSelected': 0 },
    //   { 'day': 'Friday', 'isSelected': 0 },
    //   { 'day': 'Saturday', 'isSelected': 0 },
    //   { 'day': 'Sunday', 'isSelected': 0 },
    // ];

    // this.selectedFileView = logo_url+'logo-default.png';
    this.selectedFileView = logo_url + 'logo-default.png';
    this.myItem = JSON.parse(localStorage.getItem(this.key));

    this.route.params.subscribe(
      (params: Params) => {
        if (params.id) {
          this.editStatus = true;
          this.getData(params.id);
        } else {
          this.editStatus = false;
        }

        if (params.parent_id) {
          this.parent_id = params.parent_id;
        } else {
          this.parent_id = this.myItem.id;
        }


      }
    );

    this.myForm = new FormGroup({
      'company_name': new FormControl(null, [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/_=]*)?$')]),
      'company_location': new FormControl(null, [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/_=]*)?$')]),
      'company_address': new FormControl(null, [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/_=]*)?$')]),
      'company_email': new FormControl(null, [Validators.required, Validators.email]),
      'company_number': new FormControl(null, [Validators.required, Validators.pattern('^(?!\d+$)(?:[0-9][0-9]*)?$')]),
      'company_logo': new FormControl(null, []),
      'fullday_working_hours': new FormControl(null, []),
      'halfday_working_hours': new FormControl(null, []),
      'weekend_days': new FormControl(null, []),
      'login_url': new FormControl(''),
      'is_custom_login': new FormControl(0),
      'status': new FormControl(1),
      'time_zone_id': new FormControl('', Validators.required),
      'parent_id': new FormControl(''),
      'created_by': new FormControl(''),
      'no_of_days_limit_for_mark_attendance': new FormControl(''),
      'no_of_days_limit_for_leave': new FormControl(''),
      'id': new FormControl('0'),

    }, { updateOn: 'change' });

    this.getTimeZone();
  }

  public onSubmit() {

    //this.company.parent_id = this.parent_id;
    //this.company.created_by = this.myItem.id;
    //this.company.weekend_days = JSON.stringify(this.weekDaysObj);
    this.myForm.get('parent_id').setValue(this.parent_id);
    this.myForm.get('created_by').setValue(this.myItem.id);
    this.myForm.get('weekend_days').setValue(JSON.stringify(this.weekDaysObj));
    // this.company.weekend_days = this.selectedWeekDays.toString();
    let formData: FormData = new FormData();
    formData.append('data', JSON.stringify(this.myForm.value));
    formData.append('logo', this.selectedFile);

    if (this.myForm.get('id').value == 0) {
      this.clientService.add(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.clientService.insertSuccess();
          this.location.back();
        }
        else {
          this.router.navigate(['customer/addcustomer']);
        }
      });
    }
    else {
      this.clientService.update(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.clientService.updateSuccess();
          this.location.back();
        }
        else {
          this.router.navigate(['customer/addcustomer']);
        }
      });
    }
  }

  getData(id: number) {
    this.clientService.getItem(id).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        //this.company = resp.items;
        this.myForm.get('id').setValue(resp.items.id);
        this.myForm.get('company_name').setValue(resp.items.company_name);
        this.myForm.get('company_email').setValue(resp.items.company_email);
        this.myForm.get('company_number').setValue(resp.items.company_number);
        this.myForm.get('company_location').setValue(resp.items.company_location);
        this.myForm.get('company_address').setValue(resp.items.company_address);
        this.myForm.get('fullday_working_hours').setValue(resp.items.fullday_working_hours);
        this.myForm.get('halfday_working_hours').setValue(resp.items.halfday_working_hours);
        this.myForm.get('is_custom_login').setValue(resp.items.is_custom_login);
        this.myForm.get('login_url').setValue(resp.items.login_url);
        this.myForm.get('status').setValue(resp.items.status);
        this.myForm.get('time_zone_id').setValue(resp.items.time_zone_id);
        this.myForm.get('no_of_days_limit_for_mark_attendance').setValue(resp.items.no_of_days_limit_for_mark_attendance);
        this.myForm.get('no_of_days_limit_for_leave').setValue(resp.items.no_of_days_limit_for_leave);
        this.parent_id = resp.items.parent_id;

        if (resp.items.weekend_days != '' && resp.items.weekend_days != null) {
          this.selectedWeekDays = resp.items.weekend_days.split(',');
        }
        this.weekDaysObj = resp.items.weekend_days_obj;
        if (resp.items.company_logo) {
          //this.selectedFileView =logo_url+resp.items.company_logo;
          this.selectedFileView = resp.items.company_logo;

        }
      }
    );
  }


  onFileChange(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
    this.selectedFile = file;
    readBase64(file).then(function (data) {
      //this.selectedFileView = data;   
      $('#selectedFileView').attr('src', data);
    });
  }


  public checkEmail() {

    this.clientService.checkClientEmail(this.company.company_email).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.company.company_email = '';
          this.emailStatus = true;
        }
        else {
          this.emailStatus = false;
        }
      }
    );
  }

  // public updateWeekDays(e, i) {
  //   var index = this.selectedWeekDays.indexOf(e.target.value);
  //   if (e.target.checked) {
  //     if (index < 0) {
  //       this.selectedWeekDays.push(e.target.value);
  //     }
  //   } else {
  //     this.selectedWeekDays.splice(index, 1);
  //   }
  // }

  // public getSelectedWeekday(day) {
  //   if (this.selectedWeekDays.indexOf(day) < 0) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  public updateStatus(obj, event, i) {

    if (event.target.checked) {
      this.weekDaysObj.forEach(element => {

        if (element.value == obj.value) {
          element.isSelected = 1;
          element.weeknumbers.forEach(ele => {
            ele.isSelected = 1;
          });
        }
      });
    } else {
      this.weekDaysObj.forEach(element => {
        if (element.value == obj.value) {
          element.isSelected = 0;
          element.weeknumbers.forEach(ele => {
            ele.isSelected = 0;
          });
        }
      });

    }
  }

  public singleUpdate(obj, event, i, j, mainobj) {
    var chk = true;
    if (event.target.checked) {
      this.weekDaysObj[i].weeknumbers[j].isSelected = 1;
    } else {
      this.weekDaysObj[i].weeknumbers[j].isSelected = 0;
    }

    this.weekDaysObj[i].weeknumbers.forEach(element => {
      if (element.isSelected == 0) { chk = false; }
    });

    if (chk) {
      this.weekDaysObj[i].isSelected = 1;
    } else {
      this.weekDaysObj[i].isSelected = 0;
    }
  }

  public getTimeZone() {
    this.clientService.getTimeZone().subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.time_zone_list = resp.items;
        }
      }
    );
  }

}
