import { Component, OnInit,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';

import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { TaskManagerService } from '../../task-manager/task-manager.service';

import { Router, ActivatedRoute, Params  } from "@angular/router";
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { SidebarService } from '../../shared/sidebar/sidebar.service';
import { formControlBinding } from '@angular/forms/src/directives/reactive_directives/form_control_directive';

@Component({
  selector: 'app-task-type',
  templateUrl: './task-type.component.html',
  styleUrls: ['./task-type.component.scss']
})
export class TaskTypeComponent implements OnInit {

  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};

  constructor(private location: Location,
    private TaskManagerService:TaskManagerService,
    
    private router: Router,
    private datePipe: DatePipe,
    private sidebarService : SidebarService) { }
  myForm: FormGroup;
  public key ='loginData';
  public myItem:any;
  public data:any = [];
  public permission;
  public projectlist: any = [];
  public projectlists:any=[];
  client : any;
  project :any;
  is_new:any;
  no_of_hour:any;
  no_of_minute:any
  public hours:any=["00","01","02","03","04","05","06","07","08","09"];
  public minutes:any=["00","05","10","15","20","25","30","35","40","45","50","55"]; 
  marked = false; 
  theCheckbox = false;
  ngOnInit() {
    this.permission= this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.myForm = new FormGroup({
      'id': new FormControl(0),
      'type_name': new FormControl(null, [Validators.required,Validators.pattern('^(?!\d+$)(?:[a-zA-Z][a-zA-Z ]*)?$')]),
     
     
    }, {updateOn: 'change'});
    
    this.getTypeList();
  
    this.dtOptions = {
      //used id for index 
      order: [[0, "ASC"]],
    };
  }

  toggleVisibility(e){
    this.marked= e.target.checked;
  }

  public addtype()
  {
    let formData: FormData = new FormData();   
    formData.append('type_name',this.myForm.get('type_name').value);
    formData.append('companyId',this.myItem.company_id);
    formData.append('userId',this.myItem.id);

    if(this.myForm.get('id').value == 0){
      this.TaskManagerService.addType(formData).subscribe(resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success) { 
          this.TaskManagerService.insertSuccessType();
          this.getTypeList();
         
          this.myForm.reset();
          this.myForm.get('id').setValue(0);
        }else {
          this.TaskManagerService.alreadyExistsType();
        }    
      });
    } else {
     
      formData.append('id',this.myForm.get('id').value);
      this.TaskManagerService.addType(formData).subscribe(resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success) { 
          this.TaskManagerService.updateSuccessType();
          this.getTypeList();
          this.myForm.reset();
          this.myForm.get('id').setValue(0);      
        }     
      });
    }
  }

  
  public backtotask()
  {
    this.router.navigate(['employee/task-manager']);
  }

  public getTypeList()
  {
    
    let myDate = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.TaskManagerService.getTaskType(this.myItem.company_id).subscribe((resp:any)=>{
      if(resp.status == 401)
      {
          this.router.navigate([this.myItem.url]);
      }
      if(resp['success']) { 
        this.data = resp.data;
        console.log(this.data);
        this.rerender();
      }     
    });
  }

  backClicked() {
    this.location.back();
   } 


   ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;    
      });
    });
  }
  
  public getEdit(val)
  {
    this.myForm.get('id').setValue(val.id);
    this.myForm.get('type_name').setValue(val.typeName);
    
    //this.myForm.get('short_name').setValue(val.short_name);
  }

  public Delete(id: number, i: number)
  {
    if(window.confirm('Are you sure you want to delete?')) {
      this.TaskManagerService.deleteType(id).subscribe(resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success){
          this.TaskManagerService.deleteSuccessType();
         this.data.splice(i, 1);  
         this.getTypeList();
         this.rerender();
        }
      });
    }
  }

}
