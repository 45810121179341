import { Component } from '@angular/core';
import * as Chartist from 'chartist';
import { ChartType, ChartEvent } from "ng-chartist";
import { AdminService } from "../admin.service";
 import { Router} from "@angular/router";
 import {AuthService} from '../../shared/auth/auth.service';
 import { Location } from '@angular/common';

declare var require: any;

const data: any = require('../../shared/data/chartist.json');

export interface Chart {
    type: ChartType;
    data: Chartist.IChartistData;
    options?: any;
    responsiveOptions?: any;
    events?: ChartEvent;
}

@Component({
    selector: 'app-dashboard1',
    templateUrl: './dashboard1.component.html',
    styleUrls: ['./dashboard1.component.scss']
})
 

 
export class Dashboard1Component {

    public totalClient;
    public totalEmployee;
    public totalDepartment;
    public key ='loginData';
    public myItem:any;
    public parentId;
    public client= [];
    private sub:any = null;

    constructor(private adminService : AdminService,
        private router: Router,
        private authService:AuthService,
        private location: Location) {
            //this.authService.isAuthenticated();
        }
    
    ngOnInit() {
        this.myItem = JSON.parse(localStorage.getItem(this.key));
        this.parentId = this.myItem.id;
        this.getTotalCount();
    }

    public getTotalCount()
    {
        this.adminService.getTotalCount(this.parentId).subscribe(resp=>{
            if(resp.status == 401)
            {
                this.router.navigate([this.myItem.url]);
            }
           this.totalClient = resp.totalClients;
           this.totalEmployee = resp.totalEmployees;
           this.totalDepartment= resp.totalDepartment;

      });
    }

    public openClient()
    {
        this.router.navigate(['admin/client']);
    }
    public openDepartment()
    {
        this.router.navigate(['admin/departments']);
    }
 
    
}
