import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerComponent } from "./customer.component";
 import { AddCustomerComponent } from "./add-customer/add-customer.component";
import { CustomerProfileComponent } from "./customer-profile/customer-profile.component";
import { EmployeeComponent } from "../customer/employee/employee.component";
import { AddEmployeeComponent } from "../customer/employee/add-employee/add-employee.component";
import { EmployeeProfileComponent } from "../customer/employee/employee-profile/employee-profile.component";
import { BulkEmployeeComponent } from "../customer/employee/bulk-employee/bulk-employee.component";





const routes: Routes = [
  {
    path: '',  
    
    children: [   
      {
        path: 'customer',
        component: CustomerComponent,
        data: {
          title: 'customer'
        }
      },
      {
        path: 'addcustomer',
        component: AddCustomerComponent,
        data: {
          title: 'Addcustomer'
        }
     },
      {
        path: 'addcustomer/:id',
        component: AddCustomerComponent,
        data: {
          title: 'EditCustomer'
        },
      },
        {
          path: 'customerProfile/:id',
          component: CustomerProfileComponent,
          data: {
            title: 'customerProfile'
          }
        },
     
    //  for subclient
      {
        path: 'customer/:id',
        component: CustomerComponent,
        data: {
          title: 'customer'
        }
       },

       {
        path: 'employee/:id',
        component: EmployeeComponent,
        data: {
          title: 'employee list'
        }
      },

      {
        path: 'customer_employee/addemployee',
        component: AddEmployeeComponent,
        data: {
          title: 'employee add'
        }
      },

      {
        path: 'customer_employee/addemployee/:id',
        component: AddEmployeeComponent,
        data: {
          title: 'employee edit'
        }
      },
      {
        path: 'customer_employee/employeeProfile/:id',
        component: EmployeeProfileComponent,
        data: {
          title: 'employee profile'
        }
      },
      {
        path: 'customer_employee/bulk-employee/:id',
        component: BulkEmployeeComponent,
        data: {
          title: 'employee'
        }
      },  
      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerRoutingModule { }

export const CustomerComponentList = [
  CustomerComponent,
  AddCustomerComponent,

  // new
  EmployeeComponent,
  AddEmployeeComponent,
  EmployeeProfileComponent,
  BulkEmployeeComponent
 
];

