import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DepartmentsComponent } from "../departments/departments.component";
import { AddDeparmentsComponent } from "../departments/add-deparments/add-deparments.component";



const routes: Routes = [
  {
    path: '',  
    
    children: [   
      {
        path: 'departments',
        component: DepartmentsComponent,
        data: {
          title: 'employee'
        }
      },
      {
        path: 'departments/:id',
        component: DepartmentsComponent,
        data: {
          title: 'employee'
        }
      },
      {
        path: 'adddepartments',
        component: AddDeparmentsComponent,
        data: {
          title: 'AddDepartments'
        }
      },
      {
        path: 'adddepartments/:id',
        component: AddDeparmentsComponent,
        data: {
          title: 'AddDepartments'
        }
      },
       
     
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DepartmentsRoutingModule { }

export const DepartmentsComponentList = [
  DepartmentsComponent,
  AddDeparmentsComponent
];

