export class PayrollRelationsModel {

    
    payrolls_id :number;
    company_id : number;
   
   
    
  

    constructor(params:any) {
        const model = params || {};
        this.payrolls_id =model.payrolls_id || '';
        this.company_id =model.company_id || '';
       
    }
}
