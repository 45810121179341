import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AttendanceComponent } from './attendance.component';
import { AddAttendaceComponent } from "./add-attendace/add-attendace.component";
import { EmployeeAttendanceListComponent } from "../attendance/employee-attendance-list/employee-attendance-list.component";
import { ClientAttendanceListComponent } from "../attendance/client-attendance-list/client-attendance-list.component";
import { ManageAttendanceComponent } from './manage-attendance/manage-attendance.component';


const routes: Routes = [
  {
    path: '',  
    
    children: [   
      //   {
      //   path: 'employeelist',
      //   component: EmployeeAttendanceListComponent,
      //   data: {
      //     title: 'employeelist'
      //   }
      // },
      {
        path: 'attendance',
        component: EmployeeAttendanceListComponent,
        data: {
          title: 'employeelist'
        }
      },
      {
        path: 'attendance/:id',
        component: EmployeeAttendanceListComponent,
        data: {
          title: 'employeelist'
        }
      },
      // {
      //   path: 'employeelist/:id',
      //   component: EmployeeAttendanceListComponent,
      //   data: {
      //     title: 'employeelist'
      //   }
      // },
      {
        path: 'attendancelist/:id',
        component: AttendanceComponent,
        data: {
          title: 'attendance'
        }
      },
      {
        path: 'attendancelist',
        component: AttendanceComponent,
        data: {
          title: 'attendance'
        }
      },
      // {
      //   path: 'addattendace',
      //   component: AddAttendaceComponent,
      //   data: {
      //     title: 'addattendace'
      //   }
      // },
      {
        path: 'manage-attendance',
        component: ManageAttendanceComponent,
        data: {
          title: 'manage attendance'
        }
      },
      

      {
        path: 'attendance-clientlist',
        component: ClientAttendanceListComponent,
        data: {
          title: 'clientlist'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AttendanceRoutingModule { }

export const AttendanceComponentList = [
  AttendanceComponent,
  AddAttendaceComponent,
  EmployeeAttendanceListComponent,
  ClientAttendanceListComponent,
  ManageAttendanceComponent
];

