import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocumentComponent } from "../document/document.component";
import { ClientListComponent } from './client-list/client-list.component';


const routes: Routes = [
  {
    path: '',  
    
    children: [  
      {
        path: 'document',
        component: DocumentComponent,
        data: {
          title: 'document'
        }
      },
 
      {
        path: 'clientlist',
        component: ClientListComponent,
        data: {
          title: 'document'
        }
      },
      {
        path: 'document/:id',
        component: DocumentComponent,
        data: {
          title: 'document'
        }
      },


     
      // {
      //   path: 'add-document-category/:id',
      //   component: AddDocumentCategoryComponent,
      //   data: {
      //     title: 'AddDocumentCategory'
      //   }
      // },
       
     
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DocumentRoutingModule { }

export const DocumentList = [
  ClientListComponent,
  DocumentComponent,
  
 
];

