import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BranchComponent } from "./branch.component";
import { AddBranchComponent } from './add-branch/add-branch.component';



const routes: Routes = [
  {
    path: '',

    children: [
      {
        path: 'branch',
        component: BranchComponent,
        data: {
          title: 'branch'
        }
      },
      {
        path: 'branch/:id',
        component: BranchComponent,
        data: {
          title: 'branch'
        }
      },
      {
        path: 'add-branch',
        component: AddBranchComponent,
        data: {
          title: 'branch'
        }
      },
      {
        path: 'add-branch/:id',
        component: AddBranchComponent,
        data: {
          title: 'branch'
        }
      },


    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BranchRoutingModule { }

export const BranchComponentList = [
  BranchComponent,
  AddBranchComponent
];

