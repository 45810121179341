import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ChartistModule } from 'ng-chartist';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchHeightModule } from "../../shared/directives/match-height.directive";

import {TaskViewList, TaskViewRoutingModule } from "./task-view-routing.module";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';
// import { CalendarModule } from '../../../../node_modules/angular-calendar';
// import { adapterFactory } from '../../../../node_modules/angular-calendar/node_modules/date-fns;


@NgModule({
  imports: [
    CommonModule,
    TaskViewRoutingModule,
    ChartistModule,
    NgbModule,
    MatchHeightModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    NgxDatatableModule,
    DataTablesModule,
    
  ],
  declarations: [
    TaskViewList,
],  
})
export class TaskViewModule { }


/*
* calendar, on click of calender show own task
* on click of emp shown emp task
*/