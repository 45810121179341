import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { DocumentCategoryModel } from "../document-category.model";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { DocumentCategoryService } from "../document-category.service";
import { Location } from '@angular/common';
import { DepartmentsService } from '../../departments/departments.service';
import { DesignationService } from '../../designation/designation.service';
import { BranchService } from '../../branch/branch.service';
import { element } from '@angular/core/src/render3/instructions';

@Component({
  selector: 'app-add-document-category',
  templateUrl: './add-document-category.component.html',
  styleUrls: ['./add-document-category.component.scss']
})
export class AddDocumentCategoryComponent implements OnInit {
  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;
  myForm: FormGroup;
  documentCategory: DocumentCategoryModel = new DocumentCategoryModel({});

  public editStatus = true;
  public key = 'loginData';
  public myItem: any;
  public departments: any = [];
  public designation: any = [];
  public branches: any = [];
  public selectedDepartments: any = [];
  public selectedDesignations: any = [];
  public selectedBranches: any = [];
  public designations: any = [];
  public document_category: any = [];
  public company_id;
  public designationAllSelected = false;
  public departmentAllSelected = false;
  public branchAllSelected = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private documentCategoryService: DocumentCategoryService,
    private departmentsService: DepartmentsService,
    private designationService:DesignationService,
    private branchService:BranchService) { }

  ngOnInit() {
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.company_id = this.myItem.company_id;
    
    this.getDocumentCategory();

    if(this.myItem.role_id !=1){
      this.getDepatment();
      this.getDesignation();
      this.getBranch();
    }else {
      this.designationAllSelected = true;
      this.departmentAllSelected = true;
      this.branchAllSelected = true;
    }
    

    this.myForm = new FormGroup({
      'parent_id': new FormControl(0),
      'category_name': new FormControl('', [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 -@&$%^&*!#]*)?$')]),
      'category_type': new FormControl('', [Validators.required]),
      'access_level': new FormControl('', [Validators.required]),
      'status': new FormControl(1),
      'locations': new FormControl(''),
      'designations': new FormControl(''),
      'id': new FormControl(0),

    }, { updateOn: 'change' });

    this.route.params.subscribe(
      (params: Params) => {
        if (params.id) {
          this.editStatus = true;
          this.getData(params.id);
        } else {
          this.editStatus = false;
        }
      }
    );   
  }

  public onSubmit() {

    let formData: FormData = new FormData();
    formData.append('category_name', this.myForm.get('category_name').value);
    formData.append('category_type',this.myForm.get('category_type').value);

    if(this.myForm.get('category_type').value ==1){
      formData.append('parent_id','0');
    } else {
      formData.append('parent_id',this.myForm.get('parent_id').value);
    }

    formData.append('department',this.selectedDepartments.toString());
    formData.append('created_by', this.myItem.id);
    formData.append('company_id', this.myItem.company_id);
    formData.append('status',this.myForm.get('status').value);
    formData.append('locations',this.selectedBranches.toString());
    formData.append('access_level',this.myForm.get('access_level').value);
    formData.append('designations',this.selectedDesignations.toString());

    if (this.myForm.get('id').value == 0) {
      this.documentCategoryService.add(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.documentCategoryService.message(resp.message);
          this.location.back();
        }
        else {
          this.documentCategoryService.error(resp.message);
        }
      });
    }
    else {
      formData.append('id',this.myForm.get('id').value);
      this.documentCategoryService.update(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.documentCategoryService.message(resp.message);
          this.location.back();
        }
        else {
          this.router.navigate(['document-category/add-document-category']);
        }
      });
    }
  }

  backClicked() {
    this.location.back();
  }

  public getData(id: number) {
    this.documentCategoryService.getItem(id).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.myForm.get('id').setValue(resp.items.id);
        this.myForm.get('category_name').setValue(resp.items.category_name);
        this.myForm.get('category_type').setValue(resp.items.category_type);
        this.myForm.get('parent_id').setValue(resp.items.parent_id);
        this.myForm.get('access_level').setValue(resp.items.access_level);
        this.myForm.get('status').setValue(resp.items.status);

        if (resp.items.department != '' && resp.items.department !=null) {
          this.selectedDepartments = resp.items.department.split(',');

          if(resp.items.department == 0){
            this.departments.filter(element=>{
              element.isSelected = 1;
            });
            this.departmentAllSelected = true;
          } else {
             this.departments.filter(element=>{
              var index = this.selectedDepartments.indexOf(element.id.toString());
              if (index > -1) {
                element.isSelected = 1;
              } else {
                element.isSelected = 0;
              }
            });
            this.departmentAllSelected = false;
          }
        } else {
          this.departments.filter(element=>{
            element.isSelected = 0;
          });
        }

        if (resp.items.designations != '' && resp.items.designations !=null) {
          this.selectedDesignations = resp.items.designations.split(',');
          
          if(resp.items.designations == 0){
            this.designation.filter(element=>{
              element.isSelected = 1;
            });
            this.designationAllSelected = true;
          } else {
             this.designation.filter(element=>{
              var index = this.selectedDesignations.indexOf(element.id.toString());
              if (index > -1) {
                element.isSelected = 1;
              } else {
                element.isSelected = 0;
              }
            });
            this.designationAllSelected = false;
          }
        } else {
          this.designation.filter(element=>{
            element.isSelected = 0;
          });
        }

        if (resp.items.locations != '' && resp.items.locations != null) {
          this.selectedBranches = resp.items.locations.split(',');

          if(resp.items.locations == 0){
            this.branches.filter(element=>{
              element.isSelected = 1;
            });
            this.branchAllSelected = true;
          } else {
             this.branches.filter(element=>{
              var index = this.selectedBranches.indexOf(element.id.toString());
              if (index > -1) {
                element.isSelected = 1;
              } else {
                element.isSelected = 0;
              }
            });
            this.branchAllSelected = false;
          }
        }else {
          this.branches.filter(element=>{
            element.isSelected = 0;
          });
        }
      }
    );   
  }

  public getDocumentCategory()
  {  
     let formData: FormData = new FormData();
     formData.append('role_id',this.myItem.role_id);
     formData.append('company_id',this.myItem.company_id);
     formData.append('flag','get');
     this.documentCategoryService.getCategory(formData).subscribe(resp=>{
       if(resp.status == 401)
       {
           this.router.navigate([this.myItem.url]);
       }
       this.document_category=resp.items;
       this.document_category = this.document_category.filter(
        element => element.parent_id === 0);
    });
   
 }
  public getDepatment() {
    this.departmentsService.getDepartment(this.company_id).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      this.departments = resp.items;

      if(!this.editStatus){
        this.departments.filter(element=>{
          element.isSelected = 1;
        });
        this.selectedDepartments = 0;
        this.departmentAllSelected = true;
      }

    });
  }

  public updateDepartments(e, i) {

    if(e.target.checked){
      this.departments.filter(element=>
      {
        if(element.id == e.target.value){
          element.isSelected = 1;
          this.selectedDepartments.push(e.target.value);
        }
      });
    } else {
      this.departments.filter(element=>
      {
        if(element.id == e.target.value){
          element.isSelected = 0;
          if(this.selectedDepartments.length > 0){
            var index = this.selectedDepartments.indexOf(e.target.value);
            if (index < 0) {
              this.selectedDepartments.splice(index, 1);
            }
          }
        }
      });
    }

    this.departmentAllSelected = true;
    this.selectedDepartments =[];
    this.departments.forEach(element => {
      if(element.isSelected == 0){
        this.departmentAllSelected = false;
      } else {
        this.selectedDepartments.push(element.id);
      }
    });

    if(this.departmentAllSelected == true){
      this.selectedDepartments = 0;
    }
    
  }

  public getDesignation() {    
    this.designationService.getDesignation(this.company_id).subscribe(
      resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
          this.designation = resp.items;
          if(!this.editStatus){
            this.designation.filter(element=>{
              element.isSelected = 1;
            });
            this.selectedDesignations = 0;
            this.designationAllSelected = true;
          }
        });     
  }

  public updateDesignations(e, i) {

    if(e.target.checked){
    this.designation.filter(element=>
      {
        if(element.id == e.target.value){
          element.isSelected = 1;
          this.selectedDesignations.push(e.target.value);
        }
      });
    } else {
      this.designation.filter(element=>
      {
        if(element.id == e.target.value){
          element.isSelected = 0;
          if(this.selectedDesignations.length > 0){
            var index = this.selectedDesignations.indexOf(e.target.value);
            if (index < 0) {
              this.selectedDesignations.splice(index, 1);
            }
          }
        }
      });
    }

    this.designationAllSelected = true;
    this.selectedDesignations =[];
    this.designation.forEach(element => {
      if(element.isSelected == 0){
        this.designationAllSelected = false;
      } else {
        this.selectedDesignations.push(element.id);
      }
    });

    if(this.designationAllSelected == true){
      this.selectedDesignations = 0;
    }
  
  }

  public getBranch()
  { 
     this.branchService.get_data(this.company_id).subscribe(resp=>{
      if(resp.status == 401)
      {
        this.router.navigate([this.myItem.url]);
      }
      if(resp.success){
        this.branches=resp.items.branches;
        if(!this.editStatus){
          this.branches.filter(element=>{
            element.isSelected = 1;
          });
          this.selectedBranches = 0;
          this.branchAllSelected = true;
        }
      }
    });
  }

  public updateBranches(e)
  {
    if(e.target.checked){
      this.branches.filter(element=>
        {
          if(element.id == e.target.value){
            element.isSelected = 1;
            this.selectedBranches.push(e.target.value);
          }
        });
    } else {
      this.branches.filter(element=>
      {
        if(element.id == e.target.value){
          element.isSelected = 0;
          if(this.selectedBranches.length > 0){
            var index = this.selectedBranches.indexOf(e.target.value);
            if (index < 0) {
              this.selectedBranches.splice(index, 1);
            }
          }
        }
      });
    }

    this.branchAllSelected = true;
    this.selectedBranches =[];
    this.branches.forEach(element => {
      if(element.isSelected == 0){
        this.branchAllSelected = false;
      } else {
        this.selectedBranches.push(element.id);
      }
    });

    if(this.branchAllSelected == true){
      this.selectedBranches = 0;
    }
  }

  public updateAll(e,flag)
  {
    if(flag =='designation'){
      if(e.target.checked){
        this.designation.filter(element=>{
          element.isSelected = 1;
        });
        this.selectedDesignations = 0;
        this.designationAllSelected = true;
      } else {
         this.designation.filter(element=>{
          element.isSelected = 0;
        });
        this.selectedDesignations = [];
        this.designationAllSelected = false;
      }
    }

    if(flag =='department'){
      if(e.target.checked){
        this.departments.filter(element=>{
          element.isSelected = 1;
        });
        this.selectedDepartments = 0;
         this.departmentAllSelected = true;
      } else {
         this.departments.filter(element=>{
          element.isSelected = 0;
        });
        this.selectedDepartments = [];
        this.departmentAllSelected = false;
      }
    }

    if(flag =='branch'){
      if(e.target.checked){
        this.branches.filter(element=>{
          element.isSelected = 1;
        });
        this.selectedBranches = 0;
         this.branchAllSelected = true;
      } else {
         this.branches.filter(element=>{
          element.isSelected = 0;
        });
        this.selectedBranches = [];
        this.branchAllSelected = false;
      }
    }
  }

}
