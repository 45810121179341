export class ModulePermissionModel {

    
    companyId :number;
    moduleId : number;
    permission : number;
    created_by : number;
   
    
  

    constructor(params:any) {
        const model = params || {};
        this.companyId =model.companyId || '';
        this.moduleId =model.moduleId || '';
        this.permission =model.permission || '0';
        this.created_by =model.created_by || '';
    }
}
