import { Component, OnInit } from '@angular/core';
import { ROUTES } from './employee-sidebar-routes.config';
import { RouteInfo } from "./employee-sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
    selector: 'app-employee-sidebar',
    templateUrl: './employee-sidebar.component.html',
})

export class EmployeeSidebarComponent implements OnInit {
    public menuItems: any[];
    public key ='loginData';
    public myItem:any;
    public role_id ; 

    constructor(private router: Router,
        private route: ActivatedRoute, public translate: TranslateService) {
        
    }

    ngOnInit() {
        $.getScript('./assets/js/app-sidebar.js');
        this.menuItems = ROUTES.filter(menuItem => menuItem);
       // console.log(this.menuItems);
     

        this.myItem = JSON.parse(localStorage.getItem(this.key));
     //   console.log(this.myItem);
        this.role_id = this.myItem.role_id;
    }

    //NGX Wizard - skip url change
    ngxWizardFunction(path: string) {
        if (path.indexOf('forms/ngx') !== -1)
            this.router.navigate(['forms/ngx/wizard'], { skipLocationChange: false });
    }
}
