import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { EmployeeService } from "../../employee/employee.service";
import { Router, ActivatedRoute, Params  } from "@angular/router";


@Component({
  selector: 'app-generalInformation',
  templateUrl: './generalInformation.component.html',
  styleUrls: ['./generalInformation.component.scss']
})
export class GeneralInformationComponent implements OnInit {

  public company_id;
  public key ='loginData';
  public myItem:any;
  public department;
  public designation;


  constructor(private employeeService : EmployeeService,
    private router: Router,
    private route: ActivatedRoute,) { }

  options = {
    direction: 'ltr'
  };

  @Output() directionEvent = new EventEmitter<Object>();

  ngOnInit() {
    // Customizer JS File
    $.getScript('./assets/js/customizer.js');


    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.route.params.subscribe(
      (params: Params) => {

        if(params.id)
        {
          this.company_id = params.id;
        }
        else
        {
          this.company_id = this.myItem.company_id
        }
      }
    );

  
      this.getDepartment(this.company_id);
      this.getDeignation(this.company_id);
  
  
  }


  getDepartment(company_id)
  {
    
   this.employeeService.getDepartment(company_id).subscribe(
     resp=>{
       this.department = resp.items;
     });
  }

  getDeignation(company_id)
  {     
   this.employeeService.getDesignation(company_id).subscribe(
     resp=>{
       this.designation = resp.items;
       
     });
  }
  sendOptions() {
    this.directionEvent.emit(this.options);
  }

}
