import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import { App_Url } from '../../globals';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class VehicleListService {

  constructor(private http: HttpClient, public toastr: ToastrService) { }
  public key = 'loginData';
  public myItem: any;

  public getVehicleList(company_id): Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('api_token', this.getApiToken());
    formData.append('company_id', company_id);
    return this.http.post(App_Url + `api/get_vehicle_details`, formData);
  }

  public add(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/vehicle_details_insert`, formData);
  }

  public update(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/vehicle_details_update`, formData);
  }

  public getData(id): Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id',id);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/vehicle_details_edit`,formData);
  }

  public deleteVehicleDetails(id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id',id);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/vehicle_details_delete`,formData);
  }

  insertSuccess() {
    this.toastr.success('Vehicle details has been inserted successfully!', 'Success!');
  }

  updateSuccess() {
    this.toastr.success('Vehicle details has been updated successfully!', 'Success!');
  }

  public uploadVehicleList(file): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('api_token', this.getApiToken());
    formData.append('userFile', file, file);
    return this.http.post(App_Url + `api/upload_vehicle_bulk_list`, formData);
  }

  public insertBulkVehicleList(data, company_id, createdBy): Observable<any> {

    let formData: FormData = new FormData();
    formData.append('api_token', this.getApiToken());
    formData.append('data', JSON.stringify(data));
    formData.append('company_id', company_id);
    formData.append('created_by', createdBy);
    return this.http.post(App_Url + `api/insert_bulk_vehicle_details`, formData);
  }

  successMessageOfFileUpload() {
    this.toastr.success('Vehicle details has been inserted successfully!', 'Success!');
  }
  vehicleDetailsFileUploadError() {
    this.toastr.error('', 'Error in inserting vehicle details.!', { "timeOut": 2000 });
  }


  message(message) {
    this.toastr.success(message, 'Success!');
  }

  error(message) {
    this.toastr.error('', message, { "timeOut": 2000 });
  }

  deleteSuccess() {
    this.toastr.success('Category has been deleted successfully', 'Success!');
  }

  getApiToken() {
    //var api_token ='59240171-6f5c-42e3-8d87-0044ac120184';
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    var api_token = this.myItem.tokenKey;
    return api_token;
  }

}
