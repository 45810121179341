import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params  } from "@angular/router";
import { MeetingService } from "./meeting.service";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { SidebarService } from '../shared/sidebar/sidebar.service';


@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.scss']
})
export class MeetingComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  public key ='loginData';
  public myItem:any;
  public data:  any[] = [];
  public closeResult:string ;
  public permission;


  constructor(private router:Router,
  private meetingService : MeetingService,
  private location: Location,
  private modalService: NgbModal,
  private sidebarService : SidebarService,
) { }

  ngOnInit() {

    
    this.permission= this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    if(this.myItem.role_id !=5)
    {
      this.getMeeting(this.myItem.company_id);
    }
    
    if(this.myItem.role_id==5)
    {
      this.getData(this.myItem.id);
    }
    
    //this.rerender();
    this.dtOptions = {
      //used id for index 
      order: [[0, "DESC"]],
    };
   }

 
  public getData(id : number)
   {
    
     this.meetingService.getdata(id).subscribe(
      resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        this.data = resp.items;
        this.rerender();
      }
    );
   }

   public getMeeting(id : number)
   {
    
     this.meetingService.getMeeting(id).subscribe(
      resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        this.data = resp.items;
        this.rerender();
      }
    );
   }
  

  public addMeeting()
  {
    this.router.navigate(['meeting/addmeeting']);
  }

  public getEdit(id)
  {
    this.router.navigate(['meeting/addmeeting',id]);
  }

  // public Delete(id: number, i: number)
  // {
  //   if(window.confirm('Are you sure you want to delete?')) {
  //     this.meetingService.delete(id).subscribe(resp=>{
  //       if(resp.success){
  //         //this.clientService.deleteSuccess();
  //        this.data.splice(i, 1);  
  //        this.get_data(this.parent_id);
  //        this.rerender();
  //       }
  //     });
  //   }
  // }
  
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        // For checked fields
        $('input[type=checkbox]', this.footer()).on('checked change', function () {
          if (this['value'] === 'true') {
            that.search(this['value']).draw();
          } else {
            that.search('').draw();
          }
        });

        $('input[type=text]', this.footer()).on('keyup change', function () {
        //  console.log("text : " + this['value']);
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
 
   //for modals
   open(content) {
    this.modalService.open(content).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
       // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
}

 
public backClicked()
{
  this.location.back();
}   


}
