import { Component, OnInit, EventEmitter } from '@angular/core';
import { FileSelectDirective, FileDropDirective, FileUploader, FileLikeObject } from 'ng2-file-upload';
import { VehicleListService } from '../vehicle-list.service';
import { SidebarService } from '../../../shared/sidebar/sidebar.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';


function readBase64(file): Promise<any> {
  var reader = new FileReader();
  var future = new Promise((resolve, reject) => {
    reader.addEventListener("load", function () {
      resolve(reader.result);
    }, false);
    reader.addEventListener("error", function (event) {
      reject(event);
    },
      false);
    reader.readAsDataURL(file);
  });
  return future;
};

const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

@Component({
  selector: 'app-import-bulk-vehicle-details',
  templateUrl: './import-bulk-vehicle-details.component.html',
  styleUrls: ['./import-bulk-vehicle-details.component.scss']
})
export class ImportBulkVehicleDetailsComponent implements OnInit {

  constructor(
    private vehicleListService: VehicleListService,
    private sidebarService: SidebarService,
    private router: Router,
    private location: Location,
  ) { }

  fileForm: FormGroup;
  public selectedFile: File = null;
  public selectedFileView: any = 'assets/img/drag-drop-img.png';
  public uploader: FileUploader = new FileUploader({ url: URL, disableMultipart: true });
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;
  public goodEntry: any = [];
  public badEntry: any = [];
  public key = 'loginData';
  public key1 = 'permission';
  public myItem: any;
  public permission;
  public loading = false;
  public customLoadingTemplate;


  ngOnInit() {
    this.permission = this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));

    this.fileForm = new FormGroup({
      'fileInput': new FormControl(null, [Validators.required])
    }, { updateOn: 'change' });
  }

  onFileChange(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  public onFileSelected(event: EventEmitter<File[]>) {
    this.selectedFile = null;

    this.selectedFileView = 'assets/img/drag-drop-img.png';
    const file: File = event[0];
    var ext = this.getFileExtension(file.name);
    if (ext == 'csv' || ext == 'text') {
      this.selectedFile = file;
      this.selectedFileView = 'assets/img/csv.png';
    }
  }

  public getFileExtension(filename) {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
  }

  public uploadCsvFile() {

    this.vehicleListService.uploadVehicleList(this.selectedFile).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        if (resp.items.goodEntries.length > 0) {
          this.goodEntry = resp.items.goodEntries;

        }
        else {
          this.goodEntry = [];
        }

        if (resp.items.badEntries.length > 0)
          this.badEntry = resp.items.badEntries;
        else {
          this.badEntry = [];
        }

        this.selectedFile = null;
        this.selectedFileView = 'assets/img/drag-drop-img.png';

      } else {

        // console.log(this.badEmployee);
      }
    });

  }

  public clearCsvFile() {
    this.selectedFile = null;
    this.selectedFileView = 'assets/img/drag-drop-img.png';
    this.fileForm.reset();
    this.goodEntry = [];
    this.badEntry = [];
  }

  public saveData() {
    this.loading = true;
    this.vehicleListService.insertBulkVehicleList(this.goodEntry, this.myItem.company_id, this.myItem.id).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.loading = false;
        this.vehicleListService.successMessageOfFileUpload();
        this.selectedFile = null;
        this.selectedFileView = 'assets/img/drag-drop-img.png';
        this.goodEntry = [];
        this.badEntry = [];

      } else {
        this.loading = false;
        this.vehicleListService.vehicleDetailsFileUploadError();
      }
    });
  }

  public backClicked() {
    this.location.back();
  }

}
