import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { App_Url } from '../globals';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})


export class ManageHolidaysService {
  
  constructor(private http: HttpClient,public toastr: ToastrService) { }
   public key ='loginData';
   public myItem:any;

  public add(formData:any):Observable<any>
  { 
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/holiday-add`,formData);
  }

  public update(formData:any):Observable<any>
  { 
   formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/holiday-update`,formData);
  }
  public getHolidays(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/holiday-get`,formData);

  }

  public delete(id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/holiday-delete`,formData);
  }

  public getBranches(companyId):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('companyId',companyId);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/branch-get`,formData);
  }

  insertSuccess() {
    this.toastr.success('Holiday has been inserted successfully', 'Success!');
  } 

  updateSuccess() {
    this.toastr.success('Holiday has been updated successfully', 'Success!');
  }
  deleteSuccess() {
    this.toastr.success('Holiday has been deleted successfully', 'Success!');
  }
  alreadyExists(){
    this.toastr.error('', 'Holiday name already exists', { "timeOut": 1000 });
  }

  getApiToken(){
   this.myItem = JSON.parse(localStorage.getItem(this.key));
   var api_token = this.myItem.tokenKey;
   return api_token ;
  }
}
