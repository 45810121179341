import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params  } from "@angular/router";
import { EmployeeModel } from '../employee.model';
import { EmployeeService } from "../employee.service";
import { THIS_EXPR } from '../../../../node_modules/@angular/compiler/src/output/output_ast';
import { Location } from '@angular/common';
import * as $ from 'jquery';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'app-employee-profile',
  templateUrl: './employee-profile.component.html',
  styleUrls: ['./employee-profile.component.scss']
})
export class EmployeeProfileComponent implements OnInit {
  public selectedFile :File=null;
  public key ='loginData';
  public key1 ='permission';
  public myItem:any;
  public modulePermission:any;
  public attendancePermission: any;
  public documentPermission: any;
  public salarySlipPermission: any;

   //qe code
  qrcodeForm: FormGroup;
  public qrcode;
  public status = false;
  public secrateCode: any;
  
  employee:EmployeeModel = new EmployeeModel({});

  constructor(private employeeService : EmployeeService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location) { }

  currentPage: string = "About"

  ngOnInit() {
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.modulePermission = JSON.parse(localStorage.getItem(this.key1));   
    $.getScript('./assets/js/vertical-timeline.js');

      this.route.params.subscribe(
        (params: Params) => {
          if (params.id) {
            this.getData(params.id);
          }
        });

      this.qrcodeForm = new FormGroup({
        'token_code': new FormControl(null, [Validators.required]),
        'status': new FormControl(''),
      }, { updateOn: 'change' });
  }

  getData(id : number)
  {
  this.employeeService.getItem(id).subscribe(
    resp=>{
      if(resp.status == 401)
      {
          this.router.navigate([this.myItem.url]);
      }
      this.employee = resp.items;
      this.getModulePermission();
    }
  );
  }
    
    showPage(page: string) {
      this.currentPage = page;
    }

    public onFileChange(fileInput :any)
    {
      this.selectedFile= fileInput.target.files[0];
    }

    public onSubmit()
    {
      
      let formData: FormData = new FormData();
      formData.append('id' ,JSON.stringify(this.employee.id));
      formData.append('profile' ,this.selectedFile);
      if(this.selectedFile)
      {
        this.employeeService.uploadProfile(formData).subscribe(
          resp=>{
                if(resp.status == 401)
                {
                    this.router.navigate([this.myItem.url]);
                }
                if(resp.success)
                {
                  this.employeeService.fileUplodingSuccess();
                  this.myItem.profileImage = resp.items.profileImage;
                  localStorage.setItem(this.key, JSON.stringify(this.myItem));
                  this.employee.profileImage =  this.myItem.profileImage;
                  //this.nb.changeProfile();
                // window.location.reload();
                $('.profileImage').attr('src', this.myItem.profileImage);
                }
        });
      }else
      {
        this.employeeService.selectFile();
      }
    }
 
    // public getEdit(id:number)
    // {
    //   this.router.navigate(['employee/addemployee',{id:id,flag: this.flag}]);
    // }

    public viewAttendance(id)
    {
     this.router.navigate(['attendance/attendance',id]);
    }

    public viewDocument(val)
    {
     this.router.navigate(['employee/document',{'userId':val.id,'companyId':val.company_id}]);
    }

    public viewSalaryslip(id)
    {
     this.router.navigate(['salaryslip-template/salaryslip-list',id]);
    }

    public backClicked()
    {
      this.location.back();
    }   


    public getModulePermission()
    {
      this.modulePermission.forEach(element => {
        // 11 Stand for Attendance Module Id
        if(element.moduleId==11)
        {
          this.attendancePermission = element.permissionLevel;
        }
  
        // 10 Stand for Document Module Id
        if(element.moduleId==10)
        {
          this.documentPermission = element.permissionLevel;
        }
         // 16 Stand for Salary slip Module Id
        if(element.moduleId==16)
        {
          this.salarySlipPermission = element.permissionLevel;
        }
      });
    }

    public qrCodeStatus(e)
  {
    let formData: FormData = new FormData();
    formData.append('user_id' ,JSON.stringify(this.employee.id));
    formData.append('employeeCode' ,JSON.stringify(this.employee.employeeCode));
    formData.append('email' ,JSON.stringify(this.employee.email));

    if(this.qrcodeForm.get('status').value == true){
      this.employeeService.getQrCode(formData).subscribe(
        resp=>{
          if(resp.status == 401)
          {
            this.router.navigate([this.myItem.url]);
          }
          if(resp.success)
          {
            this.qrcode = resp.items.url;
            this.status = true;
            this.secrateCode = resp.items.code;
          }
        });            
    } else {
      this.status = false;
    }
  }

  public onQrCodeSubmit()
  {
    let formData: FormData = new FormData();
    formData.append('user_id' ,JSON.stringify(this.employee.id));
    formData.append('employeeCode' ,JSON.stringify(this.employee.employeeCode));
    formData.append('email' ,JSON.stringify(this.employee.email));
    formData.append('code' ,this.qrcodeForm.get('token_code').value);
    formData.append('secrateCode' ,this.secrateCode);

    if(this.qrcodeForm.get('status').value == true){
      this.employeeService.insertQrCode(formData).subscribe(
        resp=>{
          if(resp.status == 401)
          {
            this.router.navigate([this.myItem.url]);
          }
          if(resp.success)
          {
            this.status = false;
            this.employeeService.successMessage();
            this.qrcodeForm.reset();
          }
        });            
    }
  }
    
}
