import { Component, OnInit } from '@angular/core';
import { SalaryslipTemplateModel } from "../salaryslip-template.model";
import { SalaryslipTemplateService } from "../salaryslip-template.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

//import * as jspdf from 'jspdf';
//import html2canvas from 'html2canvas';


@Component({
  selector: 'app-salaryslip-template1',
  templateUrl: './salaryslip-template1.component.html',
  styleUrls: ['./salaryslip-template1.component.scss']
})
export class SalaryslipTemplate1Component implements OnInit {
  //salary:SalaryslipTemplateModel = new SalaryslipTemplateModel({});
  public data: any = {};
  public userId;
  public monthYear;
  public month;
  public date;
  public year;
  public salary: any = {};
  public earningTotals;
  public deductionTotals;
  public netPay;
  public key = 'loginData';
  public myItem: any;
  public id;
  //public src;
  public src:string = "";

  public a = [
    '',
    'one ',
    'two ',
    'three ',
    'four ',
    'five ',
    'six ',
    'seven ',
    'eight ',
    'nine ',
    'ten ',
    'eleven ',
    'twelve ',
    'thirteen ',
    'fourteen ',
    'fifteen ',
    'sixteen ',
    'seventeen ',
    'eighteen ',
    'nineteen '];

  public b = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety'];

  constructor(
    private salaryslipTemplateService: SalaryslipTemplateService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.myItem = JSON.parse(localStorage.getItem(this.key)); 
    this.userId = this.salaryslipTemplateService.userId;
    this.monthYear = this.salaryslipTemplateService.monthYear;
    this.date = new Date(this.monthYear);
    this.month = this.date.getMonth() + 1;
    this.year = this.date.getFullYear();

    this.route.params.subscribe((params: Params) => {     
      this.id = params.id;
      //this.userId = params.id;
      // this.date = params.date;
      ////console.log(this.userId);
      //console.log(this.date);
    });
    this.getData();
  }
  // getData() {
  //   this.salaryslipTemplateService.getSalaryData(this.userId, this.monthYear).subscribe(
  //     resp => {
  //       if (resp.status == 401) {
  //         this.router.navigate([this.myItem.url]);
  //       }
  //       this.salary = resp.items.basicData;
  //       console.log(this.salary);
  //       // this.month = 
  //       this.data = resp.items.salaryData;

  //       console.log(parseInt(this.data.Basic || 0));

  //       this.earningTotals = parseInt(this.data.Basic || 0) + parseInt(this.data.HRA || 0) + parseInt(this.data.SplAllow || 0) + parseInt(this.data.Bonus || 0);
  //       this.deductionTotals = parseInt(this.data.PF || 0) + parseInt(this.data.ESI || 0) + parseInt(this.data.LWF || 0);
  //       this.netPay = parseInt(this.earningTotals || 0) - parseInt(this.deductionTotals || 0);
  //       //console.log(this.transform(parseInt(this.netPay)));
  //     }
  //   );
  // }
  getData() {
    this.salaryslipTemplateService.getSalaryData(this.id).subscribe(
      resp => {
        
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.src = resp.items;
      }
    );
  }



  // public captureScreen() {
  //   var data = document.getElementById('contentToConvert');
  //   html2canvas(data).then(canvas => {
  //     // Few necessary setting options  
  //     var imgWidth = 208;
  //     var pageHeight = 20000;
  //     var imgHeight = canvas.height * imgWidth / canvas.width;
  //     var heightLeft = imgHeight;
  //     var position = 0;

  //     const contentDataURL = canvas.toDataURL('image/png')
  //     let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  

  //     pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
  //     heightLeft -= pageHeight;
  //     while (heightLeft >= 0) {
  //       position = heightLeft - imgHeight;
  //       pdf.addPage();
  //       pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;
  //     }
  //     pdf.save('Salaryslip-' + this.month + ' / ' + this.year + '.pdf'); // Generated PDF   
  //   });
  // }

  // public transform(value: any): any {
  //   if (value) {
  //     let num: any = Number(value);
  //     if (num) {
  //       if ((num = num.toString()).length > 9) { return 'We are not the Iron Bank, you can lower down the stakes :)'; }
  //       const n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  //       if (!n) { return ''; }
  //       let str = '';
  //       str += (Number(n[1]) !== 0) ? (this.a[Number(n[1])] || this.b[n[1][0]] + ' ' + this.a[n[1][1]]) + 'crore ' : '';
  //       str += (Number(n[2]) !== 0) ? (this.a[Number(n[2])] || this.b[n[2][0]] + ' ' + this.a[n[2][1]]) + 'lakh ' : '';
  //       str += (Number(n[3]) !== 0) ? (this.a[Number(n[3])] || this.b[n[3][0]] + ' ' + this.a[n[3][1]]) + 'thousand ' : '';
  //       str += (Number(n[4]) !== 0) ? (this.a[Number(n[4])] || this.b[n[4][0]] + ' ' + this.a[n[4][1]]) + 'hundred ' : '';
  //       str += (Number(n[5]) !== 0) ? ((str !== '') ? 'and ' : '') +
  //         (this.a[Number(n[5])] || this.b[n[5][0]] + ' ' +
  //           this.a[n[5][1]]) + 'rupee' : '';
  //       return str;
  //     } else {
  //       return '';
  //     }
  //   } else {
  //     return '';
  //   }
  // }

  


  public downloadFile(src){
    var link=document.createElement('a');
    document.body.appendChild(link);
    link.href= src;
    link.download = '';
    link.target="_blank"
    link.click();
  }



}
