import { Component, OnInit,EventEmitter } from '@angular/core';
import { FileSelectDirective, FileDropDirective, FileUploader, FileLikeObject } from 'ng2-file-upload';
import { EmployeeService } from '../employee.service';
import { SidebarService } from '../../shared/sidebar/sidebar.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';


function readBase64(file): Promise<any> {
  var reader = new FileReader();
  var future = new Promise((resolve, reject) => {
    reader.addEventListener("load", function () {
      resolve(reader.result);
    }, false);
    reader.addEventListener("error", function (event) {
      reject(event);
    },
      false);
    reader.readAsDataURL(file);
  });
  return future;
}

const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

@Component({
  selector: 'app-import-ctc',
  templateUrl: './import-ctc.component.html',
  styleUrls: ['./import-ctc.component.scss']
})
export class ImportCtcComponent implements OnInit {

  constructor(
    private employeeService:EmployeeService,
    private sidebarService : SidebarService,
    private router:Router,
    private location: Location,
  ) { }

  fileForm: FormGroup;

  public selectedFile: File = null;
  public selectedFileView: any = 'assets/img/drag-drop-img.png';
  public uploader: FileUploader = new FileUploader({ url: URL, disableMultipart: true });
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;
  public goodEntry:any=[];
  public badEntry:any=[];
  public key ='loginData';
  public key1 ='permission';
  public myItem:any;
  public permission;

  ngOnInit() {
    this.permission= this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));

    this.fileForm = new FormGroup({
      'fileInput': new FormControl(null, [Validators.required])
    }, { updateOn: 'change' });
  }

  onFileChange(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  public onFileSelected(event: EventEmitter<File[]>) {

    console.log('here');
    this.selectedFile = null;

    this.selectedFileView = 'assets/img/drag-drop-img.png';
    const file: File = event[0];
    var ext = this.getFileExtension(file.name);
    console.log(ext);
    if (ext == 'csv' || ext == 'text') {
      this.selectedFile = file;
      this.selectedFileView = 'assets/img/csv.png';
    }
  }

  public getFileExtension(filename) {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
  }

  public uploadCsvFile() {
    this.employeeService.uploadCtcFile(this.selectedFile).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        if (resp.items.goodEntries.length > 0) {
          this.goodEntry = resp.items.goodEntries;
  
        }
        else {
          this.goodEntry = [];
        }

        if (resp.items.badEntries.length > 0)
          this.badEntry = resp.items.badEntries;
        else {
          this.badEntry = [];
        }

        this.selectedFile = null;
        this.selectedFileView = 'assets/img/drag-drop-img.png';

      } else {

        // console.log(this.badEmployee);
      }
    });

  }

  public clearCsvFile() {
    this.selectedFile = null;
    this.selectedFileView = 'assets/img/drag-drop-img.png';
    this.fileForm.reset();
    this.goodEntry = [];
    this.badEntry = [];
  }

  public saveData() {

    this.employeeService.addEmployeeCtc(this.goodEntry, this.myItem.company_id, this.myItem.id).subscribe(resp => {
      if (resp.success) {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.employeeService.successMessageOfCtcUpload();
        this.selectedFile = null;
        this.selectedFileView = 'assets/img/drag-drop-img.png';
        this.goodEntry = [];
        this.badEntry = [];

      } else {
        this.employeeService.ctcFileUploadError();
      }
    });
  }

  public clearCtc()
  {
    this.employeeService.clearCtc(this.myItem.company_id).subscribe(resp => {
      if (resp.success) {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
      }
    });
  }

  public backClicked()
  {
    this.location.back();
  }   
}
