import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModuleComponent } from '../module/module.component';
import { AddModuleComponent } from "../module/add-module/add-module.component";
import { RoleModuleRelationComponent } from './role-module-relation/role-module-relation.component';



const routes: Routes = [
  {
    path: '',  
    
    children: [   
      {
        path: 'module',
        component: ModuleComponent,
        data: {
          title: 'module'
        }
      },
      {
        path: 'addmodule',
        component: AddModuleComponent,
        data: {
          title: 'addmodule'
        }
      },
      {
        path: 'addmodule/:id',
        component: AddModuleComponent,
        data: {
          title: 'addmodule'
        }
      },
      {
        path: 'role_module_relation',
        component: RoleModuleRelationComponent,
        data: {
          title: 'Module Relation'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ModuleRoutingModule { }

export const ModuleComponentList = [
  ModuleComponent,
  AddModuleComponent,
  RoleModuleRelationComponent
];

