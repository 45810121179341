import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ChartistModule } from 'ng-chartist';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchHeightModule } from "../shared/directives/match-height.directive";

import { LeaveTypeList,LeaveTypeRoutingModule } from "./leave-type-routing.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';


 
@NgModule({
    imports: [
        CommonModule,
        LeaveTypeRoutingModule,
        ChartistModule,
        NgbModule,
        MatchHeightModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgxDatatableModule,
        DataTablesModule,
    ],   
    exports: [
    ],
    declarations: [
        LeaveTypeList,
    ],  
    providers: [], 
})
export class LeaveTypeModule{ }
