import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModulePermissionComponent } from "./module-permission.component";




const routes: Routes = [
  {
    path: '',  
    
    children: [   
  
      {
        path: 'module-permission',
        component: ModulePermissionComponent,
        data: {
          title: 'module-permission'
        }
      },
     
     
    //  for subclient
      {
        path: 'module-permission/:id',
        component: ModulePermissionComponent,
        data: {
          title: 'module-permission'
        }
       },
     
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ModulePermissionRoutingModule { }

export const ModulePermissionComponentList = [
  ModulePermissionComponent
];

