import { Component, OnInit ,ViewChild } from '@angular/core';
import { ClientModel } from "../client/client.model";
import { ClientService } from "../client/client.service";
import { Router, ActivatedRoute, Params  } from "@angular/router";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { SidebarService } from '../shared/sidebar/sidebar.service';

@Component({
  selector: 'app-customer-header',
  templateUrl: './customer-header.component.html',
  styleUrls: ['./customer-header.component.scss']
})
export class CustomerHeaderComponent implements OnInit {
  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;
  myForm: FormGroup;

  currentPage: string = "About";
  company:ClientModel = new ClientModel({});
  constructor(private clientService : ClientService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private sidebarService : SidebarService,) { }


    public editStatus = true;
    public key ='loginData';
    public key1 ='permission';
    public myItem:any;
    public modulePermission:any;
    public  flag;
    //public permission;
    public showHeader = false;

    //for get module permission
    public employeeModulePermission;
    public managerModulePermission;
    public departmentModulePermission;
    public designationModulePermission;
    public customerEmployeeModulePermission;
    public manageHolidaysPermission;
    public salaryslipPermission;

  ngOnInit() {
    //session
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.modulePermission = JSON.parse(localStorage.getItem(this.key1));   
    
    // if(this.myItem.role_id !=1)
    // {
    //   this.showHeader = true;
    // }

    //validation
    this.myForm = new FormGroup({
      'company_name': new FormControl(null, [Validators.required]),
      'company_location': new FormControl(null, [Validators.required]),
      'company_address': new FormControl(null, [Validators.required]),
      'company_email': new FormControl(null, [Validators.required, Validators.email]),
      'company_number': new FormControl(null, [Validators.required]),
     
  }, {updateOn: 'change'});


    this.route.params.subscribe(
      (params: Params) => {
        if (params.id) {        
          this.getData(params.id);
        }
      }
    );

    $.getScript('./assets/js/vertical-timeline.js');

    //for get module permission
    this.getModulePermission();
  }

    showPage(page: string) {
      this.currentPage = page;
  }

    getData(id : number)
    {
    this.clientService.getItem(id).subscribe(
      resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success){
          this.company = resp.items;
        }
      }
    );
    }

   public Employee(id)
   {
    this.flag ="SubClient";
    //.myItem.role = 'Employee';
    localStorage.setItem(this.key, JSON.stringify(this.myItem));
    //this.router.navigate(['customer/employee',{id:id,flag:this.flag}]);
    this.router.navigate(['customer/employee',id]);
   }
 

   public customerProfile(id)
   {
     this.router.navigate(['customer/customerProfile',id]);
   }

   public Manager(id)
   {
    //this.flag ="Client";
   // this.myItem.role ='Manager';
    localStorage.setItem(this.key, JSON.stringify(this.myItem));
    this.router.navigate(['customer/manager',id]);
   }
   
   public Departments(id)
   {
     this.currentPage = 'Departments';
    this.router.navigate(['customer/departments',id]);
   }
   public Designation(id)
   {
    this.router.navigate(['customer/designation',id]);
   }

   public ModulePermission(id)
   {
    this.router.navigate(['customer/module-permission',id]);
   }

   public Holidays(id)
   {
    this.router.navigate(['employee/manage-holidays',id]);
   }

   public getEdit(id)
   {
     this.router.navigate(['customer/addclient',id]);
   }

   public salaryslipFormat(companyId)
   {
    this.router.navigate(['salaryslip-template/add-bulk-salary',companyId]);
   }
  
   public backClicked()
  {
    this.location.back();
  }   

   public getModulePermission()
   {
    this.modulePermission.forEach(element => {
      // 7 Stands for Employee Module Id
      if(element.moduleId==7)
      {
        this.employeeModulePermission = element.permissionLevel;
      }
      // else
      // {
      //   this.employeeModulePermission = 0;
      // }

      // 8 Stands for Manager Module Id
      if(element.moduleId==8)
      {
        this.managerModulePermission = element.permissionLevel;
      }
      // else
      // {
      //   this.managerModulePermission = 0;
      // }

       // 3 Stands for Department Module Id
      if(element.moduleId==3)
      {
        this.departmentModulePermission = element.permissionLevel;
      }
      // else
      // {
      //   this.departmentModulePermission = 0;
      // }

      // 6 Stands for Designation Module
      if(element.moduleId==6)
      {
        this.designationModulePermission = element.permissionLevel;
      }

      // 14 Stand for Customer Employee Module Id

      if(element.moduleId==14)
      {
        this.customerEmployeeModulePermission = element.permissionLevel;
      }
      // else
      // {
      //   this.designationModulePermission = 0;
      // }

     // 19 stand for manage holidays module id 
      if(element.moduleId==19)
      {
        this.manageHolidaysPermission = element.permissionLevel;
      }

      // 16 stand for manage salaryslip module id 
      if(element.moduleId==16)
      {
        this.salaryslipPermission = element.permissionLevel;
      }
    });
  }
  
}
