import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params  } from "@angular/router";
import { ModuleService } from "./module.service";
import { Location } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { SidebarService } from '../shared/sidebar/sidebar.service';


@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.scss']
})
export class ModuleComponent implements OnInit {
@ViewChild(DataTableDirective) datatableElement: DataTableDirective;
dtTrigger: Subject<any> = new Subject();
dtOptions: DataTables.Settings = {};

  public data:  any[] = [];
  public key ='loginData';
  public myItem:any;
  public permission;


  constructor(
    private router:Router,
    private activatedRoute: ActivatedRoute,
    private moduleService : ModuleService,
    private location: Location,
    private sidebarService : SidebarService,

  ) { }

  ngOnInit() {
    this.permission= this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));

    this.getData();
    //this.rerender();

    this.dtOptions = {
      //used created_at index 
      order: [[0, "DESC"]],
    };

  }

  public getData()
   {
    this.moduleService.getdata().subscribe(resp=>{
      this.data=resp.items;
      this.rerender();

   });
  }

  public addModule()
  {
    this.router.navigate(['module/addmodule']);
  }
  public getEdit(id:number)
  {
    this.router.navigate(['module/addmodule',id]);
  }

  public Delete(id: number, i: number)
  {
    
    if(window.confirm('Are you sure you want to delete?')) {
      this.moduleService.delete(id).subscribe(resp=>{
        if(resp.success){
          this.moduleService.deleteSuccess();
         this.data.splice(i, 1);
         this.rerender();

        }
      });
    }
  }

    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }

    rerender(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
    }

    ngAfterViewInit(): void {
      this.dtTrigger.next();
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function () {
          const that = this;

          // For checked fields
          $('input[type=checkbox]', this.footer()).on('checked change', function () {
            if (this['value'] === 'true') {
              that.search(this['value']).draw();
            } else {
              that.search('').draw();
            }
          });

          $('input[type=text]', this.footer()).on('keyup change', function () {
            console.log("text : " + this['value']);
            if (that.search() !== this['value']) {
              that.search(this['value']).draw();
            }
          });
        });
      });
    }

    public backClicked()
    {
      this.location.back();
    }
    
    public createRoleModuleRelation()
    {
      this.router.navigate(['module/role_module_relation']);
    }
}
