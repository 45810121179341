import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ChartistModule } from 'ng-chartist';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchHeightModule } from "../shared/directives/match-height.directive";

import { LeaveRoutingModule ,LeaveComponentList } from "../leave/leave-routing.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AddLeaveComponent } from './add-leave/add-leave.component';
import { DataTablesModule } from 'angular-datatables';
import { NgxLoadingModule } from 'ngx-loading';


@NgModule({
    imports: [
        DataTablesModule,
        CommonModule,
        LeaveRoutingModule,
        ChartistModule,
        NgbModule,
        MatchHeightModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgxDatatableModule,
        NgxLoadingModule
        
    ],   
    exports: [],
    declarations: [
        LeaveComponentList,
        AddLeaveComponent,
        
        
    ],  
    providers: [], 
})
export class LeaveModule{ }
