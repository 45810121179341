import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { ForgotPasswordPageComponent } from "./forgot-password/forgot-password-page.component";
import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";
import { AuthGuard } from './shared/auth/auth-guard.service';
import { LoginPageComponent } from './login/login-page.component';
import { ErrorPageComponent } from "./error/error-page.component";


const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  // {
  //   path: '',
  //   component: LoginPageComponent,
  //   pathMatch: 'full',
  // },
  {
    path: 'login',
    component: LoginPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'fusion_76',
    component: LoginPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordPageComponent,
  },
  {
    path: 'dsr',
    redirectTo: 'dsr',
    pathMatch: 'full'
  },
  { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES, canActivate: [AuthGuard] },

  {
    path: '**',
    redirectTo: 'error',
    pathMatch: 'full',
  },
   {
     path: 'error',
    component: ErrorPageComponent,        
  },  
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
  exports: [RouterModule],
  providers: [

  ]
})

export class AppRoutingModule {
}