export class DsrDemoModel {

   
    id : number;
    userId:number;
    clientName :string;
    activationNo : string;
    planSchemeName :string;
    noOfActivation: number;
    inrPlanScheme :number;
    totalAmount :number;
    documentCollected :number;
    
    
   
    
  

    constructor(params:any) {       
        const model = params || {};
        this.id =model.id || 0;
        this.userId = model.userId || '';
        this.clientName =model.clientName || '';
        this.activationNo =model.activationNo || '';
        this.planSchemeName =model.planSchemeName || '';
        this.noOfActivation =model.noOfActivation || '';
        this.inrPlanScheme =model.inrPlanScheme || '';
        this.totalAmount =model.totalAmount || '';
        this.documentCollected =model.documentCollected || '0';    
        
    }
}
