import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocumentCategoryComponent } from "./document-category.component";
import { AddDocumentCategoryComponent } from './add-document-category/add-document-category.component';
import { EmployeeListComponent } from "./employee-list/employee-list.component";




const routes: Routes = [
  {
    path: '',  
    
    children: [   
      {
        path: 'document-category',
        component: DocumentCategoryComponent,
        data: {
          title: 'employee'
        }
      },
      {
        path: 'document-category/:id',
        component: DocumentCategoryComponent,
        data: {
          title: 'employee'
        }
      },
      {
        path: 'category/:id',
        component: DocumentCategoryComponent,
        data: {
          title: 'employee'
        }
      },
      {
        path: 'add-document-category',
        component: AddDocumentCategoryComponent,
        data: {
          title: 'AddDocumentCategory'
        }
      },
      {
        path: 'add-document-category/:id',
        component: AddDocumentCategoryComponent,
        data: {
          title: 'AddDocumentCategory'
        }
      },
      {
        path: 'employee',
        component: EmployeeListComponent,
        data: {
          title: 'Employee List'
        }
      },
       
     
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DocumentCategoryRoutingModule { }

export const DocumentCategoryList = [
  DocumentCategoryComponent,
  AddDocumentCategoryComponent,
  EmployeeListComponent
 
];

