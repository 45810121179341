
import { ToDoComponent } from './to-do.component';

import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ChartistModule } from 'ng-chartist';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchHeightModule } from "../../shared/directives/match-height.directive";

import {ToDoList, ToDoRoutingModule } from "./to-do-routing.module";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';

@NgModule({
  imports: [
    CommonModule,
    ToDoRoutingModule,
    ChartistModule,
    NgbModule,
    MatchHeightModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    NgxDatatableModule,
    DataTablesModule,
  ],
  declarations: [
    ToDoList,
],  
})

export class ToDoModule { }
