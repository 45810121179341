import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MeetingComponent } from './meeting.component';
import { AddMeetingComponent } from "./add-meeting/add-meeting.component";



const routes: Routes = [
  {
    path: '',  
    
    children: [   
      {
        path: 'meeting',
        component: MeetingComponent,
        data: {
          title: 'meeting'
        }
      },
      {
        path: 'addmeeting',
        component: AddMeetingComponent,
        data: {
          title: 'addmeeting'
        }
      },
      {
        path: 'addmeeting/:id',
        component: AddMeetingComponent,
        data: {
          title: 'addmeeting'
        }
     },
       
     
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MeetingRoutingModule { }

export const MeetingComponentList = [
  MeetingComponent,
  AddMeetingComponent
];

