import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Location } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { SalaryslipTemplateService } from "./salaryslip-template.service";
import { SidebarService } from '../shared/sidebar/sidebar.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-salaryslip-template-list',
  templateUrl: './salaryslip-template-list.component.html',
  styleUrls: ['./salaryslip-template-list.component.scss']
})
export class SalaryslipTemplateListComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  @ViewChild('tempData') private tempData;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  public data: any[] = [];
  public key = 'loginData';
  public myItem: any;
  public permission;
  public templateId;
  public clientList;
  public closeResult: string;
  public tempDataRef;
  public message = false;

  constructor(
    private sidebarService: SidebarService,
    private router: Router,
    private salaryslipTemplateService: SalaryslipTemplateService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.permission = this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));

    this.getData();

  }

  public getData() {
    this.salaryslipTemplateService.getdata().subscribe(
      resp => {
        this.data = resp.items;
        this.rerender();
      });
  }

  // public openTemplate(path)
  // {
  //   this.router.navigate([path]);
  // }

  public addSalaryTemplate() {
    this.router.navigate(['salaryslip-template/add_salaryslip_template']);
  }

  public openModel(obj) {
    this.message=false;
    this.templateId = obj.id;
    this.salaryslipTemplateService.getClients(this.templateId).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      this.clientList = resp.items;
    });

    this.tempDataRef = this.modalService.open(this.tempData);

    // this.modalService.open(this.tempData).result.then((result) => {
    //     this.closeResult = `Closed with: ${result}`;       
    // });
  }

  updateCheckedOptions(option, event) {
    this.clientList.forEach(v => {
      if (v.id == event.target.value) {
        v.selected = event.target.checked;
        return false;
      }
    });
  }

  public onSubmit() {
    let formData: FormData = new FormData();
    formData.append('data', JSON.stringify(this.clientList));
    formData.append('templateId', this.templateId);
    formData.append('createdBy', this.myItem.id);
    this.salaryslipTemplateService.assignTemplate(formData).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      //this.clientList=[];
      if (resp.success) {
        this.salaryslipTemplateService.Success();
        this.tempDataRef.dismiss();
        this.clientList = [];
        this.message = false;
      }
      else {
        //this.salaryslipTemplateService.Fail();
        this.message = true;
      }
    });

  }

  public getEdit(id: number) {
    this.router.navigate(['salaryslip-template/add_salaryslip_template', id]);
  }

  public Delete(id: number, i: number) {
    if (window.confirm('Are you sure you want to delete?')) {
      this.salaryslipTemplateService.delete(id).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.salaryslipTemplateService.deleteSuccess();
          this.data.splice(i, 1);
          this.rerender();
        }
      });
    }
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        // For checked fields
        $('input[type=checkbox]', this.footer()).on('checked change', function () {
          if (this['value'] === 'true') {
            that.search(this['value']).draw();
          } else {
            that.search('').draw();
          }
        });

        $('input[type=text]', this.footer()).on('keyup change', function () {
          console.log("text : " + this['value']);
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }


  public addBulkSalaryData() {
    this.router.navigate(['salaryslip-template/add-bulk-salary']);
  }

  public openSalaryTemplate(path){
    console.log(path);
    this.router.navigate([path]);
  }

}
