import { Component } from '@angular/core';
import * as Chartist from 'chartist';
import { ChartType, ChartEvent } from "ng-chartist";
import { ClientService } from "../../client/client.service";
import { EmployeeService } from "../employee.service";
import { SidebarService } from '../../shared/sidebar/sidebar.service';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NewsService } from "../../news/news.service";
import { DatePipe } from '@angular/common';

declare var require: any;

const data: any = require('../../shared/data/chartist.json');

export interface Chart {
    type: ChartType;
    data: Chartist.IChartistData;
    options?: any;
    responsiveOptions?: any;
    events?: ChartEvent;
}

@Component({
    selector: 'app-dashboard1',
    templateUrl: './dashboard1.component.html',
    styleUrls: ['./dashboard1.component.scss']
})



export class Dashboard1Component {

    public totalClient;
    public totalEmployee;
    public key = 'loginData';
    public key1 = 'permission';
    public myItem: any;
    public modulePermission: any;
    public parent_id;
    public permission;
    public dashboardPermission = false;
    public clientPermission: number;
    public employeePermission: number;
    public news: any = [];
    public totalEmployeePresents;
    public totalEmployeeAbsents;

    constructor(private clientService: ClientService,
        private employeeService: EmployeeService,
        private sidebarService: SidebarService,
        private router: Router,
        private newsService: NewsService,
        private datePipe: DatePipe, ) { }

    ngOnInit() {
        this.permission = this.sidebarService.getCurrentPermission();
        this.myItem = JSON.parse(localStorage.getItem(this.key));
        this.parent_id = this.myItem.company_id;
        //this.getClient(this.parent_id);
        // this.getEmployee(this.parent_id);
        this.gerDashboardPermission();
        this.getNews();
        this.getAllCounts();
    }

    public getAllCounts() {
        this.employeeService.getAllCounts(this.myItem.company_id).subscribe(resp => {
            if (resp.status == 401) {
                this.router.navigate([this.myItem.url]);
            }
            if (resp.success) {
                this.totalClient = resp.items.totalCustomer;
                this.totalEmployee = resp.items.totalEmployee;
                this.totalEmployeePresents = resp.items.totalPresent;
                this.totalEmployeeAbsents = resp.items.totalAbsent;
            }
        });
    }

    // public getClient(parent_id) {
    //     this.clientService.get_data(parent_id,this.myItem.role_id).subscribe(resp => {
    //         if (resp.status == 401) {
    //             this.router.navigate([this.myItem.url]);
    //         }
    //         if (resp.success) {
    //             this.totalClient = resp.items.length;
    //         }

    //     });
    // }

    // public getEmployee(company_id: number) {
    //     this.employeeService.getdata(company_id).subscribe(resp => {
    //         if (resp.status == 401) {
    //             this.router.navigate([this.myItem.url]);
    //         }
    //         if (resp.success) {
    //             this.totalEmployee = resp.total_count;
    //         }
    //         // console.log(this.totalEmployee);
    //     });
    // }

    public gerDashboardPermission() {
        this.sidebarService.getPermission(this.myItem.id).subscribe(resp => {
            if (resp.status == 401) {
                this.router.navigate([this.myItem.url]);
            }
            var data = resp.items;
            data.forEach(element => {
                // 1 stand for dashboard
                if (element.moduleId == 1) {
                    this.dashboardPermission = true;
                }
                // 2 stand for customer
                if (element.moduleId == 2) {
                    this.clientPermission = element.permissionLevel;
                }
                // 7 stand for employee
                if (element.moduleId == 7) {
                    this.employeePermission = element.permissionLevel;
                }
            });
        });
    }


    public redirectToClient() {
        this.router.navigate(['/employee/customer']);
    }

    public redirectToEmployee() {
        this.router.navigate(['/employee/employee']);
    }


    public getNews() {
        let formData: FormData = new FormData();
        formData.append('company_id', this.myItem.company_id);
        formData.append('user_id', this.myItem.id);
        formData.append('date', this.datePipe.transform(Date(), "yyyy-MM-dd hh:mm:ss"));
        this.newsService.getNewsForEmployee(formData).subscribe(
            resp => {
                if (resp.status == 401) {
                    this.router.navigate([this.myItem.url]);
                }
                if (resp.success) {
                    this.news = resp.items;
                }
            });
    }

    public convertDate(date) {
        var yyyy = date.getFullYear().toString();
        var mm = (date.getMonth() + 1).toString();
        var dd = date.getDate().toString();

        var mmChars = mm.split('');
        var ddChars = dd.split('');

        return yyyy + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + (ddChars[1] ? dd : "0" + ddChars[0]);
    }


}
