export class PermissionModel {

   
    userId :number;
    moduleId : number;
    permissionLevel : number;
   
    
  

    constructor(params:any) {
        const model = params || {};
        this.userId =model.userId || '';
        this.moduleId =model.created_by || '';
        this.permissionLevel =model.permissionLevel || '0';
    }
}
