import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { App_Url } from '../globals';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})

export class AttendanceService {
  
  constructor(private http: HttpClient,public toastr: ToastrService) { }
  public key ='loginData';
  public myItem:any;

 

  public get_data(parent_id):Observable<any>
  { 
    let formData: FormData = new FormData();
     formData.append('id', parent_id);
     formData.append('api_token',this.getApiToken());
     return this.http.post(App_Url+`api/attendance-get`,formData);

  }

  //All Attendance
  public get_data_web(ids:any,startDate,endDate,loginUser):Observable<any>
  { 
   
    let formData: FormData = new FormData();
     formData.append('id', ids);
     formData.append('startDate', startDate);
     formData.append('endDate', endDate);
     formData.append('loginUser', loginUser);
     formData.append('api_token',this.getApiToken());
     //return this.http.post(App_Url+`api/attendance-get-web`,formData);
     //get all attendace data with all selected date range
     return this.http.post(App_Url+`api/getAllAttendanceWithDateRange`,formData);
  }

  // public get_attendance(ids:any,flag):Observable<any>
  // { 
  //   let formData: FormData = new FormData();
  //    formData.append('id', ids);
  //    formData.append('flag', flag);
  //    formData.append('api_token',this.getApiToken());
  //    return this.http.post(App_Url+`api/attendance-get-web`,formData);
  // }

  //All Single User Attendance
  public get_single_attendance(ids:any,startDate,endDate):Observable<any>
  { 
   
    let formData: FormData = new FormData();
     formData.append('id', ids);
     formData.append('startDate', startDate);
     formData.append('endDate', endDate);
     formData.append('loginUser', '');
     formData.append('api_token',this.getApiToken());
     //return this.http.post(App_Url+`api/attendance-get`,formData);
     //get all attendace data with all selected date range
     return this.http.post(App_Url+`api/getAllAttendanceWithDateRange`,formData);
  }

  public employeeList(company_id):Observable<any>
  { 
    let formData: FormData = new FormData();
    formData.append('id', company_id);
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/get_employee`,formData);
  }


  public getEmployeeForManager(managerId):Observable<any>
  { 
    let formData: FormData = new FormData();
    formData.append('id', managerId);
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/getEmployeeForManager`,formData);

  }

  public employeeListByArray(idArray):Observable<any>
  { 
    let formData: FormData = new FormData();
    formData.append('id', idArray);
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/get-employee-by-id-array`,formData);

  }

  public getGeoTrakingByDate(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/getGeoTrakingByDate`,formData);

  }

  public getAttendanceExcelData(startDate,endDate,companyId,flag):Observable<any>
  { 
    let formData: FormData = new FormData();
     //formData.append('data', JSON.stringify(data));
     formData.append('startDate', startDate);
     formData.append('endDate', endDate);
     formData.append('companyId', companyId);
     formData.append('flag', flag);
     formData.append('api_token',this.getApiToken());
     return this.http.post(App_Url+`api/getAttendanceExcelData`,formData);

  }

  public getAttendanceExcelDataReport(data,startDate,endDate):Observable<any>
  { 
    let formData: FormData = new FormData();
     formData.append('data', JSON.stringify(data));
     formData.append('startDate', startDate);
     formData.append('endDate', endDate);
     formData.append('api_token',this.getApiToken());
     return this.http.post(App_Url+`api/getAttendanceExcelData`,formData);

  }

  public getPauseDetails(userId,punchDate):Observable<any>
  { 
    let formData: FormData = new FormData();
     formData.append('userId', userId);
     formData.append('punchDate', punchDate);
     formData.append('api_token',this.getApiToken());
     return this.http.post(App_Url+`api/getPauseDetails`,formData);
  }

  // get company name
  public getCompanyName(company_id):Observable<any>
  { 
    let formData: FormData = new FormData();
    formData.append('id', company_id);
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/company-single`,formData);
  }

  public addAttendance(formData):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/mark-attendance`,formData);
  }

  public getAttendanceHistory(userId):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('userId', userId);
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/get-attendance-history`,formData);
  }

  public getEmployeeForAttendanceApprove(userId):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('userId', userId);
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/get-employee-for-approve-attendance`,formData);
  }

  public attendanceApprovalStatus(formData):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/approve-attendance`,formData);
  }

  insertSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  approvedStatus(message)
  {
    this.toastr.success(message, 'Success!');
  }

  alreadyExists() {
    this.toastr.error('', 'Attendance already exists', { "timeOut": 1000 });
  }

  getApiToken(){ 
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    var api_token = this.myItem.tokenKey;
  // var api_token ='59240171-6f5c-42e3-8d87-0044ac120184';
   return api_token ;
  }
}
