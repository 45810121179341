import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { FileSelectDirective, FileDropDirective, FileUploader, FileLikeObject } from 'ng2-file-upload';
import { VehicleListService } from './vehicle-list.service';
import { SidebarService } from '../../shared/sidebar/sidebar.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js'


@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.scss']
})
export class VehicleListComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  public key = 'loginData';
  public key1 = 'permission';
  public myItem: any;
  public permission;
  public vehicle_list: any = [];
  constructor(
    private vehicleListService: VehicleListService,
    private sidebarService: SidebarService,
    private router: Router,
    private location: Location,
  ) { }

  ngOnInit() {
    this.permission = this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));

    this.getVehicleList();
    this.dtOptions = {
      //used id for index 
      order: [[0, "DESC"]],
    };
  }

  public getVehicleList() {

    this.vehicleListService.getVehicleList(this.myItem.company_id).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.vehicle_list = resp.items;
        this.rerender();
      });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        // For checked fields
        $('input[type=checkbox]', this.footer()).on('checked change', function () {
          if (this['value'] === 'true') {
            that.search(this['value']).draw();
          } else {
            that.search('').draw();
          }
        });

        $('input[type=text]', this.footer()).on('keyup change', function () {
          console.log("text : " + this['value']);
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  public editVehicleDetail(id: number) {
    this.router.navigate(['employee/driver_delivery/vehicle_list/add_vehicle_details',id]);
  }

  public addVehicleDetails()
  {
    this.router.navigate(['employee/driver_delivery/vehicle_list/add_vehicle_details']);
  }

  public backClicked() {
    this.location.back();
  }

  public Delete(id: number, i: number)
  {
    Swal.fire({
      title: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete It!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.vehicleListService.deleteVehicleDetails(id).subscribe(resp => {
          if (resp.status == 401) {
            this.router.navigate([this.myItem.url]);
          }
          if (resp.success) {
            this.vehicle_list.splice(i, 1);
            this.rerender();
            Swal.fire(
              'Done!',
              'Vehicle details deleted successfully.',
              'success'
            );
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled'
        )
      }
    });
  }

}
