import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { App_Url } from '../globals';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class TaskManagerService {

  constructor(private http: HttpClient, public toastr: ToastrService) { }
  public key = 'loginData';
  public myItem: any;
  public getProjectList(companyId): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('clientId', companyId);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/getProjectList`, formData);
  }

  public getTaskList(userId, taskDate) {
    let formData: FormData = new FormData();
    formData.append('userId', userId);
    formData.append('taskDate', taskDate);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/getTaskList`, formData);
  }

  public getEmployeeList(companyId, teamLeadId, isAdmin): Observable<any> {

    let formData: FormData = new FormData();
    formData.append('companyId', companyId);
    formData.append('teamLeadId', teamLeadId);
    formData.append('isAdmin', isAdmin);
    formData.append('api_token', this.getApiToken());
    // return this.http.post(App_Url+`api/getEmployeelist`,formData);
    return this.http.post(App_Url + `api/v2/getEmployeeUnderManager`, formData);
  }

  public getClientList(companyId): Observable<any> {

    let formData: FormData = new FormData();
    formData.append('clientId', companyId);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/getClientList`, formData);
  }

  insertSuccess() {
    this.toastr.success('Task has been inserted successfully', 'Success!');
  }
  insertSuccessType() {
    this.toastr.success('Task Type has been inserted successfully', 'Success!');
  }
  updateSuccess() {
    this.toastr.success('Task has been updated successfully', 'Success!');
  }
  updateSuccessType() {
    this.toastr.success('Task Type has been updated successfully', 'Success!');
  }

  public delete(id): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('taskId', id);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/deleteTask`, formData);
  }

  public deleteType(id): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/deleteTaskType`, formData);
  }

  deleteSuccess() {
    this.toastr.success('Task has been deleted successfully', 'Success!');
  }

  deleteSuccessType() {
    this.toastr.success('Task Type has been deleted successfully', 'Success!');
  }

  alreadyExists() {
    this.toastr.error('', 'Mandatory fields are required', { "timeOut": 1000 });
  }

  alreadyExistsType() {
    this.toastr.error('', 'Mandatory fields are required', { "timeOut": 1000 });
  }

  zeroMinuteError() {
    this.toastr.error('', 'Total Minute should be greater than zero', { "timeOut": 1000 });
  }

  public getTaskType(companyId): Observable<any> {

    let formData: FormData = new FormData();
    formData.append('api_token', this.getApiToken());
    formData.append('companyId', companyId);
    return this.http.post(App_Url + `api/v2/getTaskType`, formData);
  }

  public add(formData: any): Observable<any> {

    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/addTask`, formData);
  }

  public addType(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/addTaskType`, formData);
  }
  public update(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/editTask`, formData);
  }
  getApiToken() {
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    var api_token = this.myItem.tokenKey;
    return api_token;
  }

  public statusChange(taskId, status, approvedBy): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('taskId', taskId);
    formData.append('status', status);
    formData.append('approvedBy', approvedBy);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/changeTaskStatus`, formData);
  }

  public approveAll(formData:any): Observable<any> {
    
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/approve_all_task`, formData);
  }
}
