import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { SidebarService } from '../shared/sidebar/sidebar.service';
import { BranchService } from './branch.service';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss']
})
export class BranchComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};

  constructor(private router:Router,
    private location: Location,
    private sidebarService : SidebarService,
    private activatedRoute: ActivatedRoute,
    private branchService:BranchService) { }

    public companyId;
    public branchId;
    public data:any= [];
    public companyName;
    public key = 'loginData';
    public myItem: any;
    public permission;

  ngOnInit() {

    this.permission= this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));

    this.activatedRoute.params.subscribe(
      (params: Params) => {
    
      if(params.id){
        this.companyId = params.id;
        this.get_data();
      } else {
        this.companyId = this.myItem.company_id;
        this.get_data();
      }
    });
  }


  public addBranch()
  {
    this.router.navigate(['branch/add-branch',{branchId:this.branchId,companyId:this.companyId}]);
  }

  public get_data()
  { 
     this.branchService.get_data(this.companyId).subscribe(resp=>{
      if(resp.status == 401)
      {
          this.router.navigate([this.myItem.url]);
      }
      if(resp.success){
        this.data=resp.items.branches;
        this.companyName = resp.items.company_name;
        this.rerender();
      }
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        // For checked fields
        $('input[type=checkbox]', this.footer()).on('checked change', function () {
          if (this['value'] === 'true') {
            that.search(this['value']).draw();
          } else {
            that.search('').draw();
          }
        });

        $('input[type=text]', this.footer()).on('keyup change', function () {
        //  console.log("text : " + this['value']);
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  public backClicked()
  {
    this.location.back();
  }  

  public getEdit(id)
  {
    this.router.navigate(['branch/add-branch',{branchId:id,companyId:this.companyId}]);
  }

  public Delete(id: number, i: number)
  {
    if(window.confirm('Are you sure you want to delete?')) {
      this.branchService.delete(id).subscribe(resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success){
          this.branchService.deleteSuccess();
         this.data.splice(i, 1);  
         this.get_data();
         this.rerender();
        }
      });
    }
  }

}
