import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { App_Url } from '../../globals';
import { ToastrService } from 'ngx-toastr';



@Injectable({
  providedIn: 'root'
})


export class SidebarService {

  public userPermission;
  public currentPermission;
  
  constructor(private http: HttpClient,public toastr: ToastrService) { }
  public key ='loginData';
  public myItem:any;

 

  public getSidebar(userId):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('api_token',this.getApiToken());
    formData.append('userId',userId);
   return this.http.post(App_Url+`api/sidebar-get`,formData);
  }

  public getPermission(userId):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('api_token',this.getApiToken());
    formData.append('userId',userId);
   return this.http.post(App_Url+`api/permission`,formData);   
  
  }

  public getUserPermission()
    {
     return this.userPermission;
     
    }
  
    public setUserPermission(value)
    {
     this.userPermission = value;
     
    }

    public getCurrentPermission()
    {
      // return this.currentPermission;
      return  JSON.parse(localStorage.getItem('currentPermission'));
   

    }
  
    public setCurrentPermission(value)
    {
     // this.currentPermission = 0;
     // this.currentPermission = value;
      localStorage.setItem('currentPermission', JSON.stringify(value));
    }

  getApiToken(){
   //var api_token ='59240171-6f5c-42e3-8d87-0044ac120184';
   this.myItem = JSON.parse(localStorage.getItem(this.key));
   var api_token = this.myItem.tokenKey;
   return api_token ;
  }
}
