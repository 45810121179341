import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params  } from "@angular/router";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { ModuleModel } from "../module.model";
import { ModuleService } from "../module.service";


@Component({
  selector: 'app-add-module',
  templateUrl: './add-module.component.html',
  styleUrls: ['./add-module.component.scss']
})
export class AddModuleComponent implements OnInit {
  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;
   myForm:FormGroup;
  module:ModuleModel = new ModuleModel({});

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private moduleService : ModuleService
  ) { }

  public editStatus = true;
  public key ='loginData';
  public myItem:any;


  backClicked() {
    this.location.back();
   } 

ngOnInit() {
  this.myItem = JSON.parse(localStorage.getItem(this.key));

  this.route.params.subscribe(
    (params: Params) => {
      if (params.id) {
        this.editStatus = true;
        this.getData(params.id);
      } else {
        this.editStatus = false;
      }
    }
  );

  this.myForm = new FormGroup({
    'module_name': new FormControl(null, [Validators.required]),
    'status': new FormControl(null)
   
}, {updateOn: 'change'});
}

public onSubmit(data)
{ 
  //data.created_by=this.myItem.id;

  let formData: FormData = new FormData();
  formData.append('id',String(this.module.id));
 // formData.append('api_token','59240171-6f5c-42e3-8d87-0044ac120184');
  formData.append('created_by',this.myItem.id);
  formData.append('module_name',this.module.module_name);
  formData.append('status',String(this.module.status));
  
  if(this.module.id == 0){
    this.moduleService.checkModule(this.module.module_name).subscribe(
      resp=>{  
              if(resp.success)
              {
              this.moduleService.sameModuleName();
              }
              else
              {
              this.moduleService.add(formData).subscribe(resp=>{
                if(resp.success) { 
                  this.moduleService.insertSuccess();
                  this.router.navigate(['module/module']);
                }
                else{
                      this.router.navigate(['module/addmodule']);
                }
              }); }
          });
   }
   else
   {
    this.moduleService.update(formData).subscribe(resp=>{
      if(resp.success) { 
        this.moduleService.updateSuccess();
        this.router.navigate(['module/module']);
        }
        else{
            this.router.navigate(['module/addmodule']);
        }
     });
   }
 }

 getData(id : number)
 {
  this.moduleService.getItem(id).subscribe(
    resp=>{
      this.module = resp.items;
    }
  );
 }



}
