import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { EmployeeModel } from "../employee.model";
import { EmployeeService } from "../employee.service";
import { take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { FileSelectDirective, FileDropDirective, FileUploader, FileLikeObject } from 'ng2-file-upload';
import { BranchService } from '../../branch/branch.service';


function readBase64(file): Promise<any> {
  var reader = new FileReader();
  var future = new Promise((resolve, reject) => {
    reader.addEventListener("load", function () {
      resolve(reader.result);
    }, false);
    reader.addEventListener("error", function (event) {
      reject(event);
    },
      false);
    reader.readAsDataURL(file);
  });
  return future;
}

const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';
const image_url = 'http://localhost:4200/assets/img/logos/';

@Component({
  selector: 'bulk-add-employee',
  templateUrl: './bulk-employee.component.html',
  styleUrls: ['./bulk-employee.component.scss']
})
export class BulkEmployeeComponent implements OnInit {
  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;
  @ViewChild("fileInput") fileInput;
  myForm: FormGroup;
  fileForm: FormGroup;
  employee: EmployeeModel = new EmployeeModel({});
  badEmployee: EmployeeModel = new EmployeeModel({});


  public editStatus = true;
  public key = 'loginData';
  public myItem: any;
  public department: any = [];
  public company_id;
  public flag;
  public today;
  public status = true;
  public designation: any = [];
  public selectedFile: File = null;
  public selectedFileView: any = 'assets/img/drag-drop-img.png';
  public uploader: FileUploader = new FileUploader({ url: URL, disableMultipart: true });
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;
  public employeeStatus = false;
  public branches: any = [];


  constructor(private employeeService: EmployeeService,
    private branchService: BranchService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location) { }

  backClicked() {
    this.location.back();
  }

  ngOnInit() {
    this.employee = null;
    this.badEmployee = null;
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.route.params.subscribe(
      (params: Params) => {

        if (params.id) {
          this.company_id = params.id;
        }
        else {
          this.company_id = this.myItem.company_id;
        }
      }
    );

    this.myForm = new FormGroup({
      'department': new FormControl(null, [Validators.required]),
      'designation': new FormControl(null, [Validators.required]),
    }
      , { updateOn: 'change' });



    this.fileForm = new FormGroup({
      'fileInput': new FormControl(null, [Validators.required])
    }, { updateOn: 'change' });

    // fetch department
    this.getDepartment(this.company_id);
    this.getDeignation(this.company_id);
    this.getBranch(this.company_id);
  }

  getData(id: number) {
    this.employeeService.getItem(id).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.employee = resp.items;
      }
    );
  }

  getDepartment(company_id) {
    this.employeeService.getDepartment(company_id).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.department = resp.items;
      });
  }

  getDeignation(company_id) {
    this.employeeService.getDesignation(company_id).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.designation = resp.items;
      });
  }

  public getBranch(company_id) {
    this.branchService.get_data(company_id).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.branches = resp.items.branches;
      });
  }

  onFileChange(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  public onFileSelected(event: EventEmitter<File[]>) {

    this.selectedFile = null;

    this.selectedFileView = 'assets/img/drag-drop-img.png';
    const file: File = event[0];
    var ext = this.getFileExtension(file.name)

    if (ext == 'csv' || ext == 'text') {
      this.selectedFile = file;
      this.selectedFileView = 'assets/img/csv.png';
    }


  }

  public getFileExtension(filename) {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
  }


  public uploadCsvFile() {
    this.employeeService.uploadCsvFile(this.selectedFile).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        if (resp.items.goodEntries.length > 0) {
          this.employee = resp.items.goodEntries;
          this.myForm = new FormGroup(
            this.getObject(this.employee)
            , { updateOn: 'change' });
        }
        else {
          this.employee = null;
        }

        if (resp.items.badEntries.length > 0)
          this.badEmployee = resp.items.badEntries;
        else {
          this.badEmployee = null;
        }

        this.selectedFile = null;
        this.selectedFileView = 'assets/img/drag-drop-img.png';
        this.fileForm.reset();

      } else {

        // console.log(this.badEmployee);
      }
    });

  }

  public saveData() {
    this.employeeService.addBulkUser(this.employee, this.company_id, this.myItem.id).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.employeeService.insertSuccess();
        this.selectedFile = null;
        this.selectedFileView = 'assets/img/drag-drop-img.png';
        this.employee = null;
        this.badEmployee = null;
        this.location.back();

      } else {
        this.employeeService.csvError();
      }
    });
  }

  public clearCsvFile() {
    console.log('here');
    this.selectedFile = null;
    this.selectedFileView = 'assets/img/drag-drop-img.png';
    this.employee = null;
    this.badEmployee = null;
    this.fileForm.reset();
    this.myForm.reset();
  }

  public getObject(data) {
    var val = {};
    for (let i = 0; i < data.length; i++) {
      val['department_' + i] = new FormControl(null, [Validators.required]),
        val['designation_' + i] = new FormControl(null, [Validators.required])
      val['branch_' + i] = new FormControl(null, [Validators.required])
    }
    return val;
  }
}
