import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LeaveModel } from "./leave.model";
import 'rxjs/add/operator/toPromise';
import '../globals';
import { App_Url } from '../globals';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class LeaveService {
  constructor(private http: HttpClient,public toastr: ToastrService) { }
  public key ='loginData';
  public myItem:any;

  public getdata(id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/get_allLeave`,formData);
  }

  public getLeaveForManager(id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/getLeaveForManager`,formData);
  }

  public add(formData:any):Observable<any>
  { 
   formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/leave-add`,formData);
  }

  public update(formData:any):Observable<any>
  {
   formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/leave-update`,formData);
  }


  public getItem(id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/leave-single`,formData);
  }

  public userLeave(id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token',this.getApiToken());
  return this.http.post(App_Url+`api/userLeave`,formData);
  }
  public getLeave(userId):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('userId',userId);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/get-leave-type-with-user-leave-count`,formData);
  }

  public delete(id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/leave-delete`,formData);
  }

  public getTotalEmployeeLeaveForExportExcel(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/get-total-leave-balance-for-export`,formData);
  }

  public exportLeaveWithDateWise(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/get-total-leave-with-date`,formData);
  }
  

  insertSuccess() {
    this.toastr.success('Leave has been inserted successfully!', 'Success!');
  } 
  updateSuccess() {
    this.toastr.success('Leave has been updated successfully!', 'Success!');
  }
  
  leaveApprovedSuccessfully()
  {
    this.toastr.success('Leave has been approved successfully!', 'Success!');
  }

  getApiToken(){
    
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    var api_token = this.myItem.tokenKey;
       //var api_token ='59240171-6f5c-42e3-8d87-0044ac120184';
    return api_token ;
}

}
