import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';

import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { ClientsService } from '../../task-manager/clients/clients.service';
import { EmployeeService } from '../../employee/employee.service';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { SidebarService } from '../../shared/sidebar/sidebar.service';
import { formControlBinding } from '@angular/forms/src/directives/reactive_directives/form_control_directive';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  providers: [DatePipe]
})
export class ClientsComponent implements OnInit {

  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};

  constructor(private location: Location,
    private ClientsService: ClientsService,
    private employeeService:EmployeeService,
    private router: Router,
    private datePipe: DatePipe,
    private sidebarService: SidebarService) { }
    
  myForm: FormGroup;
  public key = 'loginData';
  public myItem: any;
  public data: any = [];
  public permission;
  public projectlist: any = [];
  public projectlists: any = [];
  client: any;
  project: any;
  is_new: any;
  no_of_hour: any;
  no_of_minute: any
  public hours: any = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09"];
  public minutes: any = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];
  marked = false;
  theCheckbox = false;
  public employeeList:any=[];


  ngOnInit() {
    this.permission = this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.myForm = new FormGroup({
      'id': new FormControl(0),
      'client': new FormControl(null, [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z][a-zA-Z ]*)?$')]),
      'client_name': new FormControl(null, [Validators.required, Validators.pattern('^(?!\d+$)(?:[a-zA-Z][a-zA-Z ]*)?$')]),
    }, { updateOn: 'change' });

    this.getClientList();

    this.dtOptions = {
      //used id for index 
      order: [[0, "DESC"]],
    };

    this.getEmployeeList();
  }



  toggleVisibility(e) {
    this.marked = e.target.checked;
  }

  public getEmployeeList()
  {
    this.employeeService.getdata(this.myItem.company_id).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }

      if (resp.success) {
        this.employeeList = resp.items;
      } else {
        this.employeeList = [];
      }

      this.rerender();
    });
  }


  public addclient() {
    let formData: FormData = new FormData();
    formData.append('client', this.myForm.get('client').value);
    formData.append('client_name', this.myForm.get('client_name').value);
    formData.append('companyId', this.myItem.company_id);
    formData.append('userId', this.myItem.id);


    if (this.myForm.get('id').value == 0) {
      this.ClientsService.add(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.ClientsService.insertSuccess();
          this.getClientList();

          this.myForm.reset();
          this.myForm.get('id').setValue(0);
        } else {
          this.ClientsService.alreadyExists();
        }
      });
    } else {

      formData.append('clientId', this.myForm.get('id').value);
      this.ClientsService.update(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.ClientsService.updateSuccess();
          this.getClientList();

          this.myForm.reset();
          this.myForm.get('id').setValue(0);

        }
      });
    }
  }

  public backtotask() {
    this.router.navigate(['employee/task-manager']);
  }

  public getClientList() {
    let myDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.ClientsService.getClientList(this.myItem.company_id, myDate).subscribe((resp: any) => {


      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp['success']) {
        this.data = resp.data;
        this.rerender();
      }
    });
  }

  backClicked() {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        // For checked fields
        // $('input[type=checkbox]', this.footer()).on('checked change', function () {
        //   if (this['value'] === 'true') {
        //     that.search(this['value']).draw();
        //   } else {
        //     that.search('').draw();
        //   }
        // });

        // $('input[type=search]', this.footer()).on('keyup change', function () {
        // //  console.log("text : " + this['value']);
        //   if (that.search() !== this['value']) {
        //     that.search(this['value']).draw();
        //   }
        // });
      });
    });
  }

  public getEdit(val) {
    this.myForm.get('id').setValue(val.id);
    this.myForm.get('client').setValue(val.clientName);

    this.myForm.get('client_name').setValue(val.clientRefName);
    //this.myForm.get('short_name').setValue(val.short_name);
  }

  public Delete(id: number, i: number) {
    if (window.confirm('Are you sure you want to delete?')) {
      this.ClientsService.delete(id).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.ClientsService.deleteSuccess();
          this.data.splice(i, 1);
          this.getClientList();
          this.rerender();
        }
      });
    }
  }

}
