import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManageLeavePolicyComponent } from './manage-leave-policy.component';


const routes: Routes = [
  {
    path: '', 
    
     children: [   
      {
        path: 'manage-leave-policy',
        component: ManageLeavePolicyComponent,
        data: {
          title: 'Manage leave policy'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManageLeavePolicyRoutingModule { }

export const ManageLeavePolicyList = [
  ManageLeavePolicyComponent
];

