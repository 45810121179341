import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ChartistModule } from 'ng-chartist';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchHeightModule } from "../../shared/directives/match-height.directive";

import {ProjectsList, ProjectsRoutingModule } from "./projects-routing.module";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';
// import { CalendarModule, DateAdapter } from '../../../../node_modules/angular-calendar';
// import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';


@NgModule({
  imports: [
    CommonModule,
    ProjectsRoutingModule,
    ChartistModule,
    NgbModule,
    MatchHeightModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    NgxDatatableModule,
    DataTablesModule,
  ],
  declarations: [
    ProjectsList,
],  
})
export class ProjectsModule { }
