import { Component, OnInit, ViewChild } from '@angular/core';
import { NewsService } from "./news.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { SidebarService } from '../shared/sidebar/sidebar.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { Location } from '@angular/common';


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private newsService: NewsService,
    private sidebarService: SidebarService,
    private location: Location,
  ) { }

  public modulePermission: any;
  public selfCountDept;
  public permission;
  public companyId;
  public key = 'loginData';
  public key1 = 'permission';
  public myItem: any;
  public data: any = [];

  ngOnInit() {

    this.permission = this.sidebarService.getCurrentPermission();
    this.modulePermission = JSON.parse(localStorage.getItem(this.key1));
    this.myItem = JSON.parse(localStorage.getItem(this.key));

    this.activatedRoute.params.subscribe(
      (params: Params) => {
        if (params.id) {
          this.companyId = params.id;
        } else {
          this.companyId = this.myItem.company_id;
        }
      }
    );


    this.getData();
  }

  public addNews() {
    this.router.navigate(['news/add-news', { companyId: this.companyId }]);
  }

  public getData() {
    let formData: FormData = new FormData();
    formData.append('company_id',this.companyId);
    formData.append('user_id',this.myItem.id);
    this.newsService.getdata(formData).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        this.data = resp.items;
        this.rerender();
      });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        // For checked fields
        $('input[type=checkbox]', this.footer()).on('checked change', function () {
          if (this['value'] === 'true') {
            that.search(this['value']).draw();
          } else {
            that.search('').draw();
          }
        });

        $('input[type=text]', this.footer()).on('keyup change', function () {
          console.log("text : " + this['value']);
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  public getEdit(id) {
    this.router.navigate(['news/add-news', id, { companyId: this.companyId }]);
  }

  public Delete(id: number, i: number) {

    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.newsService.delete(id).subscribe((resp: any) => {
          if (resp.success) {
            this.data.splice(i, 1);
            this.rerender();
            Swal.fire(
              'Deleted!',
              'News has been deleted successfully.',
              'success'
            )
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled'
        )
      }
    });
  }

  backClicked() {
    this.location.back();
  }
}
