import { Component, OnInit ,ViewChild,ViewEncapsulation,ElementRef} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { AttendanceService } from "../attendance/attendance.service";
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
//import { start } from 'repl';
import { TravelMarker, TravelMarkerOptions, TravelData, TravelEvents } from 'travel-marker';
import { Img_Url } from '../globals';
import {ExcelService} from '../service/excel.service';
import { logo_url } from '../globals';
import { element } from '../../../node_modules/@angular/core/src/render3/instructions';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';



@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttendanceComponent implements OnInit {

  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  @ViewChild('addAttendanceModal') addAttendanceModal: ElementRef;
  public key ='loginData';
  public myItem:any;
  public data:  any[] = [];
  public excelData : any[] = [];
  public employee_id ;
  public attendanceDetailDivShow = false;
  public mapDetailDivShow = false;

  public employeeIds= [];
  public employeeList:any = [];
  public employeeListAll:any = [];
  public attendanceDetail:any=[];
  public mapDetail:any=[];
  public today = this.convertDate(new Date());
  public addAttendanceModalRef;
  myForm: FormGroup;

  //public startDate = '2018-10-16';
  //public endDate = '2018-10-16';

  public startDate = this.convertDate(new Date());
  public endDate = this.convertDate(new Date());
  public startSearchDate = this.convertDate(new Date());
  public endSearchDate = this.convertDate(new Date());
  public employeeIdsSearch = []; 
  public selectedStag = {id:'',title:''};
  public tagList:any[]=[];
  public tagForDispaly=[];
  public mapClick=false;
  public mapImage;
  public opacity;
  public icon;
  public customLoadingTemplate;
  public attendanceLimit;


  public data2: any = [{
    eid: 'e101',
    ename: 'ravi',
    esal: 1000
  },
  {
    eid: 'e102',
    ename: 'ram',
    esal: 2000
  },
  {
    eid: 'e103',
    ename: 'rajesh',
    esal: 3000
  }];

  public options = {
    readonly: undefined,
    placeholder: '+ Add Name',   
    secondaryPlaceholder: '',
};
  public autocompleteItemsAsObjects:any = [];

//   public autocompleteItemsAsObjects = [
//     {value: 'Item1', id: 0, extra: 0},
//     {value: 'item2', id: 1, extra: 1},
// ];


  
  public mapdata:any;
  public companyId= '';
  public pauseDetails:  any[] = [];
  public companyName;
  public companyLogo;
  public absent;
  public present;
  public leaves;
  public weekEnds;
  public holidays;
  public loading = false;

  coordinates=[];
    // coordinates=[
    //   {lat:22.307159,lng:73.181221},
    //   {lat:22.563200,lng:72.960281},
    //   {lat:22.775680,lng:73.606770},
    //   ];
  zoom: number = 15;
  
  // initial center position for the map
  lat: number = 0;
  lng: number = 0;
  public selectedName;
  public status:boolean = false;
  public markAttendanceEmployeeId;

  constructor( private router:Router,
    private location: Location,
    private attendanceService : AttendanceService,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private excelService:ExcelService,
    private modalService: NgbModal) { }

  ngOnInit() {

    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.mapImage = Img_Url+'img/'+'map.png';
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        if(params.id){
        this.companyId = params.id;
        }
        else
        {
          this.companyId = this.myItem.company_id;
        }
        
      }
    );

   
    this.get_employeeList();
    this.getCompanyName();
    
    this.myForm = new FormGroup({
      'id': new FormControl(null),
      'punchDate': new FormControl(null, [Validators.required]),
      'inTime': new FormControl(null, [Validators.required]),
      'outTime': new FormControl(null, [Validators.required]),
      'reason': new FormControl(null, [Validators.required]),
    }, { updateOn: 'change' });
    
  }

  
  

  public get_data(id)
  {
    this.attendanceService.get_data(id).subscribe(resp=>{
      if(resp.status == 401)
      {
          this.router.navigate([this.myItem.url]);
      }
      this.data=resp.items;  
    });
  }

  public get_data_web(ids,startDate,endDate)
  {
    this.data = [];
    this.attendanceService.get_data_web(ids,startDate,endDate,this.myItem.id).subscribe(resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        this.data=resp.items.attendance;
        this.absent = resp.items.absent;
        this.present = resp.items.present;
        this.leaves = resp.items.leaves;
        this.weekEnds = resp.items.weekEnds;
        this.holidays = resp.items.holidays;
    });    
  }

  public get_employeeList()
  {
    if(this.myItem.role_id ==4)
    {
      this.attendanceService.getEmployeeForManager(this.myItem.id).subscribe(resp=>{  
          if(resp.status == 401)
          {
              this.router.navigate([this.myItem.url]);
          }
          this.employeeList=resp.items;  
          this.employeeListAll=resp.items;
          this.autocompleteItemsAsObjects=resp.items;
          this.employeeList.forEach(element => {
          this.employeeIds.push(element.id);    
           });
      });
    }
    else
    {
        this.attendanceService.employeeList(this.companyId).subscribe(resp=>{
            if(resp.status == 401)
            {
                this.router.navigate([this.myItem.url]);
            }
            this.employeeList=resp.items;  
            this.employeeListAll=resp.items;
            this.autocompleteItemsAsObjects=resp.items;
            this.employeeList.forEach(element => {
            this.employeeIds.push(element.id);
              //this.autocompleteItemsAsObjects.push({value:"'"+element.user_name+"'", id: element.id, extra: 0});
            });
          
        });
    }
  }

   

//  public exportAsXLSX():void {
//     this.excelService.exportAsExcelFile(this.data, 'sample');
//   }

  // public get_employeeData(ids)
  // {
  //   this.attendanceService.employeeList(ids).subscribe(resp=>{  
     
  //     this.employeeList=resp.items;
  //     this.employeeList.forEach(element => {
  //       this.employeeIds.push(element.id);
  //     });
  //     this.get_data_web(this.employeeIds,this.startDate,this.endDate);
  //   });   
  // }

  

  ngOnDestroy(): void {
  
  }

  

  ngAfterViewInit(): void {
  }
  
  public backClicked()
  {
    this.location.back();
  }

  public showDetailToggle(id){

    

    this.attendanceDetail=[];
    
    // if(this.attendanceDetailDivShow){     
    //   this.attendanceDetailDivShow = false;
    //   this.mapDetailDivShow = false;
        this.mapClick=false
       this.data=[];
  
    // }else{      
      this.attendanceDetailDivShow = true;
      this.mapDetailDivShow = false;
     
  //  }
    
    if(this.attendanceDetailDivShow){    
      this.attendanceDetail=this.get_data_web(id,this.startDate,this.endDate)
    }

  }

  public mapDetailToggle(i,value){

    this.data.forEach(element => {
      if(element.punchDate == value.punchDate){
       element.attendance = 'selected';
      }
      else
      {
       if(element.attendance == 'selected')
       {
        element.attendance = 'present';
       }
      }
    });

    this.mapDetail=[];
    this.mapClick=false
    this.coordinates=[];

    if(this.mapDetailDivShow){     
     // this.mapDetailDivShow = false;
    }else{      
      this.mapDetailDivShow = true;
    }
    
    if(this.mapDetailDivShow){
      this.mapDetail= this.data[i];
      this.attendanceService.getPauseDetails(this.mapDetail.userId,this.mapDetail.punchDate).subscribe(resp=>{ 
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success){
          this.pauseDetails=resp.items;
        } else {
          this.pauseDetails = [];
        }
      });   
   
    }
  }

  search(){
    
    this.startDate = this.startSearchDate;
    this.endDate = this.endSearchDate;
    this.attendanceDetailDivShow = false;
    this.mapDetailDivShow = false;
    this.data=[];
    if(this.employeeIdsSearch.length==0){
      this.get_employeeList();
    }else{
      this.employeeList=this.employeeListByArray(this.employeeIdsSearch);
    }
  }

  public convertDate(date) {
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth()+1).toString();
    var dd  = date.getDate().toString();

    var mmChars = mm.split('');
    var ddChars = dd.split('');

    return yyyy + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + (ddChars[1]?dd:"0"+ddChars[0]);
  }

  onItemAdded(tag){
    let obj = {id:'',title:''};
    if(tag.id){
      obj = {id:tag.id,title:tag.user_name};
    }else{
      obj = {id:'',title:tag.value};
    }
    this.tagList.push(obj);
    this.employeeIdsSearch=this.tagList.map(({ id }) => id)
  }


  onItemRemoved(tag){   
    var index = this.tagList.findIndex(x => x.id==tag.id);   
    this.tagList.splice(index, 1);   
    this.employeeIdsSearch=this.tagList.map(({ id }) => id)
  }

  remove_duplicates(a, b) {
    for (var i = 0, len = a.length; i < len; i++) { 
        for (var j = 0, len2 = b.length; j < len2; j++) { 
            if (a[i].id === b[j].id) {
              b.splice(j, 1);
              len2=b.length;
            }
        }
    }
  }
  
  searchDateChange(e){
     var date1 = new Date(this.startSearchDate);
     var date2 = new Date(this.endSearchDate);
     if(Date.parse(this.startSearchDate) > Date.parse(this.endSearchDate)){
      this.endSearchDate=this.startSearchDate;
     }
  } 

  employeeListByArray(idArray){
    this.attendanceService.employeeListByArray(idArray).subscribe(resp=>{
        this.employeeList=resp.items;    
        this.employeeList.forEach(element => {
        this.employeeIds.push(element.id);
        });  
    });
  }
  
  showMap(date,userId){
  
    this.mapClick=false;
    this.mapdata=[];
    this.coordinates=[];
    let formData: FormData = new FormData();
     formData.append('userId', userId);
     formData.append('date', date);
    this.attendanceService.getGeoTrakingByDate(formData).subscribe(resp=>{   
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        this.mapdata=resp;   
        if(this.mapdata.items.length > 0){
          this.mapClick=true;
          this.mapdata.items.forEach(element => {

          //this.icon = 'http://maps.google.com/mapfiles/ms/icons/pink-dot.png';

          if(element.locationAccuracy ==0)
          {
            this.icon = Img_Url+'icon/yellow.png';
            this.opacity = 0.0;
          }
          if(element.locationAccuracy ==0 && element.spotDurationSec ==0)
          {
            this.icon = Img_Url+'icon/yellow.png';
            this.opacity = 0.0;
          }
          if(element.locationAccuracy == 0 && element.spotDurationSec >= 300)
          {
            this.opacity = 1;
            this.icon = Img_Url+'icon/yellow.png';
          }
          //start
          if(element.locationAccuracy ==1)
          {
            this.opacity = 1;
            this.icon = Img_Url+'icon/green.png';
          }
          //pause 
          if(element.locationAccuracy ==2)
          {
            this.opacity = 1;
            this.icon = Img_Url+'icon/orange.png';
          }
          //resume 
          if(element.locationAccuracy ==3)
          {
            this.opacity = 1;
            this.icon = Img_Url+'icon/green-parrot.png';
          }
          //stop
          if(element.locationAccuracy ==4)
          {
            this.opacity = 1;
            this.icon = Img_Url+'icon/red.png';
          }
          //meeting 
          if(element.locationAccuracy ==5)
          {
            this.opacity = 1;
            this.icon = Img_Url+'icon/blue.png';
          }
          //task
          if (element.locationAccuracy == 7) {
            this.opacity = 1;
            this.icon = Img_Url + 'icon/skyblue.png';
          }

          this.coordinates.push({lat:element.latitude,lng:element.longitude,created_at:element.created_at,spotDurationSec:element.spotDurationSec,distance:element.distance,deviceDate:element.deviceDate,locationAccuracy:element.locationAccuracy,icon: this.icon,opacity :this.opacity});     
      });

        this.coordinates.map(function(res){
        res.lat=parseFloat(res.lat);
        res.lng=parseFloat(res.lng);
        return res;
      })
      
    
        if(this.mapdata.items.length > 0){
          this.lat=this.coordinates[0].lat;
          this.lng=this.coordinates[0].lng;
        }
      }
    });


   
  }

  reset(){

    this.attendanceDetailDivShow = false;
    this.mapDetailDivShow = false;
    this.startDate = this.convertDate(new Date());
    this.endDate = this.convertDate(new Date());
    this.startSearchDate = this.convertDate(new Date());
    this.endSearchDate = this.convertDate(new Date());
    this.employeeIdsSearch = []; 
    this.tagForDispaly=[];
    this.get_employeeList();

  }

  ConvertToString(value){
    return String(value);
  }
    public exportAsXLSXCUS(flag):void{
      this.startDate = this.startSearchDate;
      this.endDate = this.endSearchDate;
      this.loading = true;
      this.attendanceService.getAttendanceExcelData(this.startDate,this.endDate,this.companyId,flag).subscribe(resp=>{
          this.loading = false;
          this.excelData=resp;
          this.excelService.exportAsExcelFile(this.excelData, 'Attendance');
        });
    }
    public exportAsXLSX(flag):void {

      this.startDate = this.startSearchDate;
      this.endDate = this.endSearchDate;
      this.loading = true;
      // if(this.employeeIdsSearch.length !=0)
      // {
      
        
        this.attendanceService.getAttendanceExcelData(this.startDate,this.endDate,this.companyId,flag).subscribe(resp=>{
          this.loading = false;
          this.excelData=resp;
          this.excelService.exportAsExcelFile(this.excelData, 'Attendance');
        });
        // this.attendanceService.employeeListByArray(this.employeeIdsSearch).subscribe(resp=>{
        //   if(resp.status == 401)
        //   {
        //       this.router.navigate([this.myItem.url]);
        //   }
        //   this.employeeList=resp.items;
        //   this.attendanceService.getAttendanceExcelData(this.employeeList,this.startDate,this.endDate,this.companyId,flag).subscribe(resp=>{
        //   this.loading = false;
        //   this.excelData=resp;
        //   this.excelService.exportAsExcelFile(this.excelData, 'Attendance');
        // });
        // });
      // }
      // else{
      //   this.attendanceService.getAttendanceExcelData(this.employeeList,this.startDate,this.endDate,this.companyId,flag).subscribe(resp=>{
      //     if(resp.status == 401)
      //     {
      //         this.router.navigate([this.myItem.url]);
      //     }
      //     this.loading = false;
      //     this.excelData=resp;
      //     this.excelService.exportAsExcelFile(this.excelData, 'Attendance');
      //   });
      // }
  
    
  }

  // public exportAsXLSXReport():void{
  //   this.attendanceService.getAttendanceExcelDataReport(this.employeeList,this.startDate,this.endDate).subscribe(resp=>{
      
   
  //   });
  // }


  public getCompanyName()
  {
    this.attendanceService.getCompanyName(this.companyId).subscribe(resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        this.companyName = resp.items.company_name;
        this.companyLogo = resp.items.company_logo;
        this.attendanceLimit = resp.items.no_of_days_limit_for_mark_attendance;
    });
  }

  public addAttendance(value) {
    //this.getAttendanceHistory();
    this.markAttendanceEmployeeId = value.userId;
    this.myForm.get('punchDate').setValue(value.punchDate);
    this.addAttendanceModalRef = this.modalService.open(this.addAttendanceModal, { size: 'lg', windowClass: 'modal-xl' });
  }

  public editAttendance(value) {
    //this.getAttendanceHistory();
    this.markAttendanceEmployeeId = value.userId;
    this.myForm.get('inTime').setValue(this.datePipe.transform(value.inTime,'HH:mm'));
    this.myForm.get('outTime').setValue(this.datePipe.transform(value.outTime,'HH:mm'));
    this.myForm.get('reason').setValue(value.reason);
    this.myForm.get('punchDate').setValue(value.punchDate);
    this.addAttendanceModalRef = this.modalService.open(this.addAttendanceModal, { size: 'lg', windowClass: 'modal-xl' });
  }

  public saveAttendance()
  {

    let formData: FormData = new FormData();
    formData.append('userId', this.markAttendanceEmployeeId);
    formData.append('punchDate', this.myForm.get('punchDate').value);
    formData.append('inTime', this.myForm.get('punchDate').value + ' ' + this.myForm.get('inTime').value + ':00');
    formData.append('outTime', this.myForm.get('punchDate').value + ' ' + this.myForm.get('outTime').value + ':00');
    formData.append('approval_status', 'pending');
    formData.append('reason',  this.myForm.get('reason').value);
    
    this.attendanceService.addAttendance(formData).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if(resp.success){
        this.addAttendanceModalRef.dismiss();
        this.myForm.get('inTime').setValue('');
        this.myForm.get('outTime').setValue('');
        this.myForm.get('reason').setValue('');
        this.get_data_web(this.markAttendanceEmployeeId,this.startDate, this.endDate);
        this.attendanceService.insertSuccess(resp.message);
      } else {
       // this.attendanceService.alreadyExists();
      }
    });
  }

  public setTime()
  {
    if(this.myForm.get('outTime').value < this.myForm.get('inTime').value){
      this.status = true;
      this.myForm.get('outTime').setValidators;
    } else {
      this.status =false;
    }
  }

  public isAllowed(date)
  {
    let dte = new Date(this.today);
    dte.setDate(dte.getDate() - this.attendanceLimit);   
    this.convertDate(dte);
    if(this.convertDate(dte) <= date && date != this.today){
      return true;
    } else {
      return false;
    }
  }
}
  