import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
//import { DsrDemoModel } from "./dsr-demo.model";
import 'rxjs/add/operator/toPromise';
import { App_Url } from '../../globals';
import { ToastrService } from 'ngx-toastr';




@Injectable({
  providedIn: 'root'
})
export class DsrDemoService {

  constructor(private http: HttpClient,public toastr: ToastrService) { }

  public add(formData:any):Observable<any>
  {   
    return this.http.post(App_Url+`api/dsr-demo-add`,formData);
  }

  // public update(formData:any):Observable<any>
  // {
  //  //return this.http.post('http://127.0.0.1:8000/api/department?api_token=59240171-6f5c-42e3-8d87-0044ac120184',data);
  //  //return this.http.post(App_Url+`api/department?api_token=59240171-6f5c-42e3-8d87-0044ac120184`,data);
  //  return this.http.post(App_Url+`api/department-update`,formData);
  // }


  // public getdata():Observable<any>
  // {

  //  let formData: FormData = new FormData();
  //  formData.append('api_token',this.getApiToken());
  //  //return this.http.get('http://127.0.0.1:8000/api/department?api_token=59240171-6f5c-42e3-8d87-0044ac120184');
  // // return this.http.get(App_Url+`api/department?api_token=59240171-6f5c-42e3-8d87-0044ac120184`);
  //  return this.http.post(App_Url+`api/department`,formData);
  // }

  // public getItem(id):Observable<any>
  // {
  //   let formData: FormData = new FormData();
  //   formData.append('id',id);
  //   formData.append('api_token',this.getApiToken());
  //  //return this.http.get(`http://127.0.0.1:8000/api/department/${id}?api_token=59240171-6f5c-42e3-8d87-0044ac120184`);
  //  //return this.http.get(App_Url+`api/department/${id}?api_token=59240171-6f5c-42e3-8d87-0044ac120184`);
  //  return this.http.post(App_Url+`api/department-single`,formData);
  // }

  // public delete(id):Observable<any>
  // {
  //   let formData: FormData = new FormData();
  //   formData.append('id',id);
  //   formData.append('api_token',this.getApiToken());
  //  //return this.http.delete(`http://127.0.0.1:8000/api/department/${id}?api_token=59240171-6f5c-42e3-8d87-0044ac120184`);
  //  //return this.http.delete(App_Url+`api/department/${id}?api_token=59240171-6f5c-42e3-8d87-0044ac120184`);
  //  return this.http.post(App_Url+`api/department-delete`,formData);
  // }

  // public getDepartment(id):Observable<any>
  // {
  //   let formData: FormData = new FormData();
  //   formData.append('id',id);
  //   formData.append('api_token',this.getApiToken());
  //  //return this.http.get('http://127.0.0.1:8000/api/get_department?api_token=59240171-6f5c-42e3-8d87-0044ac120184');
  // // return this.http.get(App_Url+`api/get_department/${id}?api_token=59240171-6f5c-42e3-8d87-0044ac120184`);
  //  return this.http.post(App_Url+`api/get_department`,formData);
  // }

  typeSuccess() {
    this.toastr.success('Successfully!', 'Success!');
   }

   getApiToken(){
 
    // console.log(localStorage.getItem('loginData'));
    // console.log(JSON.parse(localStorage.getItem('loginData')));
    // return JSON.parse(localStorage.getItem('loginData')).tokenKey;    
    var api_token ='59240171-6f5c-42e3-8d87-0044ac120184';
    return api_token ;
   }
}
