import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { LeaveService } from "./leave.service";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TargetLocator } from '../../../node_modules/@types/selenium-webdriver';
import { LeaveModel } from "./leave.model";
import { Location, formatDate } from '@angular/common';
import { ResourceLoader } from '../../../node_modules/@angular/compiler';
import { SidebarService } from '../shared/sidebar/sidebar.service';
import {ExcelService} from '../service/excel.service';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';




@Component({
  selector: 'app-leave',
  templateUrl: './leave.component.html',
  styleUrls: ['./leave.component.scss']
})
export class LeaveComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  @ViewChild('leaveModal') private leaveModal;

  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  leave: LeaveModel = new LeaveModel({});

  public data: any[] = [];
  public key = 'loginData';
  public myItem: any;
  public company_id;
  public closeResult: string;
  public status;
  public remark;
  public leaveId;
  public permission;
  public reason;
  public leaveData: any = [];
  public modalObj: any = {};
  public selectedApprovedDates: any = [];
  public leaveModalRef;
  myForm: FormGroup;
  public LeaveStatus="employee";
  public customLoadingTemplate;
  public loading = false;

  public total_leave_balance:any = [];

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private location: Location,
    private leaveService: LeaveService,
    private sidebarService: SidebarService,
    private excelService:ExcelService,
  ) { }




  ngOnInit() {
    // this.selectedApprovedDays=['2020-01-30'];
    this.permission = this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        if (params.id) {
          this.company_id = params.id;
        }
        else {
          this.company_id = this.myItem.company_id;
        }
      }
    );

    this.dtOptions = {
      //used id for index 
      order: [[0, "DESC"]],
    };
    this.getData();
    //this.rerender();
    this.status;

    this.myForm = new FormGroup({
      
      'fromDate': new FormControl(null, [Validators.required]),
      'toDate': new FormControl(null, [Validators.required]),

    }, { updateOn: 'change' });
  }


  public getData() {
    if (this.myItem.role_id == 1) {
      this.leaveService.getdata(this.company_id).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.data = resp.items;
          this.rerender();
        }

      });
    } else if (this.myItem.role_id == 4) {
      this.leaveService.getLeaveForManager(this.myItem.id).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.data = resp.items;
          this.leaveData = resp.items;
          this.rerender();
        }
      });
    } else if (this.myItem.role_id == 2 || this.myItem.role_id == 3) {
      this.leaveService.getdata(this.company_id).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.data = resp.items;
          this.rerender();
        }
      });
    } else {
      this.leaveService.userLeave(this.myItem.id).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if(resp.success){
          this.data = resp.items;
          this.rerender();
        }
        
      });
    }
    
  }

  public addLeave() {
    this.router.navigate(['leave/addleave']);
  }

  public getEdit(id: number) {
    // console.log(id);
    this.router.navigate(['leave/addleave', id]);
  }

  public Delete(id: number, i: number) {
    if (window.confirm('Are you sure you want to delete?')) {
      this.leaveService.delete(id).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.data.splice(i, 1);
          //   this.rerender();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    if (this.datatableElement.dtInstance) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

        // For checked fields
        $('input[type=checkbox]', this.footer()).on('checked change', function () {
          if (this['value'] === 'true') {
            that.search(this['value']).draw();
          } else {
            that.search('').draw();
          }
        });

        $('input[type=text]', this.footer()).on('keyup change', function () {
          //  console.log("text : " + this['value']);
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
    //this.rerender();
  }

  public approvalStatus(event) {
    this.status = event.target.value;

    if (this.status != 1) {
      this.selectedApprovedDates = [];
    }
    console.log(this.selectedApprovedDates);

  }
  public leaveRemark(event) {
    this.remark = event.target.value;
  }

  openLeaveModel(obj) {
    this.selectedApprovedDates = [];
    this.status = obj.approvalStatus;
    this.leaveId = obj.id;
    this.remark = obj.remark;
    this.reason = obj.reason;

    this.leaveBalance(obj);
    // this.modalObj = obj;
    this.leaveService.getItem(obj.id).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.modalObj = resp.items;
        this.modalObj.first_name = obj.first_name +' '+ obj.last_name;
        this.modalObj.leaveType = obj.leaveType;
        this.selectedApprovedDates = this.modalObj.approvedDates;
        this.leaveModalRef = this.modalService.open(this.leaveModal, { size: 'lg', windowClass: 'modal-xl' });
      }
    });
  }

  public leaveBalance(obj) {
    this.leaveService.getLeave(obj.userId).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
     this.total_leave_balance = resp.items;
    });
  }


  public onSubmit() {
    let formData: FormData = new FormData();
    // formData.append('api_token','59240171-6f5c-42e3-8d87-0044ac120184');
    formData.append('id', String(this.leaveId));
    formData.append('remark', this.remark);
    formData.append('approvedDates', this.selectedApprovedDates);
    formData.append('approvalStatus', String(this.status));
    formData.append('approvedBy', String(this.myItem.id));

    this.leaveService.update(formData).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        //location.reload();
        this.leaveService.leaveApprovedSuccessfully();
        this.getData();
        //  this.router.navigate(['employee/leave']);
      }

    });
  }

  public displayLeave(flag) {
    if (flag == 'self') {
      this.LeaveStatus = 'self';
      let filterData;
      filterData = this.leaveData.filter(element => element.userId == this.myItem.id);
      this.data = filterData;
      this.rerender();
    }

    if (flag == 'employee') {
      this.LeaveStatus = 'employee';
      let filterData;
      filterData = this.leaveData.filter(element => element.userId != this.myItem.id);
      this.data = filterData;
      this.rerender();
    }
  }

  public updateApprovedDays(e, i) {

    var index = this.selectedApprovedDates.indexOf(e.target.value);
    if (e.target.checked) {
      if (index < 0) {
        this.selectedApprovedDates.push(e.target.value);

      }
    } else {
      this.selectedApprovedDates.splice(index, 1);
    }
  }

  public getSelectedApprovedDates(day) {
    // console.log(day);
    // console.log(this.selectedApprovedDates);
    if (this.selectedApprovedDates.length) {
      if (this.selectedApprovedDates.indexOf(day) < 0) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  public CurrentYearLeaveExportAsXLSX()
  {
    this.loading = true;
    let formData: FormData = new FormData();
    formData.append('user_id', this.myItem.id);
    formData.append('role_id', this.myItem.role_id);
    formData.append('company_id', this.myItem.company_id);
    
    this.leaveService.getTotalEmployeeLeaveForExportExcel(formData).subscribe(resp=>{
      if(resp.status == 401)
      {
          this.router.navigate([this.myItem.url]);
      }     
      if(resp.success){
        this.loading = false;
        this.excelService.exportAsExcelFile(resp.items, 'Current_year_leave_policy_status');
      } else {
        this.loading = false;
      }
    });
  }

  public exportLeaveWithDateWise()
  {
    let formData: FormData = new FormData();
    //formData.append('id', id);
    formData.append('user_id', this.myItem.id);
    formData.append('role_id', this.myItem.role_id);
    formData.append('company_id', this.myItem.company_id);
    formData.append('fromDate', this.myForm.get('fromDate').value);
    formData.append('toDate', this.myForm.get('toDate').value);
    formData.append('flag', this.LeaveStatus);

    this.leaveService.exportLeaveWithDateWise(formData).subscribe(resp=>{
      if(resp.status == 401)
      {
          this.router.navigate([this.myItem.url]);
      }
      if(resp.success){
        this.excelService.exportAsExcelFile(resp.items, 'Leave_report');
      }
    });
  }

  public changeDate()
  {
    this.myForm.get('toDate').setValue(this.myForm.get('fromDate').value);
  }
    

}
