import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule'
  },
  {
    path: 'employee',
    loadChildren: './employee/employee.module#EmployeeModule'
  },
  {
    path: 'client',
    loadChildren: './client/client.module#ClientModule'
  },
  {
    path: 'customer',
    loadChildren: './customer/customer.module#CustomerModule'
  },
 
  {
    path: 'departments',
    loadChildren: './departments/departments.module#DepartmentsModule'
  },
  {
    path: 'module',
    loadChildren: './module/module.module#ModuleModule'
  },
  {
    path: 'leave',
    loadChildren: './leave/leave.module#LeaveModule'
  },
  {
    path: 'document-category',
    loadChildren: './document-category/document-category.module#DocumentCategoryModule'
  },
  {
    path: 'document',
    loadChildren: './document/document.module#DocumentModule'
  },
  {
    path: 'meeting',
    loadChildren: './meeting/meeting.module#MeetingModule'
  },
  {
    path: 'attendance',
    loadChildren: './attendance/attendance.module#AttendanceModule'
  },
  {
    path: 'designation',
    loadChildren: './designation/designation.module#DesignationModule'
  },
  {
    path: 'manager',
    loadChildren: './manager/manager.module#ManagerModule'
  },
  {
    path: 'charts',
    loadChildren: './charts/charts.module#ChartsNg2Module'
  },
   {
    path: 'forms',
    loadChildren: './forms/forms.module#FormModule'
  },
  {
    path: 'maps',
    loadChildren: './maps/maps.module#MapsModule'
  },
  {
    path: 'tables',
    loadChildren: './tables/tables.module#TablesModule'
  },
  {
    path: 'datatables',
    loadChildren: './data-tables/data-tables.module#DataTablesModule'
  },
  {
    path: 'uikit',
    loadChildren: './ui-kit/ui-kit.module#UIKitModule'
  },
  {
    path: 'components',
    loadChildren: './components/ui-components.module#UIComponentsModule'
  },
  {
    path: 'pages',
    loadChildren: './pages/full-pages/full-pages.module#FullPagesModule'
  },
  {
    path: 'taskboard-ngrx',
    loadChildren: './taskboard-ngrx/taskboard-ngrx.module#TaskboardNGRXModule'
  },
  {
    path: 'salaryslip-template',
    loadChildren: './salaryslip-template/salaryslip-template.module#SalaryslipTemplateModule'
  },
  {
    path: 'manage-holidays',
    loadChildren: './manage-holidays/manage-holidays.module#ManageHolidaysModule'
  },
  {
    path: 'leave-type',
    loadChildren: './leave-type/leave-type.module#LeaveTypeModule'
  },
 {
  path:'task-manager',
  loadChildren: './task-manager/task-manager.module#TaskManagerModule'
 },
  {
    path: 'manage-leave-policy',
    loadChildren: './manage-leave-policy/manage-leave-policy.module#ManageLeavePolicyModule'
  },
  {
    path: 'branch',
    loadChildren: './branch/branch.module#BranchModule'
  },
  {
    path: 'news',
    loadChildren: './news/news.module#NewsModule'
  }
];