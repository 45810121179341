import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PermissionComponent } from "./permission.component";




const routes: Routes = [
  {
    path: '',  
    
    children: [   
  
      {
        path: 'permission',
        component: PermissionComponent,
        data: {
          title: 'module-permission'
        }
      },
     
     
    //  for subclient
      {
        path: 'module-permission/:id',
        component: PermissionComponent,
        data: {
          title: 'module-permission'
        }
       },
     
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PermissionRoutingModule { }

export const PermissionComponentList = [
  PermissionComponent
];

