import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {EmpTaskComponent} from './emp-task.component';

const routes: Routes = [
  {
    path: '', 
    
     children: [   
      {
        path: 'emp-task/:Id',
        component: EmpTaskComponent,
        data: {
          title: 'emp-task'
        }
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  
})
export class EmpTaskRoutingModule { }
export const EmpTaskList = [
  EmpTaskComponent
];
