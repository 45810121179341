import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { ProjectsService } from '../../task-manager/projects/projects.service';
import { ClientsService } from '../../task-manager/clients/clients.service';
import { TaskManagerService } from '../../task-manager/task-manager.service';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { SidebarService } from '../../shared/sidebar/sidebar.service';
import { formControlBinding } from '@angular/forms/src/directives/reactive_directives/form_control_directive';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'


@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  providers: [DatePipe]
})
export class ProjectsComponent implements OnInit {
  @ViewChild("addCostModal") addCostModal;
  @ViewChild("addAdditionalCostModal") addAdditionalCostModal;

  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  projectName: any;

  constructor(private location: Location,
    private ProjectsService: ProjectsService,
    private TaskManagerService: TaskManagerService,
    private ClientsService: ClientsService,
    private router: Router,
    private datePipe: DatePipe,
    private sidebarService: SidebarService,
    private modalService: NgbModal) { }

  myForm: FormGroup;
  addCostForm: FormGroup;
  addAdditionalCostForm: FormGroup;
  public key = 'loginData';
  public myItem: any;
  public data: any = [];
  public permission;
  public clientlist: any = [];
  public projectlists: any = [];
  client: any;
  project: any;
  is_new: any;
  no_of_hour: any;
  no_of_minute: any
  public hours: any = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09"];
  public minutes: any = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];
  marked = false;
  theCheckbox = false;
  public addCostRef;
  public projectCost:any=[];
  public addAdditionalCostRef;
  public additionalCost: any = [];
  public additionalCostFilter: any = [];
  public costEditStatus: boolean = false;

  ngOnInit() {
    this.permission = this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.myForm = new FormGroup({
      'id': new FormControl(0),
      'client': new FormControl('', [Validators.required]),
      'project_name': new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z]+[a-zA-Z0-9_ ]*$')]),
    }, { updateOn: 'change' });


    this.addCostForm = new FormGroup({
      'id': new FormControl(0),
      'cost': new FormControl('', [Validators.required]),
      'date': new FormControl('', [Validators.required]),
      'project_id': new FormControl(null),
    }, { updateOn: 'change' });

    this.addAdditionalCostForm = new FormGroup({
      'id': new FormControl(0),
      'service_name': new FormControl(null, [Validators.required]),
      'cost': new FormControl(null, [Validators.required, Validators.pattern('^(?!\d+$)(?:[0-9][0-9 ,]*)?$')]),
      'project_id': new FormControl(null),
    }, { updateOn: 'change' });


    this.TaskManagerService.getClientList(this.myItem.company_id).subscribe(resp => {
      this.clientlist = resp.data;
    });

    this.getProjectList();

    this.dtOptions = {
      //used id for index 
      order: [[0, "ASC"]],
    };
  }

  toggleVisibility(e) {
    this.marked = e.target.checked;
  }
  public backtotask() {
    this.router.navigate(['employee/task-manager']);
  }

  public formReset() {
    this.myForm.get('id').setValue(0);
    this.myForm.get('project_name').setValue('');
    this.myForm.get('client').setValue('');
  }
  public addproject() {
    let formData: FormData = new FormData();
    formData.append('client', this.myForm.get('client').value);
    formData.append('project_name', this.myForm.get('project_name').value);
    formData.append('companyId', this.myItem.company_id);
    formData.append('userId', this.myItem.id);


    if (this.myForm.get('id').value == 0) {
      this.ProjectsService.add(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.ProjectsService.insertSuccess();
          this.getProjectList();
          //this.formReset();
          this.myForm.reset();
          this.myForm.get('id').setValue(0);
          this.myForm.get('client').setValue('');
        } else {
          this.ProjectsService.alreadyExists();
        }
      });
    } else {

      formData.append('id', this.myForm.get('id').value);
      this.ProjectsService.update(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.ProjectsService.updateSuccess();
          this.getProjectList();
          //this.formReset();
          this.myForm.reset();
          this.myForm.get('id').setValue(0);
          this.myForm.get('client').setValue('');
        }
      });
    }
  }

  public getProjectList() {
    let myDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.ProjectsService.getProjectList(this.myItem.id, myDate).subscribe((resp: any) => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp['success']) {
        this.data = resp.data;
        this.rerender();
      }
    });
  }

  backClicked() {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;

      });
    });
  }

  public getEdit(val) {
    console.log(val);
    this.myForm.get('id').setValue(val.id);
    this.myForm.get('client').setValue(val.clientId);
    //this.client=val.clientId;   
    this.myForm.get('project_name').setValue(val.name);
  }

  public Delete(id: number, i: number) {
    if (window.confirm('Are you sure you want to delete?')) {
      this.ProjectsService.delete(id).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.ProjectsService.deleteSuccess();
          this.data.splice(i, 1);
          this.getProjectList();
          this.rerender();
        }
      });
    }
  }

  public addCost(val) {
    this.projectName = val.name;
    this.addCostForm.get('project_id').setValue(val.id);
    this.costEditStatus = false;
    this.getPorjectCost();
    this.addCostRef = this.modalService.open(this.addCostModal, { size: 'lg' });
  }

  public saveCost() {
    let formData: FormData = new FormData();
    formData.append('cost', this.addCostForm.get('cost').value);
    formData.append('project_id', this.addCostForm.get('project_id').value);
    formData.append('id', this.addCostForm.get('id').value);
    formData.append('date', this.addCostForm.get('date').value);
    formData.append('created_by', this.myItem.id);
    this.ProjectsService.insertProjectCost(formData).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        //this.addCostRef.dismiss();
        this.getPorjectCost();
        this.addCostForm.get('id').setValue('0');
        this.addCostForm.get('date').setValue('');
        this.addCostForm.get('cost').setValue('');
        this.costEditStatus = false;

      }
    });
  }

  public getPorjectCost()
  {
    this.ProjectsService.getProjectCost(this.addCostForm.get('project_id').value).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.projectCost = resp.items;
        
      }
    });
  }

  public editProjectCost(val) {
    this.costEditStatus = true;
    this.addCostForm.get('id').setValue(val.id);
    this.addCostForm.get('date').setValue(val.date);
    this.addCostForm.get('cost').setValue(val.cost);
  }

  public deleteProjectCost(id: number, i: number) {

    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.ProjectsService.deleteProjectCost(id).subscribe((resp: any) => {
          if (resp.success) {
            this.projectCost.splice(i, 1);
            Swal.fire(
              'Deleted!',
              'Project cost has been deleted successfully.',
              'success'
            )
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled'
        )
      }
    });
  }

  public addAdditionalCost(val) {
    this.projectName = val.name;
    this.addAdditionalCostForm.reset();
    this.addAdditionalCostForm.get('id').setValue(0);
    this.costEditStatus = false;
    this.addAdditionalCostForm.get('project_id').setValue(val.id);
    this.getAdditionalCost();
    this.addAdditionalCostRef = this.modalService.open(this.addAdditionalCostModal, { size: 'lg' });
  }

  public insertAdditionalCost() {
    let formData: FormData = new FormData();
    formData.append('id', this.addAdditionalCostForm.get('id').value);
    formData.append('cost', this.addAdditionalCostForm.get('cost').value);
    formData.append('project_id', this.addAdditionalCostForm.get('project_id').value);
    formData.append('service_name', this.addAdditionalCostForm.get('service_name').value);
    formData.append('created_by', this.myItem.id);
    this.ProjectsService.insertAdditionalProjectCost(formData).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.addAdditionalCostForm.get('service_name').setValue('');
        this.addAdditionalCostForm.get('cost').setValue('');
        this.getAdditionalCost();
      }
    });
  }

  public getAdditionalCost() {
    this.ProjectsService.getProjectAdditionalCost(this.addAdditionalCostForm.get('project_id').value).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.additionalCost = resp.items;
        this.additionalCostFilter = resp.items;
      }
    });
  }

  public Search(e) {
    let term = e.target.value.toLowerCase();
    this.additionalCostFilter = this.additionalCost.filter(function (val) {
      const service_name = val.service_name.toLowerCase().includes(term);
      return service_name;
    });
  }

  public editAdditionalCost(val) {
    this.costEditStatus = true;
    this.addAdditionalCostForm.get('id').setValue(val.id);
    this.addAdditionalCostForm.get('service_name').setValue(val.service_name);
    this.addAdditionalCostForm.get('cost').setValue(val.cost);
  }
  
  public deleteAdditionalCost(id: number, i: number) {

    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.ProjectsService.deleteAdditionalCost(id).subscribe((resp: any) => {
          if (resp.success) {
            this.additionalCostFilter.splice(i, 1);
            this.rerender();
            Swal.fire(
              'Deleted!',
              'Cost has been deleted successfully.',
              'success'
            )
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled'
        )
      }
    });
  }

}
