import { Component, OnInit } from '@angular/core';
import { ClientService } from "../client/client.service";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { PayrollRelationsModel } from "./payroll-relations.model";
import { Router, ActivatedRoute, Params  } from "@angular/router";
@Component({
  selector: 'app-payroll-relations',
  templateUrl: './payroll-relations.component.html',
  styleUrls: ['./payroll-relations.component.scss']
})
export class PayrollRelationsComponent implements OnInit {
  myForm:FormGroup;
  permission:PayrollRelationsModel = new PayrollRelationsModel({});
  constructor(private clientService : ClientService,
    private location: Location,
    private route: ActivatedRoute,
    private router:Router,) { }

  public module:any = [];
  public selectedModule:any;
  public userId ;
  public permissionData ;
  public key ='loginData';
  public myItem:any;
  public company_id;
  public clientName;
  public parentId;
  public headerStatus = false;
  public customerHeader = false;
  public moduleNotFound = false;
  ngOnInit() {
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.selectedModule=[];
    this.myForm = new FormGroup({
      'payrolls_id': new FormControl(null, [Validators.required]),
      
  }, {updateOn: 'change'});
  this.route.params.subscribe(
    (params: Params) => {
      this.company_id =params.id; 
      this.headerStatus = true;
    });
    this.getClientName();

  }

  public getClientName()
  {
    let formData: FormData = new FormData();
    formData.append('company_id',this.company_id);
    this.clientService.getClientName(formData).subscribe(
      resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success){
          this.clientName = resp.items.company_name;  
          this.parentId = resp.items.parent_id;   
          this.getMdule();
        }
      }
    );
  }

  public onSubmit()
  {
  
     let formData: FormData = new FormData();
     formData.append('data' ,JSON.stringify(this.module));
     formData.append('companyId' ,this.company_id);
     this.clientService.addPayrollModulePermission(formData).subscribe(resp=>{
      if(resp.status == 401)
      {
          this.router.navigate([this.myItem.url]);
      }
      if(resp.success) { 
        this.clientService.permissionAssign();
        this.getMdule();
      }
    });

  }

  public getMdule()
   {

    let formData: FormData = new FormData();
    formData.append('company_id',this.company_id);
    formData.append('id',this.parentId);
     this.clientService.getpayrollModule(formData).subscribe(
       resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
         this.module = resp.items;
         if(this.module.length == 0){
           this.moduleNotFound = true;
         }
        }
     );

   }

   public backClicked() 
   {
    this.location.back();
   }


  updateStatus(e,id){   
    if(e.target.checked){
      this.module[id].permission=1; 
    }else{
      this.module[id].permission=0;
    }
    this.module[id].created_by= this.myItem.id;
  }

   

}
