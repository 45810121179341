import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { DepartmentsModel } from "../departments.model";
import { DepartmentsService } from "../departments.service";

@Component({
  selector: 'app-add-deparments',
  templateUrl: './add-deparments.component.html',
  styleUrls: ['./add-deparments.component.scss']
})
export class AddDeparmentsComponent implements OnInit {
  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;
  myForm: FormGroup;
  departments: DepartmentsModel = new DepartmentsModel({});


  constructor(private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private departmentsService: DepartmentsService) { }

  public editStatus = true;
  public key = 'loginData';
  public myItem: any;
  public depertment_status;
  public companyId;

  backClicked() {
    this.location.back();
  }

  ngOnInit() {
    this.myItem = JSON.parse(localStorage.getItem(this.key));

    this.route.params.subscribe(
      (params: Params) => {
        if (params.id) {
          this.editStatus = true;
          this.getData(params.id);
        } else {
          this.editStatus = false;
        }

        if (params.companyId) {
          this.companyId = params.companyId;
        }
        else {
          this.companyId = this.myItem.company_id;
        }
      }
    );

    this.myForm = new FormGroup({
      'department_name': new FormControl('', [Validators.required,Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/+_=]*)?$')]),
      'status': new FormControl(1),
      'id': new FormControl(0),
      'counts': new FormControl(0),
      'company_id': new FormControl(null),

    }, { updateOn: 'change' });
  }

  public onSubmit() {

    let formData: FormData = new FormData();
    formData.append('created_by', this.myItem.id);
    formData.append('status', this.myForm.get('status').value);
    formData.append('company_id', this.companyId);
    if (this.myForm.get('id').value !=0) {
      formData.append('company_id', this.myForm.get('company_id').value);
    }
    formData.append('department_name', this.myForm.get('department_name').value);
    formData.append('id', this.myForm.get('id').value);

    if (this.myForm.get('id').value == 0) {     
      this.departmentsService.add(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.departmentsService.insertSuccess();
          this.location.back();
        }
        else {
          this.departmentsService.sameDeprtmentName();
          //this.router.navigate(['departments/adddepartments']);
        }
      });
    }
    else {
      this.departmentsService.update(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.departmentsService.updateSuccess();
          this.location.back();
        }
        else {
          this.router.navigate(['departments/adddepartments']);
        }
      });
    }
  }

  public getData(id: number) {
    this.departmentsService.getItem(id).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        //this.departments = resp.items;
        this.myForm.get('id').setValue(resp.items.id);
        this.myForm.get('status').setValue(resp.items.status);
        this.myForm.get('department_name').setValue(resp.items.department_name);
        this.myForm.get('counts').setValue(resp.items.counts);
        this.myForm.get('company_id').setValue(resp.items.company_id);
      }
    );
  }



}
