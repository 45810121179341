import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { BrowserModule } from '@angular/platform-browser';

import { ChartistModule } from 'ng-chartist';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchHeightModule } from "../shared/directives/match-height.directive";

import { CustomerRoutingModule ,CustomerComponentList } from "./customer-routing.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { DataTablesModule } from 'angular-datatables';
import { ManagerModule } from "../manager/manager.module";
import { DepartmentsModule } from "../departments/departments.module";
import { DesignationModule } from "../designation/designation.module";
import { ModulePermissionModule } from "../module-permission/module-permission.module";
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { CustomerProfileComponent } from './customer-profile/customer-profile.component';
import {CustomerHeaderModule} from '../customer-header/customer-header.module';
import {ClientHeaderModule} from '../client-header/client-header.module';
//import { EmployeeModule } from '../employee/employee.module';
import { PermissionModule } from "../permission/permission.module";
import { SharedModule } from '../shared/shared.module';
import {ManageHolidaysModule} from '../manage-holidays/manage-holidays.module';
import { BranchModule } from '../branch/branch.module';
import { UiSwitchModule } from 'ngx-ui-switch';

@NgModule({
    imports: [
        CommonModule,
        CustomerRoutingModule,
        ChartistModule,
        NgbModule,
        MatchHeightModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgxDatatableModule,
        Ng2SmartTableModule,
        DataTablesModule,
        ManagerModule,
        DepartmentsModule,
        DesignationModule,
        ModulePermissionModule,
        CustomerHeaderModule,
        ClientHeaderModule,
        PermissionModule,
        SharedModule,
        ManageHolidaysModule,
        BranchModule,
        UiSwitchModule
        //EmployeeModule
    ],   
    exports: [],
    declarations: [
        CustomerComponentList,
        AddCustomerComponent,
        CustomerProfileComponent,
        
        
    ],  
    providers: [], 
})
export class CustomerModule { }
