import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DsrDemoComponent } from './dsr-demo/dsr-demo.component';
//import { AddDocumentCategoryComponent } from './add-document-category/add-document-category.component';





const routes: Routes = [
  {
    path: 'dsr',  
    
    children: [   
      {
        path: 'dsr-demo/:id',
        component: DsrDemoComponent,
        data: {
          title: 'Daily Sales Report'
        }
      }     
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DsrRoutingModule { }

export const DsrComponentList = [
  DsrDemoComponent,
 
];

