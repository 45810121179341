import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ChartistModule } from 'ng-chartist';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchHeightModule } from "../shared/directives/match-height.directive";

import { DriverDeliveryRoutingModule ,DriverDelivreryComponentList } from "./driver-delivery-routing.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';
import { TagInputModule } from 'ngx-chips';
import { AgmCoreModule } from '@agm/core';
import { NgxLoadingModule } from 'ngx-loading';


@NgModule({
    imports: [
        CommonModule,
        DriverDeliveryRoutingModule,
        ChartistModule,
        NgbModule,
        MatchHeightModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgxDatatableModule,
        DataTablesModule,
        TagInputModule,
        AgmCoreModule.forRoot({
            // please get your own API key here:
            // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
            apiKey: 'AIzaSyArm30p8Oth1BKvWdWyM25r4rWyEYoNCUw'
          }),
        NgxLoadingModule.forRoot({})
    ],   
    exports: [],
    declarations: [
        DriverDelivreryComponentList,        
    ],  
    providers: [], 
})
export class DriverDeliveryModule{ }
