import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import { App_Url } from '../globals';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient,public toastr: ToastrService) { }
  public key ='loginData';
  public myItem:any;

  public getEmployeeDocument(formData:any):Observable<any>
  {  
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/employee-document-get`,formData);
  }

  // new
  

  public insertEmployeeDocument(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/employee-document-insert`,formData);
  }

  public updateEmployeeDocument(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/employee-document-update`,formData);
  } 

  public getOrganizationDocument(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/organization_document-get`,formData);
  }

  public insertOrganizationDocument(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/organization-document-insert`,formData);
  }

  public updateOrganizationDocument(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/organization-document-update`,formData);
  }

  public delete(id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/document-delete`,formData);
  }

  insertSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  uccess() {
    this.toastr.success('Document has been updated successfully', 'Success!');
  }
  deleteSuccess() {
    this.toastr.success('Document has been deleted successfully!', 'Success!');
  }

  getApiToken(){
   // var api_token ='59240171-6f5c-42e3-8d87-0044ac120184';
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    var api_token = this.myItem.tokenKey;
    return api_token ;
   }
}
