import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ChartistModule } from 'ng-chartist';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchHeightModule } from "../shared/directives/match-height.directive";

import { EmployeeRoutingModule ,EmployeeComponentList } from "./employee-routing.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { ClientModule } from "../client/client.module";
import { DepartmentsModule } from "../departments/departments.module";
import { ModuleModule } from "../module/module.module";
import { LeaveModule } from "../leave/leave.module";
import { DocumentCategoryModule } from  "../document-category/document-category.module";
import { DocumentModule } from "../document/document.module";
import { MeetingModule } from "../meeting/meeting.module";
import { AttendanceModule } from "../attendance/attendance.module";
import { DataTablesModule } from 'angular-datatables';
import { ManagerModule } from "../manager/manager.module";
import { SharedModule } from "../shared/shared.module";
import { DesignationModule } from "../designation/designation.module";
import { CurrencyInputModule } from '../currency-input-mask';
import { DpDatePickerModule } from 'ng2-date-picker';
import { ManageHolidaysModule } from '../manage-holidays/manage-holidays.module';
import { LeaveTypeModule } from '../leave-type/leave-type.module';
import { ManageLeavePolicyModule } from '../manage-leave-policy/manage-leave-policy.module';
import {TaskManagerModule} from '../task-manager/task-manager.module';
import {TaskViewModule} from '../task-manager/task-view/task-view.module';
import {EmpTaskModule} from '../task-manager/emp-task/emp-task.module';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NewsModule } from '../news/news.module';
import { DriverDeliveryModule } from '../driver-delivery/driver-delivery.module';

@NgModule({
    imports: [
        CommonModule,
        EmployeeRoutingModule,
        ChartistModule,
        NgbModule,
        ClientModule,
        DepartmentsModule,
        ModuleModule,
        LeaveModule,
        DocumentCategoryModule,
        DocumentModule,
        MeetingModule,
        AttendanceModule,
        ManagerModule,
        MatchHeightModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgxDatatableModule,
        DataTablesModule,
        SharedModule,
        DesignationModule,
        CurrencyInputModule,
        DpDatePickerModule,
        ManageHolidaysModule,
        LeaveTypeModule,
        ManageLeavePolicyModule,
        TaskManagerModule,
        TaskViewModule,
        EmpTaskModule,
        UiSwitchModule,
        NewsModule,
        DriverDeliveryModule
    ],   
    exports: [],
    declarations: [
        EmployeeComponentList
        
    ],  
    providers: [], 
})
export class EmployeeModule{ }
