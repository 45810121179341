import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { App_Url } from '../globals';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private http: HttpClient,public toastr: ToastrService) { }
  public key ='loginData';
  public myItem:any;
  
   public getModuleForEmployee(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/getModuleForEmployee`,formData);
  }
 
  public add(formData:any):Observable<any>
  {  
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/permission-add`,formData);
  }

  permissionAssign() {
    this.toastr.success('Permission has been assigned successfully!', 'Success!');
  } 

  public getUserPermission(id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('userId',id);
    formData.append('api_token',this.getApiToken());
  return this.http.post(App_Url+`api/permission`,formData);
  }

  getApiToken(){
   // var api_token ='59240171-6f5c-42e3-8d87-0044ac120184';
   this.myItem = JSON.parse(localStorage.getItem(this.key));
   var api_token = this.myItem.tokenKey; 
   return api_token ;
   }
}
