import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import { App_Url } from '../globals';
import { ToastrService } from 'ngx-toastr';




@Injectable({
  providedIn: 'root'
})
export class SalaryslipTemplateService {
  constructor(private http: HttpClient, public toastr: ToastrService) { }
  public key = 'loginData';
  public myItem: any;
  public userId;
  public monthYear;

  public add(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/salaryslipTemplate-add`, formData);
  }

  public update(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/salaryslipTemplate-update`, formData);
  }


  public getdata(): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/getSalaryslipTemplateList`, formData);
  }

  public getClients(templateId): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('templateId', templateId);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/getClientsForTemplateAssign`, formData);
  }

  public assignTemplate(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/assignTemplate`, formData);
  }


  public getItem(id): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/getSalaryslipTemplate-single`, formData);
  }

  public delete(id): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/salaryslipTemplate-delete`, formData);
  }

  public checkDepartment(name): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('name', name);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/checkDepartment`, formData);
  }

  // user salary list
  // public salaryslip(userId):Observable<any>
  // {
  //  let formData: FormData = new FormData();
  //  formData.append('userId',userId);
  //  formData.append('api_token',this.getApiToken());
  //  return this.http.post(App_Url+`api/getSalaryslip`,formData);
  // }

  public salaryslip(userId): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('userId', userId);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/getSalaryslipFromDocument`, formData);
  }

  // public getSalaryData(userId,date):Observable<any>
  // {
  //  let formData: FormData = new FormData();
  //  formData.append('userId',userId);
  //  formData.append('date',date);
  //  formData.append('api_token',this.getApiToken());
  //  return this.http.post(App_Url+`api/getSalaryData`,formData);
  // }

  public getSalaryData(id): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/getSingleSalaryslip`, formData);
  }


  //csv

  public uploadSalaryCsvFile(file): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('api_token', this.getApiToken());
    formData.append('userFile', file);
    return this.http.post(App_Url + `api/upload-salary-csv-file`, formData);
  }

  public uploadSalarydataFile(file):Observable<any> {
    let formData: FormData = new FormData();
    formData.append('api_token', this.getApiToken());
    formData.append('userFile', file);
    return this.http.post(App_Url + `api/upload-salary-data-file`, formData);
  }

  public addBulkSalary(data): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('api_token', this.getApiToken());
    formData.append('created_by', this.myItem.id);
    formData.append('data', JSON.stringify(data));
    return this.http.post(App_Url + `api/addBulkSalary`, formData);
  }

  public salarySlipPreview(companyId): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('api_token', this.getApiToken());
    formData.append('companyId', companyId);
    return this.http.post(App_Url + `api/salarySlipPreview`, formData);
  }

  public checkSalaryslipFileExistsOrNot(companyId): Observable<any> 
  {
    let formData: FormData = new FormData();
    formData.append('api_token', this.getApiToken());
    formData.append('companyId',companyId);
    return this.http.post(App_Url + `api/checkSalaryslipFileExistsOrNot`, formData);
  }


  //end csv


  Success() {
    this.toastr.success('Template has been assigned successfull.', 'Success!');
  }
  Fail() {
    this.toastr.error('', 'Please select atleast one record.', { "timeOut": 2000 });
  }

  insertSuccess() {
    this.toastr.success('Salary has been uploaded successfully.', 'Success!');
  }

  updateSuccess() {
    this.toastr.success('Template has been updated successfully.', 'Success!');
  }
  deleteSuccess() {
    this.toastr.success('Template has been deleted successfully.', 'Success!');
  }

  sameDeprtmentName() {
    this.toastr.error('', 'Please Enter Another Template Name.', { "timeOut": 1000 });
  }

  getApiToken() {

    this.myItem = JSON.parse(localStorage.getItem(this.key));
    var api_token = this.myItem.tokenKey;
    // var api_token ='59240171-6f5c-42e3-8d87-0044ac120184';
    return api_token;
  }


  public downloadPdf(fd): Observable<any> {
    fd.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/download-salary-slip`, fd);
  }
}
