import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { App_Url } from '../globals';
import { ToastrService } from 'ngx-toastr';
//import { environment } from '../../environments/environment.prod';



@Injectable({
  providedIn: 'root'
})


export class BranchService {
  
  constructor(private http: HttpClient,public toastr: ToastrService) { }
   public key ='loginData';
   public myItem:any;

   public add(formData:any):Observable<any>
  { 
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/branch-add`,formData);
  }

  public update(formData:any):Observable<any>
  { 
   formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/branch-update`,formData);
  }
  
  public get_data(companyId):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('companyId', companyId);
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+`api/branch-get`,formData);

  }

  public getItem(id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/branch-single`,formData);
  }

  public delete(id):Observable<any>
  {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/branch-delete`,formData);
  }

  insertSuccess() {
    this.toastr.success('Branch has been inserted successfully!', 'Success!');
  } 

  updateSuccess() {
    this.toastr.success('Branch has been updated successfully!', 'Success!');
  }
  deleteSuccess() {
    this.toastr.success('Branch has been deleted successfully!', 'Success!');
  }

  permissionAssign() {
    this.toastr.success('Permission has been assigned successfully!', 'Success!');
  }

  public getModule(formData:any):Observable<any>
  {
    formData.append('api_token',this.getApiToken());
  return this.http.post(App_Url+`api/get_module`,formData);
  }
  
  public checkBranchEmail(branch_email):Observable<any>
  {
    let formData: FormData = new FormData();
     formData.append('branch_email', branch_email);
    formData.append('api_token',this.getApiToken());
   return this.http.post(App_Url+`api/checkBranchEmail`,formData);

  }

  getApiToken(){
   this.myItem = JSON.parse(localStorage.getItem(this.key));
   var api_token = this.myItem.tokenKey;
   return api_token ;
  }
}
