import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LeaveComponent } from "../leave/leave.component";
import { AddLeaveComponent } from "../leave/add-leave/add-leave.component";
import { ClientListComponent } from './client-list/client-list.component';



const routes: Routes = [
  {
    path: '',  
    
    children: [   
      {
        path: 'leave',
        component: LeaveComponent,
        data: {
          title: 'leave'
        }
      },
      {
        path: 'leave/:id',
        component: LeaveComponent,
        data: {
          title: 'leave'
        }
      },
      {
        path: 'addleave',
        component: AddLeaveComponent,
        data: {
          title: 'AddLeave'
        }
      },
      {
        path: 'addleave/:id',
        component: AddLeaveComponent,
        data: {
          title: 'AddLeave'
        }
      },
      {
        path: 'client_list',
        component: ClientListComponent,
        data: {
          title: 'AddLeave'
        }
      },
     
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LeaveRoutingModule { }

export const LeaveComponentList = [
  LeaveComponent,
  AddLeaveComponent,
  ClientListComponent
  
];

