import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params  } from "@angular/router";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { DsrDemoModel } from "./dsr-demo.model";
import { DsrDemoService } from "./dsr-demo.service";
import { stringify } from 'querystring';

@Component({
  selector: 'app-dsr-demo',
  templateUrl: './dsr-demo.component.html',
  styleUrls: ['./dsr-demo.component.scss']
})
export class DsrDemoComponent implements OnInit {
  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;
  myForm:FormGroup;
  item:DsrDemoModel = new DsrDemoModel({});


  constructor(private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private dsrService : DsrDemoService) { }

    public editStatus = true;
    public key ='loginData';
    public myItem:any;
   


    backClicked() {
      this.location.back();
     } 

  ngOnInit() {
    

    this.route.params.subscribe(
      (params: Params) => {        
       this.item.userId=params.id;
      
      }
    );



    this.myForm = new FormGroup({
      'clientName': new FormControl(null, [Validators.required]),
      'activationNo': new FormControl(null, [Validators.required]),
      'planSchemeName': new FormControl(null, [Validators.required]),
      'noOfActivation': new FormControl(null, [Validators.required]),
      'inrPlanScheme': new FormControl(null, [Validators.required]),
      'totalAmount': new FormControl(null, [Validators.required]),
      'documentCollected': new FormControl(null, [Validators.required]),
      
     
  }, {updateOn: 'change'});
  }

  public onSubmit()
  { 
   // data.created_by=this.myItem.id;
    //data.company_id= this.myItem.company_id;



    let formData: FormData = new FormData();
    formData.append('api_token','59240171-6f5c-42e3-8d87-0044ac120184');
    formData.append('id',String(this.item.id));
    formData.append('userId',String(this.item.userId));
    formData.append('clientName',this.item.clientName);
    formData.append('activationNo',String(this.item.activationNo));
    formData.append('planSchemeName',this.item.planSchemeName);
    formData.append('noOfActivation',String(this.item.noOfActivation));
    formData.append('inrPlanScheme',String(this.item.inrPlanScheme));
    formData.append('totalAmount',String(this.item.totalAmount));
    formData.append('documentCollected',String(this.item.documentCollected));
   
   
   // if(this.departments.id == 0)
    //{
    this.dsrService.add(formData).subscribe(resp=>{
      if(resp.success) { 
        this.myForm.reset(); 
        this.item.documentCollected=0;
        }
     }); 
   // }
    //  else
    //  {
    //   this.dsrService.update(formData).subscribe(resp=>{
    //     if(resp.success) { 
    //       this.router.navigate(['admin/departments']);
    //       }
    //       else{
    //           this.router.navigate(['departments/adddepartments']);
    //       }
    //    });
    //  }
   }
  
  //  getData(id : number)
  //  {
  //   this.dsrService.getItem(id).subscribe(
  //     resp=>{
  //       this.departments = resp.items;
  //     }
  //   );
  //  }
  


}
