import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PayrollRelationsComponent } from "./payroll-relations.component";




const routes: Routes = [
  {
    path: '',  
    
    children: [   
  
      {
        path: 'payroll-relations',
        component: PayrollRelationsComponent,
        data: {
          title: 'module-permission'
        }
      },
     
     
    //  for subclient
      {
        path: 'payroll-relations/:id',
        component: PayrollRelationsComponent,
        data: {
          title: 'module-permission'
        }
       },
     
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PayrollRelationsRoutingModule { }

export const PayrollRelationsComponentList = [
  PayrollRelationsComponent
];

