import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManagerComponent } from "./manager.component";
import { AddManagerComponent } from "./add-manager/add-manager.component";




const routes: Routes = [
  {
    path: '',  
    
    children: [   
        { path: 'manager', component: ManagerComponent},
        { path: 'manager/:id', component: ManagerComponent},
        { path: 'addmanager', component: AddManagerComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManagerRoutingModule { }

export const ManagerComponentList = [
  ManagerComponent,
  AddManagerComponent

];

