import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SalaryslipTemplateListComponent } from "./salaryslip-template-list.component";
import { AddSalaryslipTemplateComponent } from './add-salaryslip-template/add-salaryslip-template.component';
import { SalaryslipTemplate1Component } from "./salaryslip-template1/salaryslip-template1.component";
import { SalaryslipTemplate2Component } from "./salaryslip-template2/salaryslip-template2.component";
import { AddBulkSalaryComponent } from "./add-bulk-salary/add-bulk-salary.component";
import { SalaryslipListComponent } from './salaryslip-list/salaryslip-list.component';
import { SalaryDataComponent } from "./salary-data/salary-data.component";

const routes: Routes = [
  {
    path: '',  
    
    children: [   
      {
        path: 'salaryslip_template_list',
        component: SalaryslipTemplateListComponent,
        data: {
          title: 'salary slip list'
        }
      },
      {
        path: 'add_salaryslip_template',
        component: AddSalaryslipTemplateComponent,
        data: {
          title: 'salary slip add'
        }
      },
      {
        path: 'add_salaryslip_template/:id',
        component: AddSalaryslipTemplateComponent,
        data: {
          title: 'salary slip edit'
        }
      },
      {
        path: 'salaryslip_template1',
        component: SalaryslipTemplate1Component,
        data: {
          title: 'salaryslip template1'
        }
      },
      {
        path: 'salaryslip_template1/:id',
        component: SalaryslipTemplate1Component,
        data: {
          title: 'salaryslip template1'
        }
      },
      {
        path: 'salaryslip_template2',
        component: SalaryslipTemplate2Component,
        data: {
          title: 'salaryslip template2'
        }
      },
      {
        path: 'salaryslip_template2/:id',
        component: SalaryslipTemplate2Component,
        data: {
          title: 'salaryslip template2'
        }
      },
      {
        path: 'add-bulk-salary',
        component: AddBulkSalaryComponent,
        data: {
          title: 'add bulk salary'
        }
      },
      {
        path: 'add-bulk-salary/:id',
        component: AddBulkSalaryComponent,
        data: {
          title: 'add bulk salary'
        }
      },
      {
        path: 'add-salary-data',
        component: SalaryDataComponent,
        data: {
          title: 'add salary data'
        }
      },
      {
        path: 'add-salary-data/:id',
        component: SalaryDataComponent,
        data: {
          title: 'add salary data'
        }
      },
      {
        path: 'salaryslip-list/:id',
        component: SalaryslipListComponent,
        data: {
          title: 'salaryslip list'
        }
      },
      {
        path: 'salaryslip-list',
        component: SalaryslipListComponent,
        data: {
          title: 'salaryslip list'
        }
      },
       
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SalaryslipTemplateRoutingModule { }

export const SalaryslipTemplateComponentList = [
  SalaryslipListComponent,
  SalaryslipTemplateListComponent,
  AddSalaryslipTemplateComponent,
  SalaryslipTemplate1Component,
  SalaryslipTemplate2Component,
  AddBulkSalaryComponent,
  SalaryDataComponent
];

