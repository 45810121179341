import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params  } from "@angular/router";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { DesignationModel } from "../designation.model";
import { DesignationService } from "../designation.service";
import { stringify } from 'querystring';

@Component({
  selector: 'app-add-designation',
  templateUrl: './add-designation.component.html',
  styleUrls: ['./add-designation.component.scss']
})
export class AddDesignationComponent implements OnInit {
  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;
  myForm:FormGroup;
  designation:DesignationModel = new DesignationModel({});

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private designationService : DesignationService
  ) { }

   public editStatus = true;
    public key ='loginData';
    public myItem:any;
    public designation_status;
    public companyId;


    backClicked() {
      this.location.back();
     } 


  ngOnInit() {

    this.myItem = JSON.parse(localStorage.getItem(this.key));

    this.route.params.subscribe(
      (params: Params) => {
        if (params.id) {
          this.editStatus = true;
        this.getData(params.id);
        } else {
          this.editStatus = false;
        }

        if(params.companyId){
          this.companyId = params.companyId;
        }
        else
        {
          this.companyId= this.myItem.company_id;
        }
      }
    );

    this.myForm = new FormGroup({
      'designation_name': new FormControl('', [Validators.required,Validators.pattern('^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$%^&*!#-/+_=]*)?$')]),
      'status': new FormControl(1),
      'id': new FormControl(0),
      'counts': new FormControl(0),
      'company_id': new FormControl(''),
     
  }, {updateOn: 'change'});
  }
  
  public onSubmit()
  { 
   
    let formData: FormData = new FormData();
    formData.append('created_by',this.myItem.id);
    formData.append('status', this.myForm.get('status').value);
    formData.append('company_id', this.companyId);
    if(this.myForm.get('id').value !=0) {
      formData.append('company_id', this.myForm.get('company_id').value);
    }
    formData.append('designation_name',this.myForm.get('designation_name').value);
    formData.append('id',this.myForm.get('id').value);
                   
    if( this.myForm.get('id').value == 0)
    {
      this.designationService.add(formData).subscribe(resp=>{
        if(resp.success) { 
          this.designationService.insertSuccess();
          this.location.back();
          }
          else{
            this.designationService.sameDesignationName();
            //this.router.navigate(['designation/adddesignation']);
          }
      }); 
    }      
    else
    {
      this.designationService.update(formData).subscribe(resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        if(resp.success) { 
          this.designationService.updateSuccess();
          this.location.back();
        }
        else {
          this.router.navigate(['designation/adddesignation']);
        }
      });
    }
 }


 getData(id : number)
 {
  this.designationService.getItem(id).subscribe(
    resp=>{
        if(resp.status == 401)
        {
            this.router.navigate([this.myItem.url]);
        }
        // this.designation = resp.items;
        this.myForm.get('id').setValue(resp.items.id);
        this.myForm.get('status').setValue(resp.items.status);
        this.myForm.get('designation_name').setValue(resp.items.designation_name);
        this.myForm.get('counts').setValue(resp.items.counts);
        this.myForm.get('company_id').setValue(resp.items.companyId);
        });
 }
}
