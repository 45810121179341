import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
//import { BrowserModule } from '@angular/platform-browser';

import { ChartistModule } from 'ng-chartist';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchHeightModule } from "../shared/directives/match-height.directive";

import { SalaryslipTemplateRoutingModule ,SalaryslipTemplateComponentList } from "./salaryslip-template-routing.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { DataTablesModule } from 'angular-datatables';
import { SalaryslipTemplate2Component } from './salaryslip-template2/salaryslip-template2.component';
import { BnNgPdfViewerModule } from 'bn-ng-pdf-viewer';
import { NgxLoadingModule } from 'ngx-loading';

//import { EmployeeModule } from '../employee/employee.module';

@NgModule({
    imports: [
        CommonModule,
        SalaryslipTemplateRoutingModule,
        ChartistModule,
        NgbModule,
        MatchHeightModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgxDatatableModule,
        Ng2SmartTableModule,
        DataTablesModule,
        BnNgPdfViewerModule,
        //BrowserModule
      //  EmployeeModule
        NgxLoadingModule.forRoot({})
    ],   
    exports: [],
    declarations: [
        SalaryslipTemplateComponentList,
        SalaryslipTemplate2Component,
        
      
        
    ],  
    providers: [], 
})
export class SalaryslipTemplateModule { }
