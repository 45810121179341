import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { EmployeeModel } from "../../employee/employee.model";
import { SalaryslipTemplateService } from "../salaryslip-template.service";
import { take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { FileSelectDirective, FileDropDirective, FileUploader, FileLikeObject } from 'ng2-file-upload';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

function readBase64(file): Promise<any> {
  var reader = new FileReader();
  var future = new Promise((resolve, reject) => {
    reader.addEventListener("load", function () {
      resolve(reader.result);
    }, false);
    reader.addEventListener("error", function (event) {
      reject(event);
    },
      false);
    reader.readAsDataURL(file);
  });
  return future;
}

const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';
const image_url = 'http://localhost:4200/assets/img/logos/';

@Component({
  selector: 'add-bulk-salary',
  templateUrl: './add-bulk-salary.component.html',
  styleUrls: ['./add-bulk-salary.component.scss']
})
export class AddBulkSalaryComponent implements OnInit {
  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;
  @ViewChild("fileInput") fileInput;
  @ViewChild('salarySlipPreviewModal') private salarySlipPreviewModal;

  myForm: FormGroup;
  fileForm: FormGroup;
  //employee:EmployeeModel = new EmployeeModel({});
  //:EmployeeModel = new EmployeeModel({});


  public editStatus = true;
  public key = 'loginData';
  public myItem: any;
  public department;
  public company_id;
  public flag;
  public today;
  public status = true;
  public designation;
  public selectedFile: File = null;
  public selectedFileView: any = 'assets/img/drag-drop-img.png';
  public uploader: FileUploader = new FileUploader({ url: URL, disableMultipart: true });
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;
  public employeeStatus = false;
  public goodEntries;
  public badEntries;
  public saveButtonStatus = false;
  public loading = false;
  public customLoadingTemplate;
  public previewStatus = false;
  public src;
  public salarySlipPreviewModalRef;


  constructor(private salaryslipTemplateService: SalaryslipTemplateService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private modalService: NgbModal, ) { }

  backClicked() {
    this.location.back();
  }

  ngOnInit() {
    this.goodEntries = null;
    this.badEntries = null;
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    this.route.params.subscribe(
      (params: Params) => {

        if (params.id) {
          this.company_id = params.id;
        }
        else {
          this.company_id = this.myItem.company_id;
        }
      }
    );

    this.myForm = new FormGroup({
      'department': new FormControl(null),
      'designation': new FormControl(null),
    }
      , { updateOn: 'change' });



    this.fileForm = new FormGroup({
      'fileInput': new FormControl(null, [Validators.required])
    }, { updateOn: 'change' });

    // fetch department

    this.checkSalaryslipFileExistsOrNot();
  }




  onFileChange(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  public onFileSelected(event: EventEmitter<File[]>) {

    this.selectedFile = null;

    this.selectedFileView = 'assets/img/drag-drop-img.png';
    const file: File = event[0];
    var ext = this.getFileExtension(file.name)

    if (ext == 'csv' || ext == 'text') {
      this.selectedFile = file;
      this.selectedFileView = 'assets/img/csv.png';
    }


  }

  public getFileExtension(filename) {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
  }


  public uploadCsvFile() {
    this.salaryslipTemplateService.uploadSalaryCsvFile(this.selectedFile).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        if (resp.items.goodEntries.length > 0) {
          this.goodEntries = resp.items.goodEntries;
          //this.saveButtonStatus = true;
          this.goodEntries.filter(element => {
            element.description = '';
          });
          // console.log(this.employee);
          this.myForm = new FormGroup(
            this.getObject(this.goodEntries)
            , { updateOn: 'change' });
        }
        else {
          this.goodEntries = null;
        }

        if (resp.items.badEntries.length > 0)
          this.badEntries = resp.items.badEntries;
        else {
          this.badEntries = null;
        }

        this.selectedFile = null;
        this.selectedFileView = 'assets/img/drag-drop-img.png';
        this.fileForm.reset();

      } else {

        // console.log(this.badEmployee);
      }
    });

  }

  public saveData() {
    this.loading = true;
    this.salaryslipTemplateService.addBulkSalary(this.goodEntries).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.salaryslipTemplateService.insertSuccess();
        this.selectedFile = null;
        this.selectedFileView = 'assets/img/drag-drop-img.png';
        this.goodEntries = null;
        this.badEntries = null;
        this.loading = false;

      } else {
        //this.employeeService.csvError();
      }
    });
  }

  public clearCsvFile() {
    console.log('here');
    this.selectedFile = null;
    this.selectedFileView = 'assets/img/drag-drop-img.png';
    this.goodEntries = null;
    this.badEntries = null;
    this.fileForm.reset();
    this.myForm.reset();
  }

  public getObject(data) {
    var val = {};
    for (let i = 0; i < data.length; i++) {
      val['department_' + i] = new FormControl(null, [Validators.required]),
        val['designation_' + i] = new FormControl(null, [Validators.required])
    }
    return val;
  }

  public salarySlipPreview() {
    this.loading = true;
    this.salaryslipTemplateService.salarySlipPreview(this.company_id).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.loading = false;
        this.src = resp.items;
        this.salarySlipPreviewModalRef = this.modalService.open(this.salarySlipPreviewModal, { size: 'lg' });
      }
    });
  }

  public checkSalaryslipFileExistsOrNot() {
    this.salaryslipTemplateService.checkSalaryslipFileExistsOrNot(this.company_id).subscribe(resp => {
      if (resp.status == 401) {
        this.router.navigate([this.myItem.url]);
      }
      if (resp.success) {
        this.previewStatus = true;
        this.src = resp.items;
      } else {
        this.previewStatus = false;
      }
    });
  }


  public addDescription(e, val, flag) {

    if (flag == 'addAll') {
      this.goodEntries.filter(element => {
        element.description = e.target.value;
      });
    } else {
      this.goodEntries.forEach((value, index) => {
       if(index == val){
        value.description = e.target.value;
       }
      });
    }

   let data = this.goodEntries.find(element=>element.description =='');
    if(data == undefined){
      this.saveButtonStatus = true;
    } else{
      this.saveButtonStatus = false;
    }
  }

}
