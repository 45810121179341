import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { App_Url } from '../../globals';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})


export class ProjectsService {

  constructor(private http: HttpClient, public toastr: ToastrService) { }
  public key = 'loginData';
  public myItem: any;

  public add(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/addProject`, formData);
  }

  public update(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/addProject`, formData);
  }
  public getProjectList(companyId, roleId): Observable<any> {

    let formData: FormData = new FormData();
    formData.append('companyId', companyId);
    formData.append('roleId', roleId);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/getProjectWithCompany`, formData);
  }

  public delete(id): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/deleteProject`, formData);
  }

  public insertAdditionalProjectCost(formData: any): Observable<any> 
  {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/insert_additional_cost`, formData);
  }

  public getProjectAdditionalCost(id):Observable<any> {
    let formData: FormData = new FormData();
    formData.append('project_id', id);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/get_additional_cost`, formData);
  }

  public deleteAdditionalCost(id): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/delete_additional_cost`, formData);
  }

  public getProjectCost(id):Observable<any> {
    let formData: FormData = new FormData();
    formData.append('project_id', id);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/get_project_cost`, formData);    
  }

  public insertProjectCost(formData: any): Observable<any> 
  {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/insert_project_cost`, formData);
  }

  public deleteProjectCost(id): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/v2/delete_project_cost`, formData);
  }  


  insertSuccess() {
    this.toastr.success('Project has been inserted successfully', 'Success!');
  }

  updateSuccess() {
    this.toastr.success('Project has been updated successfully', 'Success!');
  }
  deleteSuccess() {
    this.toastr.success('Project has been deleted successfully', 'Success!');
  }
  alreadyExists() {
    this.toastr.error('', 'Project already exists', { "timeOut": 1000 });
  }

  getApiToken() {
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    var api_token = this.myItem.tokenKey;
    return api_token;
  }
}
