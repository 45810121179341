import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { FileSelectDirective, FileDropDirective, FileUploader, FileLikeObject } from 'ng2-file-upload';
import { stringify } from '../../../../node_modules/@angular/compiler/src/util';
import { logo_url } from '../../globals';
import { BranchService } from '../branch.service';
import { ClientService } from '../../client/client.service';


const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

function readBase64(file): Promise<any> {
  var reader = new FileReader();
  var future = new Promise((resolve, reject) => {
    reader.addEventListener("load", function () {
      resolve(reader.result);
    }, false);
    reader.addEventListener("error", function (event) {
      reject(event);
    }, false);
    reader.readAsDataURL(file);
  });
  return future;
}

@Component({
  selector: 'app-add-branch',
  templateUrl: './add-branch.component.html',
  styleUrls: ['./add-branch.component.scss']
})
export class AddBranchComponent implements OnInit {
  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;

  public key = 'loginData';
  public myItem: any;
  public selectedFile: File = null;
  public selectedFileView: any;
  public uploader: FileUploader = new FileUploader({ url: URL, disableMultipart: true });
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;
  public parent_id;
  public status = true;
  public emailStatus = false;
  public weekDays: any = [];
  public weekDaysObj: any = [];
  public selectedWeekDays: any = [];
  public companyId;
  public branchId;
  public editStatus = false;
  public time_zone_list: any = [];

  constructor(private branchService: BranchService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private clientService:ClientService) { }
    myForm: FormGroup;

  ngOnInit() {
    this.selectedFileView = logo_url + 'logo-default.png';
    this.myItem = JSON.parse(localStorage.getItem(this.key));

    this.route.params.subscribe(
      (params: Params) => {
        
        this.branchId = params.branchId;
        this.companyId = params.companyId;

        if(this.branchId !='undefined'){
          this.getData();
          this.editStatus=true;
        }
        
       });


       this.weekDaysObj = [
        { 'day': 'Monday','value':'1.0','isSelected': 0, 'weeknumbers':[
            { 'value':1,  'isSelected': 0 },
            { 'value':2,  'isSelected': 0 },
            { 'value':3,  'isSelected': 0 },
            { 'value':4,  'isSelected': 0 },
            { 'value':5,  'isSelected': 0 },
          ]
        },
        { 'day': 'Tuesday','value':'2.0','isSelected': 0, 'weeknumbers':[
          { 'value':1,  'isSelected': 0 },
          { 'value':2,  'isSelected': 0 },
          { 'value':3,  'isSelected': 0 },
          { 'value':4,  'isSelected': 0 },
          { 'value':5,  'isSelected': 0 },
        ] },
        { 'day': 'Wednesday','value':'3.0','isSelected': 0, 'weeknumbers':[
          { 'value':1,  'isSelected': 0 },
          { 'value':2,  'isSelected': 0 },
          { 'value':3,  'isSelected': 0 },
          { 'value':4,  'isSelected': 0 },
          { 'value':5,  'isSelected': 0 },
        ] },
        { 'day': 'Thursday','value':'4.0','isSelected': 0, 'weeknumbers':[
          { 'value':1,  'isSelected': 0 },
          { 'value':2,  'isSelected': 0 },
          { 'value':3,  'isSelected': 0 },
          { 'value':4,  'isSelected': 0 },
          { 'value':5,  'isSelected': 0 },
        ] },
        { 'day': 'Friday','value':'5.0','isSelected': 0, 'weeknumbers':[
          { 'value':1,  'isSelected': 0 },
          { 'value':2,  'isSelected': 0 },
          { 'value':3,  'isSelected': 0 },
          { 'value':4,  'isSelected': 0 },
          { 'value':5,  'isSelected': 0 },
        ] },
        { 'day': 'Saturday','value':'6.0','isSelected': 0, 'weeknumbers':[
          { 'value':1,  'isSelected': 0 },
          { 'value':2,  'isSelected': 0 },
          { 'value':3,  'isSelected': 0 },
          { 'value':4,  'isSelected': 0 },
          { 'value':5,  'isSelected': 0 },
        ] },
        { 'day': 'Sunday','value':'7.0','isSelected': 0, 'weeknumbers':[
            { 'value':1,  'isSelected': 0 },
            { 'value':2,  'isSelected': 0 },
            { 'value':3,  'isSelected': 0 },
            { 'value':4,  'isSelected': 0 },
            { 'value':5,  'isSelected': 0 },
          ] },
      ];
  

    this.weekDays = [
      { 'day': 'Monday', 'isSelected': 0 },
      { 'day': 'Tuesday', 'isSelected': 0 },
      { 'day': 'Wednesday', 'isSelected': 0 },
      { 'day': 'Thursday', 'isSelected': 0 },
      { 'day': 'Friday', 'isSelected': 0 },
      { 'day': 'Saturday', 'isSelected': 0 },
      { 'day': 'Sunday', 'isSelected': 0 },
    ];

    this.myForm = new FormGroup({
      'branch_name': new FormControl(null, [Validators.required]),
      'branch_location': new FormControl(null, [Validators.required]),
      'branch_address': new FormControl(null, [Validators.required]),
      'branch_email': new FormControl(null, [Validators.required, Validators.email]),
      'branch_number': new FormControl(null, [Validators.required]),
      'branch_logo': new FormControl(null, []),
      'fullday_working_hours': new FormControl(null, []),
      'halfday_working_hours': new FormControl(null, []),
      'weekend_days': new FormControl(null, []),
      'weekend_days_obj': new FormControl(null, []),
      'id': new FormControl('0', []),
      'companyId': new FormControl(null, []),
      'created_by': new FormControl(null, []),
      'time_zone_id': new FormControl(''),
      

    }, { updateOn: 'change' });

    this.getTimeZone();
  }

  public onSubmit() {

    this.myForm.get('created_by').setValue(this.myItem.id);
    this.myForm.get('companyId').setValue(this.companyId);
    this.myForm.get('weekend_days').setValue(this.selectedWeekDays.toString());
    this.myForm.get('weekend_days_obj').setValue(this.weekDaysObj);
    let formData: FormData = new FormData();
    formData.append('data', JSON.stringify(this.myForm.value));
    formData.append('logo', this.selectedFile);

    if ( this.myForm.get('id').value == 0) {
      this.branchService.add(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.branchService.insertSuccess();
          this.location.back();
        }
        else {
          this.router.navigate(['branch/add-branch',this.companyId]);
        }
      });
    }
    else {
      this.branchService.update(formData).subscribe(resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.branchService.updateSuccess();
          this.location.back();
        }
        else {
          this.router.navigate(['branch/add-branch',this.companyId]);
        }
      });
    }
  }

  getData() {
    this.branchService.getItem(this.branchId).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
         if(resp.success){
          this.myForm.get('id').setValue(resp.items.id);
          this.myForm.get('companyId').setValue(resp.items.company_id);
          this.myForm.get('branch_name').setValue(resp.items.branch_name);
          this.myForm.get('branch_location').setValue(resp.items.branch_location);
          this.myForm.get('branch_address').setValue(resp.items.branch_address);
          this.myForm.get('branch_email').setValue(resp.items.branch_email);
          this.myForm.get('branch_number').setValue(resp.items.branch_number);
          this.myForm.get('fullday_working_hours').setValue(resp.items.fullday_working_hours);
          this.myForm.get('halfday_working_hours').setValue(resp.items.halfday_working_hours);
          this.myForm.get('time_zone_id').setValue(resp.items.time_zone_id);

          if (resp.items.weekend_days != ''  && resp.items.weekend_days != null) {
            this.selectedWeekDays = resp.items.weekend_days.split(',');
          }

          this.weekDaysObj=resp.items.weekend_days_obj;

          if (resp.items.company_logo) {
            this.selectedFileView = resp.items.company_logo;
          }
        }
      }
    );
  }

  onFileChange(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
    this.selectedFile = file;
    //console.log(file);   
    readBase64(file).then(function (data) {
      //this.selectedFileView = data;   
      $('#selectedFileView').attr('src', data);
      //console.log(data);  
      //console.log(this.selectedFileView); 
    });
  }


  public checkEmail() {
    this.branchService.checkBranchEmail(this.myForm.get('branch_email').value).subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.myForm.get('branch_email').setValue('');
          this.emailStatus = true;
        }
        else {
          this.emailStatus = false;
        }
      }
    );
  }

  public updateWeekDays(e, i) {
    var index = this.selectedWeekDays.indexOf(e.target.value);
    if (e.target.checked) {
      if (index < 0) {
        this.selectedWeekDays.push(e.target.value);
      }
    } else {
      this.selectedWeekDays.splice(index, 1);
    }
  }

  public getSelectedWeekday(day) {
    if (this.selectedWeekDays.indexOf(day) < 0) {
      return false;
    } else {
      return true;
    }
  }

  backClicked() {
    this.location.back();
  }

  public updateStatus(obj,event,i){
    console.log(event.target.checked);
    if(event.target.checked)
    {
      console.log('checked');
      this.weekDaysObj.forEach(element => {
       
        if(element.value == obj.value){
          element.isSelected=1;
          element.weeknumbers.forEach(ele => {
            ele.isSelected=1;            
          });
        }
     });
    }else
    {
      console.log('un checked');
      this.weekDaysObj.forEach(element => {
        if(element.value == obj.value){
          element.weeknumbers.forEach(ele => {
            ele.isSelected = 0;            
          });
        }
     });

    }
    console.log(this.weekDaysObj);
  }

  public singleUpdate(obj,event,i,j,mainobj){
      var chk = true;
      if(event.target.checked){                
        this.weekDaysObj[i].weeknumbers[j].isSelected = 1;          
      }else
      {         
        this.weekDaysObj[i].weeknumbers[j].isSelected = 0;         
      } 

      this.weekDaysObj[i].weeknumbers.forEach(element => {         
            if(element.isSelected == 0){ chk = false; }
      });

      if(chk){
        this.weekDaysObj[i].isSelected = 1;
      }else
      {
        this.weekDaysObj[i].isSelected = 0;
      }    
  };

  public getTimeZone() {
    this.clientService.getTimeZone().subscribe(
      resp => {
        if (resp.status == 401) {
          this.router.navigate([this.myItem.url]);
        }
        if (resp.success) {
          this.time_zone_list = resp.items;
        }
      }
    );
  }

}
