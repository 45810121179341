import { Component, OnInit, ViewChild } from '@angular/core';
import { ModuleService } from "../module.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SidebarService } from '../../shared/sidebar/sidebar.service';


@Component({
  selector: 'app-role-module-relation',
  templateUrl: './role-module-relation.component.html',
  styleUrls: ['./role-module-relation.component.scss']
})
export class RoleModuleRelationComponent implements OnInit {
  @ViewChild('relationModal') private relationModal;

  public role_module_relation: any = [];
  public data: any[];
  public relationModelRef;
  public moduleList: any = [];
  public roleId: any;
  public moduleId: number = 0;
  public key = 'loginData';
  public key1 = 'permission';

  public myItem:any;
  public permission;

  constructor(private moduleService: ModuleService,
    private modalService: NgbModal,
    private sidebarService : SidebarService,
  ) { }

  ngOnInit() {
    this.getRoleModuleRelation();
    this.permission= this.sidebarService.getCurrentPermission();
    this.myItem = JSON.parse(localStorage.getItem(this.key));
  }

  public getRoleModuleRelation() {
    this.moduleService.getRoleModuleRelation().subscribe(resp => {
      if (resp.success) {
        this.role_module_relation = resp.items;
        this.data = [
          { 'roleId': 1, 'roleName': 'Super Admin', 'module': this.role_module_relation.filter(ele => ele.roleId == 1) },
          { 'roleId': 2, 'roleName': 'Client Admin', 'module': this.role_module_relation.filter(ele => ele.roleId == 2) },
          { 'roleId': 3, 'roleName': 'Customer Admin', 'module': this.role_module_relation.filter(ele => ele.roleId == 3) },
          { 'roleId': 4, 'roleName': 'Manager', 'module': this.role_module_relation.filter(ele => ele.roleId == 4) },
          { 'roleId': 5, 'roleName': 'Employee', 'module': this.role_module_relation.filter(ele => ele.roleId == 5) }
        ];
      }
    });
  }

  public addModuleRoleRelation(roleId) {
    this.roleId = roleId;
    this.moduleService.getdata().subscribe(resp => {
      if (resp.success) {
        this.moduleList = resp.items;
        this.relationModelRef = this.modalService.open(this.relationModal);
      }
    });
  }

  public onSubmit() {
    let formData: FormData = new FormData();
    formData.append('roleId', this.roleId);
    formData.append('moduleId', this.moduleId.toString());
    formData.append('createdBy', this.myItem.id);
    this.moduleService.storeRoleModuleRelation(formData).subscribe(resp => {
      if (resp.success) {
        this.moduleService.insertRoleModuleRelation();
       this.getRoleModuleRelation();
        this.relationModelRef.dismiss();
        this.moduleId =0;
      } else {
        this.moduleService.alreadyExists();
      }
    });
  }

 


}
