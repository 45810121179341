import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { BrowserModule } from '@angular/platform-browser';

import { ChartistModule } from 'ng-chartist';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchHeightModule } from "../shared/directives/match-height.directive";

import { ClientRoutingModule ,ClientComponentList } from "./client-routing.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { DataTablesModule } from 'angular-datatables';
import { ManagerModule } from "../manager/manager.module";
import { CustomerModule } from "../customer/customer.module"
import { ModulePermissionModule } from "../module-permission/module-permission.module";
import {PayrollRelationsModule } from "../payroll-relations/payroll-relations.module";
import { DepartmentsModule } from "../departments/departments.module";
import { DesignationModule } from "../designation/designation.module";
import {ClientHeaderModule} from '../client-header/client-header.module';
//import { EmployeeModule } from '../employee/employee.module';
///import { SalaryslipTemplateModule } from '../salaryslip-template/salaryslip-template.module';
import { PermissionModule } from "../permission/permission.module";
//import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { GeneralInformationComponent } from '../shared/generalInformation/generalInformation.component';
import { SharedModule } from '../shared/shared.module';
import {ManageHolidaysModule} from '../manage-holidays/manage-holidays.module';
import {BranchModule} from '../branch/branch.module';
import { UiSwitchModule } from 'ngx-ui-switch';

@NgModule({
    imports: [
        CommonModule,
        ClientRoutingModule,
        ChartistModule,
        NgbModule,
        MatchHeightModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgxDatatableModule,
        Ng2SmartTableModule,
        DataTablesModule,
        ManagerModule,
        CustomerModule,
        DepartmentsModule,
        DesignationModule,
        ModulePermissionModule,
        PayrollRelationsModule,
        ClientHeaderModule,
        //SalaryslipTemplateModule,
        PermissionModule,
        BranchModule,
     //   BsDatepickerModule.forRoot(),
      //  EmployeeModule
        SharedModule,
        ManageHolidaysModule,
        UiSwitchModule
      
    ],   
    exports: [],
    declarations: [
        ClientComponentList
      
        
    ],  
    providers: [], 
})
export class ClientModule { }
