import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ModuleModel } from "./module.model";
import 'rxjs/add/operator/toPromise';
import { App_Url } from '../globals';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  constructor(private http: HttpClient, public toastr: ToastrService) { }
  public key = 'loginData';
  public myItem: any;

  public add(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/module-add`, formData);
  }

  public update(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/module-update`, formData);
  }


  public getdata(): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/module`, formData);
  }

  public getItem(id): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/module-single`, formData);
  }

  public delete(id): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('id', id);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/module-delete`, formData);
  }

  public checkModule(name): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('name', name);
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/checkModule`, formData);
  }

  public getRoleModuleRelation(): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/get_role_module_relation`, formData);
  }

  public storeRoleModuleRelation(formData: any): Observable<any> {
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url + `api/insert_role_module_relation`, formData);
  }

  insertSuccess() {
    this.toastr.success('Module has been inserted successfully!', 'Success!');
  }

  updateSuccess() {
    this.toastr.success('Module has been updated successfully!', 'Success!');
  }

  deleteSuccess() {
    this.toastr.success('Module has been deleted successfully!', 'Success!');
  }
  sameModuleName() {
    this.toastr.error('', 'Please Enter Another Modul Name!', { "timeOut": 1000 });
  }

  insertRoleModuleRelation()
  {
    this.toastr.success('Role module relation has been inserted successfully!', 'Success!');
  }

  alreadyExists() {
    this.toastr.error('', 'Relation already exists!', { "timeOut": 1000 });
  }


  getApiToken() {
    //var api_token ='59240171-6f5c-42e3-8d87-0044ac120184';
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    var api_token = this.myItem.tokenKey;
    return api_token;
  }
}
